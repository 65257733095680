import axios from 'axios';

// const BASE_URL = 'http://localhost:4000';
const BASE_URL ='https://server-pg.onrender.com'
// const BASE_URL = 'https://valuationshub-api.onrender.com';

// const baseURL:'https://valuationshub.api.onrender.com'
// origin: ["www.one.com","www.two.com","www.three.com"],
const valuationsWebApi = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  },
  withCredentials: true, // Include credentials (cookies) in the request
});

export default valuationsWebApi;
