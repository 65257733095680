import React, { useState, useContext, useEffect } from 'react';
// Incluir CircularProgress y Squeleton
import { Grid, Paper, Box, CircularProgress, Snackbar, SnackbarContent, useTheme, useMediaQuery, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { LoginContext } from '../../../helpers/Context';
import valuationsWebApi from '../../../services/valuationsWebApi';
import useAxios from '../../../hooks/useAxios';
import useDataHandling from '../../../hooks/useDataHandling';
import useValuation from '../../../hooks/useValuation';

import Disclaimer from '../../../components/Disclaimer';
import TableHistoricalData from '../../Valuations/TableHistoricalData';
import FormAssumptionsSummaryText from './FormAssumptionsSummaryText';
import FormCashFlowAssumptions from './FormCashFlowAssumptions';
import FormBusinesAssumptions from './FormBusinessAssumptions';
import FormCostOfCapitalAssumptions from './FormCostOfCapitalAssumptions';
import NewInfoPanel from './NewInfoPanel';
import TableIncomeStatement from '../../Valuations/TableIncomeStatement';
import TableCashFlow from '../../Valuations/TableCashFlow';
import TableValuation from '../../Valuations/TableValuation';
import ChartFreeCashFlow from '../../Valuations/ChartFreeCashFlow';
import ChartRevenue from '../../Valuations/ChartRevenue';
import CheckboxEstimateJustCashFlow from '../CheckboxEstimateJustCashFlow';
import CheckboxEstimateTtmAsCurrYear from '../CheckboxEstimateTtmAsCurrYear';
import CheckboxFinancialView from '../CheckboxFinancialView';
import CheckboxEstimateCostOfCapital from '../CheckboxEstimateCostOfCapital';
import FormUserCostOfCapital from './FormUserCostOfCapital';
import DialogModal from '../../../components/modals/DialogModal';
import SocialNetworks from '../../../components/SocialNetworks';

const useStyles = makeStyles( (mainTheme) => ({
  contentStyle: {
    position: 'absolute',
    top: '65px',
  },
  paperStyle: {
    width: "100%",   
    marginLeft: "2px",
    marginRight: "2px",
    color: "white",
    backgroundColor: "#f0f8ff",
    padding: "5px",
  },
  boxSelectStyle:{
    height: "30px",
    width: "100%",
  },
  buttonStyle: {
    width:"80px",
    [mainTheme.breakpoints.down('sm')]: {
      fontSize: "10px"
    },
  },
  CompanyInfo: {
    height:"165px",
    marginLeft: "0px",
    marginRight: "0px",
    padding: "10px",
    color: "black",
    backgroundColor: "white",
  },
  TableContainerStyle: {
    width: "100%",
    minHeight: "668px", 
    marginLeft: "3px",
    marginRight: "0px",
    color: "white",
    backgroundColor: "#f0f8ff", 
    padding: "2px",
  },
  textStyle:{
    fontSize:"11px", 
    marginLeft:"5px",
    marginTop:"0px",
    paddingTop:"5px",
    paddingLeft:"5px",
    color:mainTheme.palette.tertiary.main,
  },
  greenSnackbarContent: {
    backgroundColor: "#228B22",
  },
}));

export default function NewValuation(){
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const { userData } = useContext(LoginContext);
  const { userId } = userData;
  const currentYear = new Date().getFullYear();  
  const { axiosFetch: getFinancials, isLoading:isLoadingFinancials, error: isErrorFinancials } = useAxios();        // function to fecth previous years financial data from one specific company
  const { axiosFetch: getAllCompanies, isLoading:isLoadingAllCompanies, error: isErrorAllCompanies } = useAxios();  // function to fecth the list of companies with financial info available
  const { axiosFetch: getCompany, isLoading:isLoadingCompany, error: isErrorCompany } = useAxios();                 // function to fecth the list of companies with financial info available
  const [ companyIdSearch, setCompanyIdSearch ] = useState("");
  const [ companiesList, setCompaniesList ] = useState([]); // List of companies with financial info, to populate the Autocomplete Search
  const [ companyData, setCompanyData] = useState({symbol:"",shortName:"", sharesOustanding: 0, regularMarketPrice:0, fiftyTwoWeekLow:0, fiftyTwoWeekHigh:0, marketCap:0, beta:0, totalCash:0, totalDebt:0 });
  const companySearchName = companyData.symbol !=="" ? (companyData.symbol + " - " + companyData.shortName) : "" ;
  const [ historicalFinancialData, setHistoricalFinancialData ] = useState([]); // Array with fetched financial data from previous years
  const [ assumptions, setAssumptions ] = useState({revenueGrowth:"", marginTarget:"", opexGrowth:"", interestGrowth:"", otherGrowth:"",cashFlowAvgGrowth:"", taxRate:"", capexGrowth:"", nwcGrowth:"", perpetualGrowthRate: 3, cashFlowDiscretePeriod:5, companyBeta:0, riskFreeReturn:4.6, marketReturn:0, equityRiskPremium: 4.4, debtTotalRatio:0, costOfDebt:0, inputedCostOfCapital:9.9, assumptionsText:""});
  const { calcForecastedCashFlow, calcValuation, calcCostOfCapital, isValuationCompleted, checkValuationStatus } = useValuation();
  const { createFinancialHistoricalData, createCombinedData} = useDataHandling();
  const [ isTtmAsCurrYear, setIsTtmAsCurrYear ] = useState(false); 
  const [ isEstimateFcffOnly, setIsEstimateFcffOnly ] = useState(false); //useState(assumptions.casshFlowAvgGrowth !=="" && assumptions.revenueGrowth ===""? true: false );                  // Check if valuation assumptions will be complete or just estimate directly the cash flow growth
  const [ isUserCostOfCapital, setIsUserCostOfCapital] = useState(false);
  const [ isCheckedShowIncStatement, setIsCheckedShowIncStatement] = useState(true);
  const [ isCheckedShowPreviousYears, setIsCheckedShowPreviousYears ] = useState(true);   // Check if previous years will be shown in income statement and cash flow (default)
  const [ isCheckedDescOrder, setIsCheckedDescOrder] = useState(true);                    // Check if numbers will appear in descending order (default)
  const [ isHistoricalTableVisible, setIsHistoricalTableVisible] = useState(true)
  const calculatedCostOfCapital = historicalFinancialData.length > 0 && companyData ? calcCostOfCapital(historicalFinancialData, companyData, assumptions, isUserCostOfCapital): { costOfEquity:0, costOfCapital:0 }  ;
  const forecastedFinancialData = historicalFinancialData.length > 0 ? calcForecastedCashFlow (historicalFinancialData, assumptions, calculatedCostOfCapital, isEstimateFcffOnly): (Array.from({ length: assumptions.cashFlowDiscretePeriod }, (a,b) => ({ year:currentYear + b, period:0, totalRevenue:0, costOfRevenue: 0, grossProfit: 0, grossProfitPercent:0, operatingExpenses: 0, depreciation: 0, interestExpense: 0, other: 0, incomeBeforeTax: 0, incomeTaxExpense: 0, netIncome: 0, ebit: 0, capitalExpenditures: 0, cash: 0, shortLongTermDebt:0, longTermDebt:0, workingCapitalChanges:0, cashFlow:0, discountedCashFlow:0 }))).reverse();
  const combinedFinancialData = historicalFinancialData.length > 0 ? createCombinedData(historicalFinancialData, forecastedFinancialData, isCheckedShowPreviousYears, isCheckedDescOrder): (Array.from({ length: parseInt(assumptions.cashFlowDiscretePeriod) + 3 }, (a,b) => ({ year:currentYear-3 + b, period:0, totalRevenue:0, costOfRevenue: 0, grossProfit: 0, grossProfitPercent:0, operatingExpenses: 0, depreciation: 0, interestExpense: 0, other: 0, incomeBeforeTax: 0, incomeTaxExpense: 0, netIncome: 0, ebit: 0, capitalExpenditures: 0, cash: 0, shortLongTermDebt:0, longTermDebt:0, workingCapitalChanges:0, cashFlow:0, discountedCashFlow:0 }))).reverse();
  const valuation = forecastedFinancialData ? calcValuation(companyData, historicalFinancialData, assumptions, calculatedCostOfCapital, forecastedFinancialData): {valuationId:"", calculatedCashFlowGrowth:0, sumOfCashFlowPresentValue:0, perpetuityValue:0, perpetuityPresentValue:0, enterpriseValue:0, cash:0, debt:0, equityValue:0, sharesOutstanding:0, targetStockPrice:0, dateStockPrice:0,marketCap:0, irr:0, updatedAt:"",published:"", publishedDate:"",lastHistoricalYear:0 } ;
  const [ isDisabledChkBox, setIsDisabledChkBox]= useState(false);
  const [ editMode, setEditMode] = useState("new");
  const [ isSnackbarOpen, setIsSnackbarOpen]=useState(false);
  const [ snackbarMessage, setSnackbarMessage]=useState("");
  const [ isDialogOpen, setIsDialogOpen] = useState(false);
  const [ dialogOptions, setDialogOptions] = useState({severity:"",title:"",message:"",buttons:{}, action:""});
  const [ isBusinessAssumptions, setIsBusinessAssumptions]= useState(false);
  const banksInsuranceTickersList=['AIG','JPM','BAC','SCHW','BK','COF','C','GS','CFG','CMA','FITB','HBAN','KEY','MET','MS','MTB','NTRS','PNC','STT','SYF','TFC','USB','WFC','ZION','PGR','CB','MET','AIG','MMC','ALL','TRV','AFL'];
  
  const handleDialogClose=()=>{
    setIsDialogOpen(false);
  }
  const handleSnackbarClose=()=>{
    setIsSnackbarOpen(false);
  }

  const getAllCompaniesSuccessCb =(apiData)=> {
    const allCompanies = apiData; 
    if (allCompanies) {  
      let searchListCompanies = allCompanies.map(company => ({
        id: company.symbol,
        shortName: company.shortName,
        name: `${company.symbol} - ${company.shortName}`
      }));
      setCompaniesList (searchListCompanies)
    }
  }

  const getAllCompaniesErrorCb=()=>{
    setSnackbarMessage("There was an error accesing the server. Please try later");
    setIsSnackbarOpen(true);
  }

  const getCompanySuccessCb=(apiData)=>{  
    let shares = 0;
    const company = apiData;
    if (company) {
      if (company.marketCap > 0 && company.regularMarketPrice > 0 ) {
        shares = company.marketCap/1000000/company.regularMarketPrice //compare with api value....
      } 
      setCompanyData({symbol:company.symbol, shortName:company.shortName, sharesOutstanding: shares, regularMarketPrice:company.regularMarketPrice, fiftyTwoWeekLow:company.fiftyTwoWeekLow, fiftyTwoWeekHigh:company.fiftyTwoWeekHigh, marketCap:company.marketCap/1000000000, beta:company.beta, totalCash: company.totalCash/1000000000, totalDebt: company.totalDebt/1000000000})
      setAssumptions (prevState => ({...prevState, companyBeta: company.beta, revenueGrowth:"", marginTarget:"", opexGrowth:"", interestGrowth:"", otherGrowth:"",taxRate:"",capexGrowth:"",nwcGrowth:"",cashFlowAvgGrowth:"", cashFlowDiscretePeriod:5 }))
    } 
  }

  const getCompanyErrorCb=()=>{
    setSnackbarMessage("There was an error accesing the server. Please try later");
    setIsSnackbarOpen(true);
  }

  const getFinancialsSuccessCb=(apiData)=>{
    setHistoricalFinancialData(createFinancialHistoricalData ( apiData ));
  }

  const getFinancialsErrorCb=()=>{
    setSnackbarMessage("There was an error accesing the server. Please try later");
    setIsSnackbarOpen(true);
  }

  useEffect(() => {
    const defineMode = isValuationCompleted(assumptions, companyData,isEstimateFcffOnly)? "completed" : "new";
    setEditMode(defineMode);
  }, [assumptions]);

  useEffect(() => {
    if (historicalFinancialData.length > 0){
      // Calculates the cost of debt based on the last annual accrued interest dividend by the stock of debt
      if (Math.abs(historicalFinancialData[0].interestExpense) > 0 && companyData.totalDebt>0){
        const grossCostoOfDebt = Intl.NumberFormat('en-US',{style:'decimal', minimumFractionDigits:2,maximumFractionDigits:2}).format((historicalFinancialData[0].interestExpense/companyData.totalDebt)*-100)
        setAssumptions (prevState => ({...prevState, costOfDebt: grossCostoOfDebt <= 0 || grossCostoOfDebt==undefined ? 3 : grossCostoOfDebt}));
      } else {
        setAssumptions (prevState => ({...prevState, costOfDebt: 4 }));
      }
      if (historicalFinancialData[0].cashFlow < 0){ // if the last cash flow is negative, disable checkbox
          setIsDisabledChkBox(true);
      } else {
          setIsDisabledChkBox(false);
      }
      if (companyIdSearch){
        const isCompanyInList = banksInsuranceTickersList.includes(companyIdSearch);
        if (! isCompanyInList){
          // setSnackbarMessage("Complete de Business and Cost of Capital assumptions")
          // setIsSnackbarOpen(true);
          setIsBusinessAssumptions(true)
          setDialogOptions({severity:"success", title:"Step 2: Business Assumptions", message:"The financial data for the last 3 years of the company has been loaded. You have the option to leverage this historical data as a parameter for future projections or choose not to. Now, feel free to proceed and input your business assumptions for the upcoming years",buttons:{button1:"Ok"}})
          setIsDialogOpen (true);
  
        }
      }

    }
  }, [historicalFinancialData]);

  useEffect(() => {
    if (companyIdSearch){
      const isCompanyInList = banksInsuranceTickersList.includes(companyIdSearch);
      if (isCompanyInList){
        // setSnackbarMessage("valuations ok banks and Insurance companies will be available soon. Please choose another company.")
        // setCompanyIdSearch("");
        setDialogOptions({severity:"warning", title:"Banks and Insurance Valuations", message:"We apologize, but valuations for banks and insurance companies are not currently available. This feature will be coming soon. In the meantime, please feel free to choose another company.",buttons:{button1:"Ok"}})
        setIsDialogOpen (true);
        setHistoricalFinancialData([]);
        // setIsSnackbarOpen(true);
      } else {
        setAssumptions({revenueGrowth:"", marginTarget:"", opexGrowth:"", interestGrowth:"", otherGrowth:"",cashFlowAvgGrowth:"", taxRate:"", capexGrowth:"", nwcGrowth:"", perpetualGrowthRate: 3, cashFlowDiscretePeriod:5, companyBeta:0, riskFreeReturn:4.60, marketReturn:0, equityRiskPremium: 4.7, debtTotalRatio:0, costOfDebt:0, inputedCostOfCapital:9.9, assumptionsText:""})
        getCompany({ axiosInstance: valuationsWebApi, method: 'GET', url: `/companies/${companyIdSearch}`, requestConfig: { headers: {'Authorization': userId,},}},getCompanySuccessCb, getCompanyErrorCb);
        getFinancials({ axiosInstance: valuationsWebApi, method: 'GET', url: `/financials?companyId=${companyIdSearch}`, requestConfig: { headers: {'Authorization': userId,},}},getFinancialsSuccessCb, getFinancialsErrorCb);
        setIsEstimateFcffOnly(false); 
      }
      setAssumptions({revenueGrowth:"", marginTarget:"", opexGrowth:"", interestGrowth:"", otherGrowth:"",cashFlowAvgGrowth:"", taxRate:"", capexGrowth:"", nwcGrowth:"", perpetualGrowthRate: 3, cashFlowDiscretePeriod:5, companyBeta:0, riskFreeReturn:4.60, marketReturn:0, equityRiskPremium: 4.7, debtTotalRatio:0, costOfDebt:0, inputedCostOfCapital:9.9, assumptionsText:""})

    }
  }, [companyIdSearch]);

  useEffect(() => {
    getAllCompanies({ axiosInstance: valuationsWebApi, method: 'GET', url: '/companies', }, getAllCompaniesSuccessCb, getAllCompaniesErrorCb);
    // setSnackbarMessage("Select the company from the list to start the valuation");
    // setIsSnackbarOpen(true);
    setDialogOptions({severity:"success", title:"Step 1: Select a company", message:"Select the company from the list to start the valuation process",buttons:{button1:"Ok"}})
    setIsDialogOpen (true);
  }, []);
  
  return (
    <>
    { ! isLoadingAllCompanies ? 
    <>
    <Grid container direction="column" alignItems="center" style = {{minHeight: '80vh'}}  >
      
      {/* Grid para ocupar el heigth del app bar */}
      <Grid item xs={12} style = {{ minHeight: '69px'}} /> 

      {/* main container grid */}
      <Grid item container direction="row" spacing={1}   >

        { isMobile || isTablet ? // En caso de Mobile/Tablet, pasa al inicio de la pantalla...y no en el centro, como en el desktop
        <>
        <Grid item xs={12} >
        {/* className={classes.paperStyle} */}
          <Paper  elevation={3} > 
            <NewInfoPanel 
              companiesList={companiesList}
              companyIdSearch={companyIdSearch} 
              setCompanyIdSearch={setCompanyIdSearch} 
              companySearchName={companySearchName} 
              companyData={companyData} 
              assumptions={assumptions}
              calculatedCostOfCapital={calculatedCostOfCapital}
              forecastedFinancialData={forecastedFinancialData}
              valuation={valuation}
              editMode={editMode}
              setEditMode={setEditMode}
              isUserCostOfCapital={isUserCostOfCapital}
            />
          </Paper>
        </Grid> 
        </>
        : null }
        {/* isMobile || isTablet ? */}

        {/* left side grid */}
        <Grid item xs={12} sm={6} md={3}  >
          <Paper  elevation={0} className={classes.paperStyle} > 
            <FormAssumptionsSummaryText 
              assumptions={assumptions} 
              setAssumptions={setAssumptions}
            />
            <TableHistoricalData 
              historicalFinancialData={historicalFinancialData} 
              isHistoricalTableVisible={isHistoricalTableVisible}
              setIsHistoricalTableVisible={setIsHistoricalTableVisible}
            />
            { isTablet && ! isMobile ? <Box sx={{height:"8px"}}/>: null}
            <Box sx={{height:"6px"}}/>
            <CheckboxEstimateTtmAsCurrYear 
              isTtmAsCurrYear={isTtmAsCurrYear} 
              setIsTtmAsCurrYear={setIsTtmAsCurrYear}
            />
            <Box sx={{height:"6px"}}/>
            <CheckboxEstimateJustCashFlow 
              isEstimateFcffOnly={isEstimateFcffOnly} 
              setIsEstimateFcffOnly={setIsEstimateFcffOnly} 
              isDisabledChkBox={isDisabledChkBox} 
              isClickable={true}
            />
            {/* { isTablet? <Box sx={{height:"8px"}}/>: null} */}
            <Box sx={{height:"6px"}}/>
            { isEstimateFcffOnly? 
              <FormCashFlowAssumptions 
                assumptions={assumptions} 
                setAssumptions={setAssumptions} 
                calculatedCostOfCapital={calculatedCostOfCapital}
                editMode={editMode}
              /> 
              : 
              <FormBusinesAssumptions // user inputs revenue growth, cost, margins, expenses, etc
                assumptions={assumptions} 
                setAssumptions={setAssumptions} 
                editMode={editMode}
                isBusinessAssumptions={isBusinessAssumptions}
              />
            } 
            {/* isEstimateFcffOnly? */}
            <Box sx={{height:"6px"}}/>
            <CheckboxEstimateCostOfCapital 
              isUserCostOfCapital={isUserCostOfCapital}
              setIsUserCostOfCapital={setIsUserCostOfCapital}
              isDisabledChkBox={false} 
              isClickable={true}
            />
            { isUserCostOfCapital ? 
            <>
              <Box sx={{height:"5px"}}/>
              <FormUserCostOfCapital
                assumptions={assumptions} 
                setAssumptions={setAssumptions}     
              />
            </>
            : 
            <>
              <Box sx={{height:"5px"}}/>
              <FormCostOfCapitalAssumptions // user input risk free rate, cost of debt, etc
                assumptions={assumptions} 
                setAssumptions={setAssumptions} 
                calculatedCostOfCapital={calculatedCostOfCapital}
              />
            </>
            }
            <Box sx={{height:"5px"}}/>
            { ! isMobile && !isTablet ? 
              <Paper sx={{height:"auto"}} elevation={6}>
                <Typography className={classes.textStyle} gutterBottom>Comments from other users about this valuation:</Typography>
                <Typography align="justify" sx={{paddingleft:"2px",paddingRight:"5px"}} className={classes.textStyle} gutterBottom>To enable other users to provide valuable feedback and comments on your valuation, please ensure that your valuation is saved and published.</Typography>
              </Paper>            
            : null}
          </Paper>
        </Grid>
        { isTablet && ! isMobile? 
          <Grid item xs={12} sm={6} md={3}  >
            <Paper className={classes.paperStyle} elevation={0}  > 
            { combinedFinancialData ? 
              <>
              {/* <Box sx={{height:"5px"}}/> */}
              <SocialNetworks />
              <TableValuation 
                companyData={companyData}
                combinedFinancialData={combinedFinancialData} 
                calculatedCostOfCapital={calculatedCostOfCapital} 
                valuation={valuation} 
              />
              <Box sx={{height:"5px"}}/>
              <Paper elevation={3}>
                <ChartFreeCashFlow
                  assumptions={assumptions} 
                  combinedFinancialData={combinedFinancialData} 
                  isCheckedShowPreviousYears={isCheckedShowPreviousYears} 
                  isCheckedDescOrder={isCheckedDescOrder} isEstimateFcffOnly={isEstimateFcffOnly}
                />
              </Paper>
              </>
              : null } 
                  {/* combinedFinancialData ? */}
            </Paper>
          </Grid>
        : null }
            {/* isTablet && ! isMobile? */}

        {/* center grid */}
        <Grid item xs={12} md={6}  >
          <Paper className={classes.paperStyle} elevation={3}  > 
            { ! isMobile && ! isTablet? 
              <>
              <NewInfoPanel 
                companiesList={companiesList}
                companyIdSearch={companyIdSearch} 
                setCompanyIdSearch={setCompanyIdSearch} 
                companySearchName={companySearchName} 
                companyData={companyData} 
                assumptions={assumptions}
                calculatedCostOfCapital={calculatedCostOfCapital}
                forecastedFinancialData={forecastedFinancialData}
                valuation={valuation}
                editMode={editMode}
                setEditMode={setEditMode}
                isUserCostOfCapital={isUserCostOfCapital}
              />
              <Box sx={{height:"5px"}}/>
              </>
            : null }
                    {/* ! isMobile && ! isTablet? */}
          
            <CheckboxFinancialView 
              isCheckedDescOrder={isCheckedDescOrder} 
              setIsCheckedDescOrder={setIsCheckedDescOrder} 
              isCheckedShowIncStatement={isCheckedShowIncStatement} 
              setIsCheckedShowIncStatement={setIsCheckedShowIncStatement} 
              isCheckedShowPreviousYears={isCheckedShowPreviousYears} 
              setIsCheckedShowPreviousYears={setIsCheckedShowPreviousYears} 
            />
            { combinedFinancialData ? 
            <>
              { isCheckedShowIncStatement ? 
              <>
                <Box sx={{height:"5px"}}/>
                <TableIncomeStatement 
                  assumptions={assumptions} 
                  combinedFinancialData={combinedFinancialData} 
                  isCheckedShowPreviousYears={isCheckedShowPreviousYears} 
                  isCheckedDescOrder={isCheckedDescOrder} 
                  isEstimateFcffOnly={isEstimateFcffOnly}
                /> 
              </>
              : null }
                 {/* isCheckedShowIncStatement? */}
              <Box sx={{height:"5px"}}/>
              <TableCashFlow 
                assumptions={assumptions} 
                combinedFinancialData={combinedFinancialData} 
                isCheckedShowPreviousYears={isCheckedShowPreviousYears} 
                isCheckedDescOrder={isCheckedDescOrder} 
                isEstimateFcffOnly={isEstimateFcffOnly}
              /> 
              { ! isMobile?  <Disclaimer />: null }
              </> 
            : null }
                {/* combinedFinancialData? */}
          </Paper>
        </Grid>  
        
        {/* rigth side grid ...Obs.: en veridon Mobile y tablet no hay right side grid*/}
        { ! isTablet || isMobile ? 
          <>
          <Grid item xs={12} sm={6} md={3}  >
            <Paper className={classes.paperStyle} elevation={0}  > 
            { combinedFinancialData ? 
              <>
              {/* <Box sx={{height:"5px"}}/> */}
              <SocialNetworks />
              <TableValuation 
                companyData={companyData}
                combinedFinancialData={combinedFinancialData} 
                calculatedCostOfCapital={calculatedCostOfCapital} 
                valuation={valuation} 
              />
              <Box sx={{height:"5px"}}/>
              <Paper elevation={3} >
                <ChartFreeCashFlow
                  assumptions={assumptions} 
                  combinedFinancialData={combinedFinancialData} 
                  isCheckedShowPreviousYears={isCheckedShowPreviousYears} 
                  isCheckedDescOrder={isCheckedDescOrder} isEstimateFcffOnly={isEstimateFcffOnly}
                />
              </Paper>
              <Box sx={{height:"5px"}}/>
              <Paper elevation={3}>
                <ChartRevenue
                  assumptions={assumptions} 
                  combinedFinancialData={combinedFinancialData} 
                  isCheckedShowPreviousYears={isCheckedShowPreviousYears} 
                  isCheckedDescOrder={isCheckedDescOrder} isEstimateFcffOnly={isEstimateFcffOnly}
                />
              </Paper>
              { isMobile?  <Disclaimer /> : null }
              </>
              : 
              <CircularProgress />
              } 
                  {/* combinedFinancialData ? */}
            </Paper>
          </Grid>  
          </> 
          : null }
              {/* !isMobile && ! isTablet ?  */}
      </Grid> 
    </Grid>
    <Snackbar
      open={isSnackbarOpen}
      autoHideDuration={2000} 
      onClose={handleSnackbarClose}
      >
      <SnackbarContent
        className={classes.greenSnackbarContent}
        message={snackbarMessage}
      />
    </Snackbar>
    <DialogModal open={isDialogOpen} onClose={handleDialogClose} severity={dialogOptions.severity} title={dialogOptions.title} buttons={dialogOptions.buttons} action={dialogOptions.action}>
        {dialogOptions.message}
    </DialogModal> 
    </>

    : 
    <CircularProgress />
    } 
        {/* ! isLoadingAllCompanies ?  */}
    </>
  )
}
