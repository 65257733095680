import React, {useState, useEffect} from 'react';

import { Grid, Paper, Box, Hidden } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import valuationsWebApi from '../../services/valuationsWebApi';
import useAxios from '../../hooks/useAxios';

import DialogModal from '../../components/modals/DialogModal';

import DescriptionText from './DescriptionText';
import TableDividendYield from './TableDividendYield';
import FilterOptions from './FilterOptions';
import SocialNetworks from '../../components/SocialNetworks';

const useStyles = makeStyles( (mainTheme) => ({
  contentStyle: {
    position: 'absolute',
    top: '65px',
  },
  container:{
    position: "absolute",
  },
  TableContainerStyle: {
    width: "100%",   
    minHeight:"550px", // vai ser do tamanho do conteudo da tabela
    marginTop:"5px",
    marginLeft:"0px",
    marginRight:"0px",
    color: "white",
    backgroundColor: mainTheme.palette.secondary.main,
    padding: "10px",
  },
}));

export default function DividendYield (){
  const classes = useStyles();
  const marks = [
    { value: 0, label: '0%'},
    { value: 1, label: '1%'},
    { value: 2, label: '2%'},
    { value: 3, label: '3%'},
    { value: 4, label: '4%'},
    { value: 5, label: '5%'},
    { value: 6, label: '6%'},
    { value: 7, label: '7%'},
    { value: 8, label: '8%'},
    { value: 9, label: '9%'},
    { value: 10, label: '10%'},
  ];
  const [ dialogOptions, setDialogOptions] = useState({severity:"",title:"",message:"",buttons:{}, action:""});
  const [ isDialogOpen, setIsDialogOpen] = useState(false);
  const [ minimumYield, setMinimumYield ] = useState(2);
  const [ companiesList, setCompaniesList ] = useState();
  const { axiosFetch: getDividendsCompanies } = useAxios();

  function allCompaniesSuccessCallback (apiData) {
    const allCompanies = apiData;
    // setCompaniesList(allCompanies.filter(company => (company.dividendYield * 100) > minimumYield));
    setCompaniesList(allCompanies.filter(company => (company.dividendYield ) > minimumYield));
    
  }

  function handleDialogClose (value, action) { 
    setIsDialogOpen (false);
    setDialogOptions({severity:"",title:"",message:"",buttons:{},action:""});
  }

  function errorCallback(errorMessage){
    setDialogOptions({severity:"error", title:"Oops", message:errorMessage,buttons:{button1:"Ok"}})
    setIsDialogOpen (true);
  }

  useEffect ( ()=> {  // revisar, fazer o fech somente 1 vez e filtra o array nas mudancas de state
    getDividendsCompanies({ axiosInstance: valuationsWebApi, method: 'GET', url: '/dividendyield', }, allCompaniesSuccessCallback, errorCallback);
  },[minimumYield]) 

  return <>
  { companiesList ? <div>
    <Grid container direction="column" alignItems="center" style = {{ minHeight: '80vh'}}  className={classes.container}>
      <Grid item xs={12} style = {{ minHeight: '69px'}} /> 

      <Grid item container direction="row" spacing={0}  >

        <Hidden mdDown>
          <Grid item xs={12} md={2} >
            <DescriptionText />
          </Grid>  

          <Grid item xs={12} md={7} > 
            <Paper className={classes.TableContainerStyle} >
              <TableDividendYield companiesList = {companiesList} />
            </Paper>  
          </Grid>

          <Grid item xs={12} md={3} > 
            <Box style={{marginLeft:"10px", marginTop:"5px"}}>  
              <SocialNetworks />
            </Box >  

            <FilterOptions marks = {marks} minimumYield = {minimumYield} setMinimumYield = {setMinimumYield}/>
            <Box style={{height: "5px"}}/>
          </Grid>
        </Hidden>

        <Hidden mdUp>
          <Grid  container direction="row" justifyContent="center" alignItems="flex-start" >

  
            <Grid item xs={12} sm={12} md={6} >
            <Box style={{marginLeft:"10px", marginTop:"5px"}}>  
              <SocialNetworks/>
            </Box>  
              <DescriptionText />
            </Grid>  
            <Grid item xs={12} sm={12} md={6} >

              <FilterOptions marks = {marks} minimumYield = {minimumYield} setMinimumYield = {setMinimumYield}/>

            </Grid>
            
            {/* <Grid item xs={12} sm={6} md={6} > 
              <FilterOptions marks = {marks} minimumYield = {minimumYield} setMinimumYield = {setMinimumYield}/>
            </Grid> */}

          </Grid>

          <Grid item xs={12}  > 
            <Paper className={classes.TableContainerStyle} >
              <TableDividendYield companiesList = {companiesList} />
            </Paper>  
          </Grid>

        </Hidden>
      </Grid>  
    </Grid>

    <Grid container direction="column" alignItems="center" style= {{ minHeight: '5vh'}} />
  <DialogModal open={isDialogOpen} onClose={handleDialogClose} severity={dialogOptions.severity} title={dialogOptions.title} buttons={dialogOptions.buttons} action={dialogOptions.action}>
    {dialogOptions.message}
  </DialogModal> 
  </div>: null }
  </>;
}