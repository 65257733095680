import React,{ useState } from 'react';
import reactGA from 'react-ga4';

// import {Elements} from '@stripe/react-stripe-js';
// import {loadStripe} from '@stripe/stripe-js';

import { ThemeProvider, StyledEngineProvider } from '@mui/material';
// import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { LoginContext } from './helpers/Context';
import mainTheme from './mainTheme';
import Routes from './routes.js';
import './global.css';

function App() {
  const trackingId="G-F6R1VBKV77";//"G-F6PJT7W43W";
  reactGA.initialize(trackingId)
  // const stripePromise=loadStripe("pk_test_51OVJNyLCP51AssL9HGlo3XpRbYmbxlU8z9WOlsmM3qCaxmPtj4p71g3cxDQROB1bkQWPdepusTjpNx0IlP7H8ueh00ogtiO52K")
  const [ userData, setUserData] = useState({userId:"", userPassword:"", userProduct:"", userFirstName:"", userLastName:"", userCountry:"", userCountryName:"", userPhone:"", userBirthday:"", userDescription:""})
  const [ peerProfileData, setPeerProfileData] = useState({profileId:"", profileFirstName:"", profileLastName:"", profileCountry:"", profilePhone:"", profileBirthday:"", profileDescription:""})
  const [ usersImagesList, setUsersImagesList] = useState([{userId:"", image:null}]);
  const [ usersList, setUsersList]=useState();
  const [ valuationsList, setValuationsList] = useState();
  const [ qtyOfValuationsViews, setQtyOfValuationsViews] = useState(0); 
  const [ qtyOfValuationsCreated, setQtyOfValuationsCreated] = useState(0); 
  const [ isNewComment, setIsNewComment] = useState(false); // Precisa?
  const [ qtyOfComments, setQtyOfComments] = useState(false); // Precisa?
  const [ isStripeCheckout, setIsStripeCheckout] = useState(false);
  const [ isValuationSample, setIsValuationSample]= useState(false);
  const [ isUpgradingPlan, setIsUpgradingPlan]=useState(false)
  const [ savedValuationData, setSavedValuationData]= useState({valuationId:"", profileId:"", profileFirstName:"", profileLastName:"", symbol:"",shortName:"", createdAt:"", updatedAt:"", published:null, publishedDate:null})
  const [ valuationsFilterCriteria, setValuationsFilterCriteria] = useState(0) // 0-recommended valuations, 1-valuations favorited by current logged user, 2-valuations created by current logged user
  // const queryClient = new QueryClient();

  return (
    <LoginContext.Provider value = {{ userData, setUserData, peerProfileData, setPeerProfileData, valuationsList, setValuationsList, usersImagesList, setUsersImagesList, valuationsFilterCriteria, setValuationsFilterCriteria, isNewComment, setIsNewComment, qtyOfComments, setQtyOfComments, savedValuationData, setSavedValuationData, usersList, setUsersList, isValuationSample, setIsValuationSample,  qtyOfValuationsViews, setQtyOfValuationsViews, qtyOfValuationsCreated, setQtyOfValuationsCreated, isStripeCheckout, setIsStripeCheckout, isUpgradingPlan, setIsUpgradingPlan }} >
      <StyledEngineProvider injectFirst> 
        <ThemeProvider theme = { mainTheme } >
          {/* <Elements stripe={stripePromise}> */}
            <Routes />
          {/* </Elements> */}
        </ThemeProvider>
      </StyledEngineProvider>
    </LoginContext.Provider>
  );
}

export default App;

{/* <QueryClientProvider client = {queryClient} > */}
{/* </QueryClientProvider> */}