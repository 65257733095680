import { useTheme, useMediaQuery } from "@mui/material";

export default function useTableStyling () {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  function defineYearFormat (year, assumptions ){
    if (isMobile && assumptions.cashFlowDiscretePeriod > 5 ) {
      return year-2000
    } else
    return year
  }

  function defineYearsColWidth ( assumptions, isCheckedShowPreviousYears) {
  
    // assumption: first column (account name) width: 22%, remaining columns ((accounting figures) width 78%
    if (isCheckedShowPreviousYears){
      return (0.78/(+assumptions.cashFlowDiscretePeriod + 3)*100).toString + "%"
    } 
    return (0.78/(+assumptions.cashFlowDiscretePeriod)*100).toString + "%"
  }

  function defineYearsColColor ( index, assumptions, isCheckedShowPreviousYears, isCheckedDescOrder ) {

    if (isCheckedShowPreviousYears){
      if (isCheckedDescOrder) {
        if (index < assumptions.cashFlowDiscretePeriod){
          return theme.palette.secondary.main
        } 
        return "whitesmoke"; 
      } if (index < 3) {
          return "whitesmoke"; 
        } 
        return theme.palette.secondary.main;
    } 
      return theme.palette.secondary.main;
  }

  function defineYearsColBackColor ( index, assumptions, isCheckedShowPreviousYears, isCheckedDescOrder ) {

    if (isCheckedShowPreviousYears){
    if (isCheckedDescOrder) {
      if (index < assumptions.cashFlowDiscretePeriod) { 
        return theme.palette.primary.main;
      } 
      return theme.palette.tertiary.main;
    } if (index < 3) {
        return theme.palette.tertiary.main;
      } 
      return theme.palette.primary.main;
  } return theme.palette.primary.main;
  }

  function defineNumberFormat ( number, style, showAsBlankIfZero, dataField, isEstimateFcffOnly,cashFlowDiscretePeriod ){
      // if (dataField[0]==="depreciation"){
        // alert(cashFlowDiscretePeriod)
    const percentFracDigits = isMobile ? 0 : 1 ;
    const decimalFracDigits = isMobile ? 0 : cashFlowDiscretePeriod > 7 ? 0: 1 ; // isMobile ? 0 : 1
    // console.log(dataField[0])
    if (Math.abs(number[0]) === 0.00 ) {
      if (showAsBlankIfZero[0]){
        return ""
      } else {
        return 0.00
      }
    } 
    if (dataField[0]==="cashFlowDiscretePeriod"){
      return  Intl.NumberFormat('en-US',{style:"decimal", minimumFractionDigits:0,maximumFractionDigits:0}).format(number); 
    }
    if (style[0] === "percent") {
        return Intl.NumberFormat('en-US',{style:"percent", minimumFractionDigits:percentFracDigits,maximumFractionDigits:percentFracDigits}).format(number/100); 
    } else { 
        return Intl.NumberFormat('en-US',{style:"decimal", minimumFractionDigits:decimalFracDigits,maximumFractionDigits:decimalFracDigits}).format(number); 
      }
  }

  return { defineYearFormat, defineYearsColWidth, defineYearsColColor, defineYearsColBackColor, defineNumberFormat }
}
