import React, { useState, useContext, useEffect, useRef, memo }  from 'react';
import { useHistory } from 'react-router-dom';

import { Paper, Rating, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TableFooter, TablePagination, TableSortLabel, Button, Tooltip, Typography,useTheme, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { format, parseISO } from 'date-fns';

import { LoginContext } from '../../helpers/Context';
import useTableSorting from '../../hooks/useTableSorting';
import useFetch from '../../hooks/useFetch';
import valuationsWebApi from '../../services/valuationsWebApi';

import DialogFreePlanLimits from '../../components/modals/DialogFreePlanLimits';
import DialogModal from '../../components/modals/DialogModal';
import TablePaginationActions from '../../components/TablePaginationActions';
import ShowUserById from '../../components/ShowUserById';

const useStyles = makeStyles((mainTheme) => ({
  table:{
    minWidth: 370,
    maxHeight: 900
  },
  TableHeader:{
    color: "white",
    backgroundColor: mainTheme.palette.primary.main, //"#A9A9A9", //"gray", //mainTheme.palette.primary.main
  },
  TableTitle:{
    color: "white",
    fontSize: 11
  },
  sortLabelStyle: {
    color: 'white',
    '&:hover': {
      color: 'orange',
    },
    '& .MuiTableSortLabel-icon': {
      color: 'orange !important',
    },
    '&.Mui-active': {
      color: 'orange',
    },
  },
  TableTitleCurrMarketValue:{
    fontSize: 11,
    color: "white",
    backgroundColor: mainTheme.palette.tertiary.main
  },
  ButtonTable:{
    display: 'inline-block',
    padding:0,
    minHeight: 0,
    minWidth: 0,
    color: mainTheme.palette.primary.main,
    backgroundColor:"white",
    fontSize: "11px",
    textTransform:"none",
    "&:hover": {
      color:mainTheme.palette.secondary.main,
      backgroundColor:"white"
    },
  },
  grow:{
      flexGrow: 1
    },
  iconButtonStyle:{
    color: mainTheme.palette.primary.main,
    // fontSize: "11px",
    // [mainTheme.breakpoints.down('xs')]: {
    //   fontSize: "10px"
    // },
    backgroundColor: mainTheme.palette.secondary.main,
    textTransform: "none",
    marginTop: "2px",
    marginLeft:"2px",
    "&:hover": {
      backgroundColor: "#F49506ed"
    },
  },
  TableRows:{
    fontSize: 11
  }
}));

  function TableRecValuations ({stakeholder}) {

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { userData, peerProfileData, valuationsList, setValuationsList, usersImagesList, valuationsFilterCriteria, setValuationsFilterCriteria, savedValuationData, setSavedValuationData, qtyOfValuationsViews } = useContext(LoginContext);
  const { userId, userProduct } = userData;
  const { profileId } = peerProfileData;
  const userIdToFilter = stakeholder==="user"? userId: profileId
  const [ favorites, setFavorites] = useState([])
  const [ page, setPage ] = useState(0);
  // const pageRef = useRef(0);
  // const [ page, setPage ] = useState(pageRef.current)
  const [ rowsPerPage, setRowsPerPage ] = useState(7);
  const [ orderDirection, setOrderDirection ] = useState('desc');
  const [ orderBy, setOrderBy ] = useState('updatedAt');
  const [ isDialogLimitsOpen, setIsDialogLimitsOpen ] = useState(false);
  const [ dialogLimitsOptions, setDialogLimitsOptions ] = useState({severity:"",title:"",message1:"",message2:"",message3:"",message4:"",buttons:{},action:""});

  const filterFunction = (valuationsFilterCriteria )=> {
    
    if (valuationsFilterCriteria==0){
      return valuationsList.filter((list) => (list.userId !== userId && list.deletedAt === null && list.published === "all" ) )  // && list.published === null && list.published === "all" always the current user not the peer
    } 
    if (valuationsFilterCriteria==1){
      if (favorites){
        return valuationsList.filter(valuation => favorites.some(favorite => favorite.valuationId === valuation.valuationId))
      } else {
        return valuationsList.filter((list) => list.userId !== userIdToFilter && list.deletedAt === null)
      }
    }
    if (valuationsFilterCriteria==2){
      return valuationsList.filter((list) => list.userId === userIdToFilter && list.deletedAt === null)
    }
  }
  let filteredValuation = filterFunction(valuationsFilterCriteria);
  const { handleDialogClose, dialogOptions, isDialogOpen}  = useFetch({valuationsList});
  const { getComparator, handleRequestSort } = useTableSorting();

  //---> Refatorar....
  // var emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage -  filteredValuation.length) : 0; // Avoid a layout jump when reaching the last page with empty rows.
  var emptyRows =  Math.max(0, (1 + page) * rowsPerPage -  filteredValuation.length) ; // Avoid a layout jump when reaching the last page with empty rows.
  const history = useHistory();

  const handleDialogLimitsClose=(value, action)=>{
    setIsDialogLimitsOpen(false);
    if (value === "Upgrade") {  
      // history.push(`/register/1`);
      history.push("/upgrade")
    }
  }

  function handleGoToValuation (valuationId, userId, firstName, lastName, companyId, shortName, createdAt, updatedAt) {
  
    if (userProduct==="0" && qtyOfValuationsViews > 4 && userId!==userData.userId){
      // alert("limitado")
      setIsDialogLimitsOpen(true);
    } else {
      // alert("va al valuation")
      //selecciona el valuation a ser mostrado en savedValuation
      setSavedValuationData 
      (prevState => ({...prevState, 
        valuationId:valuationId, 
        profileId:userId, 
        profileFirstName:firstName, 
        profileLastName:lastName, 
        symbol:companyId, 
        shortName:shortName,
        createdAt:createdAt,
        updatedAt:updatedAt,
        published:null,
        publishedDate:null

      }));
      // pageRef.current = page;  
      history.push(`/saved-valuation/${valuationId}`)
    }
  }

  const createSorthandler=(property) => (event) => {
    handleRequestSort(event, property, orderDirection, setOrderDirection, orderBy, setOrderBy);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // pageRef.current=newPage
  }; 

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setPage(0);
  }, [valuationsFilterCriteria]);

  useEffect(() => {
    if (userId){
      valuationsWebApi.get(`/favorites?userId=${userIdToFilter}`)
      .then(response => {
        setFavorites(response.data);
      })
      .catch(error => {
        console.error(`Failed to fetch favorites`, error);
      })
    } else {
      console.log("userId is not defined")
    }
  }, []);

  return (
    <>
   
    {filteredValuation !== undefined ? <>
      <TableContainer component={Paper} style={{width:"auto"}}>
        <Table className={classes.table} size="small" aria-label="stycky header" >
  
          <TableHead className={classes.TableHeader}>
            <TableRow >
              {/* width:15% */}
              <TableCell className={classes.TableTitle} style={{width: isMobile? "8%" :"14%", padding:"8px"}}  align="center">Crafted by</TableCell> 
              <TableCell style={{fontSize:"11px", width:"14%", padding:"8px"}}  align="left" key="updatedAt">

                <TableSortLabel 
                  active={orderBy==="updatedAt"} 
                  direction={orderBy==="updatedAt" ? orderDirection : 'desc'} 
                  onClick={createSorthandler("updatedAt")}
                  className={classes.sortLabelStyle}
                  >Date
                </TableSortLabel>

              </TableCell>
              <TableCell  style={{fontSize:"11px",width:"27%", padding:"8px"}} align="left" key="shortName" >
                <TableSortLabel 
                  active={orderBy==="shortName"} 
                  direction={orderBy==="shortName" ? orderDirection : 'asc'} 
                  onClick={createSorthandler("shortName")}
                  className={classes.sortLabelStyle}
                  >Company
                </TableSortLabel>
              </TableCell>
                <TableCell className={classes.TableTitle} style={{width:"15%", paddingLeft:"5px", paddingRight:"5px"}} align="right">Valuation Price</TableCell>
                <TableCell className={classes.TableTitle} style={{width:"15%", paddingLeft:"5px", paddingRight:"5px"}} align="right">Current Price</TableCell>
                <TableCell className={classes.TableTitle} style={{width:"8%", paddingLeft:"5px", paddingRight:"5px"}} align="right">WACC</TableCell>
                <TableCell className={classes.TableTitle} style={{width:"15%", paddingLeft:"5px", paddingRight:"12px"}} align="right">Rating</TableCell>
            </TableRow>
          </TableHead>
          
          <TableBody>
            {(rowsPerPage > 0 ? 
              filteredValuation.slice().sort(getComparator(orderDirection, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : filteredValuation
              ).map((currValuation) => (
                
                <TableRow key={currValuation.valuationId}>
                  {/* <Tooltip title={`Go to ${currValuation.firstName}'s profile`}> */}
                  <TableCell  style={{paddingLeft:"5px", paddingRight:"5px"}}>
                    { valuationsFilterCriteria!=="current"? <> 
                      <ShowUserById 
                        userId={currValuation.userId}   
                        usersImages={usersImagesList} 
                        firstName={currValuation.firstName} 
                        lastName={currValuation.lastName}  
                        textPosition={"below"} 
                        size={"small"} 
                        isCreatedByText={false} 
                        isCountryFlag={false}
                        />
                      </>: <>
                      <ShowUserById 
                        userId={currValuation.userId}   
                        usersImages={usersImagesList} 
                        firstName={currValuation.firstName} 
                        lastName={currValuation.lastName}  
                        textPosition={"below"} 
                        size={"small"} 
                        isCreatedByText={false} 
                        isCountryFlag={false}
                        />
                      </>
                      }
                  </TableCell>
                  <TableCell align="left" style={{fontSize: 11, paddingLeft:"5px", paddingRight:"5px"}} className={classes.TableRows}  >
                    <Button onClick={(e) => (handleGoToValuation (currValuation.valuationId, currValuation.userId, currValuation.firstName, currValuation.lastName, currValuation.companyId, currValuation.shortName, currValuation.updatedAt))} className={classes.ButtonTable} style={{fontSize:11}} disableRipple>{format(parseISO(currValuation.updatedAt),"yyyy MMM,dd")}</Button>
                    {/* { currValuation.companyId ? 
                      <>
                      { companyId==="AAPL" || companyId==="AMZN" || companyId==="CAT" ? 
                            <img src = {getCustomizedLogo(companyId)} alt="Logo" style={{height:"32px"}}/>   
                          :
                          <img src = {logoUrl} alt="Logo" style={{height:"32px"}}/>     
                          }
                      </>    
                      :
                      null
                    } */}
                  </TableCell>
                  <Tooltip title="Go to Valuation"  placement="top">
                  <TableCell align = {isMobile? "center" : "left"} className={classes.TableRows}  style={{fontSize: 11, paddingLeft:"5px", paddingRight:"5px"}}>
                    <Button onClick={(e) => (handleGoToValuation (currValuation.valuationId, currValuation.userId, currValuation.firstName, currValuation.lastName, currValuation.companyId, currValuation.shortName, currValuation.updatedAt))} className={classes.ButtonTable} style={{fontSize:11}} disableRipple>{currValuation.shortName}</Button>
                  </TableCell>
                  </Tooltip>
                  <TableCell align="right" className={classes.TableRows} style={{ paddingLeft:"5px", paddingRight:"5px"}} >
                    <Button onClick={(e) => (handleGoToValuation (currValuation.valuationId, currValuation.userId, currValuation.firstName, currValuation.lastName, currValuation.companyId, currValuation.shortName, currValuation.updatedAt))} className={classes.ButtonTable} disableRipple>{Intl.NumberFormat('en-US',{style:'currency',currency:'USD'}).format(currValuation.targetStockPrice)}</Button>
                  </TableCell>
                  <TableCell align="right" className={classes.TableRows} style={{ paddingLeft:"5px", paddingRight:"5px"}}>
                    <Button onClick={(e) => (handleGoToValuation (currValuation.valuationId, currValuation.userId, currValuation.firstName, currValuation.lastName, currValuation.companyId, currValuation.shortName, currValuation.updatedAt))} className={classes.ButtonTable} disableRipple>{Intl.NumberFormat('en-US',{style:'currency',currency:'USD'}).format(currValuation.regularMarketPrice)}</Button>
                  </TableCell>
                  <TableCell align="right" className={classes.TableRows} style={{ paddingLeft:"5px", paddingRight:"5px"}}>
                    <Button onClick={(e) => (handleGoToValuation (currValuation.valuationId, currValuation.userId, currValuation.firstName, currValuation.lastName, currValuation.companyId, currValuation.shortName, currValuation.updatedAt))} className={classes.ButtonTable} disableRipple>{Intl.NumberFormat('en-US',{style:'percent', minimumFractionDigits:2}).format(currValuation.inputedCostOfCapital > 0? currValuation.inputedCostOfCapital/100 : currValuation.costOfCapital/100)}</Button>
                  </TableCell>  
                  <Tooltip placement="top" title={currValuation.averageRating? `The average rate of this valuation by other users is ${currValuation.averageRating}`:'Not rated yet'}>
                    <TableCell style={{ paddingLeft:"5px", paddingRight:"5px"}}>
                      { currValuation.avgRating?
                        <Rating name="read-only" precision ={0.1} value={currValuation.avgRating? currValuation.avgRating: 0} readOnly size="small" style={{fontSize:"13px"}}/>
                      :
                      <Rating name="read-only" precision ={0.1} value={0} readOnly size="small" style={{fontSize:"13px"}}/>
                      }
                    </TableCell>
                  </Tooltip>  
                </TableRow>
            ))}
            { ! isMobile ? 
            <>
              {emptyRows > 0 && (
                <TableRow style={{ height: 65.5 * emptyRows }}>
                  <TableCell colSpan={7} />
                </TableRow>
              )}
            </>
            : null }

          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                // rowsPerPageOptions={[15, { label: 'All', value: -1 }]}
                rowsPerPageOptions={[]}
                colSpan={3}
                count={filteredValuation.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      </>
    : null}
    <DialogModal open={isDialogOpen} onClose={handleDialogClose} severity={dialogOptions.severity} title={dialogOptions.title} buttons={dialogOptions.buttons} action={dialogLimitsOptions.action}>
      {dialogOptions.message}
    </DialogModal> 
    <DialogFreePlanLimits open={isDialogLimitsOpen} onClose={handleDialogLimitsClose} />
    </>
  );
}
export default (TableRecValuations)

