import React, { useState, useEffect, useContext } from 'react';

import { Grid, Paper, Box, Typography, Tooltip, Button, useTheme, CircularProgress, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

// import companyLogo from '../../../assets/PYPL.png'
import aaplLogo from '../../../assets/AAPL.png'
import amznLogo from '../../../assets/AMZN.png'
import catLogo from '../../../assets/CAT.png'

import vLogo from '../../../assets/V.jpg'
import tslaLogo from '../../../assets/TSLA.png'
import googLogo from '../../../assets/GOOG.png'
import pyplLogo from '../../../assets/PYPL.png'
import mcdLogo from '../../../assets/MCD.png'
import pepLogo from '../../../assets/PEP.png'
// import vLogo from '../../../assets/V.png'


import { LoginContext } from '../../../helpers/Context';
import valuationsWebApi from '../../../services/valuationsWebApi';
import useAxios from '../../../hooks/useAxios';
import CompanyText from './CompanyText';
import ButtonSignUp from '../../../components/ButtonSignUp.jsx';
import ButtonUpgrade from '../../../components/ButtonUpgrade.jsx';
import ShowValuationRating from '../../../components/ShowValuationRating.jsx';
// import ShowValuationLikes from  '../../../components/ShowValuationLikes.jsx';
// import ShowValuationLikes     from '../../../components/ShowValuationLikes.jsx';
import ShowValuationsLikes from '../../../components/ShowValuationsLikes.jsx';
import ShowValuationFavorites from '../../../components/ShowValuationFavorites.jsx';
import ShowTotalComments from '../../../components/ShowTotalComents.jsx';
import TablePricesSummary from '../TablePricesSummary.jsx';
import ShowUserById from '../../../components/ShowUserById.jsx';
import SavedButtons from './SavedButtons';
import ShowCompanyLogo from '../../../components/ShowCompanyLogo.jsx';
// import { Tooltip } from 'chart.js';
// import ButtonUpgrade from '../../../components/ButtonUpgrade.jsx';

const useStyles = makeStyles( (mainTheme) => ({
TableContainerStyle: {
  width: "100%",
  minHeight: "668px", 
  marginLeft: "3px",
  marginRight: "0px",
  color: "white",
  backgroundColor: "#f0f8ff", 
  // backgroundColor:mainTheme.palette.primary.main,
  padding: "2px",
},
CompanyInfo: {
  // height:"165px",
  marginLeft: "2px",
  marginRight: "2px",
  paddingTop: "10px",
  paddingLeft: "10px",
  paddingRigth: "10px",
  paddingBottom:"0px",
  color: "black",
  backgroundColor: "white",
},
boxSelectStyle:{
  height: "30px",
  width: "100%",
  // backgroundColor:"black",
  [mainTheme.breakpoints.down('sm')]: {
    width: "100%"
  },
},
upgradeTextStyle:{
  color:mainTheme.palette.tertiary.main,
}
}));

// llamada por SavedValuation index
export default function SavedInfoPanel ({ 
  companyData, 
  historicalFinancialData,
  assumptions,
  calculatedCostOfCapital,
  forecastedFinancialData,
  combinedFinancialData,
  valuation,
  // setComment,
  editMode,
  setEditMode,
  // interactions,
  // setInteractions,
  // setIsDialogPdfOpen,
  isCheckedDescOrder,
  isCheckedShowPreviousYears,
  isEstimateFcffOnly,
  isUserCostOfCapital,
  isPublicValuation,
  setIsPublicValuation,
  valuationDateInfo
  }){

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const logoUrl = companyData.logoUrl;
  const companyId = companyData.symbol;
  const { userData, usersImagesList, savedValuationData, isNewComment, isValuationSample } = useContext(LoginContext);
  // const [ interactionsId, setInteractionsId ] = useState({likeId:"", favoriteId:"", commentId:"", ratingId:""});
  const [ interactions, setInteractions ] = useState({likes:0, favorites:0, comments:0, ratings:0, avgRating:0});
  const { userId, userFirstName, userLastName, userProduct } = userData;
  const { valuationId, profileId, profileFirstName, profileLastName, symbol, shortName } = savedValuationData;
  const { likes, favorites, comments, ratings, avgRating} = interactions
  const { axiosFetch: getComments, loading: isLoadingComments, error: errorComments} = useAxios();
  const { axiosFetch: getLikes, loading: isLoadingLikes, error:errorLikes} = useAxios();
  const { axiosFetch: getFavorites, loading: isLoadingFavorites, error: errorFavorites} = useAxios();
  const { axiosFetch: getRatings, loading: isLoadingRatings, error: errorRatings} = useAxios();
  // const [ snackbarMessage, setSnackbarMessage] = useState("");
  

  const getCommentsSuccessCb=(apiData)=>{
    if (apiData){  // returns all table fields 
      const comments = apiData.length? apiData.length : 0
      setInteractions(prevInteractions => ({
        ...prevInteractions,
        comments: comments,
      }));
    } else {
      setInteractions(prevInteractions => ({
        ...prevInteractions,
        comments: 0,
      })); 
    }
  }

  const getCommentsErrorCb=(apiData)=>{
    setInteractions(prevInteractions => ({
      ...prevInteractions,
      comments: 0,
    }));    
  }

  const getLikesSuccessCb = (apiData)=>{
    if (apiData){  // returns all table fields 
      const likes = apiData.length? apiData.length : 0
      setInteractions(prevInteractions => ({
        ...prevInteractions,
        likes: likes,
      }));
    } else {
      setInteractions(prevInteractions => ({
        ...prevInteractions,
        likes: 0,
      }));
    }
  }

  const getLikesErrorCb=(apiData)=>{
    setInteractions(prevInteractions => ({
      ...prevInteractions,
      likes: 0,
    }));    
  }

  const getFavoritesSuccessCb = (apiData) =>{
    if (apiData){  // returns all table fields 
      const favorites= apiData.length? apiData.length : 0
      setInteractions(prevInteractions => ({
        ...prevInteractions,
        favorites: favorites,
      }));
    } else {
      setInteractions(prevInteractions => ({
        ...prevInteractions,
        favorites: 0,
      }));
    }
  }

  const getFavoritesErrorCb=(apiData)=>{
    setInteractions(prevInteractions => ({
      ...prevInteractions,
      favorites: 0,
    }));    
  }

  const getRatingsSuccessCb = (apiData) =>{
    if (apiData){  // returns quantity, average
      setInteractions(prevInteractions => ({
        ...prevInteractions,
        ratings: apiData.quantity,
        avgRating: apiData.average
      }));
    } else {
      setInteractions(prevInteractions => ({
        ...prevInteractions,
        ratings: 0,
        avgRating: 0
      }));
    }
  }

  const getRatingsErrorCb=()=>{
    setInteractions(prevInteractions => ({
      ...prevInteractions,
      ratings: 0,
      avgRating: 0
    }));
  }

  const getCustomizedLogo=(companyId)=>{
    if (companyId==='AAPL'){
      return aaplLogo
    }
    if (companyId==='AMZN'){
      return amznLogo
    }
    if (companyId==='CAT'){
      return catLogo
    }
    if (companyId==='V'){
      return vLogo
    }
  }

  useEffect(() => { // refatorar no backend para trazer tudo de 1 vez x varios fetchs?
    if (valuationId) {
      getComments({ axiosInstance: valuationsWebApi, method: 'GET', url: `/comments/?valuationId=${valuationId}`, requestConfig: { headers: {'Authorization': userId,},}},getCommentsSuccessCb, getCommentsErrorCb);
    } 
  }, [isNewComment]);

  useEffect(() => { // refatorar no backend para trazer tudo de 1 vez x varios fetchs?
    if (valuationId) {
      getComments({ axiosInstance: valuationsWebApi, method: 'GET', url: `/comments/?valuationId=${valuationId}`, requestConfig: { headers: {'Authorization': userId,},}},getCommentsSuccessCb, getCommentsErrorCb);
      getLikes({ axiosInstance: valuationsWebApi, method: 'GET', url: `/likes/?valuationId=${valuationId}`, requestConfig: { headers: {'Authorization': userId,},}},getLikesSuccessCb, getLikesErrorCb);
      getFavorites({ axiosInstance: valuationsWebApi, method: 'GET', url: `/favorites/?valuationId=${valuationId}`, requestConfig: { headers: {'Authorization': userId,},}},getFavoritesSuccessCb, getFavoritesErrorCb);
      getRatings({ axiosInstance: valuationsWebApi, method: 'GET', url: `/ratings/?valuationId=${valuationId}`, requestConfig: { headers: {'Authorization': userId,},}},getRatingsSuccessCb, getRatingsErrorCb);
    } 
  }, []);

  return (
    <>
    {console.log(companyData)}
    {/* sx={{position:"sticky", top:"70px"}} */}
    <Paper className = {classes.CompanyInfo} >
      {/* main grid */}
      <Grid container direction="row" justifyContent="space-between" >
        {/* valuationId? */}
      { forecastedFinancialData && ! isLoadingRatings ? <> 
        {/* left grid */}
        <Grid item container direction="row" xs={12} sm={5} >
          <Grid item container className = {classes.boxSelectStyle} xs={12}  >
            <Grid item xs={1} sm={2} style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
              { companyId ? 
              <>
              { companyId==="AAPL" || companyId==="AMZN" || companyId==="CAT" || companyId==="V" ? 
                    <img src = {getCustomizedLogo(companyId)} alt="Logo" style={{height:"32px"}}/>   
                  :
                  <img src = {logoUrl} alt="Logo" style={{height:"32px"}}/>     
                  }
              </>    
              :
              null
              }
            </Grid>
            <Grid item xs={11} sm={10} >
              <CompanyText />
            </Grid>
          </Grid> 
          <Grid item xs={9}  >
            <Box style={{height:"10px"}}/>
            <ShowValuationRating 
              value={avgRating} 
              ratings={ratings}
              isGrayColor={false}
            />
            <Grid container direction="row" justifyContent="flex-start" >
              <Grid item>
                <ShowValuationsLikes 
                  value={likes}
                  isGrayColor={false}
                />
              </Grid>
              <Grid item>
                <ShowValuationFavorites 
                  value={favorites}
                  isGrayColor={false}
                />
              </Grid>
              <Grid item>
                <ShowTotalComments 
                  value={comments}
                  isGrayColor={false}
                />
              </Grid>
              <Grid item  >
                { companyData && valuation ? 
                  <TablePricesSummary 
                    companyData={companyData} 
                    valuation={valuation}
                    editMode={editMode}
                  />
                : null}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} sx={{display:"flex", alignItems:"flex-start", justifyContent:"flex-end"}}>
          { isMobile? 
            <>
            <ShowUserById 
              userId={profileId} 
              usersImages={usersImagesList}
              firstName={profileFirstName} 
              lastName={profileLastName} 
              textPosition={"below"} 
              size={"medium"} 
              isCreatedByText={true}
              isCountryFlag={true}
            />
            </>
          : null}

          </Grid>
        </Grid>

      </> : 
      <CircularProgress />
      }
      <Grid item container direction="column" xs={12} sm={7} >
        <Box style={{display:"flex", justifyContent:"flex-end", marginRight:"10px"}}>
          <Grid item container  >
            { isValuationSample || userProduct==="0" ? <>
              <Grid item xs={12} sm={9} > 
                { isValuationSample ? <>
                  <Grid container  >
                      <Grid item xs={12}>
                        <ButtonSignUp />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography align="justify" style={{marginTop:"5px",fontSize:"9px"}} className={classes.upgradeTextStyle}>
                          Unlock a world of benefits by registering with us! With our hassle-free free plan (no credit card required), you get access to 5 valuations, the ability to give likes, and more.  <b>Join our valuations community !</b></Typography>
                      </Grid>
                    </Grid>
                </>
                :<>
                  <Box style={{display:"flex", alignItems:"center",justifyContent:"flex-start", paddingLeft:"8px"}}>
                    <Grid container spacing={0}>
                      <Grid item xs={12}>
                        <ButtonUpgrade />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography align="justify" style={{marginTop:"5px",fontSize:"10px"}} className={classes.upgradeTextStyle}>
                          Upgrade to our USD 29.90 Monthly Plan for unlimited access to all valuations. Rate, comment, and download PDF reports for any valuation</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography align="justify" style={{marginTop:"5px",fontSize:"11px"}} className={classes.upgradeTextStyle}>
                          <b>It's less than a dollar a day!</b></Typography>
                      </Grid>
                    </Grid>
                  </Box> 
                </> }
              </Grid>
              <Grid item xs={3} >
                { ! isMobile? 
                  <Box style={{display:"flex", justifyContent:"flex-end"}}>
                    <ShowUserById 
                      userId={profileId} 
                      usersImages={usersImagesList}
                      firstName={profileFirstName } 
                      lastName={profileLastName} 
                      textPosition={"below"} 
                      size={"medium"} 
                      isCreatedByText={true}
                      isCountryFlag={true}
                    />
                  </Box>
                
                
                : null}

  
              </Grid>
              </> :
              <Grid item xs={12}>
              { ! isMobile ? <>
                <Box style={{display:"flex", justifyContent:"flex-end"}}>
                  <ShowUserById 
                    userId={profileId} 
                    usersImages={usersImagesList}
                    firstName={profileFirstName } 
                    lastName={profileLastName} 
                    textPosition={"below"} 
                    size={"medium"} 
                    isCreatedByText={true}
                    isCountryFlag={true}
                  />
                </Box>
                </>
              : null}
            </Grid>            
              
              
             }
            {/* <Grid item xs={9}> */}
              {/* { isValuationSample ? 
                <Box style={{display:"flex", alignItems:"center",justifyContent:"flex-start", paddingLeft:"8px"}}>
                  <ButtonSignUp />
                </Box>       
              : null}  */}
              {/* { userProduct==="0" ? 
                <Box style={{display:"flex", alignItems:"center",justifyContent:"flex-start", paddingLeft:"8px"}}>
                  <ButtonUpgrade />
                </Box>        
              
              : null} */}
            {/* </Grid> */}


          </Grid>
          

        </Box>
        {/* { ! isMobile ? <Box sx={{height:"12px"}}/> : null} */}
        <Box style={{display:"flex", alignItems: "center",justifyContent:"flex-end", marginRight:"0px"}}>
          { companyData ? <>
            {/*   handleNewValuation, setInteractions, isCheckedDescOrder, isCheckedShowPreviousYears, isEstimateFcffOnly } */}
            <SavedButtons
              companyData={companyData}
              historicalFinancialData={historicalFinancialData}
              assumptions={assumptions} 
              calculatedCostOfCapital={calculatedCostOfCapital} 
              forecastedFinancialData={forecastedFinancialData} 
              combinedFinancialData={combinedFinancialData}
              valuation={valuation}
              editMode={editMode}
              setEditMode={setEditMode}
              interactions={interactions}
              setInteractions={setInteractions}
              // setIsDialogPdfOpen={setIsDialogPdfOpen}
              isCheckedDescOrder={isCheckedDescOrder}
              isCheckedShowPreviousYears={isCheckedShowPreviousYears}
              isEstimateFcffOnly={isEstimateFcffOnly}
              isUserCostOfCapital={isUserCostOfCapital}
              isPublicValuation={isPublicValuation}
              setIsPublicValuation={setIsPublicValuation}
              valuationDateInfo={valuationDateInfo}
            />
          </> : null}
        </Box>  
      </Grid>
    </Grid>
    </Paper>
    </>
  )  
}