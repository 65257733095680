import React, {useState, useEffect} from 'react';

import { Grid, Paper, Box, Hidden } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import valuationsWebApi from '../../services/valuationsWebApi';
import useAxios from '../../hooks/useAxios';

import DialogModal from '../../components/modals/DialogModal';
import DescriptionText from './DescriptionText';
import TableGraham from './TableGraham';
import SocialNetworks from'../../components/SocialNetworks';

const useStyles = makeStyles( (mainTheme) => ({
  contentStyle: {
    position: 'absolute',
    top: '65px',
  },
  container:{
    position: "absolute",
  },
  TableContainerStyle: {
    width: "100%",   
    minHeight:"550px", // vai ser do tamanho do conteudo da tabela
    marginTop:"5px",
    marginLeft:"0px",
    marginRight:"0px",
    color: "white",
    backgroundColor: mainTheme.palette.secondary.main,
    padding: "10px",
  },
}));

export default function Graham (){
  const classes = useStyles();
  const [ dialogOptions, setDialogOptions] = useState({severity:"",title:"",message:"",buttons:{}, action:""});
  const [ isDialogOpen, setIsDialogOpen] = useState(false);
  const [ companiesList, setCompaniesList ] = useState();
  const { axiosFetch: getGrahamCompanies } = useAxios();
  let sharesOutstanding = 0;
  let earningsPerShare = 0;
  let bookValuePerShare = 0;
  let grahamIntrinsicPrice = 0;

  function allCompaniesSuccessCallback (apiData) {
    const allCompanies = apiData;
    if (allCompanies){   // hacer a mano aca x traer directo como campo calculado do backend
      let grahamList = Array.from({ length: allCompanies.length } , () =>({companyId:"", shortName:"", marketCap:"",regularMarketPrice:"",year:"",netIncome:"",totalStockholderEquity:"", sharesOutstanding:"",earningsPerShare:"",bookValuePerShare:"",grahamIntrinsicPrice:"",marginOfSafety:""}));
      for (let i = 0 ; i < allCompanies.length; i++) {
        sharesOutstanding = allCompanies[i].marketCap/allCompanies[i].regularMarketPrice;
        earningsPerShare = allCompanies[i].netIncome/sharesOutstanding;
        bookValuePerShare = allCompanies[i].totalStockholderEquity/sharesOutstanding
        grahamIntrinsicPrice = Math.sqrt(22.5 * (earningsPerShare) * bookValuePerShare)
        grahamList[i].companyId = allCompanies[i].companyId ;
        grahamList[i].shortName = allCompanies[i].shortName ;
        grahamList[i].marketCap = allCompanies[i].marketCap ;
        grahamList[i].regularMarketPrice = allCompanies[i].regularMarketPrice ;
        grahamList[i].year = allCompanies[i].year ;
        grahamList[i].netIncome = allCompanies[i].netIncome ;
        grahamList[i].totalStockholderEquity = allCompanies[i].totalStockholderEquity ;
        grahamList[i].sharesOutstanding = sharesOutstanding ;
        grahamList[i].earningsPerShare = earningsPerShare ;
        grahamList[i].bookValuePerShare =  bookValuePerShare ;
        grahamList[i].grahamIntrinsicPrice = grahamIntrinsicPrice ;
        grahamList[i].marginOfSafety = grahamList[i].grahamIntrinsicPrice > grahamList[i].regularMarketPrice ? ((grahamList[i].grahamIntrinsicPrice-grahamList[i].regularMarketPrice)/grahamList[i].grahamIntrinsicPrice) *-1 :((grahamList[i].regularMarketPrice-grahamList[i].grahamIntrinsicPrice)/grahamList[i].grahamIntrinsicPrice) ;
      }
      const companiesWithNegativeMargin = grahamList.filter(
        (company) => company.marginOfSafety < 0
      );
      // setCompaniesList(grahamList);
      setCompaniesList(companiesWithNegativeMargin)
    }
  }

  function handleDialogClose (value, action) { 
    setIsDialogOpen (false);
    setDialogOptions({severity:"",title:"",message:"",buttons:{},action:""});
  }

  function errorCallback(errorMessage){
    setDialogOptions({severity:"error", title:"Oops", message:errorMessage,buttons:{button1:"Ok"}})
    setIsDialogOpen (true);
  }

  useEffect ( ()=> {  
    getGrahamCompanies({ axiosInstance: valuationsWebApi, method: 'GET', url: '/graham', }, allCompaniesSuccessCallback, errorCallback);
  },[]) 

  return <>
 
  { companiesList ? <div>
    <Grid container direction="column" alignItems="center" style = {{ minHeight: '80vh'}}  className={classes.container}>
      <Grid item xs={12} style = {{ minHeight: '69px'}} /> 

      <Grid item container direction="row" spacing={0}  >

        <Hidden mdDown>
          <Grid item xs={12} md={3} >
            <DescriptionText />
          </Grid>  

          <Grid item xs={12} md={6} > 
            <Paper className={classes.TableContainerStyle} >
              <TableGraham companiesList = {companiesList} />
            </Paper>  
          </Grid>

          <Grid item xs={12} md={3} > 
            {/* <FilterOptions marks = {marks} minimumYield = {minimumYield} setMinimumYield = {setMinimumYield}/>
            <Box style={{height: "5px"}}/> */}
            <Box style={{marginLeft:"10px", marginTop:"5px"}}>

              <SocialNetworks />
            </Box>

          </Grid>
        </Hidden>

        <Hidden mdUp>
          <Grid  container direction="row" justifyContent="center" alignItems="center" >

            <Grid item xs={12} sm={12} md={12} >
              <Box style={{display:"flex", justifyContent:"center"}}>
                <SocialNetworks />
              </Box>

              <DescriptionText />
            </Grid>  
            
          </Grid>

          <Grid item sx={12}   > 
            <Paper className={classes.TableContainerStyle} >
              <TableGraham companiesList = {companiesList} />
            </Paper>  
          </Grid>

        </Hidden>
      </Grid>  
    </Grid>

    <Grid container direction="column" alignItems="center" style= {{ minHeight: '5vh'}} />
  <DialogModal open={isDialogOpen} onClose={handleDialogClose} severity={dialogOptions.severity} title={dialogOptions.title} buttons={dialogOptions.buttons} action={dialogOptions.action}>
    {dialogOptions.message}
  </DialogModal> 
  </div>: null }
  </>;
}