import React from 'react';

import { Paper, Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles( (mainTheme) => ({
textAreaStyle: {
  marginTop:"5px",
  marginLeft:"5px",
  // marginRight:"5px",
  height:"auto",
  [mainTheme.breakpoints.down('sm')]: {
    height: "auto"
  },
  backgroundColor:mainTheme.palette.secondary.main, 
},
boxStyle: {
  // width: "98%",   
  marginLeft:"8px",
  marginRight:"8px",
  padding: "5px",   
  marginBottom: "1px",
  color:mainTheme.palette.primary.main,
}, 
textStyle: {
  color:mainTheme.palette.primary.main
}
}));

export default function DescriptionText () {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  return(
    <>
    <Paper elevation = {6} className = {classes.textAreaStyle} style={{marginRight:isTablet && ! isMobile? "0px":"5px"}}>
      <Box className = {classes.boxStyle} >
        <Typography align="center"  style={{fontSize:"13px"}} className={classes.textStyle} gutterBottom><b>What is Dividend Yield ?</b></Typography> 
        <Typography align="justify" style={{fontSize:"12px"}}>The dividend yield, displayed as a percentage, is the amount of money a company pays shareholders for owning a share of its stock divided by its current stock price.</Typography>
        <Typography align="center"  style={{fontSize:"13px"}} className={classes.textStyle} gutterBottom><b>Income-focused investors</b></Typography> 
        <Typography align="justify" style={{fontSize:"12px"}}>It is a key factor for income-focused investors seeking to generate a steady stream of cash flow from their portfolios. Dividends are typically distributed by well-established companies as a way to share profits with shareholders.</Typography>
        <Typography align="center"  style={{fontSize:"13px"}} className={classes.textStyle} gutterBottom><b>Dividends Aristocrats</b></Typography> 
        <Typography align="justify" style={{fontSize:"12px"}}>companies that have consistently increased their dividends for an extended period, showcasing financial stability and a commitment to returning value to shareholders.</Typography>

      </Box>
    </Paper>
    </>
  )  
}