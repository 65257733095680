import React, { useState } from 'react';

import { ReactSearchAutocomplete } from 'react-search-autocomplete'

export default function CompanySelect ({ companiesList, companySearchName, setCompanyIdSearch }){
  const [ wordEntered, setWordEntered ] = useState("");  

  const handleOnSelect = (item) => {
    setCompanyIdSearch(item.id)
  }
  const handleOnSearch = (item) => {
    setWordEntered(item.name)
  }

  return (
    <>
    { companiesList ? 
      <>
        <ReactSearchAutocomplete
          items={companiesList}
          placeholder={"Enter Company Name or Symbol"}
          inputSearchString={companySearchName}
          onSearch={handleOnSearch}
          onSelect={handleOnSelect}
          styling={{ zIndex: 4, height: "30px", borderRadius: "24px",fontSize: "11px", iconColor: "#344955", color: "#344955", searchIconMargin: '0 0 0 8px',clearIconMargin: '3px 8px 0 0' }} // To display it on top of the search box below
          autoFocus
          
        />  
      </>
    : null }
    </>
  )  
}
