// isUserCostOfCapital, setIsUserCostOfCapital
import React from 'react';

import { Paper, Grid, FormControlLabel, Checkbox } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles( (mainTheme) => ({
  textCheckboxStyle:{
    color:mainTheme.palette.primary.main,
    margingLeft:"20px",
    marginRight: "5px",
    paddingRight: "2px",
    fontSize:"9px"
  },  
  input: {
    // height: "20px",
    boxSizing: "border-box" //
  }
}));

export default function CheckboxEstimateCostOfCapital ({isUserCostOfCapital, setIsUserCostOfCapital, isDisabledChkBox, isClickable}) {
  const classes = useStyles();

  const handleChangeChkBox = () => {
  if (isClickable){
    if (isUserCostOfCapital) {
      setIsUserCostOfCapital(false);
    } else {
      setIsUserCostOfCapital(true);
    }  
  }  
  };
  
  return (
  <>
  <Paper elevation = {3} >
    <Grid container>
      <Grid item>
        <FormControlLabel 
          className = {classes.textCheckboxStyle}
          disabled = {isDisabledChkBox}
          control = {<Checkbox disableRipple style={{ paddingLeft: '20px' }} defaultChecked = {false} checked = {isUserCostOfCapital} size = "small"/>} 
          label = "Estimate Cost of Capital directly (without CAPM)"
          onChange = {handleChangeChkBox}
        />
      </Grid>
    </Grid>
  </Paper>
  </>
  )
}