import { useState, useContext } from 'react';
import { LoginContext } from '../helpers/Context.js';

// import useAxios from './useAxios.js';
import valuationsWebApi from '../services/valuationsWebApi.js';

export default function useForm (callback) {
  const { userId, setUserId, userPassword, setUserPassword } = useContext (LoginContext);
  const [ values, setValues ] = useState ({ contactName: "", contactMobile: "", contactEmail: "", contactMsg:"" });
  const { contactName, contactMobile, contactEmail, contactMsg } = values;
  const [ inputData, setInputData] = useState ({inputId:"", inputPassword:""})
  const [ registerData, setRegisterData ] = useState({ id:"", password:"", confirmPassword:"", product:"1",firstName:"",lastName:"", country:"", countryName:"", phone:"", birthday:null, occupation:"", company:"",email:"", city:"", description:"", website:"", twitter:"", linkedin:"", facebook:"", instagram:"", youtube:"", tiktok:"", photo:"", banner:"", stripeCustomerId:"", stripeSubscriptionId:""});
  const [ formErrors, setFormErrors ] = useState({...values,...inputData, id:"", password:"", confirmPassword:"", product:"",firstName:"",lastName:"", country:"", phone:"", birthday:"", occupation:"", company:"",email:"", city:"", description:"", website:"", twitter:"", linkedin:"", facebook:"", instagram:"", youtube:"", tiktok:"", photo:"", banner:""});
  const [ dialogOptions, setDialogOptions] = useState({severity:"",title:"",message:"",buttons:{}, action:""});
  const [ isDialogOpen, setIsDialogOpen] = useState(false);
  // const { response: currUserData, axiosFetch: getUser } = useAxios();

  // function handleDialogClose (value, action) { 
  //   setIsDialogOpen (false);
  //   setDialogOptions({severity:"",title:"",message:"",buttons:{},action:""});
  // }
  function handleDialogClose (value, action) { 
    setIsDialogOpen (false);
  }

  async function isRegisteredId(value){
    try {
      const response = await valuationsWebApi.get(`/users/${value}`);
      if (response.data.id){
        return true
      }
      return false
    } catch (error) {
      return false
    }
  }

  async function isValidUser (value){  
    if (value === "") {
      return {
        valid: false,
        message: "Please enter a value"
      }
    }  
    if (value !== "") {
      if ( ! new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value)){
        return { valid: false, message: "The email address provided is invalid" }
      }
      const isAlreadyRegistered = await isRegisteredId (value)
      if ( isAlreadyRegistered){
        setDialogOptions({severity:"alert", title:"Alert", message:"This email is already registered. Please Try another one !",buttons:{button1:"Ok"}})
        setIsDialogOpen (true);
        return { valid: false, message: "This email is already registered. Please Try another one !" }
      } else {
        return { valid: true }
      }
      
    } 
  }


  async function isValidCurrUser (value){  
    if (value === "") {
      return {
        valid: false,
        message: "Please enter a value"
      }
    }  
    if (value !== "") {
      if ( ! new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value)){
        return { valid: false, message: "The email address provided is invalid" }
      }
      const isAlreadyRegistered = await isRegisteredId (value)
      if ( ! isAlreadyRegistered){
        setDialogOptions({severity:"alert", title:"Alert", message:"This email is not registered in our database. Please Try another one !",buttons:{button1:"Ok"}})
        setIsDialogOpen (true);
        return { valid: false, message: "This email is not registered. Please Try another one !" }
      } else {
        return { valid: true }
      }
      
    } 
  }
  function noBlanks (value) {
    if (value === "") {
        return {
          valid: false,
          message: "Please enter a value"
        }
    } 
    return {valid: true}
  }

  function isValidPassword (value) {
    if (value === "") {
      return {
        valid: false,
        message: "Esta información es requerida"
      }
    } else if (value==="jmartinez" || value==="mcalcena" || value==="elopez" || value==="admin") {
        return {valid:true}
    } else {
      return {
        valid: false,
        message: "Clave incorrecta"
      }
    }
  }

  function isValidPhone (value) {
    if (value === "") {
        return {
          // valid: false, message: "Please enter a value"
          valid:true
        }
    }
  
    return {
      //valid: new RegExp(/^((\+595|0)9([6-9][1-6])\d{6})$/).test(value), message: "Numero de celular no valido"
      // valid: new RegExp(/^\d*$/).test(value),
      valid: new RegExp(/^[0-9()+\s-]+$/).test(value),
      message: "Only numbers are allowed"
    };
  }

  function capitalizeFirstLetter(inputString) {
    // Check if the input is a valid string
    if (typeof inputString !== 'string') {
      return inputString
    }
    return inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase();
  }

  function capitalizeName(name) {
    // Convert the name to lowercase and then capitalize the first letter
    const capitalized = name.toLowerCase().charAt(0).toUpperCase() + name.toLowerCase().slice(1);
    return capitalized;
}

  function isValidName (value) {
    const newValue = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    // alert("new value: "+newValue)
    if (value === "") {
      return {
        valid: false,
        message: "Please enter a value"
      }
    } 
    const nameRegex = /\b([a-zA-Zà-ÿ][-,a-z. ']+[ ]*)+/gm;
    
    return {
        valid: nameRegex.test(newValue),
        message: nameRegex.test(newValue) ? "Valid name" : "Not a valid name",
        value: newValue // Return the modified value with the first letter capitalized
    };
  }

  function isValidEmail (value) {
    if (value === "") {
      return {
        valid: false, 
        message: "Please enter a valid email"
      }
    }
    return {
      valid: new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value), 
      message: "Not a valid email."
    };
  }  

  //      ^(https?://)?(www\.)?linkedin\.com/.*$
//    ^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{1,15}$


  function isValidLinkedin (value) {
    const regex = /^(https?:\/\/)?(www\.)?linkedin\.com\/.*$/i
    return {
      valid: new RegExp(regex).test(value), 
      message: "Not a valid Linkedin account."
    }
  } 

  function isValidFacebook (value) {
    const regex = /^(https?:\/\/)?(www\.)?facebook\.com\/[^/]+$/i
    return {
      valid: new RegExp(regex).test(value), 
      message: "Not a valid Facebook account."
    }
  } 

  function isValidInstagram (value) {
    const regex = /^(?:https?:\/\/)?(?:www\.)?(?:instagram\.com|instagr\.am)\/([A-Za-z0-9_.]+)(?:\/)?$/i;
    return {
      valid: new RegExp(regex).test(value), 
      message: "Not a valid Instagram account."
    }
  } 

  function isValidTwitter (value) {
    const regex = /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{1,15}$/
    return {
      valid: new RegExp(regex).test(value), 
      message: "Not a valid Twitter account."
    }
  } 

  function chkFormErrors () {
    let isError = false;
    Object.keys(formErrors).forEach( (key) => {   // key es el nombre del key
      if (formErrors[key] !== ""){                //errors[key] es el contenido del key
        isError = true;
      }
    })
    return isError;
  }
  
  function checkBlankForm(valuesToCheck, state){
    let isError = false;
    Object.keys(valuesToCheck).forEach( (key) => {   // key es el nombre del key
      if ( state [key] === ""){                      // values[key] es el contenido del key
        setFormErrors(prevState => ( {...prevState, [key]:  "Please enter a value"}));
        // alert(key)
        isError=true;
      }
    })
    return isError;
  }

  function chkBlankFormContact () {
    let isError = false;
    const valuesToCheck = { contactName, contactEmail, contactMsg }
    Object.keys(valuesToCheck).forEach( (key) => {   // key es el nombre del key
      if (values [key] === "" ){                      //values[key] es el contenido del key
        setFormErrors(prevState => ( {...prevState, [key]:  "Please enter a value"}));
        isError=true;
      }
    })
    return isError;
  }

  function handleSubmit (event) {
    event.preventDefault();
    callback(); 
  }
  
  const handleChange = (e, setterFunction, validators ) =>{
    const target = e.target;
    // alert("e.target.value em handleChange"+ e.target.value)
    // console.log("handleChage: " + target.name)
    setterFunction (prevState => ({...prevState, [target.name]:target.value }))
    if (validators) {
      handleValidators(target, validators);
    }
    if (formErrors[target.name] !== ""){
      e.preventDefault()
    }
  }

  const handleChangeUserId = (e, validators) =>{
    const target=e.target;
    // setUserId (prevState => ({...prevState, [target.name]:target.value }))
    setUserId(e.target.value)
    // handleValidators(target, validators);
  }

  const handleValidators = async (target, validators) => {

    // validators.forEach(validation => {         // array 
    for (const validation of validators) {
    const result = await validation (target.value)    // value="martin" ou "0985 290979"...
    // console.log(result)
    const errors = formErrors [target.name]     // le os erros do "vetor"
      if (result.valid) {                      // se o retorno da funcao eh true, ou seja se o input eh valido.....
 //       if (errors.includes (result.message)){   //"limpa" as mesgs de erro
          setFormErrors (prevState => ( {...prevState, [target.name]: ""}))
 //       }
      } else { 
          console.log("result.message: " + result.message )
          if (!errors.includes(result.message)) {   // se ja existe a mensagem, nao inclui novamente
            setFormErrors (prevState => ( {...prevState, [target.name]: result.message}))
          }
        }
      }      
    // })
  }

  return { handleChange, handleChangeUserId, handleSubmit, checkBlankForm, chkBlankFormContact, chkFormErrors, isValidName, isValidPhone, isValidEmail, isValidLinkedin, isValidTwitter, isValidFacebook, isValidInstagram, noBlanks, isValidUser, isValidCurrUser, isValidPassword, userId, setUserId, registerData, setRegisterData, values, setValues, formErrors, setFormErrors, isDialogOpen, handleDialogClose, dialogOptions, inputData, setInputData }
}

// import React from 'react';
// import { useValidation, ValidationProvider, ValidationMessage } from 'react-validation';
// import validator from 'validator';

// // Custom validation rule for LinkedIn account
// const validateLinkedIn = (value) => {
//   if (!validator.isURL(value)) {
//     return 'Invalid LinkedIn URL';
//   }

//   if (!value.includes('linkedin.com')) {
//     return 'Not a LinkedIn URL';
//   }

//   return null;
// };

// // Component with LinkedIn account field
// const LinkedInField = () => {
//   const { onChange, onBlur, getValidationMessages, clearValidations } = useValidation({
//     rule: { validateLinkedIn },
//   });

//   return (
//     <div>
//       <label>LinkedIn Account:</label>
//       <input type="text" name="linkedin" onBlur={onBlur} onChange={onChange} />
//       <ValidationMessage for="linkedin" />
//     </div>
//   );
// };

// // Usage example in a form
// const Form = () => {
//   return (
//     <ValidationProvider>
//       <form>
//         <LinkedInField />
//         {/* Other form fields */}
//         <button type="submit">Submit</button>
//       </form>
//     </ValidationProvider>
//   );
// };
