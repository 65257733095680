import React, { useState, memo } from 'react';

import { Paper, TableContainer, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CreateTable from '../../../components/CreateTable';
// import useTableStyling from '../../hooks/useTableStyling';

const useStyles = makeStyles( (mainTheme) => ({
table: {
  //   minWidth: 300,
  // maxHeight: 900,
  // "& .MuiTableCell-root": {
  //   borderLeft: "1px solid rgba(224, 224, 224, 1)",
  // }
},
TableHeader:{
  color: "white",
  backgroundColor:mainTheme.palette.secondary.main
},
TableTitle:{
  color: "white",
  fontSize: 12
},
TableRows : {
  fontSize: 9,
  color: mainTheme.palette.primary.main,
  }
}));

function TableCostOfCapitalAssumptions ({assumptions, calculatedCostOfCapital}){
  
  const classes = useStyles();
  // const { defineNumberFormat } = useTableStyling();
  // Refatorar
  const [ premisas, setPremisas] = useState({
    riskFreeReturn: assumptions.riskFreeReturn,
    marketReturn: assumptions.marketReturn,
    equityRiskPremium: assumptions.equityRiskPremium,
    companyBeta:assumptions.companyBeta,
    costOfDebt:assumptions.costOfDebt,
    costOfEquity:calculatedCostOfCapital.costOfEquity,
    costOfCapital:calculatedCostOfCapital.costOfCapital
  })
  // const { riskFreeReturn, marketReturn, companyBeta, costOfDebt } = assumptions;
  // const { costOfEquity, costOfCapital } = calculatedCostOfCapital;
  const dataPerLine = 3;
  const dataRows = [
    {
      id:0,
      text:"Rf: Risk Free Return",
      dataField: "riskFreeReturn",
      grayBackground:false,
      showAsBlankIfZero:true,  
      style:"percent",
    },
    {
      id:1,
      text:"Equity Risk Premium",
      dataField: "equityRiskPremium",
      grayBackground:false,
      showAsBlankIfZero:true,  
      style:"percent",
    },
    {
      id:2,
      text:"Beta",
      dataField: "companyBeta",
      grayBackground:false,
      showAsBlankIfZero:true,  
      style:"decimal",
    },
    {
      id:3,
      text:"Kd: Gross Cost of Debt",
      dataField: "costOfDebt",
      grayBackground:false,
      showAsBlankIfZero:true,  
      style:"percent",
    },
    {
      id:4,
      text:"Ke: Cost of Equity",
      dataField: "costOfEquity",
      grayBackground:false,
      showAsBlankIfZero:true,  
      style:"percent",
    },
    {
      id:5,
      text:"WACC: Cost of Capital",
      dataField: "costOfCapital",
      grayBackground:false,
      showAsBlankIfZero:true,  
      style:"percent",
    },
  ]
  const numberOfRows = Math.ceil(dataRows.length/dataPerLine); //rounds up number of rows
  // const arrNumberOfRows = Array.from({ length: numberOfRows });
  // const arrNumberOfCols = Array.from({ length: dataPerLine });

  return (
    <>
    <TableContainer component={Paper}>
      <Table className = {classes.table} size="small" aria-label="stycky header">

        <TableHead className = {classes.TableHeader}>
          <TableRow>
            <TableCell colSpan={6} className = {classes.TableTitle} align="left">{`Cost Of Capital Assumptions - CAPM`}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <CreateTable dataRows={dataRows} dataSource={premisas} numberOfColumns={dataPerLine} tableFontSize={"9px"}/>
        </TableBody>
      </Table>
    </TableContainer>
    </>
  )
}
export default memo (TableCostOfCapitalAssumptions)