import React from 'react';

import { Paper, Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles( (mainTheme) => ({
textAreaStyle: {
  marginTop:"5px",
  marginLeft:"5px",
  marginRight:"5px",
  paddingLeft:"5px",
  paddingRight:"5px",
  // height:"220px",
  backgroundColor:mainTheme.palette.secondary.main, 
  // [mainTheme.breakpoints.down('sm')]: {
  //   height:"120px"
  // },
},
boxStyle: {
  width: "98%",   
  marginLeft:"8px",
  marginRight:"8px",
  padding: "5px",   
  marginBottom: "1px",
  color:mainTheme.palette.primary.main,
}, 
textStyle: {
  color:mainTheme.palette.primary.main
}
}));

export default function DescriptionText () {
  const classes = useStyles();
  return(
    <>
    <Paper elevation = {6} className = {classes.textAreaStyle} >
      <Box className = {classes.boxStyle} >
        <Typography align="center" style={{fontSize:"13px"}} className={classes.textStyle} gutterBottom><b>What is the Ben Graham Formula ?</b></Typography> 
        <Typography align="justify" style={{fontSize:"12px"}} >The Graham Formula, devised by Benjamin Graham, the renowned economist and value investing pioneer, is a formula used to estimate the intrinsic value of a stock. Graham, often considered the father of value investing, proposed this formula as a method to identify stocks that were trading at a discount to their intrinsic value. </Typography>
        <Typography align="center" style={{fontSize:"13px"}} className={classes.textStyle} gutterBottom><b>Margin of Safety</b></Typography> 
        <Typography align="justify" style={{fontSize:"12px"}} >By maintaining a margin between market price and intrinsic value, investors aim to safeguard against unforeseen events and market fluctuations. </Typography>
        <Typography align="center" style={{fontSize:"13px"}} className={classes.textStyle} gutterBottom><b>Limitations</b></Typography> 
        <Typography align="justify" style={{fontSize:"12px"}} >Despite its historical significance and the success of investors, the Graham Formula is not as widely used today. One reason for its reduced popularity is the evolving nature of financial markets and the increasing complexity of stock valuation models.</Typography>
      </Box>
    </Paper>
    </>
  )  
}