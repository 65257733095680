import React from 'react';

import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FavoriteIcon from '@mui/icons-material/Favorite';

const useStyles = makeStyles((mainTheme) => ({
  iconStyle:{
    marginLeft:"5px",
    width:"18px",
    height:"18px",
    color: mainTheme.palette.tertiary.main
  },	
  iconStyleDisabled: {
    marginLeft:"5px",
    width:"18px",
    height:"18px",
    color: "silver"
  },	
  numberStyle:{
    marginTop:"2px",
    marginLeft:"2px",
    // fontSize:"9px",
    color:mainTheme.palette.tertiary.main
  },
  numberStyleDisabled:{
    marginTop:"2px",
    marginLeft:"2px",
    // fontSize:"9px",
    color:"silver"
  }
  })); 

export default function ShowValuationFavorites ({value, isGrayColor}){
	const classes = useStyles();

  return (
	<>
	<Grid container >
    <Grid item>
      <FavoriteIcon className={! isGrayColor ? classes.iconStyle: classes.iconStyleDisabled} />
    </Grid>
    <Grid item>
      <Typography className={! isGrayColor ? classes.numberStyle : classes.numberStyleDisabled } style={{fontSize:"9px"}}>{value == 1? `${value} favorite`:`${value} favorites`}</Typography>
    </Grid>
	</Grid>
	</>
	)
}