import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { Grid, Box, Button, Tooltip, Snackbar, SnackbarContent, Grow } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SaveIcon from '@mui/icons-material/Save';
import PublishIcon from '@mui/icons-material/Publish';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DeleteIcon from '@mui/icons-material/Delete';

import { LoginContext } from '../../../helpers/Context.js';
import useFetch from '../../../hooks/useFetch';

import DialogModal from '../../../components/modals/DialogModal.jsx';

const useStyles = makeStyles( (mainTheme) => ({
  buttonStyle: {
    // width:"80px",
    [mainTheme.breakpoints.down('sm')]: {
      fontSize: "10px"
    },
    paddingTop:"0px",
    color:mainTheme.palette.secondary.main,
    backgroundColor:"white",
    "&:hover": {
      color:"#CD7F32",
      backgroundColor: "whitesmoke",
      // borderColor:"#F49506ed"
    },
  },
  textStyle:{
    marginTop:"3px",
    fontSize:"11px",
    color: mainTheme.palette.secondary.main,
  },
  greenSnackbarContent: {
    backgroundColor: "#228B22"
  },
  }));
  
export default function NewButtonsPanel ({companyData, assumptions, calculatedCostOfCapital, forecastedFinancialData, valuation, editMode, setEditMode, isUserCostOfCapital} ){
  const classes = useStyles();
  const { userData, savedValuationData, setSavedValuationData, valuationsList, setValuationsList, setQtyOfValuationsCreated } = useContext(LoginContext);
  const { userId } = userData;
  const { valuationId }= savedValuationData;
  const companyId = companyData.symbol;
  const [ isDialogOpen, setIsDialogOpen] = useState(false);
  const [ dialogOptions, setDialogOptions] = useState({severity:"",title:"",message:"",buttons:{}, action:""});
  const { revenueGrowth, marginTarget, opexGrowth, interestGrowth, otherGrowth, taxRate, capexGrowth, nwcGrowth, perpetualGrowthRate, cashFlowDiscretePeriod, cashFlowAvgGrowth, companyBeta, riskFreeReturn, marketReturn, equityRiskPremium, inputedCostOfCapital, debtTotalRatio, costOfDebt, published, publishedDate, assumptionsText} = assumptions
  const { costOfEquity, costOfCapital} = calculatedCostOfCapital
  const { calculatedCashFlowGrowth, sumOfCashFlowPresentValue, perpetuityValue, perpetuityPresentValue, enterpriseValue, cash, debt, equityValue, sharesOutstanding, targetStockPrice, dateStockPrice, marketCap, lastHistoricalYear } = valuation;
  const { saveValuation, saveValuation2, handlePublish }  = useFetch({ editMode, setEditMode });
  const history = useHistory();

  const handleNewValuation=()=>{
    history.push("/valuation")
  }

  const handleDialogClose=(value)=>{
    setIsDialogOpen(false);
    if (value === "Yes"){
      handlePublish(valuationId);
    }
    history.push(`/saved-valuation/${valuationId}`)
  }

  const handleSave = async () => {
    let valuationData = { userId, companyId, cashFlowAvgGrowth, sumOfCashFlowPresentValue, perpetuityValue, perpetuityPresentValue, enterpriseValue, cash, debt, equityValue, sharesOutstanding, targetStockPrice, dateStockPrice, marketCap, revenueGrowth, marginTarget, opexGrowth, interestGrowth, otherGrowth, taxRate, capexGrowth, nwcGrowth, perpetualGrowthRate, cashFlowDiscretePeriod, companyBeta, riskFreeReturn, marketReturn, equityRiskPremium, debtTotalRatio, costOfDebt, costOfEquity, costOfCapital, published, publishedDate, lastHistoricalYear, assumptionsText };
    if (isUserCostOfCapital) {
      valuationData= { userId, companyId, cashFlowAvgGrowth, sumOfCashFlowPresentValue, perpetuityValue, perpetuityPresentValue, enterpriseValue, cash, debt, equityValue, sharesOutstanding, targetStockPrice, dateStockPrice, marketCap, revenueGrowth, marginTarget, opexGrowth, interestGrowth, otherGrowth, taxRate, capexGrowth, nwcGrowth, perpetualGrowthRate, cashFlowDiscretePeriod, companyBeta, inputedCostOfCapital, published, publishedDate, lastHistoricalYear, assumptionsText };
    } 
    try {
      await saveValuation({ valuationData, companyData, forecastedFinancialData });
      setDialogOptions({severity:"success", title:"Thank You", message:"Yaluation sucessfully saved, do you want to publish it? ",buttons:{button1:"No",button2:"Yes"},action:"save"})
      setIsDialogOpen (true);  
      // setQtyOfValuationsCreated(prevCount => prevCount + 1);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
    <Grid container direction = "row" spacing = {1} style={{paddingTop:"0px"}} >
      <Grid item xs={12} sm = {12} >
      <Box sx={{height:"15px"}}/>

        <Box display ="flex" flexDirection="row" justifyContent="flex-end">
          <Tooltip title="Create a new valuation">
            <Button variant = "text" disableRipple 
              className = {classes.buttonStyle} 
              startIcon={<AddCircleIcon />} 
              disabled={editMode==="completed"}
              onClick = {handleNewValuation} 
              >New 
            </Button>
          </Tooltip>
          <Tooltip title="Save this valuation">
            <Button variant = "text" disableRipple className = {classes.buttonStyle} 
              startIcon={<SaveIcon />} 
              disabled={editMode!=="completed"}
              onClick ={handleSave}
              >Save 
            </Button>
          </Tooltip>
          <Tooltip title="Make this valuation visible to other users">
            <Button variant = "text" disableRipple className = {classes.buttonStyle} 
              startIcon={<PublishIcon />} 
              disabled = {true}
              >Publish 
            </Button>
          </Tooltip>
          <Tooltip title="Delete this valuation">
            <Button variant = "text" disableRipple className = {classes.buttonStyle} 
              startIcon={<DeleteIcon />} 
              disabled = {true}
              >Delete
            </Button>
          </Tooltip>
          {/* <Tooltip title="Generate a pdf file to print or send to your contacts">
            <Button variant = "text" disableRipple className = {classes.buttonStyle} 
              startIcon={<PictureAsPdfIcon />} 
              disabled = {true}
              >Download
            </Button>
          </Tooltip> */}
        </Box>  
      </Grid>
    </Grid>
    <DialogModal open={isDialogOpen} onClose={handleDialogClose} severity={dialogOptions.severity} title={dialogOptions.title} buttons={dialogOptions.buttons} action={dialogOptions.action}>
        {dialogOptions.message}
    </DialogModal> 
    </>
  )  
}