import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';


import { LoginContext } from '../../helpers/Context';

import NewValuation from '../Valuations/NewValuation';
import SavedValuation from '../Valuations/SavedValuation';
import DialogFreePlanLimits from '../../components/modals/DialogFreePlanLimits';

//refatorar? poniendo States y componentes comunes, como TableIncomeStatement, TableCashFlow, Tablevaluation??
export default function Valuations (){

  const { id } = useParams();  // parameter received from the route
  const { qtyOfValuationsCreated, userData, savedValuationData } = useContext(LoginContext);
  const { userId, userProduct} = userData;
  const { profileId } = savedValuationData;
  const history = useHistory();

  
  return(
    <>
      {/* { userProduct==="0" && qtyOfValuationsCreated > 4 && userId===profileId? <>
        <DialogFreePlanLimits />
      
        </>
      : 
      <> */}
        { id ===undefined || id===null || id===""?
          <NewValuation />
        : 
          <SavedValuation  />
        }
      {/* </>
    } */}
    </>
  )  
}