import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { format, startOfMonth, endOfMonth } from 'date-fns';

import { Grid, Paper, Typography, TextField, Button, Box,Snackbar, SnackbarContent, CircularProgress,  useTheme, useMediaQuery, Grow } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
import LoginIcon from '@mui/icons-material/Login';

import { LoginContext } from '../helpers/Context.js';
import valuationsWebApi from '../services/valuationsWebApi.js';
import useAxios from '../hooks/useAxios.js';
// import api from '../services/api';
import useForm from '../hooks/useForm.js';
// import useFetch from '../hooks/useFetch.js';
import useUnsavedWarning from '../hooks/useUnsavedWarning.js';

import AlertDialog from '../components/modals/AlertDialog.js';
import DialogModal from '../components/modals/DialogModal.jsx';
import Footer from '../components/Footer';
import SocialNetworks from '../components/SocialNetworks.jsx';

const useStyles = makeStyles((mainTheme) => ({
  contentStyle: {
    position: 'absolute',
    top: '65px'
  },
  paperStyle: {
    margin: 'auto',
    padding:'10px',
    width:"350px",
    // minWidth: 350,
    // maxWidth: 500,
    backgroundColor:mainTheme.palette.secondary.main,  
  },
  buttonStyle:{
    color: "white",
    backgroundColor:mainTheme.palette.primary.main,
    textTransform:"none",
    margin: "10px",
//  marginTop: "15px",
    "&:hover": {
      color:mainTheme.palette.secondary.main,
      backgroundColor:mainTheme.palette.primary.main,
    },
  },
  iconBox:{
    width:'100%',
    textAlign: 'center',
    AlignItems: 'center',
  },
  iconStyle:{
    color: mainTheme.palette.primary.main, 
  },
  inputStyle: {
    background: "white",
  },
  formStyle:{
    backgroundColor:'white'
  },
  forgotPasswordStyle:{
    fontSize:11,
    color:"blue"
  },
  greenSnackbarContent: {
    backgroundColor: "#228B22"
  },
}))

  export default function Login () {
    const classes = useStyles();  
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const storedValue = localStorage.getItem('isRegisterComplete');
    const isRegisterComplete = (storedValue !== null) ? JSON.parse(storedValue) : true;
    // const isRegisterComplete=JSON.parse(localStorage.getItem('isRegisterComplete'))? JSON.parse(localStorage.getItem('isRegisterComplete')): true;
    const storedUserData = JSON.parse(localStorage.getItem('registerData'))? JSON.parse(localStorage.getItem('registerData')): "";
    const { setUserData, valuationsList, setIsValuationSample, setQtyOfValuationsViews, setQtyOfValuationsCreated } = useContext (LoginContext);
    const { handleChange, handleSubmit, checkBlankForm, noBlanks, formErrors, inputData, setInputData } = useForm (submit);
    const { inputId, inputPassword } = inputData;
    // const { createSession } = useFetch();
    const [ isFetchingData, setIsFetchingData] = useState(false)
    const [ isAlertOpen, setIsAlertOpen ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState({severity:"", title:"", message:""});
    const [ isSnackbarOpen, setIsSnackbarOpen]= useState(false);
    const [ snackbarMessage, setSnackbarMessage] = useState("");
    const [ Prompt, setIsDirty, setIsPristine ] = useUnsavedWarning();
    const { axiosFetch: delUser } = useAxios();
    const { axiosFetch: getValuationsViews, isLoading: isLoadingValuationViews, error: isErrorValuationViews } = useAxios();
    const [ isDialogOpen, setIsDialogOpen ] = useState(false);
    const [ dialogOptions, setDialogOptions ] = useState({severity:"",title:"",message:"",buttons:{},action:""});
  
     // const [ isLoadingLogin, setIsLoadingLogin] = useState(false);
    const valuesToCheck = { inputId, inputPassword }
    // const { axiosFetch: postSession, isLoading:isLoadingSession, error: errorSession } = useAxios();     
    const history = useHistory();

    function formatDate(date) {
      return format(date, 'yyyy-MM-dd HH:mm:ss');
    }
  


    function isDateInRange(date, start, end) {
        const formattedDate = formatDate(date);
        return formattedDate >= start && formattedDate <= end;
    }
  
    function filterValuationsByUser(userId, valuationsList) {
      const currentDate = new Date();
      const startOfMonthFormatted = formatDate(startOfMonth(currentDate));
      const endOfMonthFormatted = formatDate(endOfMonth(currentDate));
  
      return valuationsList.filter(valuation => {
          if (valuation.userId === userId && valuation.createdAt) {
              const valuationDate = new Date(valuation.createdAt);
              // Compare with the start and end of the current month
              return isDateInRange(valuationDate, startOfMonthFormatted, endOfMonthFormatted);
          }
          return false;
      });
    }

    // const sessionSuccessCb=(apiData)=>{
    //   alert("session success")
    //   setUserData({userId:apiData.id, userPassword: apiData.password, userFirstName:apiData.firstName, userLastName:apiData.lastName, userCountry:apiData.country, userBirthday:apiData.birthday, userDescription:apiData.description})
    //   setAlertMessage({severity:"success", title: "SUccess", message: "Session authorized"});
    //   setIsAlertOpen(true)
    //   // history.push('/home')
    // }
    
    // const sessionErrorCb =()=> {
    //   alert("session error")
    //   // console.log("ApiData Error " + apiData.error)
    //   setAlertMessage({severity:"error", title: "Error in Login", message: errorSession});
    //   setIsAlertOpen(true)
    // }
    
    // const createSession=(dataToProcess)=>{
    //   const { id } = dataToProcess;
    //   postSession({ axiosInstance: valuationsWebApi, method: 'POST', url: '/session', data: dataToProcess,requestConfig: { headers: {'Authorization': id,},}},sessionSuccessCb, sessionErrorCb);
    // }

    const handleSnackbarClose=()=>{
      history.push("/");
    }

    function handleDialogClose(){
      setIsDialogOpen(false);
      setDialogOptions({severity:"",title:"",message:"",buttons:{},action:""});
    }

    const getValuationsViewsSuccessCb=(apiData)=>{
      // alert("getValuationsViewsSuccessCb, apiData.length: " + apiData.length)
      if (apiData.length > 0) {
        const viewsOfTheMonth = apiData.length
        setQtyOfValuationsViews(viewsOfTheMonth)
      } else{
        setQtyOfValuationsViews(0)
      }

      // if (viewsOfTheMonth > 4){
        // setDialogOptions({
        //   severity:"alert",
        //   title:"Monthly Limit of Free Plan",
        //   message:"Great news! You've been actively using ValuationsHub, and we love having you on board. Just a quick heads up: you've hit the monthly limit for our free plan. Consider upgrading to unlock unlimited access and exclusive features. Upgrade now to keep enjoying a seamless experience. Thanks for being a valued user!",
        //   action:""});
        // setIsDialogOpen(true);
      // }
    }
  
    const getValuationsViewsErrorCb=()=>{
      setQtyOfValuationsViews(0);
    }


    async function submit () {

    if (checkBlankForm (valuesToCheck,inputData)){
      setAlertMessage({severity:"warning", title: "Error in data entry", message:"Please complete the required fields, thank you !"});
      setIsAlertOpen(true);
    } 
      else {
        setIsPristine();
        try {
          setIsFetchingData(true)
          const dataToProcess = {id: inputId.trim(), password: inputPassword } ;
          const res = await valuationsWebApi.post('/session', dataToProcess );
          if (res.data){
            const delay = 3000;
            setIsSnackbarOpen(false);
            setIsValuationSample(false);
            setUserData({userId:res.data.id, userPassword:res.data.password, userProduct: res.data.product, userFirstName:res.data.firstName, userLastName:res.data.lastName, userCountry:res.data.country, userCountryName:res.data.countryName,userBirthday:res.data.birthday, userPhone:res.data.phone, userDescription:res.data.description})
            setSnackbarMessage("Session started with sucess")
            setIsSnackbarOpen(true);
            if (res.data.product==="0") {
              const filteredValuationsByUser=filterValuationsByUser(res.data.id, valuationsList)
              setQtyOfValuationsCreated(filteredValuationsByUser.length)
              // alert("Login: res.data.id :" + res.data.id)
              getValuationsViews({ axiosInstance: valuationsWebApi, method: 'GET', url: `/valuations-views/?userId=${res.data.id}`, requestConfig: { headers: {'Authorization': res.data.id,},}},getValuationsViewsSuccessCb, getValuationsViewsErrorCb);
            }
          }
          setIsFetchingData(false);
        } catch (err) {
          setIsFetchingData(false)
          if (err.response) {
            const errorMsg = Object.values(err.response.data);
            setAlertMessage(prevState => ({...prevState, severity:"error", title: "Error in Login", message: errorMsg }));
            setIsAlertOpen(true);
          } else if(err.request) {
              setAlertMessage(prevState => ({...prevState, severity:"error", title: "Error in Login", message: "Server not available. Please try again in a few minutes." }));
              setIsAlertOpen(true);
            } else {
                setAlertMessage(prevState => ({...prevState, severity:"error", title: "Error in Login", message: "Server not available. Please try again in a few minutes." }));
                setIsAlertOpen(true);
              }
          } 
      }
  }


  const handleAlertClose = () => {
    setAlertMessage(prevState => ({...prevState, severity:"", title: "", message: "" }));
    setIsAlertOpen(false);
    if ( alertMessage.severity === "success" ) {
      history.push('/');
    }
  };

  const delUserSuccessCb=()=>{
    localStorage.removeItem("registerData")
    localStorage.removeItem("isRegisterComplete")
  }

  const delUserErrorCb=()=>{
    localStorage.removeItem("registerData")
    localStorage.removeItem("isRegisterComplete")
  }

  useEffect(() => { 
    ReactGA.send({ hitType: "pageview", page: "/login", title: "Login" });
    if (! isRegisterComplete && storedUserData!=""){
      //eliminar tb o usuario no stripe....nao somente no banco de dados
      delUser({ axiosInstance: valuationsWebApi, method: 'DELETE', url: `/users/${storedUserData.id}`, requestConfig: { headers: {'Authorization': storedUserData.id,},}},delUserSuccessCb, delUserErrorCb);
    } 
  }, []);

  return <>
  <>
   {/* { isFetchingData? <CircularProgress style={{ position: 'absolute', top: '50%', left: '50%' }} />:  */}
  <Grid container alignItems="center" justifyContent="center" className={classes.contentStyle} style={{ minHeight:'85vh'}}>
    <Grow in timeout = {1000}>
    <Grid item container className={classes.formStyle}>
      <Paper elevation={6} spacing={2} className={classes.paperStyle}>
      
        <form onSubmit={handleSubmit} noValidate>
 
          <Typography align="center" variant="subtitle1" style={{color:'white'}} gutterBottom>Login</Typography>
          <Box className={classes.iconBox} >
            <LoginIcon className={classes.iconStyle} style={{ fontSize: 40 }}/>
          </Box>

          <Grid item xs={12} md={12} spacing={1}> 
            <TextField id="inputId" label="Your E-mail" 
              variant ="filled" margin="dense" size="small" fullWidth  
              name="inputId" 
              value= { inputId } 
              onChange={ (e) => {
                handleChange (e,setInputData,[noBlanks]);
                setIsDirty ();
              }}
              inputProps={{style: {fontSize: 14}}} 
              error={formErrors.inputId} >
            </TextField>
            {formErrors.inputId ? <div className="error-helper-text">{formErrors.inputId}</div> : null}
          </Grid>
          
           <Grid item xs={12} md={9} spacing={1}> 
             <TextField id="inputPassword" label="Password *"
                 variant ="filled" margin="dense" size="small" type="password" fullWidth
                 name="inputPassword" 
                 value={inputPassword} 
                 onChange={ (e) => {
                   handleChange (e,setInputData,[noBlanks]);
                   setIsDirty ();
               }}
               inputProps={{style: {fontSize: 14}}} 
               error={formErrors.inputPassword}>
             </TextField>
               {formErrors.inputPassword ? <div className="error-helper-text">{formErrors.inputPassword}</div> : null}
               <Typography className={classes.forgotPasswordStyle}><a href="/reset">Forgot your password ?</a></Typography>
           </Grid>
           <Grid container direction="row" alignItems="center" justifyContent="center"> 
             <Button type="submit" className={classes.buttonStyle} variant="outlined" disableRipple >Login</Button>
           </Grid>
           <Typography className={classes.forgotPasswordStyle}>Not a registered user ? <a href="/register"> Sign up here !</a></Typography>
         </form>
       </Paper>
     </Grid>
     </Grow>
     <Grid />
 
   </Grid>
   {isFetchingData && (
      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
        <CircularProgress />
      </div>
  )}

  

  {/* <Footer /> */}
  </>
  {Prompt}
  <AlertDialog open={isAlertOpen} onClose={handleAlertClose} severity={alertMessage.severity} title={alertMessage.title}>
    {alertMessage.message}
  </AlertDialog>
  <DialogModal open={isDialogOpen} onClose={handleDialogClose} severity={dialogOptions.severity} title={dialogOptions.title} buttons={dialogOptions.buttons} action={dialogOptions.action}>
      {dialogOptions.message}
  </DialogModal>
  <Snackbar
  open={isSnackbarOpen}
  autoHideDuration={1000} // Adjust as needed
  onClose={handleSnackbarClose}
  >
  <SnackbarContent
    className={classes.greenSnackbarContent}
    message={snackbarMessage}
  />
  </Snackbar>
  </>
}