import React from 'react';

import { Paper, Grid, Box, FormGroup, FormControlLabel, Checkbox, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles( (mainTheme) => ({
  boxStyle: {
    width: "100%",
    marginLeft:"5px",
    padding: "1px",
    color: "white",
    backgroundColor: mainTheme.palette.tertiary.main,
    marginBottom: "1px",
  },
  textCheckboxStyle:{
    fontSize: "11px",
    [mainTheme.breakpoints.down('sm')]: {
      fontSize: "8px"
    },
    color:mainTheme.palette.tertiary.main,
  },
}));

export default function CheckboxFinancialView ({ isCheckedDescOrder, setIsCheckedDescOrder, isCheckedShowIncStatement, setIsCheckedShowIncStatement, isCheckedShowPreviousYears, setIsCheckedShowPreviousYears}){
  const classes = useStyles();
  const handleChangeCheckDescOrder = () => {
    if (isCheckedDescOrder) {
      setIsCheckedDescOrder(false);
    } else {
      setIsCheckedDescOrder(true);
    }  
  };
  const handleChangeCheckShowIncStatement = () => {
    if (isCheckedShowIncStatement) {
      setIsCheckedShowIncStatement(false);
    } else {
      setIsCheckedShowIncStatement(true);
    }  
  };
  const handleChangeCheckShowPreviousYears = () => {
    if (isCheckedShowPreviousYears) {
      setIsCheckedShowPreviousYears(false);
    } else {
      setIsCheckedShowPreviousYears(true);
    }  
  };
  
  return (
  <>
  <Paper elevation={3}>
    <FormGroup sx={{height:"35px", marginLeft:"15px"}}> 
      <Grid container direction='row'>

        <Grid item xs={4} sm={4} md={4}  >
          <Box display="flex" justifyContent="flex-start">
            <FormControlLabel 
              control = {<Checkbox defaultChecked disableRipple = {true} size="small" />} 
              label={<Typography className={classes.textCheckboxStyle}>Show Income Statement</Typography>}
              onChange = {handleChangeCheckShowIncStatement}
            />
          </Box>
        </Grid>

        <Grid item xs={4} sm={4} md={4} >
          <Box display="flex" justifyContent="center">
            <FormControlLabel 
              control = {<Checkbox defaultChecked disableRipple = {true} size="small"/>} 
              label={<Typography className={classes.textCheckboxStyle}>Show previous years</Typography>}
              onChange = {handleChangeCheckShowPreviousYears}
            />
          </Box>
        </Grid>

        <Grid item xs={4} sm={4} md={4}  >
          <Box display="flex" justifyContent="flex-end" >
            <FormControlLabel  
              control = {<Checkbox defaultChecked disableRipple = {true} size="small" />} 
              label={<Typography className={classes.textCheckboxStyle} >Show years in descending order</Typography>}
              onChange = {handleChangeCheckDescOrder}
            />
          </Box>
        </Grid>

      </Grid>
    </FormGroup>
  </Paper>
  </>
)
}