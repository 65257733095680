import React, { useState } from 'react';

import { Paper, Grid, TextField, InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import useValuationForm from '../../../hooks/useValuationForm';
import DialogModal from '../../../components/modals/DialogModal';

const useStyles = makeStyles( (mainTheme) => ({
  table: {
    minWidth: 300,
    maxHeight: 900
  },
  tableHeader:{
    backgroundColor: mainTheme.palette.secondary.main
  },
  tableHeaderDisabled:{
    backgroundColor:  mainTheme.palette.primary.main
  },
  tableTitle:{
    color: "white",
    fontSize: 12
  },
  tableTitleDisabled:{
    color: mainTheme.palette.secondary.main,
    fontSize: 12
  },
  TableRows:{
    fontSize: 11
  }
  }));

export default function FormUserCostOfCapital ({assumptions, setAssumptions, editMode}){
  const classes = useStyles();
  const { formErrors, handleChange, noBlanks, isValidDiscretePeriod } = useValuationForm({ assumptions, setAssumptions })
  // Nao usa useForm, coloca diretamente o valor no propio componente

  // const [ formErrors, setFormErrors]=useState({inputedCostOfCapital:""})
  const [ isDialogOpen, setIsDialogOpen ] = useState(false);
  const [ dialogOptions, setDialogOptions ] = useState({severity:"",title:"",message:"",buttons:{},action:""});
 
  const handleDialogClose=()=>{
    setIsDialogOpen(false);
    setDialogOptions({severity:"",title:"",message:"",buttons:{},action:""});
  }

  const handleCostOfCapitalChange = (value) => {
    const minimumValue = parseFloat(assumptions.perpetualGrowthRate);
    const recommendedGap = parseFloat(minimumValue) + 3;
    let perpetualGrowthValue = parseFloat(value);
    let isFirstDialogDisplayed = false;
  
    if (value <= minimumValue) {
      perpetualGrowthValue = recommendedGap;
      setDialogOptions({
        severity: "error",
        title: "Invalid cost of capital",
        message: "The cost of capital must be higher than the perpetuity growth rate !",
        buttons: { button1: "Ok" }
      });
      setIsDialogOpen(true);
      isFirstDialogDisplayed = true;
    }
  
    setAssumptions((prevState) => ({ ...prevState, inputedCostOfCapital: perpetualGrowthValue }));
  
    if (!isFirstDialogDisplayed && perpetualGrowthValue <= recommendedGap) {
      setDialogOptions({
        severity: "warning",
        title: "Overvaluation Alert!",
        message: "A small difference between the cost of capital and perpetual growth rate can lead to an overvaluation of the terminal value and target stock price.",
        buttons: { button1: "Ok" }
      });
      setIsDialogOpen(true);
    }
  };
  

  return (
  <>
  <TableContainer component={Paper}>
    <Table className={classes.table} size="small" aria-label="stycky header">

      <TableHead className={editMode==="saved" || editMode==="published" ? classes.tableHeaderDisabled :classes.tableHeader}>
        <TableRow>
          <TableCell className={editMode==="saved" || editMode==="published" ? classes.tableTitleDisabled :classes.tableTitle} align="left">Cost of Capital Assumption</TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        <TableRow>
          <Grid container>
            <Grid item xs = {4}>
              <TextField label="Cost of Capital"
                size="small"
                value={assumptions.inputedCostOfCapital}
                type="number"
                // min={2}
                min={0.01}  
                onChange={(e) => {handleChange (e,[])}}
                onBlur={(e) => {handleCostOfCapitalChange (e.target.value)}}
                variant="filled"
                fullWidth
                name="inputedCostOfCapital"
                disabled={editMode==="saved" || editMode==="published"}
                error={formErrors.inputedCostOfCapital}
                helperText={formErrors.inputedCostOfCapital}
                InputProps={{
                  startAdornment: <InputAdornment position="start">%</InputAdornment>,
                }}
              />
              {/* {formErrors.costOfCapital ? <div className="error-helper-text">{formErrors.costOfCapital}</div> : null} */}
            </Grid>

            <Grid item xs = {4} >
              {/* <TextField label=""
                size="small"
                // value={assumptions.perpetualGrowthRate}
                type="number"
                // onChange={(e) => {handleChange (e,[noBlanks])}}
                variant="filled"
                fullWidth
                name="perpetualGrowthRate"
                disabled={editMode==="saved" || editMode==="published"}
                error={formErrors.perpetualGrowthRate}
                helperText={formErrors.perpetualGrowthRate}
                // InputProps={{
                //   startAdornment: <InputAdornment position="start">%</InputAdornment>,
                // }}
              /> */}
            </Grid>
            
            {/* <Grid item xs={4} >
              <Tooltip title="">
                <TextField label=""
                  size="small"
                  // value={assumptions.cashFlowDiscretePeriod}
                  type="number"
                  // onChange={(e) => {handleChange (e,[isValidDiscretePeriod])}}
                  variant="filled"
                  fullWidth
                  name="cashFlowDiscretePeriod"
                  disabled={editMode==="saved" || editMode==="published"}
                  error={formErrors.cashFlowDiscretePeriod}
                  helperText={formErrors.cashFlowDiscretePeriod}
                  // InputProps={{
                  //   inputProps: { 
                  //       max: 30, min: 1 
                  //   }}}
                />
              </Tooltip>
            </Grid> */}

          </Grid>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
  <DialogModal open={isDialogOpen} onClose={handleDialogClose} severity={dialogOptions.severity} title={dialogOptions.title} buttons={dialogOptions.buttons} action={dialogOptions.action}>
    {dialogOptions.message}
  </DialogModal>
  </>
  )
}