import React, { memo } from 'react';

import { Paper, TableContainer, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';


const useStyles = makeStyles( (mainTheme) => ({
table: {
  //   minWidth: 300,
  // maxHeight: 900,
  // "& .MuiTableCell-root": {
  //   borderLeft: "1px solid rgba(224, 224, 224, 1)",
  // }
},
TableHeader:{
  color: "white",
  backgroundColor:mainTheme.palette.secondary.main
},
TableTitle:{
  color: "white",
  fontSize: 12
},
TableRows : {
  fontSize: 9,
  color: mainTheme.palette.primary.main,
  }
}));

function TableUserCostOfCapital ({assumptions}){
  
  const classes = useStyles();

  return (
    <>
    <TableContainer component={Paper} >
      <Table className = {classes.table} size="small" aria-label="stycky header">

        <TableHead className = {classes.TableHeader}>
          <TableRow>
            <TableCell colSpan={6} className = {classes.TableTitle} align="left">{`Cost Of Capital Assumptions`}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="left" className = {classes.TableRows}  style={{ width: "30%", marginLeft:"5px", marginRight:"5px", padding:"4px" }} >Estimated Cost of Capital</TableCell>
            <TableCell align="right" className = {classes.TableRows} style={{ width: "20%", paddingRight:"5px", padding:"2px" }} >{Intl.NumberFormat('en-US',{style:'percent', minimumFractionDigits:1}).format(assumptions.inputedCostOfCapital/100)}</TableCell>
          {/* <TableCell align="left" className = {classes.TableRows}  style={{ width: "20%", marginLeft:"5px", marginRight:"5px", padding:"4px" }} ></TableCell>
            <TableCell align="right" className = {classes.TableRows} style={{ width: "10%", paddingRight:"5px", padding:"2px" }} ></TableCell> */}
            {/* <TableCell align="left" className = {classes.TableRows}  style={{ width: "20%", marginLeft:"5px", marginRight:"5px", padding:"4px" }} ></TableCell>
            <TableCell align="right" className = {classes.TableRows} style={{ width: "10%", paddingRight:"7px", padding:"4px" }} ></TableCell>  */}
          </TableRow>  
        </TableBody>
      </Table>
    </TableContainer>
    </>
  )
}
export default memo (TableUserCostOfCapital)