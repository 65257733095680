import React, { useState, useEffect, useContext } from 'react';

import { Grid, Paper, Box, Button, FormControlLabel, Checkbox, Grow, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';

import axios from "axios";
import api from '../../../services/api.js';
import useAxios from '../../../hooks/useAxios.js';
import valuationsWebApi from '../../../services/valuationsWebApi.js';
import { LoginContext } from '../../../helpers/Context.js';

// import companiesList from '../../S&P500.js'

import ShowLatestValuations from './ShowLatestValuations.jsx';
import ShowLatestFavorites from './ShowLatestFavorites.jsx';
import ShowLatestUsers from './ShowLatestUsers.jsx';



const useStyles = makeStyles( (mainTheme) => ({
  contentStyle: {
    position: 'absolute',
    top: '65px'
  },
  paperStyle: {
    margin: 'auto',
    padding:'10px',
    minWidth: 350,
    maxWidth: 500,
    backgroundColor:mainTheme.palette.secondary.main,  
  },
  buttonStyle:{
    color: "white",
    backgroundColor:mainTheme.palette.primary.main,
    textTransform:"none",
    fontSize:12,
    margin: "10px",
    //marginTop: "15px",
    "&:hover": {
      color:mainTheme.palette.secondary.main,
      backgroundColor:mainTheme.palette.primary.main,
    },
  },
  }))

export default function GetApiData(){
  const classes=useStyles();
  const { userData } = useContext(LoginContext);
  const { userId }= userData;

  // const companiesList = ['BEN','CCL','JCI','RJF','KR','LEN','LMT'];
  const companiesList = ['SHEL','TTE'];

  const quoteTypeItems = [ "exchange", "shortName", "longName", "exchangeTimezoneName", "exchangeTimezoneShortName", "isEsgPopulated", "gmtOffSetMilliseconds", "quoteType", "symbol", "messageBoardId", "market" ];
  const summaryDetailItems = [ "beta", "marketCap", "fiftyTwoWeekHigh", "fiftyTwoWeekLow", "dividendRate", "dividendYield", "payoutRatio", "fiveYearAvgDividendYield", "trailingAnnualDividendRate", "trailingAnnualDividendYield", "forwardPE"];
  const financialDataItems = [ "totalCash","totalDebt" ];
  const priceItems = ["regularMarketPrice"]; 

  const incomeStatementAccounts = { costOfRevenue:0, discontinuedOperations:0, ebit:0, effectOfAccountingCharges:0,extraordinaryItems:0, grossProfit:0, incomeBeforeTax:0, incomeTaxExpense:0, interestExpense:0, minorityInterest:0, netIncome:0, netIncomeApplicableToCommonShares:0, netIncomeFromContinuingOps:0, nonRecurring:0, operatingIncome:0, otherItems:0, otherOperatingExpenses:0, researchDevelopment:0, sellingGeneralAdministrative:0, totalOperatingExpenses:0, totalOtherIncomeExpenseNet:0, totalRevenue:0 };
  const balanceSheetAccounts = { accountsPayable:0, capitalSurplus:0, cash:0, commonStock:0, endDate:"", goodWill:0, intangibleAssets:0, inventory:0, longTermDebt:0, longTermInvestments:0, netReceivables:0, netTangibleAssets:0, otherAssets:0, otherCurrentAssets:0, otherCurrentLiab:0, otherLiab:0, otherStockholderEquity:0, propertyPlantEquipment:0, retainedEarnings:0, shortLongTermDebt:0, shortTermInvestments:0, totalAssets:0, totalCurrentAssets:0, totalCurrentLiabilities:0, totalLiab:0, totalStockholderEquity:0, treasuryStock:0 };
  const cashFlowAccounts = { capitalExpenditures:0, changeInCash:0, changeToAccountReceivables:0, changeToInventory:0, changeToLiabilities:0, changeToNetincome:0, changeToOperatingActivities:0, depreciation:0, dividendsPaid:0, effectOfExchangeRate:0, investments:0, issuanceOfStock:0, netBorrowings:0, otherCashflowsFromFinancingActivities:0, otherCashflowsFromOperatingActivities:0, otherCashflowsFromInvestingActivities:0, repurchaseOfStock:0, totalCashFromFinancingActivities:0, totalCashFromOperatingActivities:0, totalCashflowsFromInvestingActivities:0 };
  const workingCapitalAccounts = { annualChangeInWorkingCapital:0 }
  let companyData = { companyId: "", exchange: "", exchangeTimezoneName: "", exchangeTimezoneShortName: "", gmtOffSetMilliseconds: "", isEsgPopulated: false, longName: "", market: "", messageBoardId: "", quoteType: "", shortName: "", symbol: "", marketCap:0, beta:0, regularMarketPrice:0, fiftyTwoWeekHigh:0, fiftyTwoWeekLow:0, dividendRate:0, dividendYield:0, payoutRatio:0, exDividendDate:0, fiveYearAvgDividendYield:0, trailingAnnualDividendRate:0, trailingAnnualDividendYield:0, forwardPE:0, trailingPE:0, totalCash:0, totalDebt:0, workingCapitalChanges:0 };
  let financials = Array.from(Array(4),() => ( {financialsId:"",companyId:"", period:0, year:0,...incomeStatementAccounts,...balanceSheetAccounts,...cashFlowAccounts}));
  let financialsQtr = financials.map(object => ({ ...object }));
  // let wcChanges = Array.from({ length: 4 } , () => ({ financialsId: "", workingCapitalChanges:0 }));
  // const { axiosFetch: putWorkingCapitalChanges } = useAxios();
  const totalCompaniesToFecth = companiesList.length
  const [ companiesSearched, setCompaniesSearched]=useState(0)
  const [ successCompanyAPIFetches, setSuccessCompanyApiFetches]=useState(0)
  const [ successFinancialsAPIFetches, setSuccessFinancialsAPIFetches]=useState(0)
  const [ successCompanyPost, setSuccessCompanyPost]=useState(0)
  const [ successFinancialsPost, setSuccessFinancialsPost]=useState(0)
  const [ companyBeingFetched, setCompanyBeingFetched]=useState("")
  const [ detailText, setDetailText]=useState("")

  const wccSuccessCallback=(apiData)=>{
    alert("Exito en Put Financials del wcc")
  }
  
  const wccErrorCallback=(apiData)=>{
    alert("Problema en Put Financials del wcc")
  }
  
  async function handleFinancials (period, typeOfResults ){
    let messageToSave=""
    try {
      //Chequea si el financialsId ya esta incluido
      if (typeOfResults==="annual"){
        const response = await api.get (`/financials?financialsId=${financials[period].financialsId}`)
      } else {
        const response = await api.get (`/financialsqtr?financialsId=${financialsQtr[period].financialsId}`)
      }
      try {
        // Intenta hacer el PUT
        if (typeOfResults==="annual"){
          setDetailText(`Actualizacion de datos financieros anuales: " ${financialsQtr[period].financialsId.slice(10)}`)
          const data =  financials[period]  ;
          const response = await api.put ('/financials', data);
        } else {
          setDetailText(`Actualizacion de datos financieros trimestrales: " ${financialsQtr[period].financialsId.slice(10)}`)
          const data =  financialsQtr[period]  ;
          const response = await api.put ('/financialsqtr', data);
        }
        setSuccessFinancialsPost(prevState => prevState + 1)
        if (typeOfResults==="annual"){
          setDetailText(`Éxito en actualización de datos financieros anuales:  ${financials[period].financialsId}`)

        } else {
          setDetailText(`Éxito en actualización de datos financieros trimestrales:  ${financialsQtr[period].financialsId}`)
        }  
        return { valid: true, message:"Éxito en actualización de datos financieros" };
      } catch (err) {
        //Error en el PUT
        setDetailText(`Error en actualización de datos financieros (catch): ${err.message}`)
        const errorMsg = Object.values(err.response.data);
      }
    } catch (err) {
      // si el financials todavia no esta incluido, hace el POST
      if (err.response.status === 404) {  
        try {
          
          if (typeOfResults==="annual"){
            alert(`Exito en la inclusion de datos financieros anuales:  ${financials[period].financialsId}`)
            setDetailText(`Inclusion de datos financieros anuales: " ${financials[period].financialsId}`)
            const data = financials[period];
            const response = await api.post('/financials', data );
          } else {
            // alert(`Exito en la inclusion de datos financieros anuales:  ${financialsQtr[period].financialsId.slice(10)}`)
            setDetailText(`Inclusion de datos financieros trimestrales: " ${financialsQtr[period].financialsId.slice(10)}`)
            const data = financialsQtr[period];
            const response = await api.post('/financialsqtr', data );
          }
          setSuccessFinancialsPost(prevState => prevState + 1)
          setDetailText("Exito en la inclusion de los financials la nueva empresa")
          return { valid: true, message:"Éxito en inclusión de datos financieros" };
        } catch (err) {
          if (typeOfResults==="annual"){
            alert(`Error en inclusión de datos financieros anuales (POST): ${financials[period].financialsId} error: ${err.message}`)
          } else {
            alert(`Error en inclusión de datos financieros trimestrales(POST): ${financialsQtr[period].financialsId} error: ${err.message}`)
          }  
          alert(`Error en inclusión de datos financieros (POST): ${financialsQtr[period].financialsId} error: ${err.message}`)
          if (typeOfResults==="annual"){
            messageToSave=`Error en inclusión de datos financieros anuales (POST): ${err.message}`
          } else {
            messageToSave=`Error en inclusión de datos financieros trimestrales (POST): ${err.message}`
          }
          const dataToProccess={companyId:financialsQtr[period].financialsId.slice(10) , message:messageToSave}
          const res= await api.post('/error-log', dataToProccess );
          setDetailText(`Error en inclusión de datos financieros: ${financialsQtr[period].financialsId} error: ${err.message}`)
          const errorMsg = Object.values(err.response.data);
          return { valid: false, message:"Error en inclusión de datos financieros" };
        }
      } else {
        const dataToProccess={companyId:financialsQtr[period].financialsId.slice(10) , message:`Error distinto a 404 en inclusión de datos financieros:${err.message}`}
        const res= await api.post('/error-log', dataToProccess );
        setDetailText(`Error distinto a 404 en inclusión de datos financieros: ${financialsQtr[period].financialsId.slice(10)} error: ${err.message}`)
        const errorMsg = Object.values(err.response.data);
        return { valid: false, message:"Error en actualización de datos financieros " };
      }
    }
  }

  async function handleCompany() {
    try {
      const CompanyAlreadySaved = await api.get(`/companies/${companyData.companyId}`);
  
      if (CompanyAlreadySaved && CompanyAlreadySaved.status === 200) {
        try {
          const data = companyData;
          const response = await api.put('/companies', data);
  
          if (response ) {
            setSuccessCompanyPost((prevState) => prevState + 1);
            setDetailText(`Éxito en actualización de datos de la empresa PUT ${companyData.companyId}`);
            return { valid: true, message: "Éxito en actualización de datos de la empresa" };
          }
  
          const dataToProcess = { companyId: companyData.companyId, message: "Error posting data on companies" };
          await api.post('/error-log', dataToProcess);
          setDetailText(`ERROR en actualización de datos de la empresa POST:${companyData.companyId}`);
          return { valid: false, message: "Error en actualización de datos de la empresa" };
        } catch (err) {
          const errorMsg = Object.values(err.response.data);
          return { valid: false, message: "Error en actualización de datos de la empresa" };
        }
      }
    } catch (err) {
      try {
        if (err.response.status == 404) {
          const data = companyData;
  
          if (companyData.beta && companyData.totalCash) {
            const response = await api.post('/companies', data);
  
            if (response  ) {
              setSuccessCompanyPost((prevState) => prevState + 1);
              setDetailText(`Éxito en inclusion de datos de la empresa POST: ${companyData.companyId}`);
              return { valid: true, message: "Éxito en inclusion de datos de la empresa" };
            }
          } else {
            // setDetailText("beta o caja esta 0 POST: " + companyData.companyId);
            const dataToProcess = { companyId: companyData.companyId, message: "Beta or cash are not included" };
            await api.post('/error-log', dataToProcess);
            console.log("Error en datos de la API " + companyData.companyId);
            return { valid: false, message: "Error en datos de la API" };
          }
  
          return { valid: false, message: "Error en inclusion de datos de la empresa " + companyData.companyId };
        } else {
          const dataToProcess = { companyId: companyData.companyId, message: "Unknown error trying to post to companies" };
          await api.post('/error-log', dataToProcess);
          setDetailText(`error desconocido en el post: ${err.message}`);
          return { valid: false, message: "Error en actualización de empresa" + companyData.companyId };
        }
      } catch (err) {
        setDetailText("Error in nested catch: " + err.message);
        return { valid: false, message: "Error en actualización de empresa" + companyData.companyId };
      }
    }
  }
    
  async function updateYFData() {
    let isValidTicker=false
    setCompaniesSearched(0)
    setSuccessCompanyApiFetches(0)
    setSuccessFinancialsAPIFetches(0)
    setSuccessCompanyPost(0)
    // setSuccessFinancialsPost(0)
    // Para cada empresa del listado....
    for (let i = 0; i < companiesList.length; i++) {
      // alert("CompaniesList.length: " + companiesList.length)      
      // setDetailText("CompaniesList: " + i)
      //Busca los datos de getSummary
      setCompaniesSearched(prevState => prevState + 1)
      setCompanyBeingFetched(companiesList[i])
      const endPoint = { method: 'GET',url:'https://yh-finance.p.rapidapi.com/stock/v2/get-summary', params:  { symbol: companiesList[i], region: 'US' }, headers: {'X-RapidAPI-Host': 'yh-finance.p.rapidapi.com','X-RapidAPI-Key': '057477ec51mshd7404991ea3c956p1dc241jsn2fbfbe21b657'}}
      await axios.request(endPoint).then(function (response) {
  
        const yfData =  response.data;

        if (yfData) {  // Si recibe los datos de la empresa desde la API
          setSuccessCompanyApiFetches(prevState => prevState + 1);
          isValidTicker=true
          companyData["companyId"] = companiesList[i];
          setDetailText(`Datos recibidos de ${companiesList[i]} por la API `)
          console.log(yfData)
          // Recibe los datos de SummaryDetailItems: [ "beta", "marketCap", "fiftyTwoWeekHigh", "fiftyTwoWeekLow", "dividendRate", "dividendYield", "payoutRatio", "fiveYearAvgDividendYield", "trailingAnnualDividendRate", "trailingAnnualDividendYield", "forwardPE"]
          summaryDetailItems.map ((currAccount) => {
            companyData[currAccount] = yfData.summaryDetail[currAccount].raw ? yfData.summaryDetail[currAccount].raw : 0
          })
          // Recibe los datos de quoteTypeItems: [ "exchange", "shortName", "longName", "exchangeTimezoneName", "exchangeTimezoneShortName", "isEsgPopulated", "gmtOffSetMilliseconds", "quoteType", "symbol", "messageBoardId", "market" ];
          quoteTypeItems.map ((currAccount) => {
            companyData[currAccount] = yfData.quoteType[currAccount] ? yfData.quoteType[currAccount] : 0
          })
          // Recibe los datos de priceItems: ["regularMarketPrice"]
          priceItems.map ((currAccount) => {
            companyData[currAccount] = yfData.price[currAccount].raw ? yfData.price[currAccount].raw : 0
          })
          // Recibe los datos de financialDataItems: [ "totalCash","totalDebt" ]...bs.: del ultimo trimestre disponible
          financialDataItems.map ((currAccount) => {
            companyData[currAccount] = yfData.financialData[currAccount].raw ? yfData.financialData[currAccount].raw : 0
          })
        }
      }).catch( async function (err) {
        setDetailText(`Data from ${companiesList[i]} was not found`)
        isValidTicker=false
        const errorMsg=err.message
        const dataToProcess = { companyId:  companiesList[i], message: errorMsg};
        const response= await api.post('/error-log', dataToProcess );
        if (response){
          setDetailText("Retornou resposta do backend para error log")
        } else{
          setDetailText("Nao resposta do backend para error log")
        }
        console.error(err);
      });
      if (isValidTicker){
        if ((await handleCompany()).valid)  {
          // get-cash-flow, get-financials
          const financialEndPoint = { method: 'GET',url:'https:yh-finance.p.rapidapi.com/stock/v2/get-cash-flow', params:  { symbol: companiesList[i], region: 'US' }, headers: {'X-RapidAPI-Host': 'yh-finance.p.rapidapi.com','X-RapidAPI-Key': '057477ec51mshd7404991ea3c956p1dc241jsn2fbfbe21b657'}}
          await axios.request(financialEndPoint).then(async function (response) {
      
            const yfDataFinancials =  response.data;
            
            if (yfDataFinancials){
                  console.log(yfDataFinancials)
              setSuccessFinancialsAPIFetches (prevState => prevState + 1)
              setDetailText(`"API tiene datos financieros de ${companiesList[i]}`)
              for (let j = 0; j < 4 ; j++) {
                financials[j]["companyId"] = companiesList[i] ;
                financials[j]["period"] = j;
                financialsQtr[j]["companyId"] = companiesList[i] ;
                financialsQtr[j]["period"] = j;
              
                Object.keys(incomeStatementAccounts).forEach( (key) => {  
                  financials[j][key] = yfDataFinancials.incomeStatementHistory.incomeStatementHistory[j][key] && yfDataFinancials.incomeStatementHistory.incomeStatementHistory[j][key].raw !==undefined ? yfDataFinancials.incomeStatementHistory.incomeStatementHistory[j][key].raw : 0
                  financialsQtr[j][key] = yfDataFinancials.incomeStatementHistoryQuarterly.incomeStatementHistory[j][key] && yfDataFinancials.incomeStatementHistoryQuarterly.incomeStatementHistory[j][key].raw !== undefined ? yfDataFinancials.incomeStatementHistoryQuarterly.incomeStatementHistory[j][key].raw : 0 
                })
    
                Object.keys(balanceSheetAccounts).forEach( (key) => {  
                  if (key ==="endDate"){
                    // setCurrentPeriod(yfDataFinancials.balanceSheetHistory.balanceSheetStatements[j][key] && yfDataFinancials.balanceSheetHistory.balanceSheetStatements[j][key].fmt !== undefined? yfDataFinancials.balanceSheetHistory.balanceSheetStatements[j][key].fmt : "")
                    financials[j][key] = yfDataFinancials.balanceSheetHistory.balanceSheetStatements[j][key] && yfDataFinancials.balanceSheetHistory.balanceSheetStatements[j][key].fmt !== undefined? yfDataFinancials.balanceSheetHistory.balanceSheetStatements[j][key].fmt : ""
                    financialsQtr[j][key] = yfDataFinancials.balanceSheetHistoryQuarterly.balanceSheetStatements[j][key] ? yfDataFinancials.balanceSheetHistoryQuarterly.balanceSheetStatements[j][key].fmt : ""
                    financials[j]["financialsId"] = yfDataFinancials.balanceSheetHistory.balanceSheetStatements[j][key] && yfDataFinancials.balanceSheetHistory.balanceSheetStatements[j][key].fmt !== undefined? yfDataFinancials.balanceSheetHistory.balanceSheetStatements[j][key].fmt + companiesList[i] : "" 
                    financialsQtr[j]["financialsId"] = yfDataFinancials.balanceSheetHistoryQuarterly.balanceSheetStatements[j][key] ? yfDataFinancials.balanceSheetHistoryQuarterly.balanceSheetStatements[j][key].fmt + companiesList[i] : ""
                    financials[j]["year"] = yfDataFinancials.balanceSheetHistory.balanceSheetStatements[j][key] && yfDataFinancials.balanceSheetHistory.balanceSheetStatements[j][key].fmt !== undefined? financials[j]["endDate"].substr(0,4) : "" 
                    financialsQtr[j]["year"] = yfDataFinancials.balanceSheetHistoryQuarterly.balanceSheetStatements[j][key] ? financialsQtr[j]["endDate"].substr(0,4) : ""
                    financialsQtr[j]["quarter"] = yfDataFinancials.balanceSheetHistoryQuarterly.balanceSheetStatements[j][key] ? financialsQtr[j]["endDate"].substr(0,7) : ""
                  } else {
                    // setCurrentAccount(key)
                    // setCurrentValue(yfDataFinancials.balanceSheetHistory.balanceSheetStatements[j][key] && yfDataFinancials.balanceSheetHistory.balanceSheetStatements[j][key].raw !==undefined? yfDataFinancials.balanceSheetHistory.balanceSheetStatements[j][key].raw : 0)
                    financials[j][key] = yfDataFinancials.balanceSheetHistory.balanceSheetStatements[j][key] && yfDataFinancials.balanceSheetHistory.balanceSheetStatements[j][key].raw !==undefined? yfDataFinancials.balanceSheetHistory.balanceSheetStatements[j][key].raw : 0
                    financialsQtr[j][key] = yfDataFinancials.balanceSheetHistoryQuarterly.balanceSheetStatements[j][key] && yfDataFinancials.balanceSheetHistoryQuarterly.balanceSheetStatements[j][key].raw !== undefined? yfDataFinancials.balanceSheetHistoryQuarterly.balanceSheetStatements[j][key].raw : 0
                  }
                })
    
                Object.keys(cashFlowAccounts).forEach( (key) => {   
                  financials[j][key] =  yfDataFinancials.cashflowStatementHistory.cashflowStatements[j][key] && yfDataFinancials.cashflowStatementHistory.cashflowStatements[j][key].raw !== undefined ? yfDataFinancials.cashflowStatementHistory.cashflowStatements[j][key].raw : 0 ;
                  financialsQtr[j][key] = yfDataFinancials.cashflowStatementHistoryQuarterly.cashflowStatements[j][key] && yfDataFinancials.cashflowStatementHistoryQuarterly.cashflowStatements[j][key].raw !== undefined ? yfDataFinancials.cashflowStatementHistoryQuarterly.cashflowStatements[j][key].raw : 0;
                })

                if (yfDataFinancials.timeSeries.annualChangeInWorkingCapital[3].reportedValue.raw){
                  financials[0].workingCapitalChanges = yfDataFinancials.timeSeries.annualChangeInWorkingCapital[3].reportedValue.raw
                }
                if (yfDataFinancials.timeSeries.annualChangeInWorkingCapital[2].reportedValue.raw){
                  financials[1].workingCapitalChanges = yfDataFinancials.timeSeries.annualChangeInWorkingCapital[2].reportedValue.raw
                }
                if (yfDataFinancials.timeSeries.annualChangeInWorkingCapital[1].reportedValue.raw){
                  financials[2].workingCapitalChanges = yfDataFinancials.timeSeries.annualChangeInWorkingCapital[1].reportedValue.raw
                }
                if (yfDataFinancials.timeSeries.annualChangeInWorkingCapital[0].reportedValue.raw){
                  financials[3].workingCapitalChanges = yfDataFinancials.timeSeries.annualChangeInWorkingCapital[0].reportedValue.raw
                }
                // alert("Chamou handleFinanciasl anual"+ j)
                await handleFinancials(j,"annual");
                // if (financialsQtr[j]["totalRevenue"]==0 && financialsQtr[j]["netIncome"]==0 ){
                  // alert("Chamou handleFinanciasl trimestral"+ j)
                  await handleFinancials(j,"quarterly");
                // }
                
              }  // for j, para cada periodo

            } //  if (yfDataFinancials){
          }).catch(function (error) {
            setDetailText("Error in 2nd catch")
            console.error(error);
          });
          
        };

      }
    } // for i, para cada ticker
  }

  return(
    <>
    {/* <Header /> */}
    { userId==="martincsl@hotmail.com" ? <>
    {/* className={classes.contentStyle} style={{ minHeight: '85vh' }} */}
    <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{backgroundColor:"cyan", marginTop:"90px"}} >
    
      <Grid item xs={12} md={6} >

        <Paper className={classes.paper} elevation={12}>
          <Button 
            variant = "contained" 
            disableRipple
            onClick = {updateYFData} 
            className = {classes.buttonStyle}
            >Update Yahoo Finance Data
          </Button>
        </Paper>
        <Box style={{height:"10px"}}/>
        {/* <Paper elevation={12} style={{margin:"5px", padding:"5px", width:"350px"}}>
          <Typography align="center" gutterBottom>Results</Typography>
          <Box style={{height:"10px"}}/>
          <Grid container>
            <Grid item xs={8}>
              <Typography variant="caption" gutterBottom>Total Companies to Fetch</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="caption" align="center" gutterBottom>{totalCompaniesToFecth}</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="caption" gutterBottom>Companies Searched</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="caption" align="center" gutterBottom>{companiesSearched}</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="caption" gutterBottom>Successful API Coompanies searches</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="caption" align="center"gutterBottom>{successCompanyAPIFetches}</Typography>
            </Grid>
        
          <Grid item xs={8}>
            <Typography variant="caption" gutterBottom>Successful companies POST</Typography>
          </Grid>
          <Grid item xs={4}>
              <Typography variant="caption" align="center" gutterBottom>{successCompanyPost}</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="caption" gutterBottom>Invalid companies POST</Typography>
          </Grid>
          <Grid item xs={4}>
              <Typography variant="caption" align="center" gutterBottom>{successCompanyAPIFetches - successCompanyPost}</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="caption" gutterBottom>Successful API financials searches</Typography>
          </Grid>
          <Grid item xs={4}>
              <Typography variant="caption" align="center" gutterBottom>{successFinancialsAPIFetches}</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="caption" gutterBottom>Successful API Financials POST</Typography>
          </Grid>
          <Grid item xs={4}>
              <Typography variant="caption" align="center" gutterBottom>{successFinancialsPost}</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="caption" gutterBottom>Ticker</Typography>
          </Grid>
          <Grid item xs={4}>
              <Typography variant="caption" align="center" gutterBottom>{companyBeingFetched}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" gutterBottom>Detail</Typography>
          </Grid>
          <Grid item xs={12}>
              <Typography variant="caption" align="center" gutterBottom>{detailText}</Typography>
          </Grid>
          </Grid>
        </Paper>  */}
      </Grid>

    </Grid>
    <ShowLatestValuations />
    <ShowLatestFavorites />
    <ShowLatestUsers />

    </> : null }
    </>
  )
}
