import React, { useState, useEffect, useContext, useLayoutEffect, useRef  } from 'react';
import { useHistory } from 'react-router-dom';

import { Grid, Paper, Box, Button, Tooltip, Typography, Avatar} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import CakeIcon from '@mui/icons-material/Cake';
// import LocationOnIcon from '@mui/icons-material/LocationOn';
// import EmailIcon from '@mui/icons-material/Email';
// import YouTubeIcon from '@mui/icons-material/YouTube';
// import TwitterIcon from '@mui/icons-material/Twitter';
// import InstagramIcon from '@mui/icons-material/Instagram';
// import FacebookIcon from '@mui/icons-material/Facebook';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import WebIcon from '@mui/icons-material/Web';
// import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
// import CancelIcon from '@mui/icons-material/Cancel';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { format, parseISO } from 'date-fns';

import { LoginContext } from '../../helpers/Context.js';
import useAxios from '../../hooks/useAxios.js';
import valuationsWebApi from '../../services/valuationsWebApi.js';
import FormEditProfile from './FormEditProfile';
import ShowUserImageByUserId from '../../components/ShowUserImageByUserId.jsx';

const useStyles = makeStyles((mainTheme) => ({
paperStyle:{
  margin:"0px", 
  padding:"5px", 
  margin:"auto", 
  height:"310px",
  [mainTheme.breakpoints.down('sm')]: {
    height: "auto"
  },
},  
// buttonStyle: {
  // width:"120px",
// },
buttonStyle: {
  // width:"80px",
  [mainTheme.breakpoints.down('sm')]: {
    fontSize: "10px"
  },
  // color:mainTheme.palette.secondary.main,
  // backgroundColor:"white",
  // "&:hover": {
  //   color:"#CD7F32",
  //   backgroundColor: "whitesmoke"//"#F49506ed",
    // borderColor:"#F49506ed"
  // },
},
sectionTitleStyle:{
  fontSize: mainTheme.sectionTitle.fontSize,
  color: mainTheme.sectionTitle.color
},
iconStyle:{
  height:"18px",
  width:"18px",
  color: mainTheme.palette.tertiary.main,
},
iconStyleInfo:{
  color: mainTheme.palette.primary.main,
},
textStyle:{
  color: mainTheme.palette.tertiary.main,
},
descriptionStyle:{
  fontSize: "10px",
  [mainTheme.breakpoints.down('sm')]: {
    fontSize: "13px"
  },
  color: mainTheme.palette.tertiary.main,
}
// socialNetworkBox:{
//   width:'100%',
//   textAlign: 'center',
//   AlignItems: 'center',
//   backgroundColor:mainTheme.palette.secondary.main,
// }, 
})); 

export default function MyProfile (){
  const classes = useStyles();
  const { userData, usersImagesList } = useContext(LoginContext);
  const { userId, userFirstName, userLastName, userBirthday, userDescription } = userData;
  const { axiosFetch: getFollowers, isLoading: isLoadingFollowers, error: isErrorFollowers } = useAxios();
  const [ followers, setFollowers] = useState();
  const [ numberOfFollowers, setNumberOfFollowers]=useState(0)
  const [ followees, setFollowees] = useState();
  const [ isEditProfile, setIsEditProfile] = useState(false); 
  const buttonRef = useRef(null);
  const history = useHistory();

  const handleRegister = ()=>{
    history.push('/pricing')
  }

  const handleEditProfile=()=>{
    setIsEditProfile(true);
  }

  const handleEditProfileClose=()=>{
    setIsEditProfile(false);
  }

  useLayoutEffect(() => {
    buttonRef.current.focus();
  }, []);

  const getFollowersSuccessCb=(apiData)=>{
    // alert("success" + apiData.length)
    if (apiData.length > 0){
      setFollowers(apiData);
      setNumberOfFollowers(apiData.length)
    } else{
      setFollowers();
      setNumberOfFollowers(0);
    }
  }

  const getFollowersErrorCb=(apiData)=>{
    // alert("error: No followers");
    setFollowers();
  }

  useEffect(() => {
    getFollowers({ axiosInstance: valuationsWebApi, method: 'GET', url: `/followers?userId=${userId}`, requestConfig: { headers: {'Authorization': userId,},}},getFollowersSuccessCb, getFollowersErrorCb);
  }, []);

  return (
  <>
  {/* style={{ margin:"0px", padding:"5px", margin:"auto", height:"310px"}} */}
  <Paper elevation={0} className={classes.paperStyle} >
    <Typography className={classes.sectionTitleStyle} >My Profile</Typography>

    <Grid container style={{marginTop:"5px"}}  >
      <Grid container justifyContent="center" >  

          <Grid item xs={12}>
            <Box style={{display:"flex", alignItems:"center", justifyContent:"center"}} >
              { userId ? 
                <ShowUserImageByUserId 
                  userId={userId} 
                  usersImages={usersImagesList} 
                  size={"large"}
                />
              : <Avatar sx={{height:68, width:68}}>You</Avatar>
              }
            </Box>
          </Grid>

      </Grid>
      <Grid item xs={12} >
        { userId?  
          <Typography align="center" gutterBottom className={classes.textStyle} style={{fontSize: 12, marginTop:"5px"}}>
            {`${userFirstName? userFirstName:""} ${userLastName? userLastName: ""}`}</Typography>
        :
          <Typography align="center" gutterBottom className={classes.textStyle} style={{fontSize: 12, marginTop:"5px"}}>
            Include your name and profesional summary so other users can connect with you! </Typography>
        }
      </Grid>
    </Grid>
    <Box style={{height:"2px"}}/>
    <Box>
      { userDescription ? 
        <Typography gutterBottom align="justify" className={classes.descriptionStyle}  >
          {userDescription}</Typography> : null 
      }
    </Box>
    {/* <Grid container direction="row" style={{marginTop:"5px"}} > 
      <Grid item xs={1} >
        { userBirthday ?
          <CakeIcon className={classes.iconStyle}  /> : null}
      </Grid>
      <Grid item xs={11} >
        { userBirthday ? 
          <Typography className={classes.textStyle} style={{fontSize: 9, marginLeft: "10px", marginTop:"2px"}}>
            {format(new Date(userBirthday), "MMM, dd")}</Typography> : null 
        }
      </Grid>
    </Grid> */}

    <Box style={{height:"2px"}}/>

    <Grid container justifyContent="center">
      { userId ? 
        <Tooltip title="Edit and save changes to your profile">
          {/* variant="text" */}
          <Button ref={buttonRef} disabled ={userId ? false : true } variant = "contained" disableRipple onClick={handleEditProfile} startIcon={<ModeEditIcon />} className = {classes.buttonStyle}>
            Edit Profile</Button>
        </Tooltip>
        :
        <Tooltip title="Register with just your email and password">
          <Button ref={buttonRef} disabled ={ ! userId ? false : true } variant="contained"  onClick={handleRegister} disableRipple startIcon={<ModeEditIcon />} className = {classes.buttonStyle}>
            Register Now!</Button>
        </Tooltip>
      }
    </Grid>
  </Paper>
  <Box style={{height: "5px"}}/>
  <FormEditProfile 
    open={isEditProfile} 
    onClose={handleEditProfileClose}
  />
  </> 
  )
}

  {/* <Grid item xs={1} >
        { userCountryName ?   
          <LocationOnIcon  className={classes.iconStyle}  /> : null
        }
      </Grid>
      <Grid item xs={9} >
        { userCountryName ?
          <Typography className={classes.textStyle} style={{fontSize: 9, marginLeft: "10px", marginTop:"2px"}}>{userCountryName}</Typography> : null
        }
      </Grid>
      <Grid item xs={2} >
        { userId ? 
          <Avatar style={{width:"18px", height:"18px", marginLeft:"12px"}}
            alt="CountryFlag"
            src={countryFlag}
          />  
        :
          null
        }
      </Grid>  */}

    
    {/* <Box className = {classes.socialNetworkBox}>
      <Tooltip title="Send an email">
          <EmailIcon className = {classes.iconStyle} style={{ fontSize:20, marginTop:"5px", marginLeft:"1px", marginRight:"1px", marginBottom:"0px" }}/>
      </Tooltip>
      <Tooltip title="Go to web">
        <WebIcon className = {classes.iconStyle} style={{ fontSize:20, marginTop:"5px", marginLeft:"1px", marginRight:"1px", marginBottom:"0px" }}/>
      </Tooltip>
      <Tooltip title="Go to Linkedin account">
        <LinkedInIcon className = {classes.iconStyle} style={{ fontSize:20, marginTop:"5px", marginLeft:"1px", marginRight:"1px", marginBottom:"0px" }}/>
      </Tooltip>
      <Tooltip title="Go to Youtube page">
        <YouTubeIcon className = {classes.iconStyle} style={{ fontSize:20, marginTop:"5px", marginLeft:"1px", marginRight:"1px", marginBottom:"0px" }}/>
      </Tooltip>
      <Tooltip title="Go to Twitter account">
        <TwitterIcon className = {classes.iconStyle} style={{ fontSize:20, marginTop:"5px", marginLeft:"1px", marginRight:"1px", marginBottom:"0px" }}/>
      </Tooltip>
      <Tooltip title="Go to Facebook account">
        <FacebookIcon className = {classes.iconStyle} style={{ fontSize:20, marginTop:"5px", marginLeft:"1px", marginRight:"1px", marginBottom:"0px" }}/>
      </Tooltip>
      <Tooltip title="Go to Instagram account">
        <InstagramIcon className = {classes.iconStyle} style={{ fontSize:20, marginTop:"5px", marginLeft:"1px", marginRight:"1px", marginBottom:"0px" }}/>
      </Tooltip>
    </Box> */}