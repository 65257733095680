import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { Grid, Paper, Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { LoginContext } from '../../helpers/Context.js';
import useAxios from '../../hooks/useAxios.js';
import DialogModal from '../../components/modals/DialogModal';
import PeerUserProfile from './PeerUserProfile'; 
// import TableYourNetwork from '../../pages/Home/TableYourNetwork';
import TableProfileNetwork from './TableProfileNetwork';
import TableRecValuations from '../../pages/Home/TableRecValuations';
import ButtonNewValuation from '../../pages/Home/ButtonNewValuation';
import NavPeerValuations from './NavPeerValuations'
import DoYouKnow from '../../pages/Home/DoYouKnow';
import SocialNetworks from '../../components/SocialNetworks.jsx'
import WhatsNext from '../../pages/Home/WhatsNext';

const useStyles = makeStyles( (mainTheme) => ({
  buttonStyle: {
    [mainTheme.breakpoints.down('sm')]: {
      fontSize: "10px"
    },
  },
  sectionTitleStyle:{
    marginLeft:"5px",
    fontSize: mainTheme.sectionTitle.fontSize,
    color: mainTheme.sectionTitle.color
  },
  titleStyle: {
    width: "100%",
    padding: "15px",
    color: mainTheme.palette.secondary.main,
    backgroundColor: "white",
    marginBottom: "10px",
  },
  paperStyle: {
    width: "100%",
    minHeight: "690px", 
    [mainTheme.breakpoints.down('lg')]: {
      minHeight: "0px"
    },
    marginLeft:"3px",
    marginRight:"0px",
    color: mainTheme.palette.primary.main,
    backgroundColor: "whitesmoke",
    padding: "5px",
  },
})) 

export default function PeerProfile (){
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const { userData, valuationsList, valuationsFilterCriteria, setValuationsFilterCriteria, savedValuationData, peerProfileData, setPeerProfileData, usersList, setQtyOfValuationsViews } = useContext(LoginContext);
  const { userId, userProduct } = userData;
  const [ isDialogOpen, setIsDialogOpen ] = useState(false);
  const [ dialogOptions, setDialogOptions ] = useState({severity:"",title:"",message:"",buttons:{},action:""});
  const history = useHistory();

  const handleRegisterMessage = ()=> {
    setDialogOptions({severity:"alert",title:"Please Register",message:"You need to register to create a new valuation. Check out our 3 options, including the free one.",action:""});
    setIsDialogOpen(true);
  }

  function handleDialogClose(){
    setIsDialogOpen(false);
    setDialogOptions({severity:"",title:"",message:"",buttons:{},action:""});
  }

  function handleNewValuation(){
    history.push('/valuation')
  }

  return (
    <>
    
    <Grid container direction="column" alignItems="center" style = {{ minHeight: '80vh'}} >

    <Grid item xs={12} style = {{ minHeight: '69px'}} /> 

      <Grid item container direction="row" spacing={0}  >
        { isTablet && !isMobile ?
        <>
        {/* <Paper className={classes.paperStyle} ></Paper> */}
          <Grid item xs={6}>
          {/* profileId */}
          <Paper elevation = {6} style={{ padding: '5px', margin:"5px", height: 300, overflow: 'hidden', overflowX: 'hidden'}}>
            <PeerUserProfile peerProfileData={peerProfileData} />
          </Paper>  
          <Box style={{height:"5px"}}/>
          </Grid>
          <Grid item xs={6}>
            {/* { usersList ?  <TableYourNetwork usersList={usersList} /> : null} */}
            { usersList ?  <TableProfileNetwork usersList={usersList} /> : null}
          </Grid>
        {/* <Paper />   */}
        </>
        : 
        <Grid item xs={12} md={2} >
          <Paper className={classes.paperStyle} sx={{position:"sticky", top:"65px"}}>
            <Paper elevation = {6} style={{ marginLeft:"5px", marginRight:"5px",padding:"5px"}}>
              <PeerUserProfile peerProfileData={peerProfileData} />
            </Paper>
            {/* <Box style={{height:"5px"}}/> */}
            {/* { usersList ?  <TableYourNetwork usersList={usersList} /> : null} */}
            { usersList ?  <TableProfileNetwork usersList={usersList} /> : null}
          </Paper>
        </Grid> 
        }

        <Grid item xs={12} md={7} > 
          <Paper className={classes.paperStyle} >
            <Grid container>
              <Grid item xs={12}>
              <Typography className={classes.sectionTitleStyle}><b>Valuations</b></Typography>
              </Grid>
              { isMobile ? <>
                <Grid item xs={12} >
                  <Box style={{display:"flex", alignItems:"flex-end", justifyContent:"flex-end"}}>
                    <ButtonNewValuation 
                      userId={userId}
                      handleNewValuation={handleNewValuation}
                      handleRegisterMessage={handleRegisterMessage}
                    />
                  </Box>
                  <Box style={{height:"10px"}}/>
                </Grid>
              </> : null}

              <Grid item xs={9}>
                <NavPeerValuations 
                  valuationsFilterCriteria={valuationsFilterCriteria}
                  setValuationsFilterCriteria={setValuationsFilterCriteria}
                />
              </Grid>
              { ! isMobile ? 
                <Grid item xs={3} >

                  <Box style={{display:"flex", alignItems:"flex-end", justifyContent:"flex-end"}}>
                    <ButtonNewValuation 
                        userId={userId}
                        handleNewValuation={handleNewValuation}
                        handleRegisterMessage={handleRegisterMessage}
                      />
                  </Box>
                </Grid>
              : null}
            </Grid>
            { valuationsList ? <TableRecValuations stakeholder="peer"/>
              : <Typography style={{fontSize:14, marginTop:"15px"}}>You don't have any valuation</Typography>
            }
          </Paper>
        </Grid>
        { isTablet && ! isMobile ? 
        <>
        <Grid container>

        <Grid item xs={6} style={{backgroundColor:"#b0c4c7"}}>
          {/* <Paper elevation={0} style={{backgroundColor:"#F9AA33"}}>   */}
            <SocialNetworks />
            <DoYouKnow 
              isMobile={isMobile}
              isTablet={isTablet}
            />
            {/* </Paper> */}
            <Box style={{height:"5px"}}/>  

          </Grid>
          <Grid item xs={6}>
            <WhatsNext />
            <Box style={{height:"5px"}}/>
          </Grid>
        </Grid>
        </>
        : 
        <Grid item xs={12} md={3} > 

          <Paper className={classes.paperStyle}>
          {/* <Paper elevation={0} style={{backgroundColor:"#F9AA33"}}>   */}
            <SocialNetworks />
            <DoYouKnow 
              isMobile={isMobile}
              isTablet={isTablet}
            />
            {/* </Paper> */}
            <Box style={{height:"5px"}}/>  

            <WhatsNext />
            <Box style={{height:"5px"}}/>
          </Paper>
      </Grid>
      }
    </Grid>
  </Grid>  
  <DialogModal open={isDialogOpen} onClose={handleDialogClose} severity={dialogOptions.severity} title={dialogOptions.title} buttons={dialogOptions.buttons} action={dialogOptions.action}>
    {dialogOptions.message}
  </DialogModal>
  </>
  )
}