import React from 'react';

import { Paper, TableContainer, Table, TableBody, TableCell, TableHead, TableRow} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CreateTable from '../../../components/CreateTable';

const useStyles = makeStyles( (mainTheme) => ({
  TableHeader:{
    color: "white",
    backgroundColor:mainTheme.palette.secondary.main
  },
  TableTitle:{
    color: "white",
    fontSize: 12
  },
  TableRows : {
    fontSize: 9,
    color: mainTheme.palette.primary.main,
    }
  }));

export default function TableBusinessAssumptions({assumptions, isEstimateFcffOnly}){
  const classes = useStyles();
  const dataPerLine = 3;
  const dataRows = ! isEstimateFcffOnly ? [
    {
      id:0,
      text:"Revenue Growth Rate",
      dataField: "revenueGrowth",
      grayBackground:false,
      showAsBlankIfZero:true,  
      style:"percent",
    },
    {
      id:1,
      text:"Margin Target",
      dataField: "marginTarget",
      grayBackground:false,
      showAsBlankIfZero:true,  
      style:"percent",
    },
    {
      id:2,
      text:"Expenses Growth Rate",
      dataField: "opexGrowth",
      grayBackground:false,
      showAsBlankIfZero:true,  
      style:"percent",
    },
    {
      id:3,
      text:"Interest Growth Rate",
      dataField: "interestGrowth",
      grayBackground:false,
      showAsBlankIfZero:true,  
      style:"percent",
    },
    {
      id:4,
      text:"Other Inc. Growth Rate",
      dataField: "otherGrowth",
      grayBackground:false,
      showAsBlankIfZero:true,  
      style:"percent",
    },
    {
      id:5,
      text:"Inc.Taxes Effec. Rate",
      dataField: "taxRate",
      grayBackground:false,
      showAsBlankIfZero:true,  
      style:"percent",
    },
    {
      id:6,
      text:"Capex Growth Rate",
      dataField: "capexGrowth",
      grayBackground:false,
      showAsBlankIfZero:true,  
      style:"percent",
    },
    {
      id:7,
      text:"Net WCC Growth Rate",
      dataField: "nwcGrowth",
      tooltipText:"Net Working Capital Changes Growth Rate",
      grayBackground:false,
      showAsBlankIfZero:true,  
      style:"percent",
    },
    {
      id:8,
      text:"Perpetual Growth Rate",
      dataField: "perpetualGrowthRate",
      grayBackground:false,
      showAsBlankIfZero:true,  
      style:"percent",
    },
  ] :
  [
    {
      id:0,
      text:"Cash Flow Growth Rate",
      dataField: "cashFlowAvgGrowth",
      grayBackground:false,
      showAsBlankIfZero:true,  
      style:"percent",
    },
    {
      id:1,
      text:"Perpetual Growth Rate",
      dataField: "perpetualGrowthRate",
      grayBackground:false,
      showAsBlankIfZero:true,  
      style:"percent",
    },
    {
      id:2,
      text:"Cash Flow Dicrete Period",
      dataField: "cashFlowDiscretePeriod",
      grayBackground:false,
      showAsBlankIfZero:true,  
      style:"decimal",
    },
  ]

  return (
    <>
    <TableContainer component={Paper}>
      <Table className = {classes.table} size="small" aria-label="stycky header">

        <TableHead className = {classes.TableHeader}>
          <TableRow>
            <TableCell colSpan={6} className = {classes.TableTitle} align="left">{`Business Assumptions`}</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <CreateTable dataRows={dataRows} dataSource={assumptions} numberOfColumns={dataPerLine} tableFontSize={"9px"}/>
        </TableBody>
      </Table>
    </TableContainer>
    </>
  ) 
}