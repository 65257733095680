import React, { useState }  from 'react';

import { Paper, TableContainer, Table, TableBody, TableHead, TableSortLabel, TableRow, TableCell, TableFooter, TablePagination, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import useTableSorting from '../../hooks/useTableSorting';

import Disclaimer from '../../components/Disclaimer';
import TablePaginationActions from '../../components/TablePaginationActions';

const useStyles = makeStyles( (mainTheme) => ({
  table: {
    minWidth: 350,
    maxHeight: 900
  },
  TableHeader:{
    color: "white",
    backgroundColor: mainTheme.palette.primary.main,
    height:"36.59px",
    padding:"6px",
    margin:"2px"
  },
  TableTitle:{
    color: "white",
    fontSize: 11,
    [mainTheme.breakpoints.down('sm')]: {
      fontSize: 9,
    },
    height:"28.72px",
    padding:"2px",
    margin:"2px"
  },
  TableRows : {
    fontSize: 11,
    [mainTheme.breakpoints.down('sm')]: {
      fontSize: 11,
    },
    height:"28.72px",
    padding:"2px",
    margin:"2px"
  },
  tablePaginationStyle:{
    [mainTheme.breakpoints.down('sm')]: {
      "& .MuiTablePagination-displayedRows": { display: "none" }
    },
  },
  sortLabelStyle: {
    color: 'white',
    '&:hover': {
      color: 'orange',
    },
    '& .MuiTableSortLabel-icon': {
      color: 'orange !important',
    },
    '&.Mui-active': {
      color: 'orange',
    },
  },
    // ButtonTable:{
  //   display: 'inline-block',
  //   padding:0,
  //   minHeight: 0,
  //   minWidth: 0,
  //   color: mainTheme.palette.primary.main,
  //   fontSize: "9px",
  //   textTransform:"none",
  //   "&:hover": {
  //     color:mainTheme.palette.secondary.main,
  //     backgroundColor:"whitesmoke"
  //   },
  // },
}));

export default function TableGraham ({companiesList}) {
  const classes = useStyles();
  const [ page, setPage ] = useState(0);
  const [ rowsPerPage, setRowsPerPage ] = useState(15); // Pre defined rows per change
  const [ orderDirection, setOrderDirection ] = useState('asc');
  const [ orderBy, setOrderBy ] = useState('marginOfSafety');
  const { getComparator, handleRequestSort } = useTableSorting();

  const createSorthandler=(property) => (event) => {
    handleRequestSort(event, property, orderDirection, setOrderDirection, orderBy, setOrderBy);
  }

  var emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - companiesList.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }; 

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  return (
    <>
    <TableContainer component={Paper} >
      <Table className={classes.table} size="small" aria-label="stycky header" >
        <TableHead className={classes.TableHeader}>
          <TableRow >
            <TableCell className={classes.TableTitle} style={{width:"50%",position:"sticky", left:0, zIndex:2}}  align="left" key="shortName">
              <TableSortLabel 
                active={orderBy==="shortName"} 
                // style={{width:"46%", paddingLeft:"5px", paddingRight:"2px"}}
                style={{width:"25%", paddingLeft:"5px", paddingRight:"2px"}}

                direction={orderBy==="shortName" ? orderDirection : 'asc'} 
                onClick={createSorthandler("shortName")}
                className={classes.sortLabelStyle}
                >Company</TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableTitle} style={{width:"6%"}} align="right">Symbol</TableCell>
            <TableCell className={classes.TableTitle} style={{width:"12%", paddingLeft:"3px", paddingRight:"3px"}} align="center">Stock Price</TableCell>
            <Tooltip title="The Graham Intrinsic Value is the price calculated for a stock using de Ben Graham formula">
              <TableCell className={classes.TableTitle} style={{width:"16%", paddingLeft:"3px", paddingRight:"3px"}} align="center">Intrinsic Value</TableCell>
            </Tooltip>
            <TableCell className={classes.TableTitle} style={{width:"20%",paddingLeft:"0px", paddingRight:"0px", fontSize:10}} padding="none" align="right" key="marginOfSafety" >
              <TableSortLabel 
                active={orderBy==="marginOfSafety"} 
                // style={{width:"106px", paddingLeft:"0px", paddingRight:"4px"}}
                style={{width:"100px", paddingLeft:"0px", paddingRight:"4px"}}
                direction={orderBy==="marginOfSafety" ? orderDirection : 'desc'} 
                onClick={createSorthandler("marginOfSafety")}
                className={classes.sortLabelStyle}
                >Mg.Of Safety</TableSortLabel>
            </TableCell>
            {/* </Tooltip> */}
          </TableRow>
        </TableHead>
        
        <TableBody>
          {(rowsPerPage > 0 ? 
            companiesList.slice().sort(getComparator(orderDirection, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : companiesList
            ).map((company) => (
              <TableRow key={company.companyId}>
                <TableCell align="left" className={classes.TableRows} style={{position:"sticky", left:0,  paddingLeft:"6px", paddingRight:"0px", backgroundColor:"white",color: company.marginOfSafety < 0 ? "#344955": "#344955"}} >{company.shortName}</TableCell>  
                <TableCell align="right" className={classes.TableRows} style={{color: company.marginOfSafety < 0 ? "#344955": "#344955"}} >{company.companyId}</TableCell>
                <TableCell align="right" className={classes.TableRows} style={{color: company.marginOfSafety < 0 ? "#344955": "#344955"}} >{Intl.NumberFormat('en-US',{style:'currency',currency:'USD'}).format(company.regularMarketPrice)}</TableCell>
                <TableCell align="right" className={classes.TableRows} style={{color: company.marginOfSafety < 0 ? "#344955": "#344955"}} >{Intl.NumberFormat('en-US',{style:'currency',currency:'USD'}).format(company.grahamIntrinsicPrice)}</TableCell>
                <TableCell align="right" className={classes.TableRows} style={{color: company.marginOfSafety < 0 ? "#344955": "#344955"}} >{Intl.NumberFormat('en-US',{style:'percent', minimumFractionDigits:2}).format(company.marginOfSafety)}</TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 28.72 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
        </TableBody>
        
        <TableFooter>
          <TableRow>
            <TablePagination
              // rowsPerPageOptions={[15, { label: 'All', value: -1 }]} : MUI's default
              rowsPerPageOptions={[10]}
              colSpan={3}
              count={companiesList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              // onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              className={classes.tablePaginationStyle}
              // sx={{ "& .MuiTablePagination-displayedRows": { display: "none" }}}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
    <Disclaimer />
    </>
  );
}