import React from 'react';

import { Box, BottomNavigation, BottomNavigationAction } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ArchiveIcon from '@mui/icons-material/Archive';
import FavoriteIcon from '@mui/icons-material/Favorite';
import RecommendIcon from '@mui/icons-material/Recommend';

const useStyles = makeStyles( (mainTheme) => ({
  bottomNavStyle:{
    width: "400px",
    [mainTheme.breakpoints.down('sm')]: {
      width: "350px"
    },
  },  
  selected: {
    fontSize:'11px',
    padding:"0px"
  },
  actionLabel: {
    fontSize: '11px',
    padding:"0px"
  },
})) 



export default function BottomNavValuations ({valuationsFilterCriteria, setValuationsFilterCriteria}){
  const classes = useStyles();
  return (
    <>
    <Box className={classes.bottomNavStyle}>
      <BottomNavigation
        showLabels
        value={valuationsFilterCriteria}
        onChange={(event, newValue) => {
          setValuationsFilterCriteria(newValue);
        }}
        sx={{
          bgcolor: "whitesmoke", //"#F9AA33",
          '& .Mui-selected': {
            '& .MuiBottomNavigationAction-label': {
              fontSize: "11px", 
              transition: 'none',
              fontWeight: 'bold',
              lineHeight: '20px'
            },
            '& .MuiSvgIcon-root, & .MuiBottomNavigationAction-label': {
              color: "#F9AA33" 
            }
          }
        }}
      >
        <BottomNavigationAction label="Recommended" icon={<RecommendIcon />} className={valuationsFilterCriteria === 0 ? classes.selected : classes.actionLabel}/>
        <BottomNavigationAction label="My Favorites" icon={<FavoriteIcon />} className={valuationsFilterCriteria === 1 ? classes.selected : classes.actionLabel}/>
        <BottomNavigationAction label="My Valuations" icon={<ArchiveIcon />} className={valuationsFilterCriteria === 2 ? classes.selected : classes.actionLabel}/>
      </BottomNavigation>
    </Box>
    </>
  )
}