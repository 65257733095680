import React, { useState, useEffect } from 'react';

import { Grid, Paper, Box, Button, FormControlLabel, Checkbox, Grow, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';

import axios from "axios";
import api from '../services/api';
// import companiesList from './S&P500';

import Header from '../components/Header.js';

const useStyles = makeStyles( (mainTheme) => ({
contentStyle: {
  position: 'absolute',
  top: '65px'
},
paperStyle: {
  margin: 'auto',
  padding:'10px',
  minWidth: 350,
  maxWidth: 500,
  backgroundColor:mainTheme.palette.secondary.main,  
},
buttonStyle:{
  color: "white",
  backgroundColor:mainTheme.palette.primary.main,
  textTransform:"none",
  fontSize:12,
  margin: "10px",
  //marginTop: "15px",
  "&:hover": {
    color:mainTheme.palette.secondary.main,
    backgroundColor:mainTheme.palette.primary.main,
  },
},
}))

export default function GetYahooFinanceData (){
  const classes = useStyles();  
  // const companiesList = ['AMZN','MSFT','GOOG','META','IBM','CLX','CL','CVX','XOM','BP','SHEL','AAPL'];
  // const companiesList = ['TSLA'];
  const companiesList = ['AMZN','MSFT','GOOG','TSLA','AAPL'];
  const quoteTypeItems = [ "exchange", "shortName", "longName", "exchangeTimezoneName", "exchangeTimezoneShortName", "isEsgPopulated", "gmtOffSetMilliseconds", "quoteType", "symbol", "messageBoardId", "market" ];
  const summaryDetailItems = [ "beta", "marketCap", "fiftyTwoWeekHigh", "fiftyTwoWeekLow", "dividendRate", "dividendYield", "payoutRatio", "fiveYearAvgDividendYield", "trailingAnnualDividendRate", "trailingAnnualDividendYield", "forwardPE"];
  const financialDataItems = [ "totalCash","totalDebt" ];
  const priceItems = ["regularMarketPrice"]; 
  const incomeStatementAccounts = { costOfRevenue:0, discontinuedOperations:0, ebit:0, effectOfAccountingCharges:0,extraordinaryItems:0, grossProfit:0, incomeBeforeTax:0, incomeTaxExpense:0, interestExpense:0, minorityInterest:0, netIncome:0, netIncomeApplicableToCommonShares:0, netIncomeFromContinuingOps:0, nonRecurring:0, operatingIncome:0, otherItems:0, otherOperatingExpenses:0, researchDevelopment:0, sellingGeneralAdministrative:0, totalOperatingExpenses:0, totalOtherIncomeExpenseNet:0, totalRevenue:0 };
  const balanceSheetAccounts = { accountsPayable:0, capitalSurplus:0, cash:0, commonStock:0, endDate:"", goodWill:0, intangibleAssets:0, inventory:0, longTermDebt:0, longTermInvestments:0, netReceivables:0, netTangibleAssets:0, otherAssets:0, otherCurrentAssets:0, otherCurrentLiab:0, otherLiab:0, otherStockholderEquity:0, propertyPlantEquipment:0, retainedEarnings:0, shortLongTermDebt:0, shortTermInvestments:0, totalAssets:0, totalCurrentAssets:0, totalCurrentLiabilities:0, totalLiab:0, totalStockholderEquity:0, treasuryStock:0 };
  const cashFlowAccounts = { capitalExpenditures:0, changeInCash:0, changeToAccountReceivables:0, changeToInventory:0, changeToLiabilities:0, changeToNetincome:0, changeToOperatingActivities:0, depreciation:0, dividendsPaid:0, effectOfExchangeRate:0, investments:0, issuanceOfStock:0, netBorrowings:0, otherCashflowsFromFinancingActivities:0, otherCashflowsFromOperatingActivities:0, otherCashflowsFromInvestingActivities:0, repurchaseOfStock:0, totalCashFromFinancingActivities:0, totalCashFromOperatingActivities:0, totalCashflowsFromInvestingActivities:0 };
  let companyData = { companyId: "", exchange: "", exchangeTimezoneName: "", exchangeTimezoneShortName: "", gmtOffSetMilliseconds: "", isEsgPopulated: false, longName: "", market: "", messageBoardId: "", quoteType: "", shortName: "", symbol: "", marketCap:0, beta:0, regularMarketPrice:0, fiftyTwoWeekHigh:0, fiftyTwoWeekLow:0, dividendRate:0, dividendYield:0, payoutRatio:0, exDividendDate:0, fiveYearAvgDividendYield:0, trailingAnnualDividendRate:0, trailingAnnualDividendYield:0, forwardPE:0, trailingPE:0, totalCash:0, totalDebt:0 };
  let financials = Array.from(Array(4),() => ( {financialsId:"",companyId:"", period:0, year:0,...incomeStatementAccounts,...balanceSheetAccounts,...cashFlowAccounts}));
  let financialsQtr = financials.map(object => ({ ...object }));
  // const [ currentCompany, setCurrentCompany ] = useState("")
  // const [ currentPeriod, setCurrentPeriod ] = useState("")
  // const [ currentAccount, setCurrentAccount ] = useState("");
  // const [ currentValue, setCurrentValue ] = useState(0);
  // const [ currentYear, setCurrentYear] = useState("");
  // const [ isYear1Complete, setIsYear1Complete]=useState(false);
  // const [ isYear2Complete, setIsYear2Complete]=useState(false);
  // const [ isYear3Complete, setIsYear3Complete]=useState(false);
  // const [ isYear4Complete, setIsYear4Complete]=useState(false);

  async function handleFinancials (period, typeOfResults ){
    try {
      if (typeOfResults==="annual"){
        const response = await api.get (`/financials?financialsId=${financials[period].financialsId}`)
      } else {
        const response = await api.get (`/financialsqtr?financialsId=${financialsQtr[period].financialsId}`)
      }
      try {
        if (typeOfResults==="annual"){
          const data =  financials[period]  ;
          const response = await api.put ('/financials', data);
        } else {
          const data =  financialsQtr[period]  ;
          const response = await api.put ('/financialsqtr', data);
        }
        return { valid: true, message:"Éxito en actualización de datos financieros" };
      } catch (err) {
          const errorMsg = Object.values(err.response.data);
      }
    } catch (err) {
      if (err.response.status == 404) {  
        try {
          if (typeOfResults==="annual"){
            const data = financials[period];
            const response = await api.post('/financials', data );
          } else {
            const data = financialsQtr[period];
            const response = await api.post('/financialsqtr', data );
          }
          return { valid: true, message:"Éxito en inclusión de datos financieros" };
        } catch (err) {
          const errorMsg = Object.values(err.response.data);
          return { valid: false, message:"Error en inclusión de datos financieros" };
        }
      } else {
        const errorMsg = Object.values(err.response.data);
        return { valid: false, message:"Error en actualización de datos financieros" };
      }
    }
  }
  async function handleCompany(){
    // Actualiza los datos de la empresa o incluye nueva en caso que no este en la base de datos
    // setCurrentCompany(companyData.shortName);
    try { 
      // const response = await api.get ('/companies', { headers: { Authorization: companyData.companyId }})
      const response = await api.get (`/companies/${companyData.companyId}`)
      try {  // Si ya esta incluida, actualiza los datos
        const data =  companyData ;
        const response = await api.put ('/companies', data);
        if (response){
          return { valid: true, message:"Éxito en actualización de datos de la empresa" };
        }
        return { valid: false, message:"Error en actualización de datos de la empresa" };
      } catch (err) {
          const errorMsg = Object.values(err.response.data);
      }
    } catch (err) {

      if (err.response.status == 404) { // Si la empresa no esta en la base de datos incluye como nueva
        try {
          const data =  companyData ;
          if (companyData.beta !==0 && companyData.totalCash==0){ // testea si no esta trayendo ceros...
            const response = await api.post('/companies', data );
            if (response){
              // alert("actualizacion correcta")
              return { valid: true, message:"Éxito en inclusion de datos de la empresa" };
            } 
          } else {
            console.log("Error en datos de la API" + companyData.companyId)
            return { valid: false, message:"Error en datos de la API" };  
          }
          return { valid: false, message:"Error en inclusion de datos de la empresa" };
        } catch (err) {
            const errorMsg = Object.values(err.response.data);
            return { valid: false, message:"Error en inclusión de empresa" };
          }
      } else { // si es otro error que no el 404...
          const errorMsg = Object.values(err.response.data);
          return { valid: false, message:"Error en actualización de empresa" };
      }
    }
  }

async function updateYFData() {

  for (let i = 0; i < companiesList.length; i++) {
    const endPoint = { method: 'GET',url:'https://yh-finance.p.rapidapi.com/stock/v2/get-summary', params:  { symbol: companiesList[i], region: 'US' }, headers: {'X-RapidAPI-Host': 'yh-finance.p.rapidapi.com','X-RapidAPI-Key': '057477ec51mshd7404991ea3c956p1dc241jsn2fbfbe21b657'}}
    await axios.request(endPoint).then(function (response) {

      const yfData =  response.data;
      if (yfData) {
        companyData["companyId"] = companiesList[i];
        summaryDetailItems.map ((currAccount) => {
          companyData[currAccount] = yfData.summaryDetail[currAccount].raw ? yfData.summaryDetail[currAccount].raw : 0
        })
        quoteTypeItems.map ((currAccount) => {
          companyData[currAccount] = yfData.quoteType[currAccount] ? yfData.quoteType[currAccount] : 0
        })
        priceItems.map ((currAccount) => {
          companyData[currAccount] = yfData.price[currAccount].raw ? yfData.price[currAccount].raw : 0
        })
        financialDataItems.map ((currAccount) => {
          companyData[currAccount] = yfData.financialData[currAccount].raw ? yfData.financialData[currAccount].raw : 0
        })
      }
    }).catch(function (error) {
      // alert(`Data from ${symbol} was not found`)
      console.error(error);
    });
    if (handleCompany()) {
      const financialEndPoint = { method: 'GET',url:'https:yh-finance.p.rapidapi.com/stock/v2/get-financials', params:  { symbol: companiesList[i], region: 'US' }, headers: {'X-RapidAPI-Host': 'yh-finance.p.rapidapi.com','X-RapidAPI-Key': '057477ec51mshd7404991ea3c956p1dc241jsn2fbfbe21b657'}}
      await axios.request(financialEndPoint).then(function (response) {
  
        const yfDataFinancials =  response.data;
        if (yfDataFinancials){
          for (let j = 0; j < 4 ; j++) {
            financials[j]["companyId"] = companiesList[i] ;
            financials[j]["period"] = j;
            financialsQtr[j]["companyId"] = companiesList[i] ;
            financialsQtr[j]["period"] = j;
          
            Object.keys(incomeStatementAccounts).forEach( (key) => {  
              financials[j][key] = yfDataFinancials.incomeStatementHistory.incomeStatementHistory[j][key] && yfDataFinancials.incomeStatementHistory.incomeStatementHistory[j][key].raw !==undefined ? yfDataFinancials.incomeStatementHistory.incomeStatementHistory[j][key].raw : 0
              financialsQtr[j][key] = yfDataFinancials.incomeStatementHistoryQuarterly.incomeStatementHistory[j][key] && yfDataFinancials.incomeStatementHistoryQuarterly.incomeStatementHistory[j][key].raw !== undefined ? yfDataFinancials.incomeStatementHistoryQuarterly.incomeStatementHistory[j][key].raw : 0 
            })

            Object.keys(balanceSheetAccounts).forEach( (key) => {  
              if (key ==="endDate"){
                // setCurrentPeriod(yfDataFinancials.balanceSheetHistory.balanceSheetStatements[j][key] && yfDataFinancials.balanceSheetHistory.balanceSheetStatements[j][key].fmt !== undefined? yfDataFinancials.balanceSheetHistory.balanceSheetStatements[j][key].fmt : "")
                financials[j][key] = yfDataFinancials.balanceSheetHistory.balanceSheetStatements[j][key] && yfDataFinancials.balanceSheetHistory.balanceSheetStatements[j][key].fmt !== undefined? yfDataFinancials.balanceSheetHistory.balanceSheetStatements[j][key].fmt : ""
                financialsQtr[j][key] = yfDataFinancials.balanceSheetHistoryQuarterly.balanceSheetStatements[j][key] ? yfDataFinancials.balanceSheetHistoryQuarterly.balanceSheetStatements[j][key].fmt : ""
                financials[j]["financialsId"] = yfDataFinancials.balanceSheetHistory.balanceSheetStatements[j][key] && yfDataFinancials.balanceSheetHistory.balanceSheetStatements[j][key].fmt !== undefined? yfDataFinancials.balanceSheetHistory.balanceSheetStatements[j][key].fmt + companiesList[i] : "" 
                financialsQtr[j]["financialsId"] = yfDataFinancials.balanceSheetHistoryQuarterly.balanceSheetStatements[j][key] ? yfDataFinancials.balanceSheetHistoryQuarterly.balanceSheetStatements[j][key].fmt + companiesList[i] : ""
                financials[j]["year"] = yfDataFinancials.balanceSheetHistory.balanceSheetStatements[j][key] && yfDataFinancials.balanceSheetHistory.balanceSheetStatements[j][key].fmt !== undefined? financials[j]["endDate"].substr(0,4) : "" 
                financialsQtr[j]["year"] = yfDataFinancials.balanceSheetHistoryQuarterly.balanceSheetStatements[j][key] ? financialsQtr[j]["endDate"].substr(0,4) : ""
                financialsQtr[j]["quarter"] = yfDataFinancials.balanceSheetHistoryQuarterly.balanceSheetStatements[j][key] ? financialsQtr[j]["endDate"].substr(0,7) : ""
              } else {
                // setCurrentAccount(key)
                // setCurrentValue(yfDataFinancials.balanceSheetHistory.balanceSheetStatements[j][key] && yfDataFinancials.balanceSheetHistory.balanceSheetStatements[j][key].raw !==undefined? yfDataFinancials.balanceSheetHistory.balanceSheetStatements[j][key].raw : 0)
                financials[j][key] = yfDataFinancials.balanceSheetHistory.balanceSheetStatements[j][key] && yfDataFinancials.balanceSheetHistory.balanceSheetStatements[j][key].raw !==undefined? yfDataFinancials.balanceSheetHistory.balanceSheetStatements[j][key].raw : 0
                financialsQtr[j][key] = yfDataFinancials.balanceSheetHistoryQuarterly.balanceSheetStatements[j][key] && yfDataFinancials.balanceSheetHistoryQuarterly.balanceSheetStatements[j][key].raw !== undefined? yfDataFinancials.balanceSheetHistoryQuarterly.balanceSheetStatements[j][key].raw : 0
              }
            })

            Object.keys(cashFlowAccounts).forEach( (key) => {   
              financials[j][key] =  yfDataFinancials.cashflowStatementHistory.cashflowStatements[j][key] && yfDataFinancials.cashflowStatementHistory.cashflowStatements[j][key].raw !== undefined ? yfDataFinancials.cashflowStatementHistory.cashflowStatements[j][key].raw : 0 ;
              financialsQtr[j][key] = yfDataFinancials.cashflowStatementHistoryQuarterly.cashflowStatements[j][key] && yfDataFinancials.cashflowStatementHistoryQuarterly.cashflowStatements[j][key].raw !== undefined ? yfDataFinancials.cashflowStatementHistoryQuarterly.cashflowStatements[j][key].raw : 0;
            })
            handleFinancials(j,"annual");
            // if (financialsQtr[j]["totalRevenue"]==0 && financialsQtr[j]["netIncome"]==0 ){
              handleFinancials(j,"quarterly");
            // }
            
          }  // for j
        }
      }).catch(function (error) {
        console.error(error);
      });
    }
  } // for i
  console.log(summaryDetailItems);
  console.log(companyData);
  console.log(financials);
  console.log(financialsQtr); 
}

useEffect(() => {
  // updateYFData()
}, []);

  return <>
  {/* <Header /> */}
  <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" className={classes.contentStyle} style={{ minHeight: '85vh' }} >
  <Grow in timeout = {1000}>
    <Grid item xs={12} md={6} >

      <Paper className={classes.paper} elevation={12}>
        <Button 
          variant = "contained" 
          disableRipple
          onClick = {updateYFData} 
          className = {classes.buttonStyle}
          >Update Daily Data
        </Button>
      </Paper>
      {/* <Box style={{height:"10px"}}/>
      <Paper elevation={12} style={{margin:"5px", padding:"5px"}}>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="caption" gutterBottom>Company</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption" gutterBottom>{currentCompany}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption" gutterBottom>Financials period</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption" gutterBottom>{currentPeriod}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption" gutterBottom>{currentAccount}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption" gutterBottom>{currentValue? currentValue: ""}</Typography>
          </Grid>
        </Grid>
      </Paper> */}
      {/* <Paper elevation = {3} >
        <Grid container>
          <Grid item>
            <FormControlLabel 
              className = {classes.textCheckboxStyle}
              disabled = {isDisabledChkBox}
              control = {<Checkbox disableRipple style={{ paddingLeft: '20px' }} defaultChecked = {false} checked = {isYear1Complete} size = "small"/>} 
              label = {`Year 1 is completed `}
              onChange = {handleChangeChkBox}
            />
          </Grid>
        </Grid>
      </Paper> */}
    </Grid>
    </Grow>
  </Grid>
  </>;
}


// const axios = require('axios');
// const options = {
//   method: 'GET',
//   url: 'https://yh-finance.p.rapidapi.com/stock/v2/get-cash-flow',
//   params: {
//     symbol: 'AMZN',
//     region: 'US'
//   },
//   headers: {
//     'X-RapidAPI-Key': '057477ec51mshd7404991ea3c956p1dc241jsn2fbfbe21b657',
//     'X-RapidAPI-Host': 'yh-finance.p.rapidapi.com'
//   }
// };

// try {
// 	const response = await axios.request(options);
// 	console.log(response.data);
// } catch (error) {
// 	console.error(error);
// }