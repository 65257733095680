import React from 'react';
import { Paper, Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((mainTheme) => ({
  paperStyle:{
    // width: "100%",
    marginLeft:"5px",
    marginRight:"5px",
    padding: "5px",
    paddingBottom:"0px",
    // color: mainTheme.palette.primary.main,
    backgroundColor:"white",
  },
  sectionTitleStyle:{
    marginLeft:"0px",
    marginTop:"3px",
    paddingLeft:"5px",
    fontSize: mainTheme.sectionTitle.fontSize,
    color: mainTheme.palette.tertiary.main, //mainTheme.sectionTitle.color
    // backgroundColor: mainTheme.palette.secondary.main,//"white",
  },
  textStyle:{
    marginLeft:"3px",
    marginTop:"3px",
    fontSize:"11px", 
    color: mainTheme.palette.tertiary.main,
  },
})); 

export default function AssumptionsText({assumptionsText}){
  const classes = useStyles();
    return (
			<>
      { assumptionsText ? 
			<Paper elevation={0} className={classes.paperStyle}>
        <Typography align="left" gutterBottom className={classes.sectionTitleStyle}>Assumptions Summary:</Typography>
        <Typography align="justify" gutterBottom className={classes.textStyle}>{assumptionsText}</Typography>
      </Paper> 
      : null}
			</>
		)
}