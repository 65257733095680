import React, { useState, useContext, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ReactGA from 'react-ga4';

import { Grid, Paper, Box, Stepper, Step, StepLabel, Typography, Radio, RadioGroup, FormControl, FormLabel, FormControlLabel, TextField, Button, Avatar, Grow, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';

import valuationsWebApi from '../../services/valuationsWebApi';
import { LoginContext } from '../../helpers/Context';
import useAxios from '../../hooks/useAxios.js';
import useForm from '../../hooks/useForm.js';
import useFetch from '../../hooks/useFetch';

import DialogModal from '../../components/modals/DialogModal';
import YourAccount from './YourAccount.jsx';
import PersonalData from './PersonalData.jsx';
import ProfilePicture from './ProfilePicture.jsx';
import EmailCode from './EmailCode.jsx';
import StripeCheckout from './StripeCheckout.jsx';

const useStyles = makeStyles((mainTheme) => ({
  gridContainerStyle:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '85vh', 
    [mainTheme.breakpoints.down('lg')]: {
      minHeight:"85vh",
    },
    marginTop: "64px",
    padding:"0px",
  },
  paperStyle: {
    // display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 350,
    // width:"100%",
    height: 470,
    margin: '10px',
    // backgroundColor:mainTheme.palette.secondary.main,  
    backgroundColor:mainTheme.palette.secondary.main,  
    padding: mainTheme.spacing(2),
  },
  customLabelStyle: {
    fontSize: "12px"
  },
  photoStyle:{
    height: '90px',
    width: "90px",
    textAlign: "center",
    display: "block",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    marginTop: "10px",
    marginBottom:"10px",
  }, 
  iconBox:{
    width:'100%',
    textAlign: 'center',
    AlignItems: 'center',
  },
  iconStyle:{
    color:mainTheme.palette.primary.main,
  },
  textStyle:{
    // color:mainTheme.palette.primary.main,
    color:"black"
  },
  welcomeMessageStyle:{
    // color:mainTheme.palette.primary.main
    color:"black"

  },
  userPhotoStyle:{
    height: mainTheme.userPhotoStyle.height,
    width: mainTheme.userPhotoStyle.width,
    alignItems: mainTheme.userPhotoStyle.alignItems,
    marginTop: mainTheme.userPhotoStyle.marginTop,
    marginBottom:mainTheme.userPhotoStyle.marginBottom,
    marginLeft:mainTheme.userPhotoStyle.marginLeft,
    marginRigth:mainTheme.userPhotoStyle.marginRight,
    display: mainTheme.userPhotoStyle.display,
    textAlign: mainTheme.userPhotoStyle.textAlign,
    justifyContent: mainTheme.userPhotoStyle.justifyContent,
  },
  buttonStyle:{
    color: "white",
    // color:mainTheme.palette.primary.main,
    textTransform:"none",
    fontSize: 12,
    margin: "3px",
    backgroundColor:mainTheme.palette.primary.main,
    "&:hover": {
      color:mainTheme.palette.secondary.main,
      backgroundColor:mainTheme.palette.primary.main,
    },
    "&:disabled": {
      color:"gray",
      backgroundColor:mainTheme.palette.primary.main,
    },
  },
  alreadyRegisteredLinkStyle:{
    marginTop:"5px",
    marginLeft:"10px",
    fontSize:12,
    color:"blue"
  },
  loginLinkStyle:{
    marginTop:"5px",
    marginLeft:"5px",
    fontSize:12,
    color:"blue"
  }
  }));

export default function Register(){
  const classes = useStyles();
  // const inputRef = useRef(null);
  const { product } = useParams();
  const productCode= product? product : "1"
  const storedValueLS = localStorage.getItem('isUpgradeComplete');
  const isUpgradeComplete = (storedValueLS !== null) ? JSON.parse(storedValueLS) : true;
  const stripeCustomerId = localStorage.getItem('customer');
  const storedValue = localStorage.getItem('isRegisterComplete');
  const isRegisterComplete = (storedValue !== null) ? JSON.parse(storedValue) : true;
  const storedUserData = localStorage.getItem('registerData') ? JSON.parse(localStorage.getItem('registerData')): "";
  const { userData, setUserData, usersImagesList, setUsersImagesList, setIsValuationSample, isStripeCheckout, setIsStripeCheckout }= useContext(LoginContext);
  const [ steps, setSteps] = useState(product==="0"? ['Your Account', 'Personal Data (optional)', 'Profile Picture (optional)','Code sent by Email'] :['Your Account', 'Personal Data (optional)', 'Profile Picture (optional)','Payment Info'])
  const [activeStep, setActiveStep] = useState(isStripeCheckout ? 2: 0);
  const [ userImageBase64, setUserImageBase64] = useState(null);
  const [ userImageFile, setUserImageFile] = useState(null);
  let userRegistrationData={id:"", password:"", product:"", firstName:"", lastName:"", phone:"", birthday:null, occupation:"", company:"",email:"", country:"", city:"", description:"", website:"", twitter:"", linkedin:"", facebook:"", instagram:"", youtube:"", tiktok:"",stripeCustomerId:"" }
  const { handleChange, isValidName, isValidPhone, noBlanks, isValidUser, registerData, setRegisterData, formErrors, setFormErrors, isDialogOpen, handleDialogClose, dialogOptions} = useForm ();
  const { saveUser2, createSession } = useFetch ({userRegistrationData})
  const [code, setCode] = useState({generatedCode:"", inputCode:""});
  const [codeError, setCodeError]=useState("");
  // const { axiosFetch: postCreateCheckoutSession } = useAxios();
  // const { axiosFetch: postSession } = useAxios(); 
  const { axiosFetch: delUser } = useAxios();
  // const { axiosFetch: putUser } = useAxios();
  const { axiosFetch: delStripeUser } = useAxios();
  const { axiosFetch: postCreateStripeConsumer } = useAxios(); 
  const { axiosFetch: postCreateStripeCheckout } = useAxios(); 
  const [ isEditField, setIsEditField] = useState(false)// eliminar?
  const countryObject = {id: registerData.country, name: registerData.countryName}
  const history = useHistory();

  const handleCancel=()=>{
    setRegisterData({ id:"", password:"", confirmPassword:"", product:"",firstName:"",lastName:"", country:"", countryName:"", phone:"", birthday:null, occupation:"", company:"",email:"", city:"", description:"", website:"", twitter:"", linkedin:"", facebook:"", instagram:"", youtube:"", tiktok:"", photo:"", banner:"",stripeCustomerId:"", stripeSubscriptionId:""});
    setUserImageFile(null);
    setUserImageBase64(null);
    history.goBack()
  }


  const handleFreePlan=()=>{
    const product="0";
    // alert(" history.push(`/home/${product}")
    history.push(`/home/${product}`);
  }

  const createStripeCheckoutSuccessCb=(apiData)=>{
    // localStorage.setItem('isRegisteringPlan', JSON.stringify(true));
    handleFinish();
    const url = apiData.url;
    window.location = url  //Llama la ventana de checkout de Stripe
  }

  const createStripeCheckoutErrorCb=(error)=>{
    // alert("Error in createStripeCheckoutErrorCb: "+ error)
  }

  const createStripeCustomerSuccessCb=(apiData)=>{
    localStorage.setItem('userId', registerData.id);
    localStorage.setItem('customer', apiData.customer.id);
    localStorage.setItem('newProduct', registerData.product);
    const customer = apiData.customer.id;
    const customer_email = registerData.id ;
    // alert("createStripeCheckout, customer_email: "+ customer_email + "customer_id: " + apiData.customer.id)
    //* refactorar: poner como variable de ambiente
    // const priceId =  registerData.product==="1"? "price_1OdhEWLCP51AssL9vkCYk7iJ":"price_1OdhEyLCP51AssL9qZemHRcz"; 
    const priceId =  registerData.product==="1"? "price_1PIf8VLCP51AssL97X5LY1Ha":"price_1PIf9BLCP51AssL9CZmTQ7Aw"; 
    const dataToProcess={customer,customer_email,priceId }
    postCreateStripeCheckout({ axiosInstance: valuationsWebApi, method: 'POST', url: '/create-stripe-checkout', data: dataToProcess, requestConfig: { headers: {'Authorization':registerData.id ,},}},createStripeCheckoutSuccessCb, createStripeCheckoutErrorCb);
  }

  const createStripeCustomerErrorCb=()=>{

  }

  const handleStripe = ()=>{
    const dataToProcess = { email: registerData.id }
    postCreateStripeConsumer({ axiosInstance: valuationsWebApi, method: 'POST', url: '/create-stripe-customer', data: dataToProcess, requestConfig: { headers: {'Authorization':registerData.id,},}},createStripeCustomerSuccessCb, createStripeCustomerErrorCb);
  }

  function checkFormErrors(){
    if (formErrors.id !=="" || formErrors.password !=="" ){
      return true
    }
    return false
  }

  const addUsersImagesList=(userImageBase64, id)=>{
    if (userImageBase64 && id){
      // const cleanedBase64 = removeBase64Prefix(userImageBase64);
      const newObject = { userId: id, image: userImageBase64 };
      setUsersImagesList([...usersImagesList, newObject]);
    }
  }

    // Graba el archivo de imagen en AWS S3, a traves de la API
    const saveUploadedFile = async (file, fileName) => {
      const formData = new FormData();
      formData.append('name',fileName)
      formData.append('image', file);
      try {
        const response = await valuationsWebApi.post('/user-image', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        const imageUrl = response.data.imageUrl; 
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    };
  
    function checkRequiredFields(){
      if (registerData.id !=="" && registerData.password !=="" && registerData.confirmPassword !==""){
        if (activeStep !== 3) {
          return true
        }
        else if (code.inputCode!=="" && code.inputCode===code.generatedCode){
          return true

        } else{
          return false
        }
      }
      return false
    }
  
    const validatePasswords = () => {
      if (registerData.password !=="" && registerData.confirmPassword !=="" && registerData.password !== registerData.confirmPassword) {
        setFormErrors (prevState => ({...prevState, password:'Passwords do not match', confirmPassword:"Passwords do not match"} ))
      } else {
        setFormErrors (prevState => ({...prevState, password:"", confirmPassword:""}))
      }
    };

    const handleNext = () => {
      setActiveStep ((prevStep) => prevStep + 1);
      if (activeStep===3){
        if (registerData.product==="0"){
          handleFinish();
          // alert("vai para o finish com o email code validado: "+ registerData.product)
        } 
      } else if (activeStep===2 && registerData.product!=="0"){
        // alert("vai p stripe checkout "+ registerData.product)
        handleStripe();
      }
    };
  
    const handleBack = () => {
      setActiveStep((prevStep) => prevStep - 1);
    };

    function handleIdBlur(e){
      handleChange (e,setRegisterData,[isValidUser]);
      // alert(formErrors.id)
      // if (formErrors.id){
      //   inputRef.current.focus()
      // }
    }

    const handleFinish = () => {
      if (! checkFormErrors() && checkRequiredFields()) {
        // alert("Entrou em handleFinish para gravar usuario no db");
        userRegistrationData.id = registerData.id;
        userRegistrationData.password = registerData.password;
        userRegistrationData.product =registerData.product;
        userRegistrationData.firstName = registerData.firstName.charAt(0).toUpperCase() + registerData.firstName.slice(1).toLowerCase();
        userRegistrationData.lastName = registerData.lastName.charAt(0).toUpperCase() + registerData.lastName.slice(1).toLowerCase();
        userRegistrationData.phone = registerData.phone;
        userRegistrationData.birthday=registerData.birthday? registerData.birthday.format("YYYY/MM/DD"): null;
        userRegistrationData.email = registerData.id;
        userRegistrationData.country = registerData.country
        if (registerData.product!=="0"){
          localStorage.setItem('registerData', JSON.stringify(registerData));
          localStorage.setItem('isRegisterComplete', JSON.stringify(false));
        } else {
          setUserData({
            userId:registerData.id, 
            userPassword:registerData.password, 
            userProduct: registerData.product, 
            userFirstName:registerData.firstName.charAt(0).toUpperCase() + registerData.firstName.slice(1).toLowerCase(), 
            userLastName:registerData.lastName.charAt(0).toUpperCase() + registerData.lastName.slice(1).toLowerCase(), 
            userCountry:registerData.country, 
            userCountryName:registerData.countryName,
            userBirthday:registerData.birthday, 
            userPhone:registerData.phone, 
            userDescription:registerData.description})
        }
        saveUser2({userRegistrationData});
        saveUploadedFile(userImageFile, registerData.id );      // Graba la nueva foto en el servidor
        addUsersImagesList(userImageBase64, registerData.id ); // actualiza el state para mostrar la nueva foto
        setUserImageFile(null);
        setUserImageBase64(null);
        setIsValuationSample(false);
      } else {
        console.log("Not saved in database")
      }
    };
  
    const handleRadioValue=(e)=>{
      if (e.target.value==="0"){
        setSteps(['Your Account', 'Personal Data (optional)', 'Profile Picture (optional)','Code sent by email'])
      } else {
        setSteps(['Your Account', 'Personal Data (optional)', 'Profile Picture (optional)','Payment Info'])
      }
      setRegisterData (prevState => ( {...prevState, product: e.target.value}));
    }

    const renderStepContent = (step) => {
      switch (step) {
        case 0:
          return (
            <>
            <Grid container spacing={1}>
              
              <Grid item xs={12}>

                <Box style={{display:'flex', justifyContent:"flex-end"}}> 
        
                  <FormControl >
                    <Box style={{height:"5px"}}/>
                    <FormLabel id="demo-controlled-radio-buttons-group" sx={{fontSize:"12px", color:"black"}} >Choose your Plan:</FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={registerData.product}
                      onChange={handleRadioValue}
                    >
                      <FormControlLabel value="0" control={<Radio size="small" disableRipple />} label="Free Plan" />
                      <FormControlLabel value="1" control={<Radio size="small" disableRipple />} label="Monthly Plan" />
                      <FormControlLabel value="2" control={<Radio size="small" disableRipple />} label="Annual Plan" />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>  
          <YourAccount 
            registerData = {registerData}
            setRegisterData = {setRegisterData}
            handleChange = {handleChange}
            isValidUser = {isValidUser}
            noBlanks = {noBlanks}
            formErrors = {formErrors}
            setFormErrors = {setFormErrors}
          />
          </>
          );
        case 1:
          return (
            <PersonalData 
              registerData = {registerData}
              setRegisterData = {setRegisterData}
              useForm = {useForm}
              isEditField = {isEditField} 
              setIsEditField = {setIsEditField}
            />
          );
        case 2:
          return (
            <ProfilePicture
              setUserImageFile = {setUserImageFile}
              userImageBase64 = {userImageBase64}
              setUserImageBase64 = {setUserImageBase64}
              setIsEditField = {setIsEditField}
            />
          );
        case 3:
          return(
          <>
            { registerData.product ==="0" ? 
              <EmailCode
                registerData = {registerData}
                code = {code}
                setCode = {setCode}
                codeError = {codeError} 
                setCodeError = {setCodeError}
              />
            :
              <StripeCheckout />
            }
          </>
          )
        default:
          return null;
      }
    };

    const delStripeUserSuccessCb=()=>{
      localStorage.removeItem("registerData")
      localStorage.removeItem("isRegisterComplete")
      localStorage.removeItem("customer")
    }
  
    const delStripeUserErrorCb=()=>{
      localStorage.removeItem("registerData")
      localStorage.removeItem("isRegisterComplete")
      localStorage.removeItem("customer")
  
    }

    const delUserSuccessCb=()=>{
      if(stripeCustomerId){
        delStripeUser({ axiosInstance: valuationsWebApi, method: 'DELETE', url: `/stripe-customer/${stripeCustomerId}`, requestConfig: { headers: {'Authorization': storedUserData.id,},}},delStripeUserSuccessCb, delStripeUserErrorCb);
      }
    }
  
    const delUserErrorCb=()=>{
      localStorage.removeItem("registerData")
      localStorage.removeItem("isRegisterComplete")
    }

    useEffect(() => { 
      ReactGA.send({ hitType: "pageview", page: "/register", title: "Register" });
      if (! isRegisterComplete && storedUserData!=""){
        delUser({ axiosInstance: valuationsWebApi, method: 'DELETE', url: `/users/${storedUserData.id}`, requestConfig: { headers: {'Authorization': storedUserData.id,},}},delUserSuccessCb, delUserErrorCb);
        
      } 
      setRegisterData (prevState => ({...prevState, product:productCode}))
    }, []);

  return (
    <>
    <Grid container alignItems="center" justifyContent="center" spacing={1}  className={classes.gridContainerStyle}  >
      <Grid item>
        <Grow in timeout = {1000}>
          <Paper elevation={12} className={classes.paperStyle} style={{paddingBottom: "10px"}} >
            <Box style={{display:"flex", flexDirection:"column", height:"100%", justifyContent:"space-between"}}>
              <Box>
              <Typography align='center' gutterBottom  className={classes.textStyle} >Sign Up & Register</Typography>  
              <Box className={classes.iconBox} >
                <AppRegistrationIcon className={classes.iconStyle} style={{ fontSize: 40 }}/>
              </Box>
                <Stepper activeStep={activeStep} alternativeLabel style={{height:"90px"}} > 
                  {steps.map((label) => (
                    <Step key={label} >
                      <StepLabel classes={{label: classes.customLabelStyle}} >{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                <Box style={{height:"5px"}}/>

                {activeStep === steps.length ? ( // si es la ultima pantalla...
                  <Grid container spacing={1}>
                    <Grid item container display="flex" alignItems="center" justifyContent="center" xs={12} style={{marginBottom:"8px"}}>
                    {/* <CheckCircleOutlineIcon className={classes.iconStyle} style={{ fontSize: 40 }} /> */}
                      <Box style={{height:"40px"}}/>  
                      <Typography variant="body2" align="center" gutterBottom className={classes.textStyle} ><b>Registration Complete!</b></Typography>
                      <Typography variant="caption" align="justify" paragraph className={classes.welcomeMessageStyle} >
                        Welcome!!  By joining our platform, you've gained access to a thriving community where you can connect with like-minded individuals, share your passions, and engage in meaningful conversations. In addition, you'll also have the opportunity to enhance your investment skills by exploring valuable financial information, empowering you to make informed decisions and excel as an investor.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} display="flex" alignItems="center" justifyContent="center" style={{paddingTop:"0px"}}>
                       {/* disabled={checkFormErrors() || ! checkRequiredFields()} */}
                      <Button autofocus variant="contained"  disabled={checkFormErrors() || ! checkRequiredFields()} className={classes.buttonStyle} disableRipple  color="primary" onClick={handleFreePlan}>
                        Go to main page
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    <form>
                      {renderStepContent(activeStep)}
                    </form>  
                  </>
                )}
              </Box>
              {activeStep !== steps.length ? ( 
                <Box>
                  <Grid container item direction="row" alignItems="flex-end" justifyContent="flex-end"  >
                    { activeStep > 0 ?
                      <Button disableRipple className={classes.buttonStyle} disabled={activeStep === 0} onClick={handleBack} >
                        Back
                      </Button>
                    : 
                      <Button disableRipple className={classes.buttonStyle} onClick={handleCancel} >
                        Cancel
                      </Button>
                    }
                    {/* disabled={checkFormErrors() || ! checkRequiredFields()}  */}
                    <Button disableRipple disabled={checkFormErrors() || ! checkRequiredFields()} className={classes.buttonStyle} onClick={handleNext}>
                      {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                  </Grid> 
                </Box>
              ): null}
            </Box>
          </Paper>
      </Grow>
      </Grid>
      </Grid>
      <DialogModal open={isDialogOpen} onClose={handleDialogClose} severity={dialogOptions.severity} title={dialogOptions.title} buttons={dialogOptions.buttons} action={dialogOptions.action}>
        {dialogOptions.message}
      </DialogModal>
    </>
  ) 
}