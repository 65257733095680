import React, { useRef } from 'react';

import {Grid, Typography, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((mainTheme) => ({
  alreadyRegisteredLinkStyle:{
    marginTop:"5px",
    marginLeft:"10px",
    fontSize:12,
    color:"blue"
  },
  loginLinkStyle:{
    marginTop:"5px",
    marginLeft:"5px",
    fontSize:12,
    color:"blue"
  }
  }));

export default function YourAccount({registerData, setRegisterData, handleChange, isValidUser, noBlanks, formErrors, setFormErrors}){
  const classes = useStyles();
  const inputRef = useRef(null);

  function handleIdBlur(e){
    handleChange (e,setRegisterData,[isValidUser]);
  }

  const validatePasswords = () => {
    if (registerData.password !=="" && registerData.confirmPassword !=="" && registerData.password !== registerData.confirmPassword) {
      setFormErrors (prevState => ({...prevState, password:'Passwords do not match', confirmPassword:"Passwords do not match"} ))
    } else {
      setFormErrors (prevState => ({...prevState, password:"", confirmPassword:""}))
    }
  };

  return (
  <>
  <Grid container spacing={1}>
    <Grid item xs={12}>
      <TextField 
        variant ="filled" margin="dense" size="small" fullWidth  
        label="Your E-mail *"
        name="id"
        value={registerData.id}
        autoComplete="email"
        inputRef={inputRef}
        onChange={ (e) => {
          handleChange (e,setRegisterData,[]);
        }}
        onBlur={(e) => handleIdBlur(e)}
        inputProps={{style: {fontSize: 14}}} 
        error={formErrors.id}
      />
      {formErrors.id ? <div className="error-helper-text">{formErrors.id}</div> : null}
    </Grid>

    <Grid item xs={6}>
      <TextField 
        variant ="filled" margin="dense" size="small" fullWidth  
        label="Create Your Password *"
        name="password"
        type="password"
        value={registerData.password}
        autoComplete="new-password"
        onChange={ (e) => {
          handleChange (e,setRegisterData,[noBlanks]);
        }}
        onBlur={validatePasswords}
        inputProps={{style: {fontSize: 14}}} 
        error={formErrors.password}
      />
      {formErrors.password ? <div className="error-helper-text">{formErrors.password}</div> : null}
    </Grid>

    <Grid item xs={6}>
      <TextField 
        variant ="filled" margin="dense" size="small" fullWidth  
        label="Confirm Your Password *"
        name="confirmPassword"
        type="password"
        value={registerData.confirmPassword}
        autoComplete="confirm-password"
        onChange={ (e) => {
          handleChange (e,setRegisterData,[noBlanks]);
        }}
        onBlur={validatePasswords}
        inputProps={{style: {fontSize: 14}}} 
        error={formErrors.confirmPassword}
      />
      {formErrors.confirmPassword ? <div className="error-helper-text">{formErrors.confirmPassword}</div> : null}
    </Grid>
    <Typography className={classes.alreadyRegisteredLinkStyle}>Already registered ?</Typography>
    <Typography className={classes.loginLinkStyle}><a href="/login">Login up here !</a></Typography>
  </Grid>
  </>
  )
}