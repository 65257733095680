import React, { useContext, useEffect } from  'react';
import { useHistory, useParams } from 'react-router-dom';

import {Grid, Paper, Box, Typography, Button, Grow } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { LoginContext } from '../helpers/Context';


const useStyles = makeStyles((mainTheme) => ({
  gridContainerStyle:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '80vh', 
    [mainTheme.breakpoints.down('lg')]: {
      minHeight:"85vh",
    },
    marginTop: "64px",
    padding:"0px",
  },
  paperStyle: {
    // display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 350,
    // width:"100%",
    height: 350,
    margin: '10px',
    // backgroundColor:mainTheme.palette.secondary.main,  
    backgroundColor:mainTheme.palette.secondary.main,  
    padding: mainTheme.spacing(2),
  },
  iconBox:{
    width:'100%',
    textAlign: 'center',
    AlignItems: 'center',
  },
  iconStyle:{
    color:mainTheme.palette.primary.main,
  },
  textStyle:{
    // color:mainTheme.palette.primary.main,
    color:"white"
  },
  welcomeMessageStyle:{
    color:mainTheme.palette.primary.main
  },
  buttonStyle:{
    color: "white",
    // color:mainTheme.palette.primary.main,
    textTransform:"none",
    fontSize: 12,
    margin: "3px",
    backgroundColor:mainTheme.palette.primary.main,
    "&:hover": {
      color:mainTheme.palette.secondary.main,
      backgroundColor:mainTheme.palette.primary.main,
    },
    "&:disabled": {
      color:"gray",
      backgroundColor:mainTheme.palette.primary.main,
    },
  },
  }));


export default function StripeSuccess(){
  const classes = useStyles();
  const { session_id } = useParams(); // Parameter sent by stripe
  const { setUserData }= useContext(LoginContext)
  const storedUserData = JSON.parse(localStorage.getItem('registerData'));
  const isUpgradingPlan=localStorage.getItem('isUpgradingPlan');  
  const storedProduct = localStorage.getItem('newProduct')!==undefined? localStorage.getItem('newProduct'):"";  
  const history = useHistory();

  const handleFinish= async ()=>{
    if (storedUserData) { // ambos para isRegisterComplete & isUpgrading? sim!
      await setUserData({
        userId:storedUserData.id, 
        userPassword: storedUserData.password, 
        userProduct: storedUserData.product, 
        userFirstName:storedUserData.firstName.charAt(0).toUpperCase() + storedUserData.firstName.slice(1).toLowerCase(), 
        userLastName:storedUserData.lastName.charAt(0).toUpperCase() + storedUserData.lastName.slice(1).toLowerCase(), 
        userCountry:storedUserData.country, 
        userCountryName:storedUserData.countryName,
        userBirthday:storedUserData.birthday, 
        userDescription:storedUserData.description })
    } 
    localStorage.removeItem('registerData');
    localStorage.removeItem("isRegisterComplete");
    // localStorage.removeItem("isUpgrading");
    // localStorage.removeItem("registerData");
    
    // alert("StripeSuccess, func handleFinish, storedProduct: " + storedProduct)
    history.push(`/home/${storedProduct}`) // criar login....
  }

  return(
    <>
    <Grid container alignItems="center" justifyContent="center" spacing={1}  className={classes.gridContainerStyle}  >
      <Grid item>
      <Grow in timeout = {1000}>
        <Paper elevation={12} className={classes.paperStyle} style={{paddingBottom: "10px"}} >
          <Box style={{display:"flex", flexDirection:"column", height:"100%", justifyContent:"space-between"}}>
            <Box>
            { isUpgradingPlan ? 
              <Typography align='center' gutterBottom  className={classes.textStyle} >Upgrade</Typography>  
            : 
              <Typography align='center' gutterBottom  className={classes.textStyle} >Welcome to ValuationsHub</Typography>  
            }
            <Box className={classes.iconBox} >
              <CheckCircleOutlineIcon className={classes.iconStyle} style={{ fontSize: 40 }}/>
            </Box>
                <Grid container spacing={1}>
                  <Grid item container display="flex" alignItems="center" justifyContent="center" xs={12} style={{marginBottom:"8px"}}>
                  { isUpgradingPlan ?  
                  <>
                    <Typography variant="body2" align="center" gutterBottom className={classes.welcomeMessageStyle} ><b>
                      Your Upgrade is Complete !</b></Typography>
                    <Typography variant="caption" align="justify" paragraph className={classes.welcomeMessageStyle} >
                      We're thrilled to have you as a valued member of our platform, now with unlimited access. Dive into our vibrant community, connect with like-minded individuals, and share your passions through meaningful conversations. Take advantage of unlimited access to explore valuable financial information, empowering you to make informed decisions and excel in your investment endeavors. 
                    </Typography>             
                  </>
                  :
                  <>
                    <Typography variant="body2" align="center" gutterBottom className={classes.welcomeMessageStyle} ><b>
                      Your Registration is Complete !</b></Typography>
                    <Typography variant="caption" align="justify" paragraph className={classes.welcomeMessageStyle} >
                      Welcome!!  By joining our platform, you've gained access to a thriving community where you can connect with like-minded individuals, share your passions, and engage in meaningful conversations. In addition, you'll also have the opportunity to enhance your investment skills by exploring valuable financial information, empowering you to make informed decisions and excel as an investor.
                    </Typography>
                  </>
                  }
                  </Grid>
                  <Grid item xs={12} display="flex" alignItems="center" justifyContent="center" style={{paddingTop:"0px"}}>
                    <Button autofocus variant="contained" className={classes.buttonStyle} disableRipple color="primary" onClick={handleFinish}>
                      Go to main page
                    </Button>
                  </Grid>
                </Grid>
            </Box>
          </Box>
        </Paper>
      </Grow>
    </Grid>
    </Grid>
    </>
  )
}