import React from'react';

import { Grid, Paper, Box, Typography, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
// import picAmazon from '../../assets/amazon.jpeg'; // ver como sera en la realidad con el cambio diario de imaganes
// import picDoYouKnow from '../../assets/images/DoYouKnow4.jpg'; 
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import { Twitter } from '@material-ui/icons';


import picDoYouKnow from '../../assets/images/IPO-TSLA-vh.png'; 

// import { Twitter } from '@mui/icons-material/X';

const useStyles = makeStyles( (mainTheme) => ({
  sectionTitleStyle:{
    marginLeft:"5px",
    fontSize: mainTheme.sectionTitle.fontSize,
    color: mainTheme.sectionTitle.color
    // color:"whitesmoke"
  },
  iconStyle:{
    fontSize:20, 
    marginLeft:"5px",
    color:mainTheme.palette.secondary.main
  }
})) 

export default function DoYouKnow({isMobile, isTablet}){
  const classes = useStyles();
  const youtubeLink="https://www.youtube.com/@ValuationsHub/featured"
  return(
    <>
    {/* <Grid container direction="row" >

      <Grid item xs={8}>
        <Typography className={classes.sectionTitleStyle}><b>Follow us on our social networks</b></Typography>

      </Grid>
      <Grid item xs={4}>
        <Tooltip title="Go to valuationshub's youtube channel">
          <a href={youtubeLink} target="_blank" rel="noopener noreferrer">  
            <YouTubeIcon className = {classes.iconStyle} style={{marginLeft:"0px"}} />
          </a>
        </Tooltip>
 
        <Tooltip title="Go to valuationshub's X's account">
          <a href={'https://twitter.com/martincslopes'} target="_blank" rel="noopener noreferrer">  
            <Twitter className = {classes.iconStyle} />
          </a>
        </Tooltip>
        <Tooltip title="Go to valuationshub's Instagram account">
          <InstagramIcon className = {classes.iconStyle} />
        </Tooltip>
        <Tooltip title="Go to valuationshub's Facebook account">
          <FacebookIcon className = {classes.iconStyle} />
        </Tooltip>
      </Grid>
    </Grid> */}
    
    {/* <Box style={{height:"5px"}}/>   */}
    {/* "#F9AA33" */}
    <Paper elevation={isTablet && ! isMobile ? 0:6} style={{backgroundColor:"#b0c4c7"}}> 
      <Box style={{display:"flex", alignItems:"center", justifyContent: (isTablet || isMobile) ? "center":"center",backgroundColor:"#b0c4c7"}}>
        <img src = {picDoYouKnow} alt="hook" style={{height: isTablet? "200px": "300px"}} /> 
      </Box>
    </Paper>
    </>
  )
}
