import React,{ useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { format, parse } from 'date-fns';

import {Grid, Paper, Box, Typography, Button, Grow } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';

import { LoginContext } from '../helpers/Context';
import useAxios from '../hooks/useAxios';
import valuationsWebApi from '../services/valuationsWebApi';

import DialogModal from '../components/modals/DialogModal';
import PlanBenefitsFree from './PlanBenefitsFree';
import PlanBenefitsPaid from './PlanBenefitsPaid';

const useStyles = makeStyles((mainTheme) => ({
  gridContainerStyle:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '80vh', 
    [mainTheme.breakpoints.down('lg')]: {
      minHeight:"85vh",
    },
    marginTop: "64px",
    padding:"0px",
  },
  paperStyle: {
    // display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 370,
    // width:"100%",
    // height: 370,
    margin: '5px',
    // backgroundColor:mainTheme.palette.secondary.main,  
    backgroundColor:mainTheme.palette.secondary.main,  
    padding: mainTheme.spacing(2),
  },
  iconBox:{
    width:'100%',
    textAlign: 'center',
    AlignItems: 'center',
  },
  iconStyle:{
    color:mainTheme.palette.primary.main,
  },
  titleStyle:{
    // color:mainTheme.palette.primary.main,
    color:"black"
  },
  textStyle:{
    // color:mainTheme.palette.primary.main,
    color:"black",
    fontSize:"14px",
  },
  buttonStyle:{
    color: "white",
    // width:"135px",
    // color:mainTheme.palette.primary.main,
    textTransform:"none",
    // fontSize: 11,
    margin: "2px",

    backgroundColor:mainTheme.palette.primary.main,
    "&:hover": {
      color:mainTheme.palette.secondary.main,
      backgroundColor:mainTheme.palette.primary.main,
    },
    "&:disabled": {
      color:"gray",
      backgroundColor:mainTheme.palette.primary.main,
    },
  },
  }));

export default function Unsubscribe(){
  const classes = useStyles();
  const { userData } = useContext(LoginContext);
  const { userId, userProduct } = userData;
  const { axiosFetch: getUser } = useAxios();
  const { axiosFetch: delUser } = useAxios();
  const { axiosFetch: putUser } = useAxios();
  const { axiosFetch: postCancelSubscription } = useAxios();
  const { axiosFetch: getStripeExpDate } = useAxios();
  const [ isDialogOpen, setIsDialogOpen ] = useState(false);
  const [ dialogOptions, setDialogOptions ] = useState({severity:"",title:"",message:"",buttons:{},action:""});
  let stripeCustomerId="";
  let stripeExpDate="";
  const history = useHistory();

  const handleDialogClose=(value)=>{
    // alert("value: " + value + " userProduct: " + userProduct)
    if (value==="Confirm"){
      
      if (userProduct==="0"){
        // alert("entrou no if value: " + value + " userProduct: " + userProduct)
        delUser({ axiosInstance: valuationsWebApi, method: 'DELETE', url: `/users/${userId}`, requestConfig: { headers: {'Authorization': userId,},}},delUserSuccessCb, delUserErrorCb);
      } else {
          // alert("entrou no else value: " + value + " userProduct: " + userProduct)
          getUser({ axiosInstance: valuationsWebApi, method: 'GET', url: `/users/${userId}`, requestConfig: { headers: {'Authorization': userId,},}},getUserSuccessCallback, getUserErrorCallback);
      }
      
    } else {
      history.push("/home")
    }
  }

  const getPlanName=(userProduct)=>{
    if (userProduct==="0"){
      return "Free Plan"
    }
    if (userProduct==="1"){
      return "Monthly Plan"
    }
    return "Annual Plan"
  }

  const handleCancel=()=>{
    history.push('/home');
  }

  const putUserSuccessCb=()=>{
    // alert("Entou em putUserSuccessCb")
    // alert(format(stripeExpDate,'yy-MMM,d'))
    // const formattedDate = stripeExpDate.toString().slice(0, 19).replace('T', ' ');
    const expireDate = new Date(stripeExpDate);
    const formattedExpirationdate=format(expireDate,'yyyy-MMM,d')
    // alert(formattedExpirationdate)
    setDialogOptions({
      severity:"success", 
      title:"Successful Unsusbcribing", 
      message:`Subscription Canceled: You won't be charged anymore. Enjoy unlimited access until ${formattedExpirationdate}. While we're sad to see you go, you're always welcome back. Thank you for your support!`,
      buttons:{button1:"Ok"}})
    setIsDialogOpen (true);
  }

  const putUserErrorCb=(error)=>{
    // alert("There was an error in the server: putUserErrorCb " + error);
    history.push("/home");
  }

  const postCancelSubscriptionSuccessCb=(apiData)=>{
    // alert("postCancelSubscriptionSuccessCb: Successful cancel of Subscription")
    // const unsubscriptionDate = format(new Date(),'yyyy-MM-dd HH:mm:ss')
    const dataToProcess={id: userId, unsubscribedAt:stripeExpDate }
    putUser({ axiosInstance: valuationsWebApi, method: 'PUT', url: '/users', data: dataToProcess, requestConfig: { headers: {'Authorization': userId,},}}, putUserSuccessCb, putUserErrorCb);
  }

  const postCancelSubscriptionErrorCb=()=>{
    // alert("postCancelSubscriptionErrorCb: There was an error in the server, please try later")
    history.push('/logout');
  }

const getStripeExpDateSuccessCb=(apiData)=>{
  // alert("apiData.expireDate recibido en getStripeExpDateSuccessCb: " + apiData.expireDate)
  stripeExpDate=apiData.expireDate
  // alert("getStripeExpDateSuccessCb "+ stripeExpDate)
  postCancelSubscription({ axiosInstance: valuationsWebApi, method: 'POST', url: '/cancel-stripe-subscription', data: { customer: stripeCustomerId }, requestConfig: { headers: {'Authorization': userId,},}},postCancelSubscriptionSuccessCb, postCancelSubscriptionErrorCb);
}

const getStripeExpDateErrorCb=()=>{
  // alert("There was an error in the server. getStripeExpDateErrorCb. Please try later.")
  history.push("/home");
}

const getUserSuccessCallback=(apiData)=>{// getting undefined...why?
  // alert("entrou em getUserSuccessCallback")
  if (apiData){
    // console.log(apiData)
    stripeCustomerId=apiData.stripeCustomerId // receives the customer_id from the backend
    // alert("StripeCustomerId no bd "+ apiData.stripeCustomerId) // getting undefined
    if (stripeCustomerId){ // if there's a customer id, cancels the subscription
      getStripeExpDate({ axiosInstance: valuationsWebApi, method: 'GET', url: `/get-stripe-expire/${stripeCustomerId}`, requestConfig: { headers: {'Authorization': userId,},}},getStripeExpDateSuccessCb, getStripeExpDateErrorCb);
    } else {
      // alert("Cliente no tiene customer_id del stripe.")
      history.push("/home")
    }
  }
}

  const delUserSuccessCb=()=>{
    history.push('/logout');
  }

  const delUserErrorCb=()=>{
    // alert("Unsubscribe: error deleting user from database");
    history.push('/logout');
  }

  const getUserErrorCallback=()=>{
    // alert("There was a problem trying to cancel your subscription, please try later")
    history.push('/logout');
  }


  // se eh product="1" ou "2" nao deleta do banco de dados, apenas msg
  // const handleDialogClose=(value)=>{
  //   if (value==="Confirm" && userProduct==="0"){// confirms the unsubscribe

  //     delUser({ axiosInstance: valuationsWebApi, method: 'DELETE', url: `/users/${userId}`, requestConfig: { headers: {'Authorization': userId,},}},delUserSuccessCb, delUserErrorCb);
  //   } 
  //   else {
  //     getUser({ axiosInstance: valuationsWebApi, method: 'GET', url: `/users/${userId}`, requestConfig: { headers: {'Authorization': userId,},}},getUserSuccessCallback, getUserErrorCallback);
  //   }
  // }



  const handleUnsubscribe=()=>{
    setDialogOptions({
      severity:"error", 
      title:"Unsusbcribing", 
      message:`Are you sure you want to unsubscribe your ${getPlanName(userProduct)} ?`,
      buttons:{button1:"Cancel",button2:"Confirm"}})
      setIsDialogOpen (true);
  }

  return (
    <>
    <Grid container alignItems="center" justifyContent="center" spacing={1}  className={classes.gridContainerStyle}  >
      <Grid item >
        <Grow in timeout = {1000}>
          <Paper elevation={12} className={classes.paperStyle} style={{paddingBottom: "10px"}} > 
            <Typography align='center' gutterBottom  className={classes.titleStyle} >
              Unsubscribe Your Plan</Typography >   
            <Box className={classes.iconBox} >
              <PersonRemoveIcon className={classes.iconStyle} style={{ fontSize: 40 }} />
            </Box>
            <Box style={{height:"10px"}}/>
            <Typography align='center' gutterBottom  className={classes.textStyle} >
              We're sorry to see you go! Before you proceed, let us share some benefits you might be missing out on:</Typography>
               {/* Full access to create and share valuations, quiclky and easily. See valuations from other users to get insights. Interact with other users from our community. If there's anything specific you're unhappy with, please let us know, and we'll do our best to address your concerns.If you still decide to cancel, we appreciate your time with us and hope you consider coming back in the future. Thank you for being a part of our community!</Typography> */}
            <Box style={{height:"10px"}}/>
            { userProduct === "0" ?  
              <PlanBenefitsFree />
            :
              <PlanBenefitsPaid />
            } 
            <Box style={{height:"20px"}}/>
            <Grid item xs={12} display="flex" alignItems="center" justifyContent="center" style={{paddingTop:"0px"}} >
              <Button autofocus variant="contained" className={classes.buttonStyle} disableRipple color="primary" onClick={handleUnsubscribe} >
                Unsubscribe
              </Button>
              { userProduct==="0"? 
                <Button autofocus variant="contained" className={classes.buttonStyle} disableRipple color="primary" onClick={handleCancel} >
                  Stay in Free Plan
                </Button>              
              :
                <Button autofocus variant="contained" className={classes.buttonStyle} disableRipple color="primary" onClick={handleUnsubscribe} >
                  Try Free Plan
                </Button>              
              }
              { userProduct!=="0"? 
                <Button autofocus variant="contained" className={classes.buttonStyle} disableRipple color="primary" onClick={handleCancel} >
                  Stay in your Plan
                </Button> 
              : null}
            </Grid>
          </Paper>
        </Grow> 
      </Grid> 
    </Grid> 
    <DialogModal open={isDialogOpen} onClose={handleDialogClose} severity={dialogOptions.severity} title={dialogOptions.title} buttons={dialogOptions.buttons} action={dialogOptions.action}>
      {dialogOptions.message}
    </DialogModal>
    </>
  )
}