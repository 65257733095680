import { createTheme, adaptV4Theme } from '@mui/material';
// import { responsiveFontSizes } from '@mui/material/styles';

const mainTheme = createTheme (adaptV4Theme({
  palette: {
    primary: {
      main: "#344955" // "#2E4053" //
    },
    secondary: {
      main: "#F9AA33", //"#F9AA33",
    },
    tertiary :{
      main: "#3C6E76" 
    },
    contrast :{
      main: "#d3d3d3"
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1078,  // 1078, Mui original: 900  , 1ra version 982
      lg: 1200,
      xl: 1536,
    },
  },
  sectionTitle:{
    fontSize: 13,
    color:"#3C6E76", //"#344955",   //palette.primary
    marginTop:"5px",
    marginLeft:"5px",
    MarginBottom:"5px",
  },
  userPhotoStyle:{
    height: "72px",
    width: "72px",
    alignItems: "center",
    marginTop: "5px",
    marginBottom:"5px",
    marginLeft:"5px",
    marginRigth:"5px",
    display: "block",
    textAlign: "center",
    justifyContent: "center",
  },
  contactPhotoStyle:{
    height: "40px",
    width: "40px",
    alignItems: "center",
    marginTop: "5px",
    marginBottom:"5px",
    marginLeft:"5px",
    marginRigth:"5px",
    display: "block",
    textAlign: "center",
    justifyContent: "center",
  },
  overrides: {
    MuiAvatar:{
      root:{
        MuiAvatarGroup: {
          root: {
            // Override the width and height props for AvatarGroup
            width: '30px',
            height: '30px',
          },
        },
    }
    },
    // MuiTableCell:{
    //   root:{
    //     height: "25px"
    //   }
    // },
    // MuiTableRow:{
    //   root:{
    //     height: "25px"
    //   }
    // },
    MuiTableSortLabel: {
      root: {
        color: "white", // Default label color (white)
        "&:focus": {
          color: "orange"
        },
        "&:hover": {
          color: "orange"
        },
        '& .MuiTableSortLabel-icon': {
          opacity: 1, // Always show the icons
          color:"white",
        },
        '&$active': {
          color: "orange", // Active label color (orange)
        },
        '&$active .MuiTableSortLabel-icon': {
          opacity: 1, // Always show the icons
          color: "orange", // Icon color (orange) when label is active
        },
      },
    },
    MuiTextField: {
      root:{
        variant:'filled',
        margin:'dense',
        size:'small',
      }
    },
    MuiBottomNavigationAction: {
      label: {
        // fontWeight: 'bold', // Change the font weight to 'bold' (or any other desired font style)
        fontSize: '11px',   // Change the font size (optional)
        color:"3C6E76",
        // fontFamily: 'Arial', // Change the font family (optional)
        // Add any other CSS styles to customize the label as needed
      },
      '& .Mui-selected': {
        '& .MuiBottomNavigationAction-label': {
          fontSize: "11px", 
          transition: 'none',
          // fontWeight: 'bold',
          lineHeight: '20px'
        },
        '& .MuiSvgIcon-root, & .MuiBottomNavigationAction-label': {
          color: "#1e4620",//"#344955",//theme => theme.palette.primary.main
        }
      }
    },
    MuiInputLabel: {
      root: {
        color: '#808080',
        "&$focused": {
          color: "#1C1C49"
        },
        fontSize:'12px',//11
      },
    },  
    MuiFilledInput:{
      root: { 
        color: "#1C1C49", 
        fontSize:"12px",
        backgroundColor: "white",
        "&:hover": {
          color:"#1C1C49",
          backgroundColor:"#D3D3D3",// light gray
        },
        "&$focused": { // increase the specificity for the pseudo class
          color:"blue",
          backgroundColor: "white"
        },
        "&$disabled": {
          color:"#1C1C49",
          backgroundColor: "white"
        },  
      }
      },
    MuiFormHelperText: {
      root: {
      fontSize:'8px',
    }
  },
    MuiInput:{
      root: { 
        color: "344955", //#1C1C49
        backgroundColor: "white",
        "&:hover": {
          color:"#1C1C49",
          backgroundColor:"#D3D3D3",
        },
        "&$focused": { 
          color:"blue",
          backgroundColor: "white"
        },
        "&$disabled": { 
          color:"#1C1C49",
          backgroundColor: "white"
        },  
      },
    },
    MuiOutlinedInput: {
      input: {
        fontSize: '12px', // Adjust the font size here
      },
    },
    MuiSelectOutlined:{
      color: "#1C1C49",
      backgroundColor: "white"
    },
    MuiStepLabel: {
      label: {
        fontSize: '18px', // Adjust the fontSize as needed for regular labels
      },
      alternativeLabel: {
        fontSize: '9px !important', // Adjust the fontSize as needed for alternative labels
      },
    },
    // MuiStepLabel: {
    //   // styleOverrides: {
    //     active: {
    //       "&$active": {
    //         color: 'red', // Customize the font color here
    //       },
    //     // },
    //   },
    // },
    MuiButton:{
      contained:{
        color:"#344955",
        fontSize: "11px",
        // [mainTheme.breakpoints.down('xs')]: {
        //   fontSize: "10px"
        // },
        backgroundColor:"#F9AA33",
        // borderColor:"#F9AA33",
        textTransform: "none",
        // width:"120px",
        height: "30px",
        marginTop: "2px",
        marginLeft:"1px",
        marginRight:"1px",
        "&:hover": {
          backgroundColor: "#F49506ed",
          // borderColor:"#F49506ed"
        },
      },
      text:{
        color: "#344955",
        fontSize: "11px",
        // [mainTheme.breakpoints.down('xs')]: {
        //   fontSize: "10px"
        // },
        backgroundColor: "#F9AA33",
        textTransform: "none",
        // width:"95px",
        height: "30px",
        marginTop: "2px",
        marginLeft:"2px",
        "&:hover": {
          backgroundColor: "#F49506ed"
        },
      },
    },
    MuiFormControlLabel:{
      label:{
        fontSize:11 //11
      } 
    },
    MuiSlider:{
      markLabel : {
        fontSize:11
      },
    },
    // MuiTableCell:{
    //   root:{
    //     MuiTableCell:{
    //       body:{
    //         fontsize:8
    //       },
    //     }
    //   }
    // },
    },
}));
// mainTheme.palette.tertiary = mainTheme.palette.augmentColor(mainTheme.palette.tertiary);
// mainTheme.palette.contrast = mainTheme.palette.augmentColor(mainTheme.palette.contrast);
export default mainTheme;