import React, { useEffect, useRef } from 'react';

import { Grid, Typography, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import useAxios from '../../hooks/useAxios';
import valuationsWebApi from '../../services/valuationsWebApi';

const useStyles = makeStyles((mainTheme) => ({
  textStyle:{
    // color:mainTheme.palette.primary.main,
    color:"black"
  },
  }));

export default function EmailCode({ registerData, code, setCode,codeError, setCodeError }){
  const classes = useStyles();
  const codeTextFieldRef = useRef(null);
  const { axiosFetch: postSendCodeByEmail} = useAxios(); 

  const generateShortHash=()=> {
    const min = 100000; 
    const max = 999999; 
    const randomHash = Math.floor(Math.random() * (max - min + 1)) + min;
    return randomHash.toString(); 
  }

  const sendCodeSuccessCb=()=>{

  }

  const sendCodeErrorCb=()=>{

  }

  const sendCodeByEmail=(code)=>{
    const dataToProcess = { userId: registerData.id, code:code}
    postSendCodeByEmail({ axiosInstance: valuationsWebApi, method: 'POST', url: '/code-by-email', data: dataToProcess, requestConfig: { headers: {'Authorization': "martincsl@hotmail.com",},}},sendCodeSuccessCb, sendCodeErrorCb);
  }

  const handleChangeInputCode=(e)=>{
    const currentCode = e.target.value;
    setCode (prevState => ({...prevState, inputCode:currentCode }));
    if (code.inputCode === code.generatedCode){
      setCodeError("");
    } else {
        if (currentCode.length === 6 && (currentCode !== code.generatedCode)){
          setCodeError("The code you provided is not the same that we've sent you by email");  
        } else {
          if (currentCode.length > 6){
            setCodeError("The code you provided has more than 6 digits");  
          } else {
            if (currentCode.length <= 6){
              setCodeError("")
            }
          }
        }
      }
  }

  const handleInputCode=()=>{
    if (code.inputCode === code.generatedCode){
      setCodeError("");
    } else {
      if (code.inputCode.length < 6){
        setCodeError("The code you provided does not have 6 digits");  
      } else {
        if (code.inputCode.length > 6){
          setCodeError("The code you provided has more than 6 digits");  
        } else {
          setCodeError("The code you provided is not the same that we've sent you by email");
        } 
      }
    }
  }

  useEffect ( ()=> {
    const hash = generateShortHash()
    setCode (prevState => ({...prevState, generatedCode:hash }))
    sendCodeByEmail(hash)
  },[]) 

  return (
  <>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        
        <Typography align ="center" gutterBottom className={classes.textStyle} sx={{marginTop:"10px",marginBottom:"0px", fontSize:"13px"}} >
          {`Please include the 6 digits code we've sent you by email at ${registerData.id}`}</Typography>
      </Grid> 
      {/* { code.generatedCode? 
          <Typography style={{fontSize:"13px"}}>{code.generatedCode?code.generatedCode:"sin codigo"}</Typography>
        :null } */}
      {/* <Typography style={{fontSize:"8px"}}>{code.generatedCode?code.generatedCode:"sin codigo"}</Typography> */}
      {/* { code.generatedCode? <p>{code.generatedCode}</p> : null}    */}
      <Grid item xs={6} sm={6}>
        <TextField 
          autoFocus 
          variant ="filled" margin="dense" size="small"  
          // variant ="outlined" margin="dense" size="small" 
          fullWidth
          label="Input the code"
          name="code.inputCode"
          value={code.inputCode}
          autofocus
          autoComplete="code"
          onChange={ (e) => {handleChangeInputCode (e) }}
          onBlur={(e) => handleInputCode(e)}
          inputProps={{style: {fontSize: 14}}} 
          inputRef={codeTextFieldRef}
          error={codeError}
        />
        {codeError ? <div className="error-helper-text">{codeError}</div> : null}
      </Grid>
    </Grid>
  </>
  ) 
}