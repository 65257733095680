import React, { useState }  from 'react';

import { Paper, TableContainer, Table, TableBody, TableHead, TableSortLabel, TableRow, TableCell, TableFooter, TablePagination, Tooltip, useTheme, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import useTableSorting from '../../hooks/useTableSorting';

import Disclaimer from '../../components/Disclaimer';
import TablePaginationActions from '../../components/TablePaginationActions';

const useStyles = makeStyles( (mainTheme) => ({
  table: {
    minWidth: 370,
    maxHeight: 900
  },
  TableHeader:{
    color: "white",
    backgroundColor: mainTheme.palette.primary.main
  },
  TableTitle:{
    color: "white",
    fontSize: 11,
    [mainTheme.breakpoints.down('sm')]: {
      fontSize: 9,
    },
  },
  sortLabelStyle: {
    color: 'white',
    '&:hover': {
      color: 'orange',
    },
    '& .MuiTableSortLabel-icon': {
      color: 'orange !important',
    },
    '&.Mui-active': {
      color: 'orange',
    },
  },
  tablePaginationStyle:{
    [mainTheme.breakpoints.down('sm')]: {
      "& .MuiTablePagination-displayedRows": { display: "none" }
    },
  },
  TableRows : {
    fontSize: 11,
    color: mainTheme.palette.primary.main,
    // [mainTheme.breakpoints.down('xs')]: {
    //   fontSize: 9,
    // },
  }
}));

export default function TableDividendYield ({companiesList}) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [ page, setPage ] = useState(0);
  const [ rowsPerPage, setRowsPerPage ] = useState(15);
  const [ orderDirection, setOrderDirection ] = useState('desc');
  const [ orderBy, setOrderBy ] = useState('dividendYield');
  const { getComparator, handleRequestSort } = useTableSorting();

  const createSorthandler=(property) => (event) => {
    handleRequestSort(event, property, orderDirection, setOrderDirection, orderBy, setOrderBy);
  }

  var emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - companiesList.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }; 

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
    <TableContainer component={Paper} >
      <Table className={classes.table} size="small" aria-label="stycky header" >
        <TableHead className={classes.TableHeader}>
          <TableRow >
            <TableCell className={classes.TableTitle} style={{width:"50%",position:"sticky", left:0, zIndex:2}}  align="left" key="shortName">
              <TableSortLabel 
                active={orderBy==="shortName"} 
                style={{width:"30%", paddingLeft:"0px", paddingRight:"2px"}}
                direction={orderBy==="shortName" ? orderDirection : 'asc'} 
                onClick={createSorthandler("shortName")}
                className={classes.sortLabelStyle}
                >Company</TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableTitle} style={{width:"6%"}} align="right">Symbol</TableCell>
            <Tooltip title="Dividend of the last 12 months">
              <TableCell className={classes.TableTitle} style={{width:"6%"}} align="right">Dividend</TableCell>
            </Tooltip>
            <TableCell className={classes.TableTitle} style={{width:"6%", paddingLeft:"3px", paddingRight:"3px"}} align="center">Stock Price</TableCell>
            <Tooltip title="Dividend of the last 12 months divided by the current stock price">
              <TableCell className={classes.TableTitle} style={{width:"16%", paddingLeft:"0px", paddingRight:"0px"}} align="right" key="dividendYield" >
                <TableSortLabel 
                  active={orderBy==="dividendYield"} 
                  style={{width:"110px", paddingLeft:"0px", paddingRight:"2px"}}
                  direction={orderBy==="dividendYield" ? orderDirection : 'desc'} 
                  onClick={createSorthandler("dividendYield")}
                  className={classes.sortLabelStyle}
                  >Dividend Yield</TableSortLabel>
              </TableCell>
            </Tooltip>
            {/* <Tooltip title="Average Five Years Dividend Yield: Average divided by current stock price"> */}
            {/* <TableCell className={classes.TableTitle} style={{width:"16%",paddingLeft:"0px", paddingRight:"0px"}} padding="none" align="right" key="fiveYearAvgDividendYield" >
              <TableSortLabel 
                active={orderBy==="payoutRatio"} 
                style={{width:"106px", paddingLeft:"0px", paddingRight:"4px"}}
                direction={orderBy==="payoutRatio" ? orderDirection : 'desc'} 
                onClick={createSorthandler("payoutRatio")}
                className={classes.sortLabelStyle}
                >Payout</TableSortLabel>
            </TableCell> */}
            {/* </Tooltip> */}
          </TableRow>
        </TableHead>
        
        <TableBody>
          {(rowsPerPage > 0 ? 
            companiesList.slice().sort(getComparator(orderDirection, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : companiesList
            ).map((company) => (
              <TableRow key={company.companyId}>
                <TableCell align="left" className={classes.TableRows} style={{position:"sticky", left:0,  paddingLeft:"6px", paddingRight:"0px", backgroundColor:"white"}} >{company.shortName}</TableCell>  
                <TableCell align="right" className={classes.TableRows} >{company.companyId}</TableCell>
                <TableCell align="right" className={classes.TableRows} >{Intl.NumberFormat('en-US',{style:'currency',currency:'USD'}).format(company.dividendRate)}</TableCell>
                <TableCell align="right" className={classes.TableRows} >{Intl.NumberFormat('en-US',{style:'currency',currency:'USD'}).format(company.regularMarketPrice)}</TableCell>
                <TableCell align="right" className={classes.TableRows} >{Intl.NumberFormat('en-US',{style:'percent', minimumFractionDigits:2}).format(company.dividendYield/100)}</TableCell>
                {/* <TableCell align="right" className={classes.TableRows} >{Intl.NumberFormat('en-US',{style:'percent', minimumFractionDigits:2}).format(company.payoutRatio)}</TableCell> */}
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 28.72 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
        </TableBody>
        
        <TableFooter>
          <TableRow>
            <TablePagination
              // rowsPerPageOptions={[15, { label: 'All', value: -1 }]}
              rowsPerPageOptions={[]}
              colSpan={3}
              count={companiesList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              className={classes.tablePaginationStyle}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
    <Disclaimer />
    </>
  );
}