import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { Grid, Box, Typography, CircularProgress, useTheme, useMediaQuery, Grow, Slide, Hidden } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import YouTubeIcon from '@mui/icons-material/YouTube';

import homeImage from    '../../assets/images/landing-home2.png';
import LookOthersImage from '../../assets/images/LookOthers2.png';
import feedbacksImage from '../../assets/images/feedbacks-icons.png';
import communityImage from '../../assets/images/join-community.jpg';
import stepsImage from '../../assets/images/landing-3-steps.png';
import resultsImage from '../../assets/images/landing-finished.png';

import { LoginContext } from '../../helpers/Context';
import ButtonSignUp from './../../components/ButtonSignUp';

const useStyles = makeStyles((mainTheme) => ({
  root: {
    flexGrow: 1,
  },
  contentStyle: {
    position: 'absolute',
    top: '65px'
  },
  iconStyle:{
    fontSize: 40, 
    color:mainTheme.palette.secondary.main,
    '&:hover': {
      color: "#F49506ed", 
    },
  },
  textStyle:{
    // fontSize:"13px",
    color:mainTheme.palette.tertiary.main
  },
  footerStyle: {
    bottom:"0px",
    left:"0px",
    width: "100%",
    height:"25px",
    position:"fixed",
    paddingLeft:"5px",
    paddingRight:"5px",
    paddingBottom:"0px",
    marginBottom:"0px",
    backgroundColor: "#344955"
  }
  })); 

export default function LandingPage (){
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const youtubeLink = "https://www.youtube.com/watch?v=4ECoNgBvgSo&t=17s";
  const { userData} = useContext(LoginContext);
  const { userId } = userData;
  const [ isImageLoaded, setIsImageLoaded ] = useState(false);
  const history = useHistory();

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  return (
    <>
    { userId !=="" && userId !== undefined && userId !== null ? 
    <>
      { history.push("/home")}
    </> 
    : <>
    <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" className={classes.contentStyle} style={{ minHeight: '85vh' }} >
      <Grid item xs={12} >
      
        { isMobile? <>
          <Box style={{height:"30px"}}/>
          <div style={{ position: "relative" }}>
          <img src = {homeImage} alt="home" style={{width:300}} onLoad={handleImageLoad} />
            <div style={{ position: "absolute", bottom: 10, left: "50%", transform: "translateX(-50%)" }}>
            { isImageLoaded ? (
              <>
                <ButtonSignUp />
              </>
  
            ) : (
              <CircularProgress />
            )}
            </div>
          </div>
          </>
        : <>
        <Box style={{height:"40px"}}/>
          <div style={{ position: "relative" }}>
          <img src = {homeImage} alt="home" style={{height:400}} onLoad={handleImageLoad} />
            <div style={{ position: "absolute", bottom: 40, left: "50%", transform: "translateX(-50%)" }}>
            { isImageLoaded ? (
              <ButtonSignUp />
            ) : (
              <CircularProgress />
            )}
            </div>
          </div>  
          </>
        }

      </Grid>
    { isMobile? <>

      <Grid item container xs={12} style={{display:"flex", justifyContent:"center"}}>
      <Box style={{display:"flex", justifyContent:"center"}}>
        <Grid item xs={2}>
          <Box style={{display:"flex", justifyContent:"right"}}>
            <a href={youtubeLink} target="_blank" rel="noopener noreferrer">  
              <YouTubeIcon className = {classes.iconStyle} />
            </a>
          </Box>
        </Grid>
        <Grid item xs={10}>
          <Typography align="justify" style={{marginTop:"10px",fontSize:"10px"}} className={classes.textStyle}>
            <b>Watch a video of how it works</b></Typography>
        </Grid>  
      </Box>  
    </Grid>
    
    
    </>
    : 
    
    <>
    <Grid item container xs={12} style={{display:"flex", justifyContent:"center"}}>
      <Box style={{display:"flex", justifyContent:"center", width:"30%"}}>
        <Grid item xs={2}>
          <Box style={{display:"flex", justifyContent:"right"}}>
            <a href={youtubeLink} target="_blank" rel="noopener noreferrer">  
              <YouTubeIcon className = {classes.iconStyle} />
            </a>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Typography align="justify" style={{marginTop:"10px",fontSize:"13px"}} className={classes.textStyle}>
            <b>Watch a video of how it works</b></Typography>
        </Grid>  
      </Box>  
    </Grid>
    </>
    }  


    <Grid item xs={12} >
      { isMobile? 
          <img src = {LookOthersImage} alt="look-others" style={{width:300}} />
        : 
        <>
          <Box style={{height:"40px"}}/>
          <img src = {LookOthersImage} alt="look-others" style={{height:400}} />
        </>
      }
    </Grid>

    <Grid item xs={12} >
      { isMobile? 
        <div style={{ position: "relative" }}>
        <img src = {feedbacksImage} alt="home" style={{width:300}} onLoad={handleImageLoad} />
          <div style={{ position: "absolute", bottom: 0, left: "5%" }}>
          { isImageLoaded ? (
            <ButtonSignUp />
          ) : (
            <CircularProgress />
          )}
          </div>
        </div>
      : <>
        <Box style={{height:"60px"}}/>
        <img src = {feedbacksImage} alt="feedback" style={{height:400}} />
        </>
      }
    </Grid>

    <Grid item xs={12} >

      { isMobile? 
        <div style={{ position: "relative" }}>
        <img src = {communityImage} alt="home" style={{width:300}} onLoad={handleImageLoad} />
          <div style={{ position: "absolute", bottom: 0, left: "5%" }}>
          { isImageLoaded ? (
            <ButtonSignUp />
          ) : (
            <CircularProgress />
          )}
          </div>
        </div>
      : 
      <>
        <Box style={{height:"0px"}}/>
        <img src = {communityImage} alt="community" style={{height:400}} />      
      </>
      }
    </Grid>

    <Grid item xs={12} >

      { isMobile? 
        <div style={{ position: "relative" }}>
        <img src = {stepsImage} alt="home" style={{width:300}} onLoad={handleImageLoad} />
          <div style={{ position: "absolute", bottom: -5, left: "50%", transform: "translateX(-50%)" }}>
          { isImageLoaded ? (
            <ButtonSignUp />
          ) : (
            <CircularProgress />
          )}
          </div>
        </div> 
      : 
      <>
        <Box style={{height:"20px"}}/>
        <img src = {stepsImage} alt="steps" style={{height:400}} />
      </>
      }
    </Grid>

    <Grid item xs={12} >

      { isMobile? 
        <img src = {resultsImage} alt="results" style={{width:300}} />
        : 
        <>
          <Box style={{height:"0px"}}/>
          <img src = {resultsImage} alt="results" style={{height:400}} />
        </>
      }
    </Grid>
    <Box style={{height:"100px"}}/>
  </Grid>
  <Box className={classes.footerStyle}>
    <Grid container direction="row" xs={12}>
      <Hidden smDown>
        <Grid item xs={12} md={4}>
          <Typography variant="caption" align="left" style={{fontSize:11, color:"#F9AA33"}}>Avenida Paulista nº 1.471, Conj. 511, Bela Vista, São Paulo-SP, Brasil</Typography>
        </Grid>
      </Hidden>
      <Grid item xs={12} md={4} align="center">
        <Typography variant="caption" align="center"  style={{fontSize:11,color:"#F9AA33"}}>E-mail: valuationshub@gmail.com</Typography>
      </Grid>
      <Hidden smDown>
        <Grid item xs={12} md={4} align="right">
          <Typography variant="caption" align="right"  style={{fontSize:11,color:"#F9AA33"}}>@Copyright 2024, Martin Calcena Simoes Lopes</Typography>
        </Grid>
      </Hidden>
    </Grid>
  </Box>
  </>
  }
  </>
  )
}