import React, { memo, useState, useEffect, useContext } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { format, startOfMonth, endOfMonth } from 'date-fns';

import { AppBar, Toolbar, Grid, IconButton, Box, Button, Hidden, Drawer, List, ListItem, ListItemText, Divider, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import MenuIcon from '@mui/icons-material/Menu';

import { LoginContext } from '../helpers/Context.js';
// import { useQuery } from '@tanstack/react-query';
import useAxios from'../hooks/useAxios';
import valuationsWebApi from '../services/valuationsWebApi.js';
import mainLogo from '../assets/LogoVH.svg';
import ShowUserImageByUserId from './ShowUserImageByUserId.jsx';
import DialogModal from './modals/DialogModal.jsx';
import DialogFreePlanLimits from './modals/DialogFreePlanLimits.jsx'

const useStyles = makeStyles((mainTheme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    // padding: mainTheme.spacing(2),
    backgroundColor: mainTheme.palette.primary.main,
    color: mainTheme.palette.common.white,
  },
grow:{
    flexGrow: 1
  },
buttonMenuStyle: {
  display: 'inline-block',
  padding: 6,
  minWidth: 0,
  color: "white",
  backgroundColor:mainTheme.palette.primary.main,
  textTransform:"none",
  margin: "2px",
  fontSize:"13px",
  "&:hover": {
    color:mainTheme.palette.secondary.main,
    backgroundColor: mainTheme.palette.primary.main,
  },
},
buttonDrawerStyle: {
  color: "white",
  textTransform:"none",
  "&:hover": {
    color:mainTheme.palette.secondary.main
  },
},
logoStyle: {
  position: "relative",
  height: "60px",
  // padding: "24px",
  top:"5px"

},
toolbarButtons: {
  marginLeft: 'auto',
},
drawer: {
  width: '240',
  flexShrink: 0,
},
drawerPaper: {
  width: '240',
  color: 'white',
  backgroundColor: mainTheme.palette.primary.main,
},
drawerContainer: {
  overflow: 'auto',
},
drawerText:{
  fontSize:"13px"
}
})); 


function Header() {

  const formatDate=(date)=> {
    return format(date, 'yyyy-MM-dd HH:mm:ss');
  }

  const isDateInRange=(date, start, end)=> {
    const formattedDate = formatDate(date);
    return formattedDate >= start && formattedDate <= end;
  }

  const filterValuationsByUser=(userId, valuationsList)=> {
    const currentDate = new Date();
    const startOfMonthFormatted = formatDate(startOfMonth(currentDate));
    const endOfMonthFormatted = formatDate(endOfMonth(currentDate));

    return valuationsList.filter(valuation => {
        if (valuation.userId === userId && valuation.createdAt) {
            const valuationDate = new Date(valuation.createdAt);
            // Compare with the start and end of the current month
            return isDateInRange(valuationDate, startOfMonthFormatted, endOfMonthFormatted);
        }
        return false;
    });
  }

  const classes = useStyles();
  const { userData, setUserData, usersImagesList, setUsersImagesList, valuationsList, setValuationsList, usersList, setUsersList, isValuationSample, setIsValuationSample, qtyOfValuationsViews ,setQtyOfValuationsViews, qtyOfValuationsCreated, setQtyOfValuationsCreated } = useContext (LoginContext);
  const { axiosFetch: getAllValuations, isLoading: isLoadingAllValuations, error: isErrorAllValuations } = useAxios();
  const { axiosFetch: getAllUsers, isLoading: isLoadingAllUsers, error: isErrorAllUsers } = useAxios();
  const { axiosFetch: getSession, isLoading: isLoadingSession, error: isErrorSession } = useAxios();
  const { axiosFetch: getValuationsViews, isLoading: isLoadingValuationViews, error: isErrorValuationViews } = useAxios();
  // const { data: valuations, isLoading: isLoadingValuations, isErrorValuations } = useQuery(["valuations"],fetchValuations)
  const { userId, userFirstName, userLastName, userProduct } = userData;
  // const filteredValuationsByUser = valuationsList ? filterValuationsByUser(userId, valuationsList) : []
  let filteredValuationsByUser=[];
  const [ isDrawerOpen, setIsDrawerOpen ] = useState(false);
  const [ isServerProblem, setIsServerProblem ]= useState(false)
  const dividerOption = 6;
  const [ isDialogOpen, setIsDialogOpen ] = useState(false);
  const [ isDialogLimitsOpen, setIsDialogLimitsOpen ] = useState(false);
  const [ dialogOptions, setDialogOptions ] = useState({severity:"",title:"",message:"",buttons:{},action:""});
  const history = useHistory();
  const location = useLocation();
  // const isLimit = userId && userProduct && userProduct==="0" && setQtyOfValuationsCreated > 4? true : false;
  const [ isLimitReached, setIsLimitReached]= useState(false);
  
  const menuOptions = [
    { id:0,
      title:"Home",
      route:"/home"
    },
    { id:1,
      title:"New Valuation",
      route:"/valuation"
    },
    { id:2,
      title:"Graham Model",
      route:"/graham"
    },
    { id:3,
      title:"Dividend Yield Model",
      route:"/dividend-yield"
    },
    { id:4,
      title:"Valuation Sample",
      route:"/valuation-sample/56696d99"
    },

    { id:5,
      title:"Pricing",
      route:"/pricing"
    },
    { id:6,
      title:"Who I Am",
      route:"/who-we-are"
    },
    { id:7,
      title:"Contact",
      route:"/contact"
    },
    { id:8,
      title:"Login",
      route:"/login"
    },
    { id:9,
      title:"Sign Up",
      route:"/register"
    },
    { id:10,
      title:"Logout",
      route:"/logout"
    },
  ]

  // { id:5,
  //   title:"Books",
  //   route:"/books"
  // },

  const handleMenuLinkClick = () => {
    // alert("Dialog mesage")
    setIsDialogLimitsOpen(true);
  }

  const handleDialogLimitsClose = () => {
    // Close the dialog when the user clicks "Close"
    setIsDialogLimitsOpen(false);
  };

  function handleDialogClose(){
    setIsDialogOpen(false);
    setDialogOptions({severity:"",title:"",message:"",buttons:{},action:""});
  }

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const displayShortName =(firstName, lastName)=>{
    const names = firstName.split(" ");
    const surnames = lastName.split("");
    if (firstName.length < 10) {
      return `Hi, ${firstName}`
    }
    if (names.length > 0){
      if (names[0].trim().length < 10){
        return `Hi, ${names[0].trim()}`
      }
    }
    if (lastName.length < 10){
      return `Hi, ${lastName}`
    }
    if (surnames.length > 0){
      if (surnames[0].trim().length < 10){
        return `Hi, ${surnames[0].trim()}`
      }
    }
    return `Hi ${firstName.slice(0, 1)}${lastName.slice(0, 1)}`
  }

  const getAllValuationsSuccessCb=(apiData)=>{ 

    if (apiData.length > 0 ){
      const updatedData = apiData.map((item) => {
        const updatedItem = {
          valuationId:item.valuationId,
          userId: item.userId,
          firstName:item.firstName,
          lastName:item.lastName,
          companyId:item.companyId,
          shortName:item.shortName,
          revenueGrowth:item.revenueGrowth,
          marginTarget:item.marginTarget,
          opexGrowth:item.opexGrowth,
          interestGrowth:item.interestGrowth,
          otherGrowth: item.otherGrowth,
          taxRate: item.taxRate,
          capexGrowth: item.capexGrowth,
          ncwGrowth: item.ncwGrowth,
          perpetualGrowthRate:item.perpetualGrowthRate,
          cashFlowDiscretePeriod:item.cashFlowDiscretePeriod,
          companyBeta: item.companyBeta,
          riskFreeReturn:item.riskFreeReturn,
          marketReturn: item.marketReturn,
          debtTotalRatio: item.debtTotalRatio,
          costOfDebt: item.costOfDebt,
          costOfEquity: item.costOfEquity,
          costOfCapital:item.costOfCapital,
          inputedCostOfCapital:item.inputedCostOfCapital,
          cashFlowAvgGrowth:item.cashFlowAvgGrowth,
          sumOfCashFlowPresentValue:item.sumOfCashFlowPresentValue,
          perpetuityValue:item.perpetuityValue,
          perpetuityPresentValue:item.perpetuityPresentValue,
          enterpriseValue:item.enterpriseValue,
          cash:item.cash,
          debt:item.debt,
          equityValue:item.equityValue,
          sharesOutstanding:item.sharesOutstanding,
          targetStockPrice:item.targetStockPrice,
          dateStockPrice:item.dateStockPrice,
          regularMarketPrice:item.regularMarketPrice,
          marketCap:item.marketCap,
          createdAt:item.createdAt,
          updatedAt:item.updatedAt,
          published: item.published,
          publishedDate: item.publishedDate,
          deletedAt:item.deletedAt,
          lastHistoricalYear:item.lastHistoricalYear,
          assumptionsText:item.assumptionsText,
          avgRating: item.avgRating,
        };
        return updatedItem;
      });
      setValuationsList(updatedData)
    } else {
      // alert("getAllValuationsSuccessCb, apiData.length > 0 ")
      setIsServerProblem(true)
      setDialogOptions({
        severity:"error", 
        title:"Server Access", 
        message:"We regret to inform you that there is currently an issue with server access. Our technical team has been notified and is diligently working to resolve the issue. We apologize for any inconvenience this may have caused. Please try accessing the service again shortly. If the problem persists, kindly contact our support team for further assistance. Thank you for your understanding and patience as we work to restore normal functionality.",
        buttons:{button1:"Ok"}})
      // setIsDialogOpen (true);
    }
  }

  const getAllValuationsErrorCb=()=>{
    // alert("getAllValuationsErrorCb")
    setIsServerProblem(true)
    setDialogOptions({
      severity:"error", 
      title:"Server Access", 
      message:"We regret to inform you that there is currently an issue with server access. We apologize for any inconvenience this may have caused. Please try accessing the service again shortly. If the problem persists, kindly contact our support team for further assistance. Thank you for your understanding and patience as we work to restore normal functionality.",
      buttons:{button1:"Ok"}})
    // setIsDialogOpen (true);
  }

  const getAllUsersSuccessCb=(apiData)=>{ 
    const updatedData = apiData.map((item) => {
      const updatedItem = {
        profileId:item.id,
        profileFirstName: item.firstName,
        profileLastName:item.lastName,
        profileBirthday:item.birthday,
        profileDescription:item.description,
      };
      return updatedItem;
    });
    setUsersList(updatedData)
  }
  const getAllUsersErrorCb=()=>{
    if ( ! isServerProblem){
      // alert("getAllUsersErrorCb")
      setIsServerProblem(true)
      setDialogOptions({
        severity:"error", 
        title:"Server Access", 
        message:"We regret to inform you that there is currently an issue with server access. We apologize for any inconvenience this may have caused. Please try accessing the service again shortly. If the problem persists, kindly contact our support team for further assistance. Thank you for your understanding and patience as we work to restore normal functionality.",
        buttons:{button1:"Ok"}})
      setIsDialogOpen (true);
    }
  }
  
  const getSessionSuccessCb = async (apiData)=>{
    setIsValuationSample(false);
    await setUserData({userId:apiData.id, userPassword: apiData.password, userProduct: apiData.product, userFirstName:apiData.firstName, userLastName:apiData.lastName, userCountry:apiData.country, userCountryName:apiData.countryName,userBirthday:apiData.birthday, userDescription:apiData.description})
    const currentRoute = location.pathname;
    if (apiData.product==="0") {
      getValuationsViews({ axiosInstance: valuationsWebApi, method: 'GET', url: `/valuations-views/?userId=${apiData.id}`, requestConfig: { headers: {'Authorization': apiData.id,},}},getValuationsViewsSuccessCb, getValuationsViewsErrorCb);
    }
    if (currentRoute.startsWith('/saved-valuation')){
      history.push(`/home`)   
    }
  }
  
  const getSessionErrorCb=(error)=>{
    // history.push("/landing");
  }

  const getValuationsViewsSuccessCb=(apiData)=>{
    const viewsOfTheMonth = apiData.length
    setQtyOfValuationsViews(viewsOfTheMonth);
  }

  const getValuationsViewsErrorCb=()=>{
    // alert("getValuationsViewsErrorCb")
    // setDialogOptions({
    //   severity:"error", 
    //   title:"Server Access", 
    //   message:"We regret to inform you that there is currently an issue with server access. Our technical team has been notified and is diligently working to resolve the issue. We apologize for any inconvenience this may have caused. Please try accessing the service again shortly. If the problem persists, kindly contact our support team for further assistance. Thank you for your understanding and patience as we work to restore normal functionality.",
    //   buttons:{button1:"Ok"}})
    // setIsDialogOpen (true);
  }

  useEffect(() => {

    if (userId && valuationsList){
      const filteredValuationsByUser = filterValuationsByUser(userId, valuationsList)
      if (filteredValuationsByUser.length > 0 ){
        // alert(filteredValuationsByUser.length)
        setQtyOfValuationsCreated(filteredValuationsByUser.length)
        if (userProduct==="0" && filteredValuationsByUser.length > 4){
          // alert("setIsLimitReached(true)")
          setIsLimitReached(true)
        } else {
          setIsLimitReached(false)
        }
      }
    }
  }, [valuationsList, userId]);

  useEffect(() => {
    valuationsWebApi.get(`/user-image`)
    .then(response => {
      setUsersImagesList(response.data);
    })
    .catch(error => {
      console.error(`Failed to fetch images`, error);
    })
    getAllValuations({ axiosInstance: valuationsWebApi, method: 'GET', url: '/valuations', requestConfig: { headers: {'Authorization': userId,},}},getAllValuationsSuccessCb, getAllValuationsErrorCb);
    getAllUsers({ axiosInstance: valuationsWebApi, method: 'GET', url: '/users', requestConfig: { headers: {'Authorization': userId,},}},getAllUsersSuccessCb, getAllUsersErrorCb);
    if (! userId){  // check if the session is already on by checking the token
      getSession({ axiosInstance: valuationsWebApi, method: 'GET', url: `/session`, requestConfig: { headers: {'Authorization': userId,},}},getSessionSuccessCb, getSessionErrorCb);
    }

  }, []);

  return (
    <>
    { ! isLoadingAllValuations ? <>
    {/* { alert(isLimitReached)} */}
    <AppBar position="fixed" style={{top:'0px', height: '64px'}}>
      <Toolbar style={{minWidth:'360',height: '56px',paddingLeft:'24px'}} >

        <Link to={menuOptions[0].route}>
          <img src = {mainLogo} alt="Logo" className={classes.logoStyle} />
        </Link>
        <Hidden mdDown>
          <Box style={{ width: '20px' }}/> 
          { userData.userId !== "" && userData.userId !== undefined && userData.userId !== null ? 
            menuOptions
            .filter((menuItem) =>  menuItem.title !== 'Valuation Sample' && menuItem.title !== 'Pricing' && menuItem.title !== 'Login' && menuItem.title !== 'Sign Up') // Si t=ya esta con la sesion iniciada, no muestra valuation sample, login y sign up...
            .map ( (currElement, index) => (
            <React.Fragment key={index}>
              { dividerOption===currElement.id? <div className={classes.grow} />: null }
              { isLimitReached && menuOptions[currElement.id].route==="/valuation" ? 
                <Button onClick={()=> handleMenuLinkClick()}className={classes.buttonMenuStyle} disableRipple>{menuOptions[currElement.id].title}</Button>   
              : 
                <Button component={Link} to={menuOptions[currElement.id].route} className={classes.buttonMenuStyle} disableRipple>{menuOptions[currElement.id].title}</Button>   
              } 
            </React.Fragment>
          ))
          : 
            menuOptions
            .filter((menuItem) => menuItem.title !== 'Dividend Yield Model' &&  menuItem.title !== 'Graham Model' && menuItem.title !== 'Logout' && menuItem.title !== 'New Valuation') // Si no esta son la sesion iniciada, no muestra logout, etc
            .map ( (currElement, index) => (
            <React.Fragment key={index}>
              { dividerOption===currElement.id? <div className={classes.grow} />: null}
                <Button component={Link} to={menuOptions[currElement.id].route} className={classes.buttonMenuStyle} disableRipple>{menuOptions[currElement.id].title}</Button>   
            </React.Fragment>
          ))
          } 
          { userId !== "" && userId !== undefined && userId !== null ? <>
            <div className={classes.root}>
              <Grid container direction="column" alignItems="center" spacing={0}>
                <Grid item >
                  { usersImagesList.length !== 0  ? <>
                    <ShowUserImageByUserId userId={userId} usersImages={usersImagesList} size="small" />
                    </>
                  : null}

                </Grid>
                <Grid item>
                  { userFirstName !== "" &&  userFirstName !== undefined && userFirstName !== null ? 
                    <>
                      <Typography style={{fontSize:"9px", color:"#F9AA33", marginTop:"4px"}}>{displayShortName(userFirstName, userLastName)}</Typography>
                    </>
                    : null }
                </Grid>
              </Grid>
            </div>
            </>
          : null}
        </Hidden>

        <div className={classes.toolbarButtons}> 
          <Hidden mdUp>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerOpen}
              size="large">
              <MenuIcon />
            </IconButton>
          </Hidden>
        </div> 
      </Toolbar>
    </AppBar>

    <Hidden mdUp>
      <Drawer
        className={classes.drawer}
        variant="temporary"
        anchor="right"
        open={isDrawerOpen}
        onClose={handleDrawerClose}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
      <div className={classes.drawerContainer}>
        { userData.userId !== "" && userData.userId !== undefined && userData.userId !== null ? <>
        { menuOptions
          .filter((menuItem) =>  menuItem.title !== 'Valuation Sample' && menuItem.title !== 'Pricing' && menuItem.title !== 'Login' && menuItem.title !== 'Sign Up') // Si t=ya esta con la sesion iniciada, no muestra valuation sample, pricing, login y sign up...
          .map ( (currElement, index) => (
              <React.Fragment key={index}>
                { dividerOption===currElement.id? <Divider /> : null}
                { isLimitReached && menuOptions[currElement.id].route==="/valuation" ? 
                <Button onClick={()=> handleMenuLinkClick()} className={classes.buttonMenuStyle} style={{marginLeft:"10px"}} disableRipple>{menuOptions[currElement.id].title}</Button>   
              : 
                <ListItem button component={Link} to={menuOptions[currElement.id].route} onClick={handleDrawerClose} disableRipple className={classes.buttonDrawerStyle}>
                  <ListItemText primary={menuOptions[currElement.id].title} disableTypography className={classes.drawerText}/>
                </ListItem>  
                }
              </React.Fragment>
            ))} 
        </> 
        : 
        <>
        { menuOptions
          .filter((menuItem) => menuItem.title !== 'Dividend Yield Model' &&  menuItem.title !== 'Graham Model' && menuItem.title !== 'Logout' && menuItem.title !== 'New Valuation') // Si no esta son la sesion iniciada, no muestra logout, etc
          .map ( (currElement, index) => (
              <React.Fragment key={index}>
                { dividerOption===currElement.id? <Divider /> : null}
                <ListItem button component={Link} to={menuOptions[currElement.id].route} onClick={handleDrawerClose} disableRipple className={classes.buttonDrawerStyle}>
                  <ListItemText primary={menuOptions[currElement.id].title} disableTypography className={classes.drawerText}/>
                </ListItem>
              </React.Fragment>
        ))} 
        </>}
        {/* <List>
          { menuOptions.map ( (currElement, index) => (
            <React.Fragment key={index}>
              { dividerOption===currElement.id? <Divider /> : null}
              <ListItem button component={Link} to={menuOptions[currElement.id].route} disableRipple className={classes.buttonDrawerStyle}>
                <ListItemText primary={menuOptions[currElement.id].title} disableTypography className={classes.drawerText}/>
              </ListItem>
            </React.Fragment>
          ))} 
        </List> */}
      </div>
      </Drawer>
    </Hidden>
    </>: null}
    {/* { userId===""? 
    <Footer />
    : null} */}
    <DialogFreePlanLimits open={isDialogLimitsOpen} onClose={handleDialogLimitsClose} typeOfLimit={"created"}/>
    <DialogModal open={isDialogOpen} onClose={handleDialogClose} severity={dialogOptions.severity} title={dialogOptions.title} buttons={dialogOptions.buttons} action={dialogOptions.action}>
      {dialogOptions.message}
    </DialogModal>
  </>);
  }
export default (Header)