import React, { useState } from 'react';

import { Paper, Grid, TextField, InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import useValuationForm from '../../../hooks/useValuationForm';
import DialogModal from '../../../components/modals/DialogModal';

const useStyles = makeStyles( (mainTheme) => ({
  table: {
    minWidth: 300,
    maxHeight: 900
  },
  tableHeader:{
    backgroundColor: mainTheme.palette.secondary.main
  },
  tableHeaderDisabled:{
    backgroundColor:  mainTheme.palette.primary.main
  },
  tableTitle:{
    color: "white",
    fontSize: 12
  },
  tableTitleDisabled:{
    color: mainTheme.palette.secondary.main,
    fontSize: 12
  },
  TableRows : {
    fontSize: 11
    }
  }));

  export default function FormCostOfCapitalAssumptions ({assumptions, setAssumptions, calculatedCostOfCapital}){

  const classes = useStyles();
  const { handleChange, isValidDiscretePeriod } = useValuationForm({ assumptions, setAssumptions })
  const [ isDialogOpen, setIsDialogOpen ] = useState(false);
  const [ dialogOptions, setDialogOptions ] = useState({severity:"",title:"",message:"",buttons:{},action:""});
 
  const handleDialogClose=()=>{
    setIsDialogOpen(false);
    setDialogOptions({severity:"",title:"",message:"",buttons:{},action:""});
  }


  const handleRiskFreeReturn=(value)=>{
    const recommendedValue = 4.0
    let isFirstDialogDisplayed = false;
    if (value <= 0){
      setDialogOptions({
        severity: "error",
        title: "Invalid riske free rate",
        message: "Risk free rate must be higher than 0. Using USA Treasury Bills as a paraneter it should be between 4.47 and 5.27 !",
        buttons: { button1: "Ok" }
      });
      setIsDialogOpen(true);
      isFirstDialogDisplayed = true;
      setAssumptions(prevState => ({...prevState, riskFreeReturn: recommendedValue }))

    } 
    if (value < recommendedValue ) {
      // Using USA Treasury Bills as a paraneter it should be between 4.47% and 5.27%
      setDialogOptions({
        severity: "warning",
        title: "Overvaluation Alert!",
        message: "A low risk free rate comparing to the current market values can lead to a underestimate of the cost of capital, increasing the calculated stock price.",
        buttons: { button1: "Ok" }
      });
      setIsDialogOpen(true);
    } else {
      setAssumptions(prevState => ({...prevState, riskFreeReturn: value }))
    }
  }


  const handleEquityRiskPremium =(value)=>{
    const recommendedValue = 4.0
    if ( value < recommendedValue ) {
      setDialogOptions({
        severity: "warning",
        title: "Overvaluation Alert!",
        message: "A low equity risk premium comparing to the current market values can lead to a underestimate of the cost of capital, increasing the calculated stock price.",
        buttons: { button1: "Ok" }
      });
      setIsDialogOpen(true);
    } 
    setAssumptions(prevState => ({...prevState, equityRiskPremium: value }))
  }

  const handleBeta=(value)=>{
    if ( value < 0 ) {
      setDialogOptions({
        severity: "error",
        title: "Invalid Beta",
        message: "Beta should be equal or higher than zero.",
        buttons: { button1: "Ok" }
      });
      setIsDialogOpen(true);
      setAssumptions(prevState => ({...prevState, companyBeta: 0 }))
    } else {
      setAssumptions(prevState => ({...prevState, companyBeta: value }))  
    }
  }

  const handleCostOfDebt=(value)=>{
    if ( value <= 0 ) {
      setDialogOptions({
        severity: "error",
        title: "Invalid Cost of Debt",
        message: "Cost of Debt should be higher than zero",
        buttons: { button1: "Ok" }
      });
      setIsDialogOpen(true);
      setAssumptions(prevState => ({...prevState, costOfDebt: 1 }))
    } else {
      setAssumptions(prevState => ({...prevState, costOfDebt: value }))
    }
  }

  //refatorar... pq nao esta usando handleChange de useForm?
  return (
    <>
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="stycky header">
      <TableHead className={classes.tableHeader}>

        <TableRow>
          <TableCell className={classes.tableTitle} align="left">Cost of Capital Assumptions - CAPM Model</TableCell>
        </TableRow>

      </TableHead>

        <TableBody>
          <TableRow>
            <Grid container>
              <Grid item xs={4} sm={4}>
                <TextField
                  label="Rf: Risk Free Return"
                  size="small"
                  value={assumptions.riskFreeReturn}
                  type="number"
                  // onChange = {(e) => setAssumptions(prevState => ({...prevState, riskFreeReturn:e.target.value }))}
                  onChange={(e) => {handleChange (e,[])}}
                  onBlur={(e) => {handleRiskFreeReturn (e.target.value)}}
                  variant="filled"
                  fullWidth
                  name="riskFreeReturn"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">%</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={4} sm={4}>
                <TextField
                  label="Equity Risk Premium"
                  size="small"
                  value={assumptions.equityRiskPremium}
                  type="number"
                  // onChange = {(e) => setAssumptions(prevState => ({...prevState,equityRiskPremium:e.target.value }))}
                  onChange={(e) => {handleChange (e,[])}}
                  onBlur={(e) => {handleEquityRiskPremium (e.target.value)}}
                  variant="filled"
                  fullWidth
                  name="equityRiskPremium"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">%</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={4} sm={4}>
                <TextField
                  label="Beta"
                  size="small"
                  value={assumptions.companyBeta}
                  type="number"
                  // onChange = {(e) => setAssumptions(prevState => ({...prevState, companyBeta:e.target.value }))}
                  onChange={(e) => {handleChange (e,[])}}
                  onBlur={(e) => {handleBeta (e.target.value)}}
                  variant="filled"
                  fullWidth
                  name="companyBeta"
                />
              </Grid>
            </Grid>
          </TableRow>
          <TableRow>
            <Grid container>
              <Grid item xs={4} sm={4}>
                <TextField
                  label="Kd: Gross Cost of Debt"
                  size="small"
                  value={assumptions.costOfDebt}
                  type="number"
                  // onChange = {(e) => setAssumptions(prevState => ({...prevState, costOfDebt:e.target.value }))}
                  onChange={(e) => {handleChange (e,[])}}
                  onBlur={(e) => {handleCostOfDebt (e.target.value)}}
                  variant="filled"
                  fullWidth
                  name="costOfDebt"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">%</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={4} sm={4}>
                <TextField
                  label="Ke: Cost of Equity"
                  size="small"
                  value={calculatedCostOfCapital.costOfEquity}
                  variant="filled"
                  fullWidth
                  name="costOfEquity"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">%</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={4} sm={4}>
                <TextField
                  label="WACC: Cost of Capital"
                  size="small"
                  value={calculatedCostOfCapital.costOfCapital}
                  // value={assumptions.costOfCapital}

                  variant="filled"
                  fullWidth
                  name="costOfCapital"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">%</InputAdornment>,
                  }}
                />
              </Grid>
            </Grid>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    <DialogModal open={isDialogOpen} onClose={handleDialogClose} severity={dialogOptions.severity} title={dialogOptions.title} buttons={dialogOptions.buttons} action={dialogOptions.action}>
    {dialogOptions.message}
  </DialogModal>
    </>
  )
}