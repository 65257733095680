import React from 'react';

import { Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((mainTheme) => ({
table: {
  // minWidth: 300,
  maxHeight: 900,
  [mainTheme.breakpoints.down('sm')]: {
    // maxWidth: "360px",
    margin:"5px"
    // marginLeft:"5px",
    // marginRigth:"5px"
  },
},
TableHeader:{
  color: "white",
  backgroundColor: mainTheme.palette.primary.main
},
TableTitle:{
  color: "white",
  fontSize: 12
},
TableRows : {
  fontSize: 11,
  color: mainTheme.palette.primary.main,
  },
TableRowsSubtitle : {
  fontSize: 11,
  height: 16,
  color: mainTheme.palette.primary.main,
  backgroundColor: mainTheme.palette.contrast.main, //light gray
  }, 
TableValuationPrice:{
  color: mainTheme.palette.secondary.main,
  backgroundColor: mainTheme.palette.primary.main,
  fontSize: 11
},
TableCurrentPrice:{
  color: "white",
  backgroundColor: mainTheme.palette.tertiary.main, //mainTheme.palette.primary.main,
  fontSize: 11,
},
}));

export default function TableValuation ({valuation, combinedFinancialData, calculatedCostOfCapital, companyData, valuationDateInfo}){
  const classes = useStyles();
  const finalYear = combinedFinancialData ? combinedFinancialData[0].year : "";
  const firstYear = combinedFinancialData ? combinedFinancialData[combinedFinancialData.length-4].year : "" ;

  return (
  <>
  {/* sx={{position:"fixed", top:"74px"}}  */}
  <TableContainer component={Paper} >
    <Table className={classes.table} size="small" aria-label="stycky header">
      <TableHead className={classes.TableHeader}>
        <TableRow>
          <TableCell className={classes.TableValuationPrice} style={{fontSize: 12}}align="left">Valuation (USD billions)</TableCell>
          <TableCell className={classes.TableValuationPrice} align="right">
            {/* <Rating style={{ fontSize:"9px"}} name="read-only" value={4} size="small" readOnly /> */}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell align="left" className={classes.TableRows}>{`Free Cash Flow to Firm Growth ${firstYear}-${finalYear}`}</TableCell>
          {/* <TableCell align="left" className={classes.TableRows}>{`Free Cash Flow Avg. Growth`}</TableCell> */}
          <TableCell align="right" className={classes.TableRows} >{Intl.NumberFormat('en-US',{style:'percent', minimumFractionDigits:2,maximumFractionDigits:2}).format(valuation.calculatedCashFlowGrowth/100)}</TableCell>
        </TableRow>
        {/* <TableRow>
          <TableCell align="left" className={classes.TableRows}>{`Sum of Free Cash Flow to Firm ${firstYear}-${finalYear}`}</TableCell>
          <TableCell align="right" className={classes.TableRows} >{Intl.NumberFormat('en-US',{style:'percent', minimumFractionDigits:2,maximumFractionDigits:2}).format(valuation.calculatedCashFlowGrowth/100)}</TableCell>
        </TableRow> */}
        <TableRow>
          <Tooltip placement="top-start" title="Present Value of the Free Cash Flow to Firm, using WACC as Discount Rate">
            <TableCell align="left" className={classes.TableRows}>{`FCFF Present Value at ${Intl.NumberFormat('en-US',{style:'decimal', minimumFractionDigits:2,maximumFractionDigits:2}).format(calculatedCostOfCapital.costOfCapital)}% per year`}</TableCell>
          </Tooltip>  
          <TableCell align="right" className={classes.TableRows} >{Intl.NumberFormat('en-US',{style:'decimal', minimumFractionDigits:1,maximumFractionDigits:1}).format(valuation.sumOfCashFlowPresentValue)}</TableCell>
        </TableRow>
        <TableRow>
          <Tooltip placement="top-start" title="Terminal Value calculated based on last Free Cash Flow to the Firm and perpetual growth rate assumptions">
            <TableCell align="left" className={classes.TableRows}>Terminal Value </TableCell>
          </Tooltip>
          <TableCell align="right" className={classes.TableRows} >{Intl.NumberFormat('en-US',{style:'decimal', minimumFractionDigits:1,maximumFractionDigits:1}).format(valuation.perpetuityValue)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left" className={classes.TableRows}>Present Value of Terminal Value</TableCell>
          <TableCell align="right" className={classes.TableRows} >{Intl.NumberFormat('en-US',{style:'decimal', minimumFractionDigits:1,maximumFractionDigits:1}).format(valuation.perpetuityPresentValue)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left" className={classes.TableRowsSubtitle}>Valuation Enterprise Value</TableCell>
          <TableCell align="right" className={classes.TableRowsSubtitle} >{Intl.NumberFormat('en-US',{style:'decimal', minimumFractionDigits:1,maximumFractionDigits:1}).format(valuation.enterpriseValue)}</TableCell>
        </TableRow>
        {/* <TableRow>
          <TableCell align="left" className={classes.TableCurrentPrice}>Current Enterprise Value</TableCell>
          <TableCell align="right" className={classes.TableCurrentPrice} >{Intl.NumberFormat('en-US',{style:'decimal', minimumFractionDigits:1,maximumFractionDigits:1}).format(companyData.marketCap - valuation.cash + valuation.debt)}</TableCell>
        </TableRow> */}
        <TableRow>
          <TableCell align="left" className={classes.TableRows}>(+) Cash and Short Term Investments</TableCell>
          <TableCell align="right" className={classes.TableRows} >{Intl.NumberFormat('en-US',{style:'decimal', minimumFractionDigits:1,maximumFractionDigits:1}).format(valuation.cash)}</TableCell>   
        </TableRow>
        <TableRow>
          <TableCell align="left" className={classes.TableRows}>(-) Debt and Leasing Obligations</TableCell>
          <TableCell align="right" className={classes.TableRows} >{Intl.NumberFormat('en-US',{style:'decimal', minimumFractionDigits:1,maximumFractionDigits:1}).format(valuation.debt)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left" className={classes.TableValuationPrice}>Valuation Equity Value</TableCell>
          <TableCell align="right" className={classes.TableValuationPrice} >{Intl.NumberFormat('en-US',{style:'decimal', minimumFractionDigits:1,maximumFractionDigits:1}).format(valuation.equityValue)}</TableCell>
        </TableRow>
        <TableRow>
          
          { valuationDateInfo!==null && valuationDateInfo!==undefined? 
            <>
              <TableCell align="left" className={classes.TableCurrentPrice}>Market Value at Valuation Date</TableCell>  
              <TableCell align="right" className={classes.TableCurrentPrice} >{Intl.NumberFormat('en-US',{style:'decimal', minimumFractionDigits:1,maximumFractionDigits:1}).format(valuationDateInfo.dateMarketCap)}</TableCell>
            </>
          : <>
              <TableCell align="left" className={classes.TableCurrentPrice}>Current Market Value</TableCell>  
              <TableCell align="right" className={classes.TableCurrentPrice} >{Intl.NumberFormat('en-US',{style:'decimal', minimumFractionDigits:1,maximumFractionDigits:1}).format(companyData.marketCap)}</TableCell>
            </>
          }
        </TableRow>
        <TableRow>
          <TableCell align="left" className={classes.TableValuationPrice}>Valuation Target Stock Price </TableCell>
          <TableCell align="right" className={classes.TableValuationPrice} >{Intl.NumberFormat('en-US',{style:'currency',currency:'USD'}).format(valuation.targetStockPrice)}</TableCell>
        </TableRow>

        <TableRow>

          
          { valuationDateInfo!==null && valuationDateInfo!==undefined?
            <>
              <TableCell className={classes.TableCurrentPrice} align="left">Stock Price at Valuation Date</TableCell>
              <TableCell align="right" className={classes.TableCurrentPrice} >{Intl.NumberFormat('en-US',{style:'currency',currency:'USD'}).format(valuationDateInfo.datePrice)}</TableCell>
            </>
          :
            <>
              <TableCell className={classes.TableCurrentPrice} align="left">Current Stock Price</TableCell>
              <TableCell align="right" className={classes.TableCurrentPrice} >{Intl.NumberFormat('en-US',{style:'currency',currency:'USD'}).format(companyData.regularMarketPrice)}</TableCell>
            </>
          }
        </TableRow>
        {/* <TableRow>
          <TableCell className={classes.TableValuationPrice} align="left">Estimated IRR</TableCell>
          { valuation.irr >= 0? 
            <TableCell align="right" className={classes.TableValuationPrice} >{Intl.NumberFormat('en-US',{style:'percent',minimumFractionDigits:2,maximumFractionDigits:2}).format(valuation.irr)}</TableCell>
            :
            <TableCell align="right" className={classes.TableValuationPrice} >NA</TableCell>
          }
        </TableRow> */}
        {/* <TableRow>
          <TableCell className={classes.TableCurrentPrice} align="left">52 Week Price Range</TableCell>
          <TableCell align="right" className={classes.TableCurrentPrice} style={{ padding:"0px", marginRight:"5px", fontSize:9}}>{priceRange}</TableCell>
        </TableRow> */}
        {/* <TableRow>
          <TableCell align="left" className={classes.TableCurrentPrice}>Current Equity Value</TableCell>
          <TableCell align="right" className={classes.TableCurrentPrice} >{Intl.NumberFormat('en-US',{style:'decimal', minimumFractionDigits:1,maximumFractionDigits:1}).format(companyData.marketCap)}</TableCell>
        </TableRow> */}
        <TableRow>
          <TableCell align="left" className={classes.TableRows}>Total Shares Outstanding (millions)</TableCell>
          { companyData.sharesOutstanding ? <>
            <TableCell align="right" className={classes.TableRows} >{Intl.NumberFormat('en-US',{style:'decimal', minimumFractionDigits:0,maximumFractionDigits:0}).format(companyData.sharesOutstanding)}</TableCell>
          </>: <>
            <TableCell align="right" className={classes.TableRows} >{Intl.NumberFormat('en-US',{style:'decimal', minimumFractionDigits:0,maximumFractionDigits:0}).format(0)}</TableCell>
          </>
        }
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
  </>
  )
}