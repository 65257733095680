import React, { useState, useContext } from 'react';

import { Grid, Paper, Box, Button, Typography} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';

import useAxios from '../../../hooks/useAxios.js';
import valuationsWebApi from '../../../services/valuationsWebApi.js';
import { LoginContext } from '../../../helpers/Context.js';

const useStyles = makeStyles( (mainTheme) => ({
  buttonStyle:{
    color: "white",
    backgroundColor:mainTheme.palette.primary.main,
    textTransform:"none",
    fontSize:12,
    margin: "10px",
    //marginTop: "15px",
    "&:hover": {
      color:mainTheme.palette.secondary.main,
      backgroundColor:mainTheme.palette.primary.main,
    },
  },
  }))

export default function ShowLatestUsers (){
  const classes = useStyles();
  const { userData } = useContext(LoginContext);
  const { userId } = userData;
  const [ savedUsersList, setSavedUsersList ] = useState([])
  const { axiosFetch: getAllUsers, isLoading: isLoadingAllUsers, error: isErrorAllUsers } = useAxios();

  const getAllUsersSuccessCb=(apiData)=>{ 
    // const dataFromApi=apiData
    const dataFromApi = apiData.map(item => ({
      ...item,
      updatedAt: new Date(item.updatedAt)
    }));
    const sortedArrayDescending = dataFromApi.sort((a, b) => b.updatedAt - a.updatedAt);
    // setSavedUsersList(apiData)
    setSavedUsersList(sortedArrayDescending)

  }

  const getAllUsersErrorCb=(apiData)=>{
    alert("Error fetching users")
  }

  const getSavedUsers=()=>{
    getAllUsers({ axiosInstance: valuationsWebApi, method: 'GET', url: '/users', requestConfig: { headers: {'Authorization': userId,},}},getAllUsersSuccessCb, getAllUsersErrorCb);
  }

  return ( 
    <>
    { userId==="martincsl@hotmail.com" ? 
      <>
      <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" >
    
          <Grid item xs={12} md={6} >
            <Paper className={classes.paper} elevation={12}>
              <Button 
                variant = "contained" 
                disableRipple
                onClick = {getSavedUsers} 
                className = {classes.buttonStyle}
                >Get Latest Users
              </Button>
            </Paper>
            <Box style={{height:"10px", backgroundColor:"green"}}/>
          </Grid>
          { savedUsersList.length > 0 ? 
            
            <Paper elevation={12}>
              <Typography>{`Total de usuarios: ${savedUsersList.length}`}</Typography>
                <>
                  { savedUsersList.map ((currUser)=>(<>
                    <Typography>{`User ${currUser.id}  updated at ${currUser.updatedAt}}`}</Typography>
                    </>
                  ))}
                </>
            </Paper>
          : null}
      </Grid>
    </>
    : 
    null }
  </>
  )
}