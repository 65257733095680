import React from 'react';

import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((mainTheme) => ({
simpleValuationStyle:{
    fontSize:11, 
    margin:"3px",
    color: mainTheme.palette.tertiary.main
  },
}))

export default function EstimatedCostOfCapitalText(){
const classes=useStyles();

return (
  <>
  <Typography align="justify" gutterBottom className={classes.simpleValuationStyle} >
  This valuation was conducted by directly estimating the cost of capital, without utilizing the Capital Asset Pricing Model.</Typography>
  </>
)
}