import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// import Books from './pages/Books';
import Contact from './pages/Contact';
// import Valuation from './pages/Valuation';
import Valuations from './pages/Valuations';
// import SavedValuation from './pages/SavedValuation';
import DividendYield from './pages/DividendYield';
import Graham from './pages/Graham';
import Home from './pages/Home';
import GetApiData from './pages/Graham/GetApiData.jsx';
import GetWorkingCapitalChanges from './pages/GetWorkingCapitalChanges.jsx';
import GetYahooFinanceData from './pages/GetYahooFinanceData';
import PricingTest from './pages/PricingTest';
import RegisterStepper from './pages/RegisterStepper';
import Whoweare from './pages/Whoweare';
import LandingPage from './pages/LandingPage';
import PeerProfile from './pages/PeerProfile';
import Login from './pages/Login';
import Logout from './pages/Logout';
import Header from './components/Header';
import ResetPassword from './pages/ResetPassword';
import Register from './pages/Register';
import StripeSuccess from './pages/StripeSuccess.jsx';
import StripeCanceled from './pages/StripeCanceled.jsx';
import Unsubscribe from './pages/Unsubscribe.jsx';
import Upgrade from './pages/Upgrade.jsx';
import ValuationSample from './pages/Valuations/ValuationSample'


export default function Routes() {

  return (
    <Router>
      <Header />
      <Switch>
        <Route path = "/" exact component = {Home} />
        <Route path = "/home/:id?" component = {Home} />
        <Route path = "/peer-profile" component = {PeerProfile} />
        <Route path = "/landing" component = {LandingPage} />
        <Route path = "/dividend-yield" component = {DividendYield} />
        <Route path = "/graham" component = {Graham} />
        <Route path = "/valuation" component = {Valuations} />
        {/* <Route path = "/books" component = {Books} /> */}
        {/* <Route path = "/blog" component = {Blog} /> */}
        <Route path = "/pricing" component = {PricingTest} />
        <Route path = "/saved-valuation/:id?" component = {Valuations} />
        <Route path = "/valuation-sample/:id?" component = {ValuationSample} />
        <Route path = "/who-we-are" component = {Whoweare} />
        <Route path = "/contact" component = {Contact} />
        <Route path = "/login" component = {Login} /> 
        <Route path = "/logout" component = {Logout} /> 
        {/* <Route path = "/register/:product?" component = {RegisterStepper} /> */}
        <Route path = "/register/:product?" component = {Register} />
        <Route path = "/update" component = {GetApiData} />
        <Route path = "/wcc" component = {GetWorkingCapitalChanges} />
        <Route path = "/reset" component = {ResetPassword} />
        <Route path = "/stripe-success/:session_id?" component = {StripeSuccess} />
        <Route path = "/stripe-canceled" component = {StripeCanceled} />
        <Route path = "/unsubscribe" component = {Unsubscribe} />        
        <Route path = "/upgrade" component = {Upgrade} />
      </Switch>
    </Router>
  );
}
