import React, { useState } from 'react';

import { Paper, Grid, TextField, InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import useValuationForm from '../../../hooks/useValuationForm';
import DialogModal from '../../../components/modals/DialogModal';

const useStyles = makeStyles( (mainTheme) => ({
  table: {
    minWidth: 300,
    maxHeight: 900
  },
  tableHeader:{
    backgroundColor: mainTheme.palette.secondary.main
  },
  tableHeaderDisabled:{
    backgroundColor:  mainTheme.palette.primary.main
  },
  tableTitle:{
    color: "white",
    fontSize: 12
  },
  tableTitleDisabled:{
    color: mainTheme.palette.secondary.main,
    fontSize: 12
  },
  TableRows:{
    fontSize: 11
  }
  }));



export default function FormCashFlowAssumptions ({assumptions, setAssumptions, calculatedCostOfCapital, editMode}){
  const classes = useStyles();
  const { formErrors, handleChange, noBlanks, isValidDiscretePeriod } = useValuationForm({ assumptions, setAssumptions })
  const [ isDialogOpen, setIsDialogOpen ] = useState(false);
  const [ dialogOptions, setDialogOptions ] = useState({severity:"",title:"",message:"",buttons:{},action:""});
 
  const handleDialogClose=()=>{
    setIsDialogOpen(false);
    setDialogOptions({severity:"",title:"",message:"",buttons:{},action:""});
  }

  
  const handleChangeDiscretePeriod=(value)=>{
    if (value <=0 || value==="" || value===null){
      setAssumptions((prevState) => ({ ...prevState, cashFlowDiscretePeriod: 1 }));
      setDialogOptions({
        severity: "error",
        title: "Invalid discrete period",
        message: "The discrete period must be at least 1. Please choose a value between 1 and 20 !",
        buttons: { button1: "Ok" }
      });
      setIsDialogOpen(true);
    } else {
      setAssumptions((prevState) => ({ ...prevState, cashFlowDiscretePeriod: value }));
    }
  }
  
  const handleDiscretePeriod=(value)=> {
    const maximumValue = 15
    const minimumValue = 1
    const recommendedValue = 3

    if (value < minimumValue){

      setDialogOptions({
        severity: "error",
        title: "Invalid discrete period",
        message: "The discrete period must be at least 1. Please choose a value between 1 and 20 !",
        buttons: { button1: "Ok" }
      });
      setIsDialogOpen(true);
      setAssumptions((prevState) => ({ ...prevState, cashFlowDiscretePeriod: recommendedValue }));
    }
    setAssumptions((prevState) => ({ ...prevState, cashFlowDiscretePeriod: value }));
  }


  const handlePerpetualGrowth = (value) => {
    const maximumValue = calculatedCostOfCapital.costOfCapital
    const recommendedGap = calculatedCostOfCapital.costOfCapital> 0 ? parseFloat(maximumValue) - 3: 3;
    let isFirstDialogDisplayed = false;

    if (calculatedCostOfCapital.costOfCapital > 0 && calculatedCostOfCapital.costOfCapital !==null && calculatedCostOfCapital.costOfCapital!==""){
      if ( value >= maximumValue){
        setDialogOptions({
          severity: "error",
          title: "Invalid perpetual growth rate",
          message: "The perpetual growth rate must be lower than the cost of capital !",
          buttons: { button1: "Ok" }
        });
        setIsDialogOpen(true);
        isFirstDialogDisplayed = true;
        setAssumptions((prevState) => ({ ...prevState, perpetualGrowthRate: recommendedGap> 5? 4 : recommendedGap }));
      } else {
        setAssumptions((prevState) => ({ ...prevState, perpetualGrowthRate: value }));
      }
      if (!isFirstDialogDisplayed && value >= recommendedGap) {
        setDialogOptions({
          severity: "warning",
          title: "Overvaluation Alert!",
          message: "A small difference between the cost of capital and perpetual growth rate can lead to an overvaluation of the terminal value and target stock price.",
          buttons: { button1: "Ok" }
        });
        setIsDialogOpen(true);
      }
    } else {
      setAssumptions((prevState) => ({ ...prevState, perpetualGrowthRate: value }));
    }
  }  

  return (
  <>
  <TableContainer component={Paper}>
    <Table className={classes.table} size="small" aria-label="stycky header">

      <TableHead className={editMode==="saved" || editMode==="published" ? classes.tableHeaderDisabled :classes.tableHeader}>
        <TableRow>
          <TableCell className={editMode==="saved" || editMode==="published" ? classes.tableTitleDisabled :classes.tableTitle} align="left">Business Assumptions</TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        <TableRow>
          <Grid container>
            <Grid item xs = {4}>
              <TextField label="Free Cash Flow Growth Rate"
                size="small"
                value={assumptions.cashFlowAvgGrowth}
                type="number"
                min={2}
                onChange={(e) => {handleChange (e,[])}}
                variant="filled"
                fullWidth
                name="cashFlowAvgGrowth"
                disabled={editMode==="saved" || editMode==="published"}
                error={formErrors.cashFlowAvgGrowth}
                helperText={formErrors.cashFlowAvgGrowth}
                InputProps={{
                  startAdornment: <InputAdornment position="start">%</InputAdornment>,
                }}
              />
              {/* {formErrors.cashFlowAvgGrowth ? <div className="error-helper-text">{formErrors.cashFlowAvgGrowth}</div> : null} */}
            </Grid>

            <Grid item xs = {4} >
              <TextField label="Perpetual Growth Rate"
                size="small"
                value={assumptions.perpetualGrowthRate}
                type="number"
                onChange={(e) => {handleChange (e,[])}}
                onBlur={(e) => {handlePerpetualGrowth (e.target.value)}}
                variant="filled"
                fullWidth
                name="perpetualGrowthRate"
                disabled={editMode==="saved" || editMode==="published"}
                error={formErrors.perpetualGrowthRate}
                helperText={formErrors.perpetualGrowthRate}
                InputProps={{
                  startAdornment: <InputAdornment position="start">%</InputAdornment>,
                }}
              />
            </Grid>
            
            <Grid item xs={4} >
              <Tooltip title="Free Cash Flow discrete period, in years, before perpetuity">
                <TextField label="FCFF Discrete Period (Years)"
                  size="small"
                  value={assumptions.cashFlowDiscretePeriod}
                  type="number"
                  placeholder="Value ?"
                  onChange={(e) => {handleChangeDiscretePeriod (e.target.value)}}
                  onBlur={(e) => {handleDiscretePeriod (e.target.value)}}
                  variant="filled"
                  fullWidth
                  name="cashFlowDiscretePeriod"
                  disabled={editMode==="saved" || editMode==="published"}
                  error={formErrors.cashFlowDiscretePeriod}
                  helperText={formErrors.cashFlowDiscretePeriod}
                  InputProps={{
                    inputProps: { 
                        max: 30, min: 1 
                    }}}
                />
              </Tooltip>
            </Grid>

          </Grid>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
  <DialogModal open={isDialogOpen} onClose={handleDialogClose} severity={dialogOptions.severity} title={dialogOptions.title} buttons={dialogOptions.buttons} action={dialogOptions.action}>
    {dialogOptions.message}
  </DialogModal>
  </>
  )
}