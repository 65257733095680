import React, { useState, useContext } from 'react';

import { Modal, Grid, Box, TextField, Typography, Button, Rating } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

// import useFetch from '../../hooks/useFetch.js';
import { LoginContext } from '../../../helpers/Context.js';
import useAxios from '../../../hooks/useAxios.js';
import valuationsWebApi from '../../../services/valuationsWebApi';
import DialogModal from '../../../components/modals/DialogModal.jsx';

const useStyles = makeStyles((mainTheme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    backgroundColor: "whitesmoke",
    padding: mainTheme.spacing(2),
    borderRadius: mainTheme.shape.borderRadius,
    width: 350,
  },
  titleStyle:{
    fontSize:"13px",
    color:mainTheme.palette.tertiary.main
  }
}));

//  Llamada por OtherUserValuationButtons y OwnValuationButtons
const CreateCommentModal = ({ open, onClose, valuationId, userId, comment, setComment, setInteractions }) => {
  const classes = useStyles();
  // const [ comment, setComment] = useState('');
  const { isNewComment, setIsNewComment } = useContext(LoginContext);
  const [ dialogOptions, setDialogOptions ] = useState({severity:"",title:"",message:"",buttons:{}, action:""});
  const [ isDialogOpen, setIsDialogOpen ] = useState(false);
  const { axiosFetch: postComment} = useAxios();
  // const { saveComment } = useFetch();

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleCommentDialogClose =()=>{
    setIsNewComment(false);
    setIsDialogOpen(false);
  }

  const saveCommentSuccessCb=()=>{
    setDialogOptions({severity:"success", title:"Thank You!", message:"Thank you for sharing your thougths with our users !",buttons:{button1:"Ok"}})
    setInteractions(prevInteractions => ({
      ...prevInteractions,
      comments: prevInteractions.comments + 1,
    }));
    setIsNewComment(true)
    setIsDialogOpen (true);
  } 

  const saveComment=(commentData)=>{  
    // console.log("comment data: " + commentData)
    const { valuationId, profileId, text } = commentData
    // const userId = userId; 
    const dataToProcess = { valuationId, profileId, text }
    // setIsNewComment(true);
    postComment({ axiosInstance: valuationsWebApi, method: 'POST', url: '/comments', data: dataToProcess, requestConfig: { headers: {'Authorization': userId,},}},saveCommentSuccessCb, errorCallback);
  }

  function errorCallback(errorMessage){
    // alert("Error saving comment")
    // setDialogOptions({severity:"error", title:"Error", message:errorMessage,buttons:{button1:"Ok"}})
    // setIsDialogOpen (true);
  }

  const handleCommentSubmit = () => {
    if (comment !=="") {
      saveComment({"valuationId":valuationId,"profileId":userId,"text":comment})
      setComment("");
      setIsNewComment(true);
    }
    onClose();
  };

  return (
    <>
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
    > 
      <div className={classes.modalContent}>
        <Typography align="center" className={classes.titleStyle} >Please, add your comment</Typography>
        <Box sx={{height:"10px"}}/>
        <TextField
          // label="Add a comment to this Valuation"
          autoFocus
          multiline
          rows={4}
          variant="outlined"
          fullWidth
          value={comment}
          onChange={handleCommentChange}
        />
        <Box style={{height:"5px"}}/>
        {/* {console.log("valuationId :"+valuationId)} */}
        <Grid container direction="row">
          {/* <Grid item xs={8}>
            <Typography align="center" sx={{fontSize:"12px"}}>Do you want to rate this valuation?</Typography>
          </Grid>
          <Grid item xs={4}>
          <Rating
            name="simple-controlled"
            size={"small"}
            value={rating}
            onChange={(event, newValue) => {
              setRating(newValue);
            }}
          />
          </Grid> */}

        </Grid>
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button variant="contained" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleCommentSubmit}
            style={{ marginLeft: '8px' }}
          >
            Comment
          </Button>
        </Box>
      </div>
    </Modal>
    <DialogModal open={isDialogOpen} onClose={handleCommentDialogClose} severity={dialogOptions.severity} title={dialogOptions.title} buttons={dialogOptions.buttons} action={dialogOptions.action}>
      {dialogOptions.message}
    </DialogModal> 
    </>
  );
};

export default CreateCommentModal;