import React from'react';

import { Grid, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import YouTubeIcon from '@mui/icons-material/YouTube';
// import InstagramIcon from '@mui/icons-material/Instagram';
// import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';

const useStyles = makeStyles( (mainTheme) => ({
  sectionTitleStyle:{
    marginLeft:"5px",
    fontSize:"13px",
    color: mainTheme.palette.tertiary.main
  },
  iconStyle:{
    fontSize:20, 
    marginLeft:"5px",
    color:mainTheme.palette.secondary.main,
    '&:hover': {
      color: "#F49506ed", 
    },
  }
})) 

export default function SocialNetworks(){

  const classes = useStyles();
  const youtubeLink = "https://www.youtube.com/@ValuationsHub/featured";
  const twitterLink = "https://twitter.com/valuationshub" 
  // const fbLink="https://www.facebook.com/profile.php?id=61560009957782"

  return (
    <Grid container direction="row" style={{width:"300px"}}>

      <Grid >
        <Typography className={classes.sectionTitleStyle} style={{fontSize:13}}><b>Follow us on our social networks</b></Typography>
      </Grid>
      
      <Grid>
        <Tooltip title="Go to ValuationsHub's youtube channel">
          <a href={youtubeLink} target="_blank" rel="noopener noreferrer">  
            <YouTubeIcon className = {classes.iconStyle} />
          </a>
        </Tooltip>
        <Tooltip title="Go to ValuationsHub's X's account">
          <a href={twitterLink} target="_blank" rel="noopener noreferrer">  
            <XIcon className = {classes.iconStyle} style={{fontSize:"16px", marginBottom:"2px"}} />
          </a>
        </Tooltip>
        {/* <Tooltip title="Go to ValuationsHub's Instagram account">
          <InstagramIcon className = {classes.iconStyle} />
        </Tooltip> */}
        {/* <Tooltip title="Go to ValuationsHub's Facebook account">
          <a href={fbLink} target="_blank" rel="noopener noreferrer">  
            <FacebookIcon className = {classes.iconStyle} />
          </a>
        </Tooltip>  */}
      </Grid>
    </Grid>
  )
}