import React from 'react';
import { format, parseISO , startOfMonth, endOfMonth } from 'date-fns';

import { TableContainer, Table, TableBody, TableRow, TableCell } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((mainTheme) => ({
  table: {
    // minWidth: 300,
    marginLeft:"10px",
    maxWidth: "200px",
  },
  TableHeader:{
    color: "white",
    backgroundColor: mainTheme.palette.primary.main
  },
  TableTitle:{
    color: "white",
    marginLeft:"2px",
    fontSize: 12
  },
  TableRows : {
    fontSize: 11,
    marginLeft:"2px",
    },
  CurrentPriceTextStyle:{
    color: "white",
    width:"70%",
    marginLeft:"2px",
    paddingLeft:"5px",
    paddingRight:"5px",
    backgroundColor: mainTheme.palette.tertiary.main, //mainTheme.palette.primary.main,
    fontSize: 11,
  },
  CurrentPriceNumberStyle:{
    color: "white",
    width:"30%",
    paddingLeft:"5px",
    paddingRight:"5px",
    marginRight:"2px",
    backgroundColor: mainTheme.palette.tertiary.main, //mainTheme.palette.primary.main,
    fontSize: 11,
  },
  TargetPriceTextStyle:{
    color: mainTheme.palette.secondary.main,
    backgroundColor: mainTheme.palette.primary.main,
    width:"70%",
    paddingLeft:"5px",
    paddingRight:"5px",
    fontSize: 11
  },
  TargetPriceNumberStyle:{
    color: mainTheme.palette.secondary.main,
    backgroundColor: mainTheme.palette.primary.main,
    width:"30%",
    paddingLeft:"5px",
    paddingRight:"5px",
    marginRight:"2px",
    fontSize: 11
  }
  }));

export default function TableMarginOfSafety ({valuation, companyData}){

  const classes = useStyles();
  const updatedAtDate = companyData.updatedAt? parseISO(companyData.updatedAt):null;
  const currentPriceDate = updatedAtDate? format(updatedAtDate, "MMM,dd"):"";
  return (
    <>
    <TableContainer >
      <Table className={classes.table} size="small" aria-label="stycky header">
        <TableBody>
          <TableRow>
            <TableCell align="left" className={classes.TargetPriceTextStyle}>Target Stock Price </TableCell>
            <TableCell align="right" className={classes.TargetPriceNumberStyle} >{Intl.NumberFormat('en-US',{style:'currency',currency:'USD'}).format(valuation.targetStockPrice)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.CurrentPriceTextStyle} align="left">{`Current Price (${currentPriceDate})`}</TableCell>
            <TableCell align="right" className={classes.CurrentPriceNumberStyle} >{Intl.NumberFormat('en-US',{style:'currency',currency:'USD'}).format(companyData.regularMarketPrice)}</TableCell>
          </TableRow>
        </TableBody>  
    </Table >
    </TableContainer >
    </>
  )
}