import React from 'react';

import { Button, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import AddCircleIcon from '@mui/icons-material/AddCircle';

const useStyles = makeStyles( (mainTheme) => ({
  buttonStyle: {
    [mainTheme.breakpoints.down('sm')]: {
      fontSize: "10px"
    },
  },
})) 

export default function ButtonNewValuation ({ userId, handleNewValuation, handleRegisterMessage}){
  const classes = useStyles();
  return (
    <>
    <Tooltip title="Create a new valuation">
      <Button 
        variant = "contained" 
        startIcon = {<AddCircleIcon />} 
        autoFocus
        disableRipple
        onClick = {userId ? handleNewValuation : handleRegisterMessage } 
        // disabled = {userId? false : true}
        className = {classes.buttonStyle}
        >New valuation 
      </Button>
    </Tooltip>
    </>
  )
}