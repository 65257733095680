import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { LoginContext } from '../../../../helpers/Context';

import OwnValuationButtons from './OwnValuationButtons';
import OtherUserValuationButtons from './OtherUserValuationButtons';


// Llamada por SavedButtons index
export default function SavedButtons ({ companyData, historicalFinancialData, forecastedFinancialData, combinedFinancialData, editMode, setEditMode, assumptions, calculatedCostOfCapital, valuation, handleNewValuation, interactions, setInteractions, isCheckedDescOrder, isCheckedShowPreviousYears, isEstimateFcffOnly, isUserCostOfCapital, valuationDateInfo }){
  const { id } = useParams();
  const { userData, savedValuationData } = useContext(LoginContext);
  const { userId } = userData;
  const { profileId } = savedValuationData

  return (
    <>
    { userId === profileId ? 
    <>
      <OwnValuationButtons
        companyData={companyData}
        historicalFinancialData={historicalFinancialData}
        assumptions={assumptions} 
        calculatedCostOfCapital={calculatedCostOfCapital} 
        forecastedFinancialData={forecastedFinancialData} 
        combinedFinancialData={combinedFinancialData}
        valuation={valuation}
        editMode={editMode}
        setEditMode={setEditMode}
        setInteractions={setInteractions}
        isCheckedDescOrder={isCheckedDescOrder}
        isCheckedShowPreviousYears={isCheckedShowPreviousYears}
        isEstimateFcffOnly={isEstimateFcffOnly}
        isUserCostOfCapital={isUserCostOfCapital}
        valuationDateInfo={valuationDateInfo}
      />
    </> 
    : 
    <>
      <OtherUserValuationButtons 
        companyData={companyData}
        historicalFinancialData={historicalFinancialData}
        assumptions={assumptions} 
        calculatedCostOfCapital={calculatedCostOfCapital} 
        forecastedFinancialData={forecastedFinancialData} 
        combinedFinancialData={combinedFinancialData}
        valuation={valuation}
        editMode={editMode}
        setEditMode={setEditMode}
        interactions={interactions}
        setInteractions={setInteractions}
        isCheckedDescOrder={isCheckedDescOrder}
        isCheckedShowPreviousYears={isCheckedShowPreviousYears}
        isEstimateFcffOnly={isEstimateFcffOnly}
        isUserCostOfCapital={isUserCostOfCapital}
        valuationDateInfo={valuationDateInfo}
      />
    </>}
    </>
  )  
}