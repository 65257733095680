import React, { useState, useContext, useEffect } from 'react';
import ReactGA from 'react-ga4';


// import 3rd party libs
import html2canvas from 'html2canvas';  
import jsPDF from 'jspdf';

// import mui components and icons
import { Paper, Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box, Button, Avatar, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
import CancelIcon from '@mui/icons-material/Cancel';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image'; // in case of downloading the report inpng format


// import contexts and hooks
import { LoginContext } from '../../../../helpers/Context.js';
import useAxios from '../../../../hooks/useAxios.js';
import valuationsWebApi from '../../../../services/valuationsWebApi.js';

// import assets
import LogoWhiteBackground from '../../../../assets/LogoVH-B&W1.svg';

// import custom components
import AssumptionsText from '../../AssumptionsText.jsx';
import ChartFreeCashFlow from '../../ChartFreeCashFlow.jsx';
import CompanyText from '../CompanyText.jsx';
import DisclaimerText from '../../DisclaimerText.jsx';
import EstimatedCostOfCapitalText from '../EstimatedCostOfcapitalText.jsx';
import EstimatedFcffText from '../EstimatedFcffText.jsx';
import ShowCompanyLogo from '../../../../components/ShowCompanyLogo.jsx';
import TableBusinessAssumptions from '../TableBusinessAssumptions.jsx';
import TableCashFlow from '../../TableCashFlow.jsx';
import TableCostOfCapitalAssumptions from '../TableCostOfCapitalAssumptions.jsx';
import TableIncomeStatement from '../../TableIncomeStatement.jsx';
import TableMarginOfSafety from '../TableMarginOfSafety.jsx';
import TableUserCostOfCapital from '../TableUserCostOfCapital.jsx';
import TableValuation from '../../TableValuation.jsx';

const useStyles = makeStyles((mainTheme) => ({
  buttonStyle:{
    minWidth:"130px",
  }, 
  dialogStyle:{
    width: "1100px", 
  },
  TableMarginOfSafetyStyle:{
    // height: "80px",
    [mainTheme.breakpoints.down('sm')]: {
      height: "5px"
    }
  },
  logoStyle: {
    position: "relative",
    height: "56px",
    // padding: "24px",
    top:"0px"
  },
  companyDataStyle:{
    fontSize: "12px",
    marginLeft:  "10px",//"5px",
    color: mainTheme.palette.primary.main
  },
  currencyDataStyle:{
    fontSize: "12px",
    marginLeft:"5px",
    color: mainTheme.palette.tertiary.main
  },
  linkTextStyle:{
    fontSize: "12px",
    marginLeft:"5px",
    color: mainTheme.palette.tertiary.main
  },
  userNameStyle:{
    fontSize: "12px",
    color: mainTheme.palette.tertiary.main
  },
}))

export default function DownloadDesktopReport ({ open, onClose, companyData, historicalFinancialData, combinedFinancialData, assumptions, calculatedCostOfCapital, valuation, isCheckedDescOrder, isCheckedShowPreviousYears, isEstimateFcffOnly, isUserCostOfCapital,valuationDateInfo }) {
  const classes = useStyles();
  const { savedValuationData } = useContext(LoginContext);
  const { profileId, profileFirstName, profileLastName} = savedValuationData
  const { assumptionsText } = assumptions;
  const companyId= companyData.symbol;
  const { axiosFetch: getProfileImage } = useAxios();
  // const { axiosFetch: getLogoImage } = useAxios();
  const [ profilePictureDataUrl, setProfilePictureDataUrl] = useState(null);
  // const [ companyLogoDataUrl, setCompanyLogoDataUrl] = useState(null);
  const [ loader, setLoader] = useState(false);
  // const [imageDataUrl, setImageDataUrl] = useState(null);


  const canvasOptions = {
    backgroundColor: '#ffffff',
    allowTaint : true,
    useCors : true,
    imageTimeout: 15000,
    scale: 2
  };

  const downloadReport = () =>{
    const capture = document.querySelector('.valuation-report');
    setLoader(true);
    html2canvas(capture, canvasOptions).then((canvas)=>{
      const imgData = canvas.toDataURL('image/png', 1); // 0.8 is the quality parameter (0 to 1)
      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;
      const link = document.createElement('a');
      link.href = imgData;
      link.target = "_blank"; // Open link in a new tab/window
      // link.download = `valuation-${companyData.shortName}.png`; // You can change the file extension to .jpeg if needed
      // link.click();

      var pdf = new jsPDF('l', 'px', [canvasWidth,canvasHeight]);
      pdf.addImage(imgData, 'PNG', 0, 0, canvasWidth, canvasHeight);

      pdf.setPage(1);
      pdf.setTextColor(52,73,85);
      pdf.setFont("roboto",undefined, "bold");
      pdf.setFontSize(35);

 
      pdf.textWithLink(
        'www.valuationshub.com',
        1185, 
        720, 
      {
        url: 'https://www.valuationshub.com', 
      }
      );

      pdf.textWithLink(
        'www.valuationshub.com',
        1995, 
        165, 
      {
        url: 'https://www.valuationshub.com', 
      }
      );
      pdf.save(`DCF Valuation-${companyData.shortName}.pdf`);
      setLoader(false);
    })
  }

  const getFirstAndLastName= (fullName) => {
    const nameParts = fullName.split(' ');
    const firstName = nameParts[0];
    const lastName = nameParts[nameParts.length - 1];
    if (firstName==="" && lastName===""){
      return "Unidentified"
    }
    return `${firstName} ${lastName}`;
  } 


  const getProfileImageSuccessCb=(apiData)=>{
    if (apiData){
      setProfilePictureDataUrl(apiData.dataUrl)
    }
  }

  const getProfileImageErrorCb=()=>{
    // alert("error receiving user photo from the server")
  }

  // const getLogoImageSuccessCb=(apiData)=>{
  //   if (apiData){
  //     // alert("recibio datos de la API")
  //     setCompanyLogoDataUrl(apiData.dataUrl)
  //   }
  // }

  // const getLogoImageErrorCb=(error)=>{
  //   alert("error en logo image "+ error)
  // }




  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/report-desktop", title: "Report Desktop" });
    getProfileImage({ axiosInstance: valuationsWebApi, method: 'GET', url: `/proxy-image/${profileId}`, requestConfig: { headers: {'Authorization': profileId,},}},getProfileImageSuccessCb, getProfileImageErrorCb);
    // getLogoImage({ axiosInstance: valuationsWebApi, method: 'GET', url: `/proxy-image-logo/${companyId}`, requestConfig: { headers: {'Authorization': profileId,},}},getLogoImageSuccessCb, getLogoImageErrorCb);
  }, []); 

  return (
    <>
    { companyData ? <>
      <Box >  
      <Dialog 
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
      >
        <DialogTitle id="alert-dialog-title" ><Typography align="center" variant="h6" style={{color:'white'}}>Generate Pdf</Typography></DialogTitle>
        <DialogContent style={{color:'white'}}>
          <DialogContentText id="alert-dialog-description">

            <div className="valuation-report">
              <Grid container spacing ={1} sx={{paddingTop:"60px",paddingLeft:"60px"}} >
                
                {/* left side grid */}
                <Grid item container xs={12} sm={7} > 

                  <Grid item container xs={12} sm={4} > 
                    
                    <Grid item container xs={12} >

                      <Grid item xs={12}>
                        <img src = {LogoWhiteBackground} alt="Logo" className={classes.logoStyle}  />
                      </Grid>

                      <Grid item xs={12}  >
                        <Box style={{marginLeft:"5px"}} />
                        <Box style={{display:"inline-flex",alignItems:"center", justifyContent:"center", marginLeft:"10px"}}>

                          <Grid container direction="column" style={{ display: "flex", alignItems: "center" }}>
                            <Grid style={{ display: "inline-flex", alignItems: "center", justifyContent: "center"}}>
                              <Typography align ="center" className={classes.userNameStyle}  >
                                Crafted by</Typography>
                            </Grid>
                            <Grid style={{ display: "inline-flex", alignItems: "center", justifyContent: "center"}}>
                              <Box style={{display:"flex", justifyContent:"center"}}>
                              { profilePictureDataUrl ? 
                                <Avatar
                                  style={{ width: '68px', height: '68px', marginLeft:"0px" }}
                                  alt={`User ${profileId}`}
                                  src={profilePictureDataUrl}
                                />
                              : 
                                <Avatar  style={{ width: '68px', height: '68px' }} src="/broken-image.jpg" /> 
                              }
                              </Box>
                            </Grid>
                            <Grid style={{ display: "inline-flex", alignItems: "center", justifyContent: "center"}}>
                              <Typography align = "center" className={classes.userNameStyle} style={{marginLeft:"0px"}}>
                              {getFirstAndLastName(`${profileFirstName} ${profileLastName}`)}</Typography>
                            </Grid>
                          </Grid>

                        </Box>
                      </Grid>
                    
                      <Grid item container direction="row" xs={12} style={{marginLeft:"5px",marginTop:"5px",marginBottom:"5px"}}>
                        {/* <Box style={{marginLeft:"5px", marginTop:"5px"}}> */}
                        <Grid item xs={1} sm={2} style={{ display:"flex", alignItems:"center", justifyContent:"center"}}>
                          <ShowCompanyLogo 
                            companyId={companyId}
                            // companyLogoDataUrl={companyLogoDataUrl}
                            size="medium"
                          /> 
                        
                          {/* { companyLogoDataUrl ? <img src = {companyLogoDataUrl} alt="company logo" style={{height:"28px"}}/>: <Typography>Sin imagen</Typography>}     */}
                        </Grid>  
                        <Grid item xs={11} sm={10} >
                          <CompanyText />
                        </Grid>  
                        {/* { companyId==="AAPL" || companyId==="AMZN" || companyId==="CAT" ? 
                            <img src = {getCustomizedLogo(companyId)} alt="Logo" style={{height:"32px"}}/>   
                            : */}
                        {/* { companyLogoDataUrl ? <img src = {companyLogoDataUrl} alt="Logo" style={{height:"32px"}}/>: <Typography>Sin imagen</Typography>}     */}
                          
                        {/* } */}
                          {/* <CompanyText /> */}
                        {/* </Box> */}
                      </Grid>
      
                      <Grid item xs={12}>
                        <Box style={{width:"100%", marginLeft:"0px"}}>
                          <TableMarginOfSafety 
                            valuation = {valuation} 
                            historicalFinancialData = {historicalFinancialData} 
                            calculatedCostOfCapital = {calculatedCostOfCapital}
                            assumptions = {assumptions}
                            companyData = {companyData}
                          />  
                        </Box>
                      </Grid>
                      <Box style={{height:"5px"}}/> 
                    </Grid>
                  </Grid>

                  <Grid item container xs={12} sm={8} >
                    { assumptionsText !=="" ? 
                      <Grid item container display="flex" alignItems="flex-end" justifyContent="flex-end" >  
                        <Box sx={{marginRight:"5px",paddingRight:"15px", width:"100%"}}>
                          <Grid item >
                            <AssumptionsText assumptionsText={assumptionsText}/>
                          </Grid>
                        </Box>
                      </Grid>
                    : null  }
                  </Grid>

                  <Grid item xs={12} >

                    <Paper style={{backgroundColor:"white"}}>
                        <Box style={{height:"8px"}}/>
                        <Grid container direction="row" >
                          <Grid item xs={4}>
                          <Typography gutterBottom className={classes.currencyDataStyle}>
                            Values in USD Billions</Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography align='left' gutterBottom className={classes.linkTextStyle} style={{marginLeft:"12px"}}>
                              empower yourself by crafting your own valuations  at:</Typography>  
                          </Grid>
                        </Grid>

                        <Box style={{height:"0px"}}/>
                        <TableIncomeStatement 
                            combinedFinancialData = {combinedFinancialData}
                            assumptions = {assumptions}
                            isCheckedShowPreviousYears = {isCheckedShowPreviousYears}
                            isCheckedDescOrder = {isCheckedDescOrder}
                            isEstimateFcffOnly = {isEstimateFcffOnly}
                        />
                        <Box style={{height:"5px"}}/>
                        <TableCashFlow 
                          combinedFinancialData = {combinedFinancialData}
                          assumptions = {assumptions}
                          isCheckedShowPreviousYears = {isCheckedShowPreviousYears}
                          isCheckedDescOrder = {isCheckedDescOrder}
                          isEstimateFcffOnly = {isEstimateFcffOnly}
                        />
                        <Box style={{height:"10px"}}/>
                    </Paper>

                  </Grid>
                </Grid>

                {/* right side grid */}
                <Grid item xs={12} sm={4} style={{marginRigth:"5px"}} >

                  <Grid item container alignItems="center" justifyContent="flex-end">  
                    <Typography align="left" className={classes.linkTextStyle} style={{marginRight:"145px"}}>
                      find more valuations like this one at:</Typography>
                  </Grid>
                  { isEstimateFcffOnly ? <>
                    <Box style={{height:"10px"}}/>
                    <Box> 
                      <EstimatedFcffText />  
                    </Box>
                  </> : null }
                  <Box style={{height:"5px"}}/> 
                  <TableBusinessAssumptions assumptions = {assumptions} isEstimateFcffOnly={isEstimateFcffOnly}/>
                    
                    <Box style={{height:"5px"}}/>
                    { isUserCostOfCapital? 
                      <>
                        <EstimatedCostOfCapitalText />
                        <TableUserCostOfCapital assumptions = {assumptions} />
                      </>
                      : 
                      <TableCostOfCapitalAssumptions assumptions = {assumptions} calculatedCostOfCapital = {calculatedCostOfCapital} />
                    }
                    <Paper >
                      <TableValuation 
                        valuation = {valuation} 
                        combinedFinancialData = {combinedFinancialData}
                        historicalFinancialData = {historicalFinancialData} 
                        calculatedCostOfCapital = {calculatedCostOfCapital}
                        assumptions = {assumptions}
                        companyData={companyData}
                        valuationDateInfo={valuationDateInfo}
                      />
                    </Paper> 

                    <Box style={{height:"5px"}}/> 
                    <Paper elevation={0} style={{width:"380px", height:"160px"}}>
                      <ChartFreeCashFlow
                        combinedFinancialData = {combinedFinancialData}
                        assumptions = {assumptions}
                        isCheckedShowPreviousYears = {isCheckedShowPreviousYears}
                        isCheckedDescOrder = {isCheckedDescOrder}
                      /> 
                    </Paper>

                  <Box style={{height:"10px"}}/> 
                </Grid>

                <Box sx={{height:"5px"}}/>
                  <Box sx={{width:"92%"}}>
                    <DisclaimerText/>
                  </Box>
                <Box sx={{height:"5px"}}/> 

              </Grid>  
              <Grid item sm={1}>

              </Grid>

            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions >
        
          <Grid container direction="row" xs={6} style={{textAlign:'center'}}>
            <Button disableRipple className={classes.buttonStyle} onClick={onClose} startIcon={<CancelIcon />}>Cancel</Button>
            <Button disableRipple className={classes.buttonStyle} onClick={downloadReport} startIcon={<PictureAsPdfIcon />}>Download as PDF</Button>
            <Box style={{height: "20px"}}/>
          </Grid> 
          
        </DialogActions>
      </Dialog>
    </Box>
    </>: null}
  </>
  )
}