import React, { useState, useContext, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import ReactGA from 'react-ga4';
import { Container, Grid, Paper, Box, Stepper, Step, StepLabel, Typography, Radio, RadioGroup, FormControl, FormLabel, FormControlLabel, TextField, Button, Avatar, Grow, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import CloudUploadIcon  from '@mui/icons-material/CloudUpload';

import valuationsWebApi from '../services/valuationsWebApi';
import { LoginContext } from '../helpers/Context';
import useAxios from '../hooks/useAxios.js';
import useForm from '../hooks/useForm';
import useFetch from '../hooks/useFetch';

import CountryAutocomplete from '../components/CountryAutocomplete.jsx';
import DialogModal from '../components/modals/DialogModal';
import Footer from '../components/Footer.jsx';

const useStyles = makeStyles((mainTheme) => ({
  gridContainerStyle:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '80vh', 
    [mainTheme.breakpoints.down('lg')]: {
      minHeight:"85vh",
    },
    marginTop: "64px",
    padding:"0px",
  },
  paperStyle: {
    // display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 350,
    // width:"100%",
    height: 470,
    margin: '10px',
    // backgroundColor:mainTheme.palette.secondary.main,  
    backgroundColor:mainTheme.palette.secondary.main,  
    padding: mainTheme.spacing(2),
  },
  customLabelStyle: {
    fontSize: "12px"
  },
  photoStyle:{
    height: '90px',
    width: "90px",
    textAlign: "center",
    display: "block",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    marginTop: "10px",
    marginBottom:"10px",
  }, 
  iconBox:{
    width:'100%',
    textAlign: 'center',
    AlignItems: 'center',
  },
  iconStyle:{
    color:mainTheme.palette.primary.main,
  },
  textStyle:{
    // color:mainTheme.palette.primary.main,
    color:"white"
  },
  welcomeMessageStyle:{
    color:mainTheme.palette.primary.main
  },
  userPhotoStyle:{
    height: mainTheme.userPhotoStyle.height,
    width: mainTheme.userPhotoStyle.width,
    alignItems: mainTheme.userPhotoStyle.alignItems,
    marginTop: mainTheme.userPhotoStyle.marginTop,
    marginBottom:mainTheme.userPhotoStyle.marginBottom,
    marginLeft:mainTheme.userPhotoStyle.marginLeft,
    marginRigth:mainTheme.userPhotoStyle.marginRight,
    display: mainTheme.userPhotoStyle.display,
    textAlign: mainTheme.userPhotoStyle.textAlign,
    justifyContent: mainTheme.userPhotoStyle.justifyContent,
  },
  buttonStyle:{
    color: "white",
    // color:mainTheme.palette.primary.main,
    textTransform:"none",
    fontSize: 12,
    margin: "3px",
    backgroundColor:mainTheme.palette.primary.main,
    "&:hover": {
      color:mainTheme.palette.secondary.main,
      backgroundColor:mainTheme.palette.primary.main,
    },
    "&:disabled": {
      color:"gray",
      backgroundColor:mainTheme.palette.primary.main,
    },
  },
  alreadyRegisteredLinkStyle:{
    marginTop:"5px",
    marginLeft:"10px",
    fontSize:12,
    color:"blue"
  },
  loginLinkStyle:{
    marginTop:"5px",
    marginLeft:"5px",
    fontSize:12,
    color:"blue"
  }
  }));

const RegisterStepper = () => {
  const classes = useStyles();
  const inputRef = useRef(null);
  const { product } = useParams();
  const storedValueLS = localStorage.getItem('isUpgradeComplete');
  const isUpgradeComplete = (storedValueLS !== null) ? JSON.parse(storedValueLS) : true;
  const stripeCustomerId = localStorage.getItem('customer');
  const storedValue = localStorage.getItem('isRegisterComplete');
  const isRegisterComplete = (storedValue !== null) ? JSON.parse(storedValue) : true;
  const storedUserData = localStorage.getItem('registerData') ? JSON.parse(localStorage.getItem('registerData')): "";
  // const previousLocation = history.action === 'PUSH' ? history.location.pathname : null;
  const productCode= product? product : "1"
  const { userData, setUserData, usersImagesList, setUsersImagesList, setIsValuationSample, isStripeCheckout, setIsStripeCheckout }= useContext(LoginContext);
  const [ steps, setSteps] = useState(product==="0"? ['Your Account', 'Personal Data (optional)', 'Profile Picture (optional)','Code sent by email'] :['Your Account', 'Personal Data (optional)', 'Profile Picture (optional)','Payment Info'])
  const [activeStep, setActiveStep] = useState(isStripeCheckout ? 2: 0);
  const [ userImageBase64, setUserImageBase64] = useState(null);
  const [ userImageFile, setUserImageFile] = useState(null);
  let userRegistrationData={id:"", password:"", product:"", firstName:"", lastName:"", phone:"", birthday:null, occupation:"", company:"",email:"", country:"", city:"", description:"", website:"", twitter:"", linkedin:"", facebook:"", instagram:"", youtube:"", tiktok:"", photo:null, banner:null, stripeCustomerId:"", stripeSubscriptionId:""}
  const { handleChange, isValidName, isValidPhone, noBlanks, isValidUser, registerData, setRegisterData, formErrors, setFormErrors, isDialogOpen, handleDialogClose, dialogOptions} = useForm ();
  const { saveUser2 } = useFetch ({userRegistrationData})
  // const { axiosFetch: postCreateCheckoutSession } = useAxios();
  const { axiosFetch: postSession } = useAxios(); 
  const { axiosFetch: delUser } = useAxios();
  const { axiosFetch: putUser } = useAxios();
  const { axiosFetch: delStripeUser } = useAxios();
  const { axiosFetch: postCreateStripeConsumer } = useAxios(); 
  const { axiosFetch: postCreateStripeCheckout } = useAxios(); 
  const [ isEditField, setIsEditField] = useState(false)// eliminar?
  const countryObject = {id: registerData.country, name: registerData.countryName}
  const history = useHistory();
  // const [ radioBtnValue, setRadioBtnValue] = useState("1")

  function checkFormErrors(){
    if (formErrors.id !=="" || formErrors.password !=="" ){
      return true
    }
    return false
  }
  
  // const handleLogin=(data)=>{ 
  //   const dataToProcess = {id: data.id, password: data.password }
  //   const { id, password }= dataToProcess
  //    // data eh apenas o parametro...mudar o nome...
  //   // alert(id + " " + password )
  //   createSession(dataToProcess)
  // }

  const handleCancel=()=>{
    setRegisterData({ id:"", password:"", confirmPassword:"", product:"",firstName:"",lastName:"", country:"", countryName:"", phone:"", birthday:null, occupation:"", company:"",email:"", city:"", description:"", website:"", twitter:"", linkedin:"", facebook:"", instagram:"", youtube:"", tiktok:"", photo:"", banner:"",stripeCustomerId:"", stripeSubscriptionId:""});
    setUserImageFile(null);
    setUserImageBase64(null);
    history.goBack()
  }

  const addUsersImagesList=(userImageBase64, id)=>{
    if (userImageBase64 && id){
      // const cleanedBase64 = removeBase64Prefix(userImageBase64);
      const newObject = { userId: id, image: userImageBase64 };
      setUsersImagesList([...usersImagesList, newObject]);
    }
  }

  const removeBase64Prefix=(base64String)=> {
    const prefix = "data:image/jpeg;base64,";
    if (base64String){
      if (base64String.startsWith(prefix)) {
        return base64String.substring(prefix.length);
      } else {
        // If the prefix is not found, return the string as is
        return base64String;
    } 
    }
    return ""
  }

  const handlePicture = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64Image = event.target.result;
        setUserImageFile(file);
        setUserImageBase64(base64Image);
        setIsEditField(true);
      };
      reader.readAsDataURL(file);
    }
  };

  // Graba el archivo de imagen en el servidor, a traves de la API
  const saveUploadedFile = async (file, fileName) => {
    const formData = new FormData();
    formData.append('name',fileName)
    formData.append('image', file);
    try {
      const response = await valuationsWebApi.post('/user-image', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const imageUrl = response.data.imageUrl; 
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  function checkRequiredFields(){
    if (registerData.id !=="" && registerData.password !=="" && registerData.confirmPassword !==""){
      return true
    }
    return false
  }

  const validatePasswords = () => {
    if (registerData.password !=="" && registerData.confirmPassword !=="" && registerData.password !== registerData.confirmPassword) {
      setFormErrors (prevState => ({...prevState, password:'Passwords do not match', confirmPassword:"Passwords do not match"} ))
    } else {
      setFormErrors (prevState => ({...prevState, password:"", confirmPassword:""}))
    }
  };

  function formatDateObjectToString(date) {
    const formatter = new Intl.DateTimeFormat('en', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false, timeZone:'UTC'});
    const parts = formatter.formatToParts(date);
    const year = parts.find(part => part.type === 'year').value;
    const month = parts.find(part => part.type === 'month').value;
    const day = parts.find(part => part.type === 'day').value;
    return `${year}-${month}-${day}`;
  }
  
  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
    if (activeStep===2 && product!=="0"){
      handleStripe();
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  function handleIdBlur(e){
    handleChange (e,setRegisterData,[isValidUser]);
    // alert(formErrors.id)
    // if (formErrors.id){
    //   inputRef.current.focus()
    // }
  }

  const sessionSuccessCb= async (apiData)=>{
    alert("session success")
    console.log("session success")
    setIsValuationSample(false);
    await setUserData({userId:apiData.id, userPassword: apiData.password, userProduct: apiData.product, userFirstName:apiData.firstName, userLastName:apiData.lastName, userCountry:apiData.country, userCountryName:apiData.countryName,userBirthday:apiData.birthday, userDescription:apiData.description})
  }

  // const sessionErrorCb=(error)=>{
  //   // alert("sessionErrorCb: " + error)
  //   console.log("sessionErrorCb: " + error)
  // }

  // const createSession=(dataToProcess)=>{
  //   const { id, password } = dataToProcess;
  //   // alert("createSession: id:" + id + " password: " + password)
  //   postSession({ axiosInstance: valuationsWebApi, method: 'POST', url: '/session', data: dataToProcess, requestConfig: { headers: {'Authorization': id,},}},sessionSuccessCb, sessionErrorCb);
  // }

  const handleFinish = () => {
    if (! checkFormErrors() && checkRequiredFields()) {
      // <CircularProgress />
      // alert("Entrou em handleFinish");
      userRegistrationData.id = registerData.id;
      userRegistrationData.password = registerData.password;
      // alert("registerData.product: " + registerData.product)
      userRegistrationData.product =registerData.product;
      userRegistrationData.firstName = registerData.firstName;
      userRegistrationData.lastName = registerData.lastName;
      userRegistrationData.phone = registerData.phone;
      // userRegistrationData.birthday = formatDateObjectToString(registerData.birthday);
      userRegistrationData.birthday=registerData.birthday? registerData.birthday.format("YYYY/MM/DD"): null;
      userRegistrationData.email = registerData.id;
      userRegistrationData.country = registerData.country
      localStorage.setItem('registerData', JSON.stringify(registerData));
      // localStorage.setItem('isStripeCheckoutIncomplete', JSON.stringify(true));
      // localStorage.setItem("stripeCheckoutIncompleteUserId",registerData.id);
      localStorage.setItem('isRegisterComplete', JSON.stringify(false));
      // alert("save user in register under handleFinish")
      saveUser2({userRegistrationData});
      saveUploadedFile(userImageFile, registerData.id );      // Graba la nueva foto en el servidor
      addUsersImagesList(userImageBase64, registerData.id ); // actualiza el state para mostrar la nueva foto
      // setUserPicture(null);
      setUserImageFile(null);
      setUserImageBase64(null);
      setIsValuationSample(false);
      // createSession({id:registerData.id, password:registerData.password})
      // handleLogin(userRegistrationData)
      // setRegisterData({ id:"",  password:"", confirmPassword:"", product:"",firstName:"",lastName:"", country:"", countryId:"", phone:"", birthday:null, occupation:"", company:"",email:"", city:"", description:"", website:"", twitter:"", linkedin:"", facebook:"", instagram:"", youtube:"", tiktok:"", photo:null, banner:null})
      // setActiveStep(0);
    } else {
      console.log("Not saved in database")
    }
  };

  const handleRadioValue=(e)=>{
    // setRadioBtnValue(e.target.value);
    // alert("handleRadioValue" + e.target.value)
    if (e.target.value==="0"){
      setSteps(['Your Account', 'Personal Data (optional)', 'Profile Picture (optional)','Code sent by email'])
    } else {
      setSteps(['Your Account', 'Personal Data (optional)', 'Profile Picture (optional)','Payment Info'])
    }
    setRegisterData (prevState => ( {...prevState, product: e.target.value}));
  }

  const createStripeCheckoutSuccessCb=(apiData)=>{
    // localStorage.setItem('isRegisteringPlan', JSON.stringify(true));
    handleFinish();
    const url = apiData.url;
    window.location = url  //Llama la ventana de checkout de Stripe
  }

  const createStripeCheckoutErrorCb=(error)=>{
    // alert("Error in createStripeCheckoutErrorCb: "+ error)
  }

  const createStripeCustomerSuccessCb=(apiData)=>{
    localStorage.setItem('userId', registerData.id);
    localStorage.setItem('customer', apiData.customer.id);
    localStorage.setItem('newProduct', registerData.product);
    const customer = apiData.customer.id;
    const customer_email = registerData.id ;
    // alert("createStripeCheckout, customer_email: "+ customer_email + "customer_id: " + apiData.customer.id)
    //* refactorar: poner como variable de ambiente
    // const priceId =  registerData.product==="1"? "price_1OdhEWLCP51AssL9vkCYk7iJ":"price_1OdhEyLCP51AssL9qZemHRcz"; 
    const priceId =  registerData.product==="1"? "price_1PIf8VLCP51AssL97X5LY1Ha":"price_1PIf9BLCP51AssL9CZmTQ7Aw"; 

    const dataToProcess={customer,customer_email,priceId }
    postCreateStripeCheckout({ axiosInstance: valuationsWebApi, method: 'POST', url: '/create-stripe-checkout', data: dataToProcess, requestConfig: { headers: {'Authorization':registerData.id ,},}},createStripeCheckoutSuccessCb, createStripeCheckoutErrorCb);
  }

  const createStripeCustomerErrorCb=()=>{

  }

  const handleStripe = ()=>{
    const dataToProcess = { email: registerData.id }
    postCreateStripeConsumer({ axiosInstance: valuationsWebApi, method: 'POST', url: '/create-stripe-customer', data: dataToProcess, requestConfig: { headers: {'Authorization':registerData.id,},}},createStripeCustomerSuccessCb, createStripeCustomerErrorCb);
  }
  
  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Grid container spacing={1}>
            
            <Grid item xs={12}>
            {/* , backgroundColor:"white" */}
              <Box style={{display:'flex', justifyContent:"flex-end"}}> 
      
                <FormControl >
                <Box style={{height:"5px"}}/>
                  <FormLabel id="demo-controlled-radio-buttons-group" sx={{fontSize:"12px"}} >Choose your Plan:</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={registerData.product}
                    // value={radioBtnValue}
                    onChange={handleRadioValue}
                    // onChange={ (e) => {
                    //   handleChange (e,setRegisterData,[]);
                    // }}
                  >
                    <FormControlLabel value="0" control={<Radio size="small" disableRipple />} label="Free Plan" />
                    <FormControlLabel value="1" control={<Radio size="small" disableRipple />} label="Monthly Plan" />
                    <FormControlLabel value="2" control={<Radio size="small" disableRipple />} label="Annual Plan" />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <TextField 
                variant ="filled" margin="dense" size="small" fullWidth  
                // variant ="outlined" margin="dense" size="small" fullWidth
                label="Your E-mail *"
                name="id"
                value={registerData.id}
                autoComplete="email"
                inputRef={inputRef}
                onChange={ (e) => {
                  handleChange (e,setRegisterData,[]);
                }}
                onBlur={(e) => handleIdBlur(e)}
                inputProps={{style: {fontSize: 14}}} 
                error={formErrors.id}
              />
              {formErrors.id ? <div className="error-helper-text">{formErrors.id}</div> : null}
            </Grid>

            <Grid item xs={6}>
              <TextField 
                variant ="filled" margin="dense" size="small" fullWidth  
                // variant ="outlined" margin="dense" size="small" fullWidth
                label="Create Your Password *"
                name="password"
                type="password"
                value={registerData.password}
                autoComplete="new-password"
                onChange={ (e) => {
                  handleChange (e,setRegisterData,[noBlanks]);
                }}
                onBlur={validatePasswords}
                inputProps={{style: {fontSize: 14}}} 
                error={formErrors.password}
              />
              {formErrors.password ? <div className="error-helper-text">{formErrors.password}</div> : null}
            </Grid>

            <Grid item xs={6}>
              <TextField 
                variant ="filled" margin="dense" size="small" fullWidth  
                // variant ="outlined" margin="dense" size="small" fullWidth
                label="Confirm Your Password *"
                name="confirmPassword"
                type="password"
                value={registerData.confirmPassword}
                autoComplete="confirm-password"
                onChange={ (e) => {
                  handleChange (e,setRegisterData,[noBlanks]);
                }}
                onBlur={validatePasswords}
                inputProps={{style: {fontSize: 14}}} 
                error={formErrors.confirmPassword}
              />
              {formErrors.confirmPassword ? <div className="error-helper-text">{formErrors.confirmPassword}</div> : null}
            </Grid>
            <Typography className={classes.alreadyRegisteredLinkStyle}>Already registered ?</Typography>
            <Typography className={classes.loginLinkStyle}><a href="/login">Login up here !</a></Typography>
          </Grid>
        );
      case 1:
        return (
          <Grid container spacing={2}>
            <Grid item xs={6} sm={6}>
              <TextField autoFocus 
                variant ="filled" margin="dense" size="small" fullWidth  

                // variant ="outlined" margin="dense" size="small" fullWidth
                label="First Name"
                name="firstName"
                value={registerData.firstName}
                autoComplete="first-name"
                onChange={ (e) => {
                  handleChange (e,setRegisterData,[isValidName]);
                }}
                // onBlur={(e) => {
                //   handleChange (e,setRegisterData,[isValidName]);
                // }}
                inputProps={{style: {fontSize: 14}}} 
                error={formErrors.firstName}
              />
              {formErrors.firstName ? <div className="error-helper-text">{formErrors.firstName}</div> : null}
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField 
                variant ="filled" margin="dense" size="small" fullWidth  

                // variant ="outlined" margin="dense" size="small" fullWidth
                label="Last Name"
                name="lastName"
                value={registerData.lastName}
                autoComplete="last-name"
                onChange={ (e) => {
                  handleChange (e,setRegisterData,[isValidName]);
                }}
                inputProps={{style: {fontSize: 14}}} 
                error={formErrors.lastName}
              />
              {formErrors.lastName ? <div className="error-helper-text">{formErrors.lastName}</div> : null}
            </Grid>
            <Grid item xs={12} sm={12} >
              <Box style={{backgroundColor:"white"}}>
              <CountryAutocomplete 
                countryObject={countryObject}
                registerData={registerData}
                setRegisterData={setRegisterData}
                isEditField={isEditField}
                setIsEditField={setIsEditField}
                variant="filled"
              />
              </Box>
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField 
                variant ="filled" margin="dense" size="small" fullWidth  

                // variant ="outlined" margin="dense" size="small" fullWidth
                label="Phone"
                name="phone"
                value={registerData.phone}
                autoComplete="phone"
                onChange={ (e) => {
                  handleChange (e,setRegisterData,[isValidPhone]);
                }}
                inputProps={{style: {fontSize: 14}}} 
                error={formErrors.phone}
              />
              {formErrors.phone ? <div className="error-helper-text">{formErrors.phone}</div> : null}
            </Grid>
            <Grid item xs={6} sm={6} >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker disableFuture format="YYYY/MM/DD" 
                  label = "Birthday"
                  name = "birthday"
                  value = {registerData.birthday}
                  onChange={ (date) => {
                    setRegisterData (prevState => ({...prevState, birthday:date}))
                  }}
                  // slotProps = {{ textField: { variant: 'outlined' , size: "small", margin:"dense"}}}/>
                  slotProps = {{ textField: { variant :"filled", margin:"dense", size:"small"}}}
                />

              </LocalizationProvider>
            </Grid>
          </Grid>
        );
      case 2:
        return (
          <Grid container spacing={2} >
            <Grid item container xs={12} style={{paddingTop:"10px"}}>
              <Grid container display="flex" alignItems="center" justifyContent="center">
                {/* <Avatar alt="Uploaded Image" src={userPicture && URL.createObjectURL(userPicture)}  className={classes.userPhotoStyle} /> */}
                {/* <Avatar alt="Uploaded Image" src={userPicture ? `data:image/jpeg;base64,${userPicture}` : ''}  className={classes.userPhotoStyle} /> */}
                <Avatar alt="Uploaded Image" src={userImageBase64 ? userImageBase64 : ''}  className={classes.userPhotoStyle} />
              </Grid>                                    
              <Typography variant="caption" align="justify" paragraph className={classes.welcomeMessageStyle} style={{marginBottom:"5px"}} >Let your online connections see the face behind the username and make a lasting impression !! A picture is worth a thousand words, and having your photo on your profile can help you connect and engage with others more authentically.</Typography>
              <Button
                variant="contained"
                component="label"
                className={classes.buttonStyle}
                disableRipple
                startIcon={<CloudUploadIcon />}
              >
                Upload Photo
                <input
                  type="file"
                  accept="image/*"
                  hidden
                  autoComplete="off"
                  // onChange={(e) => setUserPicture(e.target.files[0])}
                  onChange={(e)=> handlePicture(e)}
                />
              </Button>
            </Grid>
          </Grid>
        );
        // case 3:
        //   return (
        //     <Grid container spacing={2} >
        //       <Typography>You are being redirected for a stripe management account</Typography>
            
        //         { handleStripe()}
        //     </Grid>
        //   );
      default:
        return null;
    }
  };


  const delStripeUserSuccessCb=()=>{
    localStorage.removeItem("registerData")
    localStorage.removeItem("isRegisterComplete")
    localStorage.removeItem("customer")
  }

  const delStripeUserErrorCb=()=>{
    localStorage.removeItem("registerData")
    localStorage.removeItem("isRegisterComplete")
    localStorage.removeItem("customer")

  }

  const delUserSuccessCb=()=>{
    // alert("delete in db successfull")
    // localStorage.removeItem("isStripeCheckoutIncomplete")
    // localStorage.removeItem("stripeCheckoutIncompleteUserId")
    // alert('deletando usuario stripe: '+ stripeCustomerId )
    if(stripeCustomerId){
      delStripeUser({ axiosInstance: valuationsWebApi, method: 'DELETE', url: `/stripe-customer/${stripeCustomerId}`, requestConfig: { headers: {'Authorization': storedUserData.id,},}},delStripeUserSuccessCb, delStripeUserErrorCb);
    }
  }

  const delUserErrorCb=()=>{
    // alert("delete in db unsuccessfull")
    // localStorage.removeItem("isStripeCheckoutIncomplete")
    // localStorage.removeItem("stripeCheckoutIncompleteUserId")
    localStorage.removeItem("registerData")
    localStorage.removeItem("isRegisterComplete")
  }

  // const putUserSuccessCb=()=>{
  //   localStorage.removeItem("isUpgradeComplete")
  // }

  // const putUserErrorCb=()=>{
  //   localStorage.removeItem("isUpgradeComplete")
  // }

  useEffect(() => { 
    // alert("renderizou no useEffect")
    // const storedValue = localStorage.getItem('isRegisterComplete');
    // const isRegisterComplete = (storedValue !== null) ? JSON.parse(storedValue) : true;
    // const storedUserData = localStorage.getItem('registerData') ? JSON.parse(localStorage.getItem('registerData')): "";
    ReactGA.send({ hitType: "pageview", page: "/register", title: "Register" });
    // alert("useEffect em register, isRegisterComplete: "+ isRegisterComplete)
    if (! isRegisterComplete && storedUserData!=""){
      // alert("deleta o usuario con register incompleto:"+ storedUserData.id )
      delUser({ axiosInstance: valuationsWebApi, method: 'DELETE', url: `/users/${storedUserData.id}`, requestConfig: { headers: {'Authorization': storedUserData.id,},}},delUserSuccessCb, delUserErrorCb);
      
    } 
    // if (! isUpgradeComplete && storedUserData!=""){
    //   const dataToProcess={id: storedUserData.id, product:"0"}
    //   putUser({ axiosInstance: valuationsWebApi, method: 'PUT', url: '/users', data: dataToProcess, requestConfig: { headers: {'Authorization':storedUserData.id ,},}}, putUserSuccessCb, putUserErrorCb);
      
    // }
    setRegisterData (prevState => ({...prevState, product:productCode}))
  }, []);

  return (
    <>
    {/* { alert ("renderizou o componente")} */}
      <Grid container alignItems="center" justifyContent="center" spacing={1}  className={classes.gridContainerStyle}  >
      {/* <Container maxWidth="xs">   */}
      <Grid item>
      <Grow in timeout = {1000}>
        <Paper elevation={12} className={classes.paperStyle} style={{paddingBottom: "10px"}} >
          <Box style={{display:"flex", flexDirection:"column", height:"100%", justifyContent:"space-between"}}>
            <Box>
            <Typography align='center' gutterBottom  className={classes.textStyle} >Sign Up & Register</Typography>  
            <Box className={classes.iconBox} >
              <AppRegistrationIcon className={classes.iconStyle} style={{ fontSize: 40 }}/>
            </Box>
              <Stepper activeStep={activeStep} alternativeLabel style={{height:"90px"}} > 
                {steps.map((label) => (
                  <Step key={label} >
                    <StepLabel classes={{label: classes.customLabelStyle}} >{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <Box style={{height:"5px"}}/>

              {activeStep === steps.length ? 
              ( // si es la ultima pantalla...
                // <Grid container spacing={1}>
                //   <Grid item container display="flex" alignItems="center" justifyContent="center" xs={12} style={{marginBottom:"8px"}}>
                //     <Typography variant="body2" align="center" gutterBottom className={classes.textStyle} ><b>Registration Complete!</b></Typography>
                //     <Typography variant="caption" align="justify" paragraph className={classes.welcomeMessageStyle} >
                //       Welcome!!  By joining our platform, you've gained access to a thriving community where you can connect with like-minded individuals, share your passions, and engage in meaningful conversations. In addition, you'll also have the opportunity to enhance your investment skills by exploring valuable financial information, empowering you to make informed decisions and excel as an investor.
                //     </Typography>
                //   </Grid>
                //   <Grid item xs={12} display="flex" alignItems="center" justifyContent="center" style={{paddingTop:"0px"}}>
                //     <Button autofocus variant="contained" className={classes.buttonStyle} disableRipple disabled={checkFormErrors() || ! checkRequiredFields()} color="primary" onClick={handleFinish}>
                //       Go to main page
                //     </Button>
                //   </Grid>
                // </Grid>
                null
              ) : (
                <>
                  <form>
                    {renderStepContent(activeStep)}
                  </form>  
                </>
              )}
            </Box>
            {activeStep !== steps.length ? ( 
              <Box>
                <Grid container item direction="row" alignItems="flex-end" justifyContent="flex-end"  >
                  { activeStep > 0 ?
                    <Button disableRipple className={classes.buttonStyle} disabled={activeStep === 0} onClick={handleBack} >
                      Back
                    </Button>
                  : 
                    <Button disableRipple className={classes.buttonStyle} onClick={handleCancel} >
                      Cancel
                    </Button>
                  }
                  <Button disableRipple className={classes.buttonStyle} disabled={checkFormErrors() || ! checkRequiredFields()} onClick={handleNext}>
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                </Grid> 
              </Box>
            ):
            <Box>
              <Grid container item direction="row" alignItems="flex-end" justifyContent="flex-end"  >
                <Button disableRipple className={classes.buttonStyle} disabled={activeStep === 0} onClick={handleBack} >
                  Back
                </Button>
                { product==="0"? 
                  <Button disableRipple className={classes.buttonStyle} disabled={checkFormErrors() || ! checkRequiredFields()} onClick={handleFinish}>
                    Confirm
                  </Button>
                : 
                  <Button disableRipple className={classes.buttonStyle} disabled={checkFormErrors() || ! checkRequiredFields()} onClick={handleFinish}>
                    Proceed to Pay
                  </Button>
                
                }

              </Grid> 
            </Box>
          }
        </Box>
      </Paper>
      </Grow>
      </Grid>

      {/* </Container> */}

      </Grid>
      <Footer />
      <DialogModal open={isDialogOpen} onClose={handleDialogClose} severity={dialogOptions.severity} title={dialogOptions.title} buttons={dialogOptions.buttons} action={dialogOptions.action}>
        {dialogOptions.message}
      </DialogModal> 
    </>
  );
};

export default RegisterStepper;
