import React, {useState, useEffect, useContext} from 'react';

import { Paper, Grid, Box, Snackbar,SnackbarContent, IconButton, Button, Typography, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
// import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
// import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import CloseIcon from '@mui/icons-material/Close';

import { LoginContext } from '../../../helpers/Context.js';
// import { useQuery } from '@tanstack/react-query'
// import TablePaginationActions from '../../components/TablePaginationActions';
import ShowUserById from '../../../components/ShowUserById';
import valuationsWebApi from '../../../services/valuationsWebApi';

const useStyles = makeStyles((mainTheme) => ({
  paperStyle:{
    width: "98%",
    marginLeft:"5px",
    marginRight:"5px",
    paddingRight:"5px",
    padding: "5px",
    color: mainTheme.palette.primary.main,
    backgroundColor: "whitesmoke",
  },
  sectionTitleStyle:{
    marginLeft:"3px",
    marginTop:"3px",
    fontSize: mainTheme.sectionTitle.fontSize,
    color: mainTheme.sectionTitle.color
  },
  textStyle:{
    marginLeft:"3px",
    marginTop:"3px",
    fontSize:"11px", 
    color:mainTheme.palette.tertiary.main,
  },
  buttonStyle: {
    width:"15px",
    height:"15px",
    fontSize:"9px",
    [mainTheme.breakpoints.down('sm')]: {
      fontSize: "9px"
    },
    color:mainTheme.palette.secondary.main,
    backgroundColor:"white",
    "&:hover": {
      color:"#CD7F32",
      backgroundColor: "whitesmoke"//"#F49506ed",
      // borderColor:"#F49506ed"
    },
  },
  commentContainer:{
    flex: 1, 
    overflowY: "auto"
  },
  buttonsContainer:{
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    // padding: 10px;
  },
  likeStyle:{
    fontSize:"9px", 
    color:mainTheme.palette.tertiary.main,
    marginBottom:"4px"
  },
  greenSnackbarContent: {
    backgroundColor: "#228B22"
  },
})); 

export default function ShowComments ({ valuationId, setInteractions }) {
  const classes = useStyles();
  const { userData, usersImagesList, isNewComment, setIsNewComment } = useContext (LoginContext);
  const { userId } = userData;
  const [ valuationComments, setValuationComments] = useState([]);
  // const filteredArray = valuationComments.filter(item => item.profileId === userId);// Para likes....
  const [ isSnackbarOpen, setIsSnackbarOpen] = useState(false);

  const deleteComment = (id)=>{
    valuationsWebApi.delete(`/comments/${id}`)
    .then(response => {
      setIsNewComment(true);
      setIsSnackbarOpen(true);
      setValuationComments(valuationComments.filter(comment => comment.id !==id))
      setInteractions(prevInteractions => ({
        ...prevInteractions,
        comments: prevInteractions.comments -1
      }));
    })
    .catch(error => {
      console.error(`Failed to delete comment`, error);
    })
  }

  const handleSnackbarClose= ()=>{
    setIsSnackbarOpen(false);
  }

  // const action = (
  //   <>
  //   <IconButton
  //     size="small"
  //     aria-label="close"
  //     color="inherit"
  //     onClick={handleSnackbarClose}
  //   >
  //     <CloseIcon fontSize="small" />
  //   </IconButton>
  //   </>
  // ); 

  // const fetchComments = async ()=> {
  //   const res = await valuationsWebApi.get(`/comments?valuationId=${valuationId}`)
  //   const comments = res.data
  //   return comments
  // }

  // const { data: valuationComments, isLoading: isLoadingComments, isError: isErrorComments } = useQuery(["comments"],fetchComments);
  
  useEffect(() => {
    valuationsWebApi.get(`/comments?valuationId=${valuationId}`)
    .then(res => {
      if (res.status !== 404){
        setValuationComments(res.data);
      } else {
        setValuationComments([])
      }
    })
    .catch(error => {
      setValuationComments([])
      console.error(`Failed to fetch comments`, error);
    })
  }, [isNewComment]);

  return (
    <>
    <Paper elevation={12} className={classes.paperStyle}>
      <Typography className={classes.sectionTitleStyle}>Comments</Typography>
      {valuationComments !== undefined && valuationComments.length > 0 ? (
        valuationComments.map((currComment, index) => (
          <React.Fragment key={index}>
            <Paper elevation={3} className={classes.commentContainer} >
              <Grid container>
                <Grid item xs={4} alignItems="center" justifyContent="center">
                  <Box sx={{ height: "5px" }} />
                    <ShowUserById 
                      userId={currComment.profileId} 
                      firstName={currComment.firstName} 
                      lastName={currComment.lastName} 
                      usersImages={usersImagesList} 
                      textPosition={"below"} 
                      size={"small"} 
                      isCreatedByText={false} 
                      isCountryFlag={false}
                    />  
                </Grid>
                <Grid item container direction="row" xs={8}>
                  <Grid item xs={12}>
                    <Typography align="justify" style={{ paddingRight: "3px", marginRight: "3px" }} className={classes.textStyle}>
                      {currComment.text}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}  className={classes.buttonsContainer}>
                  <Grid container alignItems="flex-end" justifyContent="flex-end">
                    <Grid item>
                      { userId !== currComment.profileId ? null
                        // <Tooltip title="Give a Like to this comment">
                        //   <Button 
                        //     variant="text" 
                        //     disabled={userId===""} 
                        //     disableRipple 
                        //     className={classes.buttonStyle}
                        //     // onClick={handleChangeLikeBtn(currComment.index)}
                        //     startIcon={ isLikedBtn? <ThumbUpAltIcon />: <ThumbUpOffAltIcon /> } 
                        //     >Like
                        //   </Button>
                        // </Tooltip>
                      : 
                      null
                      // <Typography className={classes.likeStyle} >8 Likes</Typography>
                      }
                    </Grid>

                    <Grid item>
                    { userId !== currComment.profileId ? 
                      <Tooltip title="Reply this comment">
                         {/* disabled={userId===""} */}
                        <Button variant="text"  disabled disableRipple className={classes.buttonStyle}>Reply</Button>
                      </Tooltip>
                    : 
                      <Tooltip title="Delete your comment">
                        <Button 
                          variant="text" 
                          disableRipple 
                          className={classes.buttonStyle}
                          onClick={() => deleteComment(currComment.id)}
                          >
                          Delete
                        </Button>
                      </Tooltip>
                    }
                    </Grid>
                  </Grid>

                  </Grid>
                </Grid>
              </Grid>
            </Paper>
            <Box style={{ height: "2px" }} />
          </React.Fragment>
        ))
      ) : (
        <Typography align="left" className={classes.textStyle}>No comments yet</Typography>
      )}
    </Paper>
    <Snackbar
      open={isSnackbarOpen}
      autoHideDuration={3000} 
      onClose={handleSnackbarClose}
      >
      <SnackbarContent
        className={classes.greenSnackbarContent}
        message={"Your comment was deleted"}
      />
    </Snackbar>
  </>
  );
}