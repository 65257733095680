import React, {useState, useEffect, useRef} from 'react';

import { Paper, Grid, Tooltip, Typography, TextField, InputAdornment,Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Popover } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import useValuationForm from '../../../hooks/useValuationForm';

const useStyles = makeStyles( (mainTheme) => ({
  table: {
    // minWidth: 300,
    // maxHeight: 900
  },
  textFieldStyle:{
    // "& .MuiFilledInput-root":{
    //   fontSize:"8px",
    //   color: "red",
    //   backgroundColor:"yellow",
    //   height: "28px",
    // }
  },
  tableHeader:{
    backgroundColor: mainTheme.palette.secondary.main
  },
  tableHeaderDisabled:{
    backgroundColor:  mainTheme.palette.primary.main
  },
  tableTitle:{
    color: "white",
    fontSize: 12
  },
  tableTitleDisabled:{
    color: mainTheme.palette.secondary.main,
    fontSize: 12
  }
  }));

export default function FormBusinessAssumptions ({assumptions, setAssumptions, editMode, isBusinessAssumptions}){
  const classes = useStyles();  
  const { formErrors, handleChange, noBlanks, isValidTaxRate, isValidDiscretePeriod } = useValuationForm({ assumptions, setAssumptions })
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const revenueGrowthRef = useRef(null);
  const [ isDialogOpen, setIsDialogOpen ] = useState(false);
  const [ dialogOptions, setDialogOptions ] = useState({severity:"",title:"",message:"",buttons:{},action:""});

  const handleOpenPopover = (event) => {
    setPopoverAnchorEl(event.currentTarget);
    // Automatically close after 3 seconds
    setTimeout(() => {
      handleClosePopover();
    }, 3000);
  };

  const handleClosePopover = () => {
    setPopoverAnchorEl(null);
  };


  const handleDialogClose=()=>{
    setIsDialogOpen(false);
    setDialogOptions({severity:"",title:"",message:"",buttons:{},action:""});
  }
  
  const handleChangeDiscretePeriod=(value)=>{
    // alert("entrou em onChange, value:" + value)
    if (value <=0 || value==="" || value===null){
      setAssumptions((prevState) => ({ ...prevState, cashFlowDiscretePeriod: 1 }));
      setDialogOptions({
        severity: "error",
        title: "Invalid discrete period",
        message: "The discrete period must be at least 1. Please choose a value between 1 and 15 !",
        buttons: { button1: "Ok" }
      });
      setIsDialogOpen(true);
    } else {
      setAssumptions((prevState) => ({ ...prevState, cashFlowDiscretePeriod: value }));
    }
  }
  
  const handleDiscretePeriod=(value)=> {
    const maximumValue = 15
    const minimumValue = 1
    const recommendedValue = 5
    // alert("entrou em onBlur, value: "+ value)
    if (value < minimumValue || value > maximumValue){

      setDialogOptions({
        severity: "error",
        title: "Invalid discrete period",
        message: "Please choose a value between 1 and 15 !",
        buttons: { button1: "Ok" }
      });
      // "The discrete period must be at least 1. 
      setIsDialogOpen(true);
      setAssumptions((prevState) => ({ ...prevState, cashFlowDiscretePeriod: recommendedValue }));
    }
    setAssumptions((prevState) => ({ ...prevState, cashFlowDiscretePeriod: value }));
  }
  
  useEffect(() => {
    if (isBusinessAssumptions) {
      revenueGrowthRef.current.focus();
    }
  }, [isBusinessAssumptions]);

  return (
  <>
  <TableContainer component={Paper}>
    <Table className={classes.table} size="small" aria-label="stycky header">
      <TableHead className={editMode==="saved" || editMode==="published" ? classes.tableHeaderDisabled :classes.tableHeader}>
      {/* <TableHead className={classes.tableHeader}> */}

        <TableRow>
          <TableCell className={editMode==="saved" || editMode==="published" ? classes.tableTitleDisabled :classes.tableTitle} align="left">Business Assumptions</TableCell>
          {/* <TableCell className={classes.tableTitle} align="left">Business Assumptions</TableCell> */}

        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <Grid container>
            <Grid item xs={4} >
          
              <TextField label="Revenue Growth Rate" className={classes.textFieldStyle} size="small" type="number" variant="filled" fullWidth
                value={assumptions.revenueGrowth}
                onChange={(e) => {handleChange (e,[])}}
                name="revenueGrowth"
                disabled={editMode==="saved" || editMode==="published"}
                // InputProps={{ startAdornment: <InputAdornment position="start">%</InputAdornment>,}}
                InputProps={{
                  startAdornment: <InputAdornment position="start">%</InputAdornment>,
                  // onFocus: handleOpenPopover, // Open popover on focus
                }}
                inputRef={revenueGrowthRef}
              />

              {/* <Popover
                  open={Boolean(popoverAnchorEl)}
                  anchorEl={popoverAnchorEl}
                  onClose={handleClosePopover}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Typography>
                    Include your business assumptions here
                  </Typography>
                </Popover> */}

            </Grid>
            <Grid item xs={4} >
              <Tooltip title="Estimate Margin for next years">
                <TextField label="Margin Target" className={classes.textFieldStyle} size="small" type="number" variant="filled" fullWidth
                  value={assumptions.marginTarget}
                  onChange={(e) => {handleChange (e,[])}}
                  name="marginTarget"
                  disabled={editMode==="saved" || editMode==="published"}
                  InputProps={{ startAdornment: <InputAdornment position="start">%</InputAdornment>,}}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={4} >
              <TextField label="Expenses Growth Rate" className={classes.textFieldStyle} size="small" type="number" variant="filled" fullWidth
                value={assumptions.opexGrowth}
                onChange={(e) => {handleChange (e,[])}}
                name="opexGrowth"
                disabled={editMode==="saved" || editMode==="published"}
                InputProps={{
                  startAdornment: <InputAdornment position="start">%</InputAdornment>,
                }}
              />
            </Grid>
          </Grid>
        </TableRow>
        <TableRow>
          <Grid container>
            <Grid item xs={4} >
              <TextField label="Interest Growth Rate" size="small" type="number" variant="filled" fullWidth
                value={assumptions.interestGrowth}
                onChange={(e) => {handleChange (e,[])}}
                name="interestGrowth"
                disabled={editMode==="saved" || editMode==="published"}
                InputProps={{
                  startAdornment: <InputAdornment position="start">%</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={4} >
                <TextField label="Other Inc. Growth Rate" size="small" type="number" variant="filled" fullWidth
                  value={assumptions.otherGrowth}
                  onChange={(e) => {handleChange (e,[])}}
                  name="otherGrowth"
                  disabled={editMode==="saved" || editMode==="published"}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">%</InputAdornment>,
                  }}
                />
            </Grid>
            <Grid item xs={4} >
              <TextField label="Income Taxes Rate" size="small" type="number" variant="filled" fullWidth
                value={assumptions.taxRate}
                onChange={(e) => {handleChange (e,[isValidTaxRate])}}
                name="taxRate"
                disabled={editMode==="saved" || editMode==="published"}
                error={formErrors.taxRate}
                helperText={formErrors.taxRate}
                InputProps={{
                  startAdornment: <InputAdornment position="start">%</InputAdornment>,
                }}
              />
            </Grid>
          </Grid>
        </TableRow>
        <TableRow>
          <Grid container>
            <Grid item xs={4}>
              <TextField label="Capex Growth Rate" size="small" type="number" variant="filled" fullWidth
                value={assumptions.capexGrowth}
                onChange={(e) => {handleChange (e,[])}}
                name="capexGrowth"
                disabled={editMode==="saved" || editMode==="published"}
                InputProps={{
                  startAdornment: <InputAdornment position="start">%</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={4} >
              <Tooltip title="Net Working Capital Changes Growth Rate">
                <TextField label="NWCC Growth Rate" size="small" type="number" variant="filled" fullWidth
                  value={assumptions.nwcGrowth}
                  onChange={(e) => {handleChange (e,[])}}
                  name="nwcGrowth"
                  disabled={editMode==="saved" || editMode==="published"}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">%</InputAdornment>,
                  }}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={4} >
              <TextField label="Perpetual Growth Rate" size="small" type="number" variant="filled" fullWidth
                value={assumptions.perpetualGrowthRate}
                onChange={(e) => {handleChange (e,[])}}
                name="perpetualGrowthRate"
                disabled={editMode==="saved" || editMode==="published"}
                InputProps={{
                  startAdornment: <InputAdornment position="start">%</InputAdornment>,
                }}
              />
            </Grid>
          </Grid>
        </TableRow>
        <TableRow>
          <Grid container>
            <Grid item xs={4} >
              <Tooltip title="Free cash Flow Discrete Period (in Years)">
                <TextField label="FCFF Discrete Period (Years)" size="small" type="number" variant="filled" fullWidth
                  value={assumptions.cashFlowDiscretePeriod}
                  onChange={(e) => {handleChangeDiscretePeriod (e.target.value)}}
                  onBlur={(e) => {handleDiscretePeriod (e.target.value)}}
                  // onChange={(e) => {handleChange (e,[])}}
                  name="cashFlowDiscretePeriod"
                  disabled={editMode==="saved" || editMode==="published"}
                />
              </Tooltip>
            </Grid>
          </Grid>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
  </>
  )
}