import React from 'react';

import { Grid, Typography, Button, Avatar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import CloudUploadIcon  from '@mui/icons-material/CloudUpload';

const useStyles = makeStyles((mainTheme) => ({
  welcomeMessageStyle:{
    // color:mainTheme.palette.primary.main
    color:"black"
  },
  userPhotoStyle:{
    height: mainTheme.userPhotoStyle.height,
    width: mainTheme.userPhotoStyle.width,
    alignItems: mainTheme.userPhotoStyle.alignItems,
    marginTop: mainTheme.userPhotoStyle.marginTop,
    marginBottom:mainTheme.userPhotoStyle.marginBottom,
    marginLeft:mainTheme.userPhotoStyle.marginLeft,
    marginRigth:mainTheme.userPhotoStyle.marginRight,
    display: mainTheme.userPhotoStyle.display,
    textAlign: mainTheme.userPhotoStyle.textAlign,
    justifyContent: mainTheme.userPhotoStyle.justifyContent,
  },
  buttonStyle:{
    color: "white",
    textTransform:"none",
    fontSize: 12,
    margin: "3px",
    backgroundColor:mainTheme.palette.primary.main,
    "&:hover": {
      color:mainTheme.palette.secondary.main,
      backgroundColor:mainTheme.palette.primary.main,
    },
    "&:disabled": {
      color:"gray",
      backgroundColor:mainTheme.palette.primary.main,
    },
  }
  }));


export default function ProfilePicture({ setUserImageFile, userImageBase64, setUserImageBase64, setIsEditField }){
  const classes = useStyles();
  // const [ userImageBase64, setUserImageBase64] = useState(null);
  
  const handlePicture = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64Image = event.target.result;
        setUserImageFile(file);
        setUserImageBase64(base64Image);
        setIsEditField(true);
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <>
    <Grid container spacing={2} >
      <Grid item container xs={12} style={{paddingTop:"10px"}}>
        <Grid container display="flex" alignItems="center" justifyContent="center">
          {/* <Avatar alt="Uploaded Image" src={userPicture && URL.createObjectURL(userPicture)}  className={classes.userPhotoStyle} /> */}
          {/* <Avatar alt="Uploaded Image" src={userPicture ? `data:image/jpeg;base64,${userPicture}` : ''}  className={classes.userPhotoStyle} /> */}
          <Avatar alt="Uploaded Image" src={userImageBase64 ? userImageBase64 : ''}  className={classes.userPhotoStyle} />
        </Grid> 
        <Typography variant="caption" align="justify" paragraph className={classes.welcomeMessageStyle} style={{marginBottom:"5px"}} >Let your online connections see the face behind the username and make a lasting impression !! A picture is worth a thousand words, and having your photo on your profile can help you connect and engage with others more authentically.</Typography>
        <Button
          variant="contained"
          component="label"
          className={classes.buttonStyle}
          disableRipple
          startIcon={<CloudUploadIcon />}
        >
          Upload Photo
          <input
            type="file"
            accept="image/*"
            hidden
            autoComplete="off"
            // onChange={(e) => setUserPicture(e.target.files[0])}
            onChange={(e)=> handlePicture(e)}
          />
        </Button>
      </Grid>
    </Grid>
    </> 
  )
}