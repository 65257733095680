import React from 'react';

import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((mainTheme) => ({
simpleValuationStyle:{
    fontSize:11, 
    margin:"3px",
    color: mainTheme.palette.tertiary.main
  },
}))

export default function EstimatedFcffText(){
const classes=useStyles();

return (
  <>
  <Typography align="justify" gutterBottom className={classes.simpleValuationStyle} >
    This valuation was made estimating directly the average Free Cash Flow to Firm growth rate for the next years, not considering revenue, margins and other business assumptions.</Typography>
  </>
)
}