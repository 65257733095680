import React, { useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import ReactGA from 'react-ga4';

import { Container, Grid, Paper, Box, Typography, Button, Icon, Grow, useTheme, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PublishIcon from '@mui/icons-material/Publish';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import StarRateIcon from '@mui/icons-material/StarRate';
// import SocialNetworks from '../components/SocialNetworks';

// import Footer from '../components/Footer';

const useStyles = makeStyles((mainTheme) => ({
  gridContainerStyle:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '80vh', 
    // [mainTheme.breakpoints.between('xs', 'sm')]: {
    //   width: '350px',
    //   backgroundColor: 'orange',
    // },
    [mainTheme.breakpoints.down('lg')]: {
      minHeight:"85vh",
    },
    
    marginTop: "64px",
    padding:"0px",
  },
  gridProductsStyle:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: "90%",
    [mainTheme.breakpoints.between('xs', 'sm')]: {
      width: '360px',
      // backgroundColor: 'orange',
    },
    [mainTheme.breakpoints.between('sm', 'lg')]: {
      width:"560px",
      // backgroundColor:"gray"
    },

    margin:"auto",
  },
  productTitleStyle:{
    width: "100%",
    marginBottom: "1px",
    padding: "2px",
    borderRadius: mainTheme.shape.borderRadius,
    fontSize:"13px",
    color: "white", 
    backgroundColor: mainTheme.palette.primary.main, 
  },
  productDiscountStyle:{
    marginLeft: "20px",
    color:"blue"
  },
  priceStyle:{
    margin:"5px",
    fontSize:"14px",
    color:mainTheme.palette.tertiary.main
  },
  iconStyle:{
    marginLeft:"5px",
    marginTop:"3px",
    color:mainTheme.palette.tertiary.main
  },
  textStyle:{
    marginLeft:"2px", 
    fontSize:12,
    // [mainTheme.breakpoints.down('sm')]: {
    //   fontSize:12,
    // },
    // [mainTheme.breakpoints.down('md')]: {
    //   fontSize:12,
    // },
    // [mainTheme.breakpoints.down('lg')]: {
    //   fontSize:11,
    // },
    color:mainTheme.palette.tertiary.main,
  },  
  buttonStyle:{
    width:110,
    justifyContent: 'center',
  },
  // paperStyle:{
  //   width: "100%",
  //   [mainTheme.breakpoints.down('md')]: {
  //     width:"350px",
  //   },
  //   margin:"auto",
  //   paddingLeft: "0px",
  //   borderRadius: mainTheme.shape.borderRadius,
  //   backgroundColor:"orange",
  // },
}))

export default function PricingTest() {
	const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const history = useHistory();
  const productsList = [
    { id:"0",
      title:"Free",
			discount:"",
      price:0,
			benefitsTitleText:"What Will You Get:",
      seeValuationsIcon:<FindInPageIcon />,
			seeValuationsText:"See up to 5 valuations of other users per month.",
      createValuationsIcon:<AddCircleIcon />,
      createValuationsText:"Create up to 5 valuations per month.",
      publishValuationsIcon:<PublishIcon />,
			publishValuationsText:"Publish up to 5 valuations per month.",
			printValuationsIcon: <PictureAsPdfIcon />,
			printValuationsText: "Generate PDF files of your own valuations.",
      printUsersValuationsIcon: <PictureAsPdfIcon />,
      printUsersValuationsText:"Generate PDF files of other users: not allowed.",
      likeAndFavoritesIcon:< ThumbUpAltIcon/>,
      likeAndFavoritesText:"Give likes and save as favourites.",
      rateAndCommentIcon:<StarRateIcon />,
			rateAndCommentText:"Rating and comments: not allowed.",
			paymentOptionsText:"No need to register payment",
      freeTrialText:"Free"
    },
    { id:"1",
      title:"Monthly Subscription",
			discount:"",
      price:29.99,
			benefitsTitleText:"What Will You Get:",
      seeValuationsIcon:<FindInPageIcon />,
			seeValuationsText:"See all valuations published by other users.",
      createValuationsIcon:<AddCircleIcon />,
      createValuationsText:"Create unlimited valuations.",
      publishValuationsIcon:<PublishIcon />,
			publishValuationsText:"Publish unlimited valuations.",
			printValuationsIcon: <PictureAsPdfIcon />,
			printValuationsText: "Generate unlimited PDF files of your valuations.",
      printUsersValuationsIcon: <PictureAsPdfIcon />,
      printUsersValuationsText:"Generate unlimited PDF files of public valuations.",
      likeAndFavoritesIcon:< ThumbUpAltIcon/>,
      likeAndFavoritesText:"Give likes and save as favorites.",
      rateAndCommentIcon:<StarRateIcon />,
			rateAndCommentText:"Rate and comment other users valuations.",
			paymentOptionsText:"We accept Credit and Debit Cards, Paypal, Bitcoin and ETH",
      freeTrialText:""
    },
    { id:"2",
      title:"Annual Subscription",
			discount:"20% discount over Monthly Subscription !!",
      price:287.00,
			benefitsTitleText:"What Will You Get:",
      seeValuationsIcon:<FindInPageIcon />,
			seeValuationsText:"See all valuations published by other users.",
      createValuationsIcon:<AddCircleIcon />,
      createValuationsText:"Create unlimited valuations.",
      publishValuationsIcon:<PublishIcon />,
			publishValuationsText:"Publish unlimited valuations.",
			printValuationsIcon: <PictureAsPdfIcon />,
			printValuationsText: "Generate unlimited PDF files of your valuations.",
      printUsersValuationsIcon: <PictureAsPdfIcon />,
      printUsersValuationsText:"Generate unlimited PDF files of public valuations.",
      likeAndFavoritesIcon:< ThumbUpAltIcon/>,
      likeAndFavoritesText:"Give likes and save as favorites.",
      rateAndCommentIcon:<StarRateIcon />,
			rateAndCommentText:"Rate and comment other users valuations.",
			paymentOptionsText:"We accept Credit and Debit Cards, Paypal, Bitcoin and ETH",
      freeTrialText:""
    }]

  const handleRegister = (product)=>{
    history.push(`/register/${product}`);
  }

  const priceFormat = (price)=>{
    return `Us$ ${Intl.NumberFormat('en-US',{style:'decimal', minimumFractionDigits:2,maximumFractionDigits:2}).format(price)}`
	}

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/pricing", title: "Pricing" });

  }, []);
  return (
    <>
    {/* <Grid container alignItems="center" justifyContent="center" spacing={1} sm={12} className={classes.gridContainerStyle}   > */}
    {/* { alert ("isMobile: " + isMobile + ", isTablet: " + isTablet)} */}
    <Grid container spacing={1} sm={12} className={classes.gridContainerStyle}   >
      {/* { isMobile?
        <Grid item xs={12} style={{display:"flex",justifyContent:"center", width:"100%"}}>
          <SocialNetworks />  
        </Grid>
      :
        <Grid item xs={12} style={{display:"flex",justifyContent:"flex-end", width:"100%", marginRight:"20px"}}>
          <SocialNetworks />  
        </Grid> 
      } */}

      <Container maxWidth="lg" disableGutters > 

          <Grid item container  spacing={2} xs ={12} style={{padding:"5px"}} className={classes.gridProductsStyle}  >


            {productsList.map (product => (
              
              <Grow in direction = "up" timeout = {1000}>
              <Grid item spacing={4} xs={12} sm={12} md={4}  >
                <Paper elevation={12} style={{ textAlign: "center" }} >
              
                  <Typography align="center" className= {classes.productTitleStyle} >
                    {product.title}</Typography>
                  <Box style={{height:"10px"}}/>

                  { product.price > 0 ?
                    <Box style={{height:"20px"}}>
                      <Typography variant="body1" align="center" className={classes.priceStyle} gutterBottom >
                        {priceFormat(product.price)}</Typography>
                    </Box>
                  : 
                    isMobile ? < null /> : <Box style={{height:"25px"}} />
                  }
                  { isMobile && (product.discount === "") ? null : 
                    <>
                      <Box style={{height:"10px"}}/>
                      <Grid item >
                        <Typography variant="caption" className={classes.productDiscountStyle} gutterBottom>
                          {product.discount}</Typography>
                      </Grid>
                    </>
                  }

                  <Box style={{height:"10px"}}/>
                  <Grid item>
                    <Typography align="center" className={classes.textStyle} gutterBottom >{product.benefitsTitleText}</Typography>
                  </Grid>

                  <Grid item container >
                    <Grid sx={1}>
                      <Icon className={classes.iconStyle}>{product.seeValuationsIcon}</Icon> 
                    </Grid>
                    <Grid sx={11} style={{marginTop:"5px"}}>
                      <Typography variant="caption" className={classes.textStyle}>{product.seeValuationsText}</Typography>
                    </Grid>
                  </Grid>

                  <Grid item container >
                    <Grid sx={1}>
                      <Icon className={classes.iconStyle}>{product.createValuationsIcon}</Icon> 
                    </Grid>
                    <Grid sx={11} style={{marginTop:"5px"}}>
                      <Typography variant="caption" className={classes.textStyle} >{product.createValuationsText}</Typography>
                    </Grid>
                  </Grid>

                  <Grid item container >
                    <Grid sx={1}>
                      <Icon className={classes.iconStyle}>{product.publishValuationsIcon}</Icon> 
                    </Grid>
                    <Grid sx={11} style={{marginTop:"5px"}}>
                      <Typography variant="caption" className={classes.textStyle}>{product.publishValuationsText}</Typography>
                    </Grid>
                  </Grid>

                  <Grid item container >
                    <Grid sx={1}>
                      <Icon className={classes.iconStyle}>{product.printValuationsIcon}</Icon> 
                    </Grid>
                    <Grid sx={11} style={{marginTop:"5px"}}>
                      <Typography variant="caption" className={classes.textStyle}>
                        {product.printValuationsText}</Typography>
                    </Grid>
                  </Grid>

                  <Grid item container >
                    <Grid sx={1}>
                      <Icon className={classes.iconStyle}>{product.printUsersValuationsIcon}</Icon> 
                    </Grid>
                    <Grid sx={11} style={{marginTop:"5px"}}>
                      <Typography variant="caption" className={classes.textStyle}>
                        {product.printUsersValuationsText}</Typography>
                    </Grid>
                  </Grid>

                  <Grid item container >
                    <Grid sx={1}>
                      <Icon className={classes.iconStyle}>{product.likeAndFavoritesIcon}</Icon> 
                    </Grid>
                    <Grid sx={11} style={{marginTop:"5px"}}>
                      <Typography variant="caption" className={classes.textStyle}>
                        {product.likeAndFavoritesText}</Typography>
                    </Grid>
                  </Grid>

                  <Grid item container >
                    <Grid sx={1}>
                      <Icon className={classes.iconStyle}>{product.rateAndCommentIcon}</Icon> 
                    </Grid>
                    <Grid sx={11} style={{marginTop:"5px"}}>
                      <Typography variant="caption" className={classes.textStyle}>
                        {product.rateAndCommentText}</Typography>
                    </Grid>
                  </Grid>
                  {/* <Box style={{height:"10px"}}/> */}
                  
                  <Grid item container >

                  <Grid item sx={12} style={{marginTop:"3px"}}>
                    {/* <Box style={{display:"flex", alignItems:"center", justifyContent:"center", width:"100%",  backgroundColor:"cyan"}}> */}
                      { product.price === 0 ? 
                      <Typography variant="caption" align="center" gutterBottom style={{color:"white"}}>{product.freeTrialText}</Typography>
                      : 
                      <Typography variant="caption" align="center" gutterBottom className={classes.productDiscountStyle} style={{width:"100%"}}>{product.freeTrialText}</Typography>
                      }
                    {/* </Box> */}
                  </Grid>
                  </Grid>
                  <Box style={{height:"15px"}}/>

                  <Grid item container justifyContent="center" >
                    { product.price === 0 ? 
                      <>
                        <Button variant="contained" onClick={()=> handleRegister("0")} disableRipple className={classes.buttonStyle}>
                          Try it for Free !</Button>
                      </>
                      :
                      <Button variant="contained" onClick={()=> handleRegister(product.id)} disableRipple className={classes.buttonStyle}>
                        Join Us Now !</Button>
                    }
                  </Grid>
                  <Box style={{height:"25px"}}/>
                </Paper>
              </Grid> 
              </Grow>
            ))}
          </Grid>  
        {/* </Paper> */}
      </Container>
  
    </Grid>
    <Box style={{height:"30px"}}/>
    {/* <Footer /> */}
    </>
  )
}