import React from 'react';

import { Grid, Paper, Box, Button, Typography, Tooltip, useTheme, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import aaplLogo from '../assets/AAPL.png'
import amznLogo from '../assets/AMZN.png'
import catLogo from '../assets/CAT.png'
import tslaLogo from '../assets/TSLA.png'
import googLogo from '../assets/GOOG.png'
import pyplLogo from '../assets/PYPL.png'
import mcdLogo from '../assets/MCD.png'
import pepLogo from '../assets/PEP.png'
import clxLogo from '../assets/CLX.png'
import cvxLogo from '../assets/CVX.png'


const useStyles = makeStyles( (mainTheme) => ({
  sectionTitleStyle:{
    marginLeft:"5px",
    fontSize:"13px",
    color: mainTheme.palette.tertiary.main
  },

}))

export default function LookOtherValuations(){
  const classes = useStyles();
  return(
    <>
    <Grid container >
  
    <Grid item xs={5}>
    <Typography className={classes.sectionTitleStyle}><b>Find other valuations</b></Typography>
      </Grid>
      <Grid item xs={1}>
        <Tooltip title="TSLA-Tesla Inc.">
        <img src = {tslaLogo} alt="Logo" style={{height:"16px"}}/> 
        </Tooltip>
      </Grid>
      <Grid item xs={1}>
        <Tooltip title="GOOG-Aplhabet Inc.">
          <img src = {googLogo} alt="Logo" style={{height:"16px"}}/> 
        </Tooltip>
      </Grid>
      <Grid item xs={1}>
        <Tooltip title="AMZN-Amazon.com Inc.">
          <img src = {amznLogo} alt="Logo" style={{height:"22px"}}/> 
        </Tooltip>
      </Grid>
      <Grid item xs={1}>
        <Tooltip title="PYPAL-PayPal Holdings, Inc.">
          <img src = {pyplLogo} alt="Logo" style={{height:"16px"}}/> 
        </Tooltip>
      </Grid>
      <Grid item xs={1}>
        <Tooltip title="MCD-McDonald's Corporation">
          <img src = {mcdLogo} alt="Logo" style={{height:"16px"}}/> 
        </Tooltip>
      </Grid>
      <Grid item xs={1}>
        <Tooltip title="PEP-PepsiCo, Inc.">
          <img src = {pepLogo} alt="Logo" style={{height:"16px"}}/> 
        </Tooltip>
      </Grid>
      <Grid item xs={1}>
        <Tooltip title="CVX-Chevron Corporation">
          <img src = {cvxLogo} alt="Logo" style={{height:"16px"}}/> 
        </Tooltip>
      </Grid>
    </Grid>
    </>
  )


}