import React, { useState, useEffect } from 'react';

import { Grid, Paper, Box, Button, FormControlLabel, Checkbox, Grow, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';

import axios from "axios";
import api from '../services/api.js';

import useAxios from '../hooks/useAxios.js';
import valuationsWebApi from '../services/valuationsWebApi';

// import Header from '../components/Header.js';

const useStyles = makeStyles( (mainTheme) => ({
  contentStyle: {
    position: 'absolute',
    top: '65px'
  },
  paperStyle: {
    margin: 'auto',
    padding:'10px',
    minWidth: 350,
    maxWidth: 500,
    backgroundColor:mainTheme.palette.secondary.main,  
  },
  buttonStyle:{
    color: "white",
    backgroundColor:mainTheme.palette.primary.main,
    textTransform:"none",
    fontSize:12,
    margin: "10px",
    //marginTop: "15px",
    "&:hover": {
      color:mainTheme.palette.secondary.main,
      backgroundColor:mainTheme.palette.primary.main,
    },
  },
  }))

export default function GetWorkingCapitalChanges(){
  const classes = useStyles();
  // const companiesList = ['MMM','MSFT','TSLA','AMZN','GOOG','META','CVX','XOM','CLX','CL','MCD','PEP','AAPL','NVDA','V','MA'];;
  const companiesList = ['COST'];;

  const { axiosFetch: putWorkingCapitalChanges } = useAxios();

  let wcChanges = Array.from({ length: 4 } , () => ({ financialsId: "", workingCapitalChanges:0 }));

  const wccSuccessCallback=(apiData)=>{
    // alert("Exito en Put Financials del wcc")
  }
  
  const wccErrorCallback=(apiData)=>{
    alert("Problema en Put Financials del wcc")
  }

  async function updateYFData() {

    for (let i = 0; i < companiesList.length; i++) {       

      const endPoint = { method: 'GET',url:'https://yh-finance.p.rapidapi.com/stock/v2/get-cash-flow', params:  { symbol: companiesList[i], region: 'US' }, headers: {'X-RapidAPI-Host': 'yh-finance.p.rapidapi.com','X-RapidAPI-Key': '057477ec51mshd7404991ea3c956p1dc241jsn2fbfbe21b657'}}
      await axios.request(endPoint).then(function (response) {
  
        const yfData =  response.data;
        console.log("Fetching data")
        console.log(yfData)

        if (yfData) {  // Si recibe los datos de la empresa desde la API
          // setSuccessWCCApiFetches(prevState => prevState + 1);
          if (yfData.timeSeries.annualChangeInWorkingCapital[0].asOfDate){
            wcChanges[0].financialsId=yfData.timeSeries.annualChangeInWorkingCapital[0].asOfDate+companiesList[i]
          }
          if (yfData.timeSeries.annualChangeInWorkingCapital[0].reportedValue.raw){
            wcChanges[0].workingCapitalChanges=yfData.timeSeries.annualChangeInWorkingCapital[0].reportedValue.raw
          }
          if (yfData.timeSeries.annualChangeInWorkingCapital[1].asOfDate){
            wcChanges[1].financialsId=yfData.timeSeries.annualChangeInWorkingCapital[1].asOfDate+companiesList[i]
          }
          if (yfData.timeSeries.annualChangeInWorkingCapital[1].reportedValue.raw){
            wcChanges[1].workingCapitalChanges=yfData.timeSeries.annualChangeInWorkingCapital[1].reportedValue.raw
          }
          if (yfData.timeSeries.annualChangeInWorkingCapital[2].asOfDate){
            wcChanges[2].financialsId=yfData.timeSeries.annualChangeInWorkingCapital[2].asOfDate+companiesList[i]
          }
          if (yfData.timeSeries.annualChangeInWorkingCapital[2].reportedValue.raw){
            wcChanges[2].workingCapitalChanges=yfData.timeSeries.annualChangeInWorkingCapital[2].reportedValue.raw
          }
          if (yfData.timeSeries.annualChangeInWorkingCapital[3].asOfDate){
            wcChanges[3].financialsId=yfData.timeSeries.annualChangeInWorkingCapital[3].asOfDate+companiesList[i]
          }
          if (yfData.timeSeries.annualChangeInWorkingCapital[3].reportedValue.raw){
            wcChanges[3].workingCapitalChanges=yfData.timeSeries.annualChangeInWorkingCapital[3].reportedValue.raw
          }
        }
        for (let i = 0; i < 4; i++) {  
          alert( "working capital Changes" + wcChanges[i].financialsId + " " + wcChanges[i].workingCapitalChanges)
          const dataToProcess = { financialsId:  wcChanges[i].financialsId, workingCapitalChanges: wcChanges[i].workingCapitalChanges }
          putWorkingCapitalChanges({ axiosInstance: valuationsWebApi, method: 'PUT', url: '/wcc', data: dataToProcess,requestConfig: { headers: {'Authorization': "martincsl@hotmail.com",},}},wccSuccessCallback, wccErrorCallback);
        }
      }).catch( async function (err) {
        // setDetailText(`Data from ${companiesList[i]} was not found`)
        const errorMsg=err.message
        const dataToProcess = { companyId:  companiesList[i], message: errorMsg};
        const response= await api.post('/error-log', dataToProcess );
        if (response){
          // setDetailText("Retornou resposta do backend para error log")
        } else{
          // setDetailText("Nao resposta do backend para error log")
        }
        console.error(err);
      });
    }
  }  

  return(
    <>
    <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" className={classes.contentStyle} style={{ minHeight: '85vh' }} >
      <Grow in timeout = {1000}>
        <Grid item xs={12} md={6} >

          <Paper className={classes.paper} elevation={12}>
            <Button 
              variant = "contained" 
              disableRipple
              onClick = {updateYFData} 
              className = {classes.buttonStyle}
              >Update Daily Data
            </Button>
          </Paper>
        </Grid>
      </Grow>
    </Grid>
    </>
  )
}

// const options = {
//   method: 'GET',
//   url: 'https://yh-finance.p.rapidapi.com/stock/v2/get-cash-flow',
//   params: {
//     symbol: 'AMZN',
//     region: 'US'
//   },
//   headers: {
//     'X-RapidAPI-Key': '057477ec51mshd7404991ea3c956p1dc241jsn2fbfbe21b657',
//     'X-RapidAPI-Host': 'yh-finance.p.rapidapi.com'
//   }
// };