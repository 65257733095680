import React, { useState, useContext, useEffect } from 'react';
// Incluir CircularProgress y Squeleton
import { Stack, Grid, Paper, Box, Snackbar, SnackbarContent, useTheme, useMediaQuery, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import makeStyles from '@mui/styles/makeStyles';

import { LoginContext } from '../../../helpers/Context';
import valuationsWebApi from '../../../services/valuationsWebApi';
import useAxios from '../../../hooks/useAxios';
import useDataHandling from '../../../hooks/useDataHandling';
import useValuation from '../../../hooks/useValuation';

import TableHistoricalData from '../../Valuations/TableHistoricalData';
// import FormCashFlowAssumptions from './FormCashFlowAssumptions';
import TableBusinessAssumptions from './TableBusinessAssumptions';
import TableCostOfCapitalAssumptions from './TableCostOfCapitalAssumptions';
import TableUserCostOfCapital from './TableUserCostOfCapital';
import SavedInfoPanel from './SavedInfoPanel';
import TableIncomeStatement from '../../Valuations/TableIncomeStatement';
import TableCashFlow from '../../Valuations/TableCashFlow';
import TableValuation from '../../Valuations/TableValuation';
import ChartFreeCashFlow from '../../Valuations/ChartFreeCashFlow';
import ChartRevenue from '../../Valuations/ChartRevenue';
import CheckboxEstimateJustCashFlow from '../CheckboxEstimateJustCashFlow';
import CheckboxEstimateCostOfCapital from '../CheckboxEstimateCostOfCapital'
import CheckboxEstimateTtmAsCurrYear from '../CheckboxEstimateTtmAsCurrYear';
import CheckboxFinancialView from '../CheckboxFinancialView';
import ShowComments from './ShowComments';
import AssumptionsText from '../AssumptionsText';
// import PrintPdf from './PrintPdf';
import DisclaimerText from '../DisclaimerText';
import EstimatedCostOfCapitalText from './EstimatedCostOfcapitalText';
import EstimatedFcffText from './EstimatedFcffText';
import SocialNetworks from '../../../components/SocialNetworks';
import LookOtherValuations from '../../../components/LookOtherValuations';

const useStyles = makeStyles( (mainTheme) => ({
  contentStyle: {
    position: 'absolute',
    top: '65px',
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh', // Set the height of the container to full viewport height
    // flexGrow: 1,
  },
  circularProgressStyle:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh', 
    color:mainTheme.palette.tertiary.main,
  },
  circularTextStyle:{
    fontSize:13,
    color:mainTheme.palette.tertiary.main,
  },
  paperStyle: {
    width: "100%",   
    marginLeft: "2px",
    marginRight: "2px",
    color: "white",
    backgroundColor: "#f0f8ff",
    padding: "5px",
  },
  boxSelectStyle:{
    height: "30px",
    width: "100%",
  },
  buttonStyle: {
    width:"80px",
    [mainTheme.breakpoints.down('sm')]: {
      fontSize: "10px"
    },
  },
  CompanyInfo: {
    height:"165px",
    marginLeft: "0px",
    marginRight: "0px",
    padding: "10px",
    color: "black",
    backgroundColor: "white",
  },
  TableContainerStyle: {
    width: "100%",
    minHeight: "668px", 
    marginLeft: "3px",
    marginRight: "0px",
    color: "white",
    backgroundColor: "#f0f8ff", 
    padding: "2px",
  },
  textStyle:{
    fontSize:"11px", 
    marginLeft:"5px",
    marginTop:"0px",
    paddingTop:"5px",
    paddingLeft:"5px",
    color:mainTheme.palette.tertiary.main,
  },

  greenSnackbarContent: {
    backgroundColor: "#228B22",
  },
}));
// precisa de getValuations se ja estao os dados em valuationsList?
// const assumptions = {};
// const calculatedCostOfCapital = {};
// const companyData = {};


export default function SavedValuation({id}){
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));//md
  const { userData, savedValuationData, setSavedValuationData, setQtyOfValuationsViews, isValuationSample } = useContext(LoginContext);
  const { userId, userProduct } = userData;
  const { valuationId, profileId } = savedValuationData;
  const currentYear = new Date().getFullYear();  
  // const [ interactions, setInteractions ] = useState({likes:0, favorites:0, comments:0, ratings:0, avgRating:0});

  const { axiosFetch: getValuation, isLoading: isLoadingValuation, error: isErrorValuation } = useAxios();
  const { axiosFetch: getFinancials, isLoading:isLoadingFinancials, error: isErrorFinancials } = useAxios();        // function to fecth previous years financial data from one specific company
  const { axiosFetch: getCompany, isLoading:isLoadingCompany, error: isErrorCompany } = useAxios();                 // function to fecth the list of companies with financial info available
  const { axiosFetch: postValuationsViews, isLoading:isLoadingValuationsViews, error: isErrorValuationsViews } = useAxios();                 // function to fecth the list of companies with financial info available
  const [ companyIdSearch, setCompanyIdSearch ] = useState("");
  const [ companyData, setCompanyData] = useState({symbol:"",shortName:"", sharesOustanding: 0, regularMarketPrice:0, fiftyTwoWeekLow:0, fiftyTwoWeekHigh:0, marketCap:0, beta:0, totalCash:0, totalDebt:0, logoUrl:"",updatedAt:"" });
  const companySearchName = companyData.symbol !=="" ? (companyData.symbol + " - " + companyData.shortName) : "" ;
  // const historicalFinancialData = [];
  const [ historicalFinancialData, setHistoricalFinancialData ] = useState([]); // Array with fetched financial data from previous years
  // const [ assumptions, setAssumptions ] = useState({revenueGrowth:"", marginTarget:"", opexGrowth:"", interestGrowth:"", otherGrowth:"",cashFlowAvgGrowth:"", taxRate:"", capexGrowth:"", nwcGrowth:"", perpetualGrowthRate: 3, cashFlowDiscretePeriod:5, companyBeta:0, riskFreeReturn:4.40, marketReturn:9.9, equityRiskPremium:5.5, debtTotalRatio:0, costOfDebt:0, assumptionsText:""});
  const [ assumptions, setAssumptions ] = useState({revenueGrowth:"", marginTarget:"", opexGrowth:"", interestGrowth:"", otherGrowth:"",cashFlowAvgGrowth:"", taxRate:"", capexGrowth:"", nwcGrowth:"", perpetualGrowthRate: 3, cashFlowDiscretePeriod:5, companyBeta:0, riskFreeReturn:4.40, marketReturn:0, equityRiskPremium: 5.5, debtTotalRatio:0, costOfDebt:0, inputedCostOfCapital:9.9, assumptionsText:""});

  const { calcForecastedCashFlow, calcValuation, calcCostOfCapital, isValuationCompleted, checkValuationStatus } = useValuation();
  const { createFinancialHistoricalData, createCombinedData} = useDataHandling();
  const [ isTtmAsCurrYear, setIsTtmAsCurrYear ] = useState(false); 
  const [ isEstimateFcffOnly, setIsEstimateFcffOnly ] = useState(false); //useState(assumptions.cashFlowAvgGrowth !=="" && assumptions.revenueGrowth ===""? true: false );                  // Check if valuation assumptions will be complete or just estimate directly the cash flow growth
  const [ isUserCostOfCapital, setIsUserCostOfCapital] = useState(false);
  const [ isCheckedShowIncStatement, setIsCheckedShowIncStatement] = useState(true);
  const [ isCheckedShowPreviousYears, setIsCheckedShowPreviousYears ] = useState(true);   // Check if previous years will be shown in income statement and cash flow (default)
  const [ isCheckedDescOrder, setIsCheckedDescOrder] = useState(true);                    // Check if numbers will appear in descending order (default)
  const [ isHistoricalTableVisible, setIsHistoricalTableVisible] = useState(true)
  // realFinancialDataLastYear
  let realFinancialDataLastYear=""
 // const [ isDialogPdfOpen, setIsDialogPdfOpen]= useState(false);
  const [ valuationDateInfo, setValuationDateInfo]=useState ({dateMarketCap:"", datePrice:"", dateTotalCash:"", dateTotalDebt:""})
  const calculatedCostOfCapital = historicalFinancialData.length > 0 && companyData.marketCap> 0 ? calcCostOfCapital(historicalFinancialData, companyData, assumptions, isUserCostOfCapital, valuationDateInfo): { costOfEquity:0, costOfCapital:0 }  ;
  const forecastedFinancialData = historicalFinancialData.length > 0 ? calcForecastedCashFlow (historicalFinancialData, assumptions, calculatedCostOfCapital, isEstimateFcffOnly): (Array.from({ length: assumptions.cashFlowDiscretePeriod }, (a,b) => ({ year:currentYear + b, period:0, totalRevenue:0, costOfRevenue: 0, grossProfit: 0, grossProfitPercent:0, operatingExpenses: 0, depreciation: 0, interestExpense: 0, other: 0, incomeBeforeTax: 0, incomeTaxExpense: 0, netIncome: 0, ebit: 0, capitalExpenditures: 0, cash: 0, shortLongTermDebt:0, longTermDebt:0, workingCapitalChanges:0, cashFlow:0, discountedCashFlow:0 }))).reverse();
  const combinedFinancialData = historicalFinancialData.length > 0 ? createCombinedData(historicalFinancialData, forecastedFinancialData, isCheckedShowPreviousYears, isCheckedDescOrder): (Array.from({ length: parseInt(assumptions.cashFlowDiscretePeriod) + 3 }, (a,b) => ({ year:currentYear-3 + b, period:0, totalRevenue:0, costOfRevenue: 0, grossProfit: 0, grossProfitPercent:0, operatingExpenses: 0, depreciation: 0, interestExpense: 0, other: 0, incomeBeforeTax: 0, incomeTaxExpense: 0, netIncome: 0, ebit: 0, capitalExpenditures: 0, cash: 0, shortLongTermDebt:0, longTermDebt:0, workingCapitalChanges:0, cashFlow:0, discountedCashFlow:0 }))).reverse();
  const valuation = forecastedFinancialData.length > 0 ? calcValuation(companyData, historicalFinancialData, assumptions, calculatedCostOfCapital, forecastedFinancialData, valuationDateInfo): {valuationId:"", cashFlowAvgGrowth:0, sumOfCashFlowPresentValue:0, perpetuityValue:0, perpetuityPresentValue:0, enterpriseValue:0, cash:0, debt:0, equityValue:0, sharesOutstanding:0, targetStockPrice:0, dateStockPrice:0, marketCap:0, irr:0, updatedAt:"",published:"", publishedDate:"",lastHistoricalYear:0 } ;
  const [ isDisabledChkBox, setIsDisabledChkBox]= useState(false);
  const [ editMode, setEditMode] = useState("new");
  const [ isSnackbarOpen, setIsSnackbarOpen]=useState(false);
  const [ snackbarMessage, setSnackbarMessage]=useState("");
  
  const handleSnackbarClose=()=>{
    setIsSnackbarOpen(false);
  }

  const getCompanySuccessCb=(apiData)=>{  
    let calculatedShares = 0;
    const company = apiData;
    if (company) {
      if (company.marketCap > 0 && company.regularMarketPrice > 0 ) {
        calculatedShares = company.marketCap/1000000/company.regularMarketPrice //compare with api value....
      } 
      // alert("company logo url: " + company.logoUrl)
      setCompanyData({symbol:company.symbol, shortName:company.shortName, sharesOutstanding: calculatedShares, regularMarketPrice:company.regularMarketPrice, fiftyTwoWeekLow:company.fiftyTwoWeekLow, fiftyTwoWeekHigh:company.fiftyTwoWeekHigh, marketCap:company.marketCap/1000000000, beta:company.beta, totalCash: company.totalCash/1000000000, totalDebt: company.totalDebt/1000000000, logoUrl:company.logoUrl, updatedAt: company.updatedAt})
    } 
  }

  const getCompanyErrorCb=()=>{
    setSnackbarMessage("There was an error accesing the server. Please try later");
    setIsSnackbarOpen(true);
  }

  const postValuationsViewsSucessCb=(apiData)=>{
    setQtyOfValuationsViews(prevCount => prevCount + 1);
  }

  const postValuationsViewsErrorCb=()=>{

  }

  const getFinancialsSuccessCb=(apiData)=>{
    setHistoricalFinancialData(createFinancialHistoricalData (apiData, realFinancialDataLastYear));
    if (userProduct === "0" && userId !==profileId){
      const dataToProcess={ userId, valuationId }
      postValuationsViews({ axiosInstance: valuationsWebApi, method: 'POST', url: '/valuations-views', data: dataToProcess, requestConfig: { headers: {'Authorization': userId,},}},postValuationsViewsSucessCb, postValuationsViewsErrorCb);
    }
  }

  const getFinancialsErrorCb=()=>{
    setSnackbarMessage("getFinancialsErrorCb. There was an error accesing the server. Please try later");
    setIsSnackbarOpen(true);
  }

  const getValuationSuccessCb=(apiData)=>{
    // poner variables como 0, si no null o ""....
    // alert("valuationsScuccessCb")
    // const valuesToGet = { revenueGrowth, marginTarget, opexGrowth, interestGrowth, otherGrowth,taxRate,capexGrowth, nwcGrowth, perpetualGrowthRate, cashFlowDiscretePeriod, companyBeta, riskFreeReturn, marketReturn, debtTotalRatio, costOfDebt, lastHistoricalYear, assumptionsText}
    // && apiData.revenueGrowth ===""
    if (apiData.cashFlowAvgGrowth !== 0  && (apiData.revenueGrowth === 0 || apiData.revenueGrowth===null )) {  // revisar
      // alert("setIsEstimateFcffOnly(true)")
      setIsEstimateFcffOnly(true);
    }
    if (apiData.inputedCostOfCapital !==0 && apiData.inputedCostOfCapital !== null){
      setIsUserCostOfCapital(true);
    }
    setAssumptions( prevState => ({...prevState, 
      revenueGrowth: apiData.revenueGrowth, 
      marginTarget: apiData.marginTarget, 
      opexGrowth: apiData.opexGrowth, 
      interestGrowth: apiData.interestGrowth, 
      otherGrowth: apiData.otherGrowth,
      cashFlowGrowthRate: apiData.cashFlowAvgGrowth, // revisar
      taxRate: apiData.taxRate, 
      capexGrowth: apiData.capexGrowth, 
      nwcGrowth: apiData.nwcGrowth, 
      perpetualGrowthRate: apiData.perpetualGrowthRate, 
      cashFlowDiscretePeriod: apiData.cashFlowDiscretePeriod, 
      companyBeta: apiData.companyBeta, // company
      riskFreeReturn: apiData.riskFreeReturn, //assumptions
      marketReturn: apiData.marketReturn, 
      equityRiskPremium: apiData.equityRiskPremium ? apiData.equityRiskPremium : apiData.marketReturn-apiData.riskFreeReturn  ,
      debtTotalRatio: apiData.debtTotalRatio,
      costOfDebt: apiData.costOfDebt,
      inputedCostOfCapital: apiData.inputedCostOfCapital,
      cashFlowAvgGrowth: apiData.cashFlowAvgGrowth,
      lastHistoricalYear: apiData.lastHistoricalYear,
      assumptionsText: apiData.assumptionsText,

    }));
    setValuationDateInfo({
      dateMarketCap:apiData.marketCap, 
      datePrice:apiData.dateStockPrice, 
      dateTotalCash:apiData.cash, 
      dateTotalDebt:apiData.debt
    })
    realFinancialDataLastYear=apiData.lastHistoricalYear;
    setSavedValuationData({valuationId: apiData.valuationId, 
                          profileId: apiData.userId, 
                          profileFirstName:
                          apiData.firstName, 
                          profileLastName:apiData.lastName, 
                          symbol: apiData.companyId, 
                          shortName: apiData.shortName, 
                          createdAt: apiData.createdAt, 
                          updatedAt:apiData.updatedAt, 
                          published:apiData.published, 
                          publishedDate:apiData.publishedDate
                        });
  
    getCompany({ axiosInstance: valuationsWebApi, method: 'GET', url: `/companies/${apiData.companyId}`, requestConfig: { headers: {'Authorization': userId,},}},getCompanySuccessCb, getCompanyErrorCb);
    getFinancials({ axiosInstance: valuationsWebApi, method: 'GET', url: `/financials?companyId=${apiData.companyId}`, requestConfig: { headers: {'Authorization': userId,},}},getFinancialsSuccessCb, getFinancialsErrorCb);
    // setCompanyData( prevState => ({...prevState, symbol: apiData.companyId }));// pq somente conpanyId e nao o resto???
  }

  const getValuationErrorCb=()=>{
    setSnackbarMessage("There was an error accesing the server. Please try later");
    setIsSnackbarOpen(true);
  }

  useEffect(() => {
    if (valuationId) {
      //refactorar get valuation data from valuationsList?
      getValuation({ axiosInstance: valuationsWebApi, method: 'GET', url: `/valuations/?valuationId=${valuationId}`, requestConfig: { headers: {'Authorization': userId,},}},getValuationSuccessCb, getValuationErrorCb);
    } 
  }, []);

  return (
    <>
    { ! historicalFinancialData.length ? <>
      {console.log("valuationDateInfo")}

      {console.log(valuationDateInfo)}
  
      <div className={classes.root}>
      <Grid container> 
        <Grid item xs={12}>
          <Typography align="center" className={classes.circularTextStyle}>Loading Valuation from database</Typography>
        </Grid>
        <Box style={{height:"10px"}}/>
        <Grid item xs={12}>
          <Box style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
            <CircularProgress className={classes.circularProgressStyle} />
          </Box>
        </Grid>
        </Grid>   
      </div>
      {/* {alert("loading.....")} */}
      </>
  : <>

    { historicalFinancialData.length > 0? 
    <>
    <Grid container item direction="column" alignItems="center" style = {{minHeight: '80vh'}}  >
      
      {/* Grid para ocupar el heigth del app bar */}
      <Grid item xs={12} style = {{ minHeight: '69px'}} /> 

      {/* main container grid */}
      <Grid item container direction="row" spacing={1}   >

        { isMobile || isTablet ? // En caso de Mobile/Tablet, pasa al inicio de la pantalla...y no en el centro, como en el desktop
        <>
        <Grid item xs={12} >
        {/* className={classes.paperStyle} */}
          <Paper  elevation={3} > 
            <SavedInfoPanel 
              companyIdSearch={companyIdSearch} 
              setCompanyIdSearch={setCompanyIdSearch} 
              companySearchName={companySearchName} 
              companyData={companyData} 
              historicalFinancialData={historicalFinancialData}
              assumptions={assumptions}
              calculatedCostOfCapital={calculatedCostOfCapital}
              forecastedFinancialData={forecastedFinancialData}
              combinedFinancialData={combinedFinancialData}
              valuation={valuation}
              editMode={editMode}
              setEditMode={setEditMode}
              isCheckedDescOrder={isCheckedDescOrder}
              isCheckedShowPreviousYears={isCheckedShowPreviousYears}
              isEstimateFcffOnly={isEstimateFcffOnly}
              isUserCostOfCapital={isUserCostOfCapital}
              valuationDateInfo={valuationDateInfo}

            />
          </Paper>
          <Box sx={{height:"5px"}}/> 
          <Paper>
            <AssumptionsText assumptionsText={assumptions.assumptionsText}/>
          </Paper>
        </Grid> 
        </>
        : null }
            {/* isMobile || isTablet ? */}

        {/* left side grid */}
        <Grid item xs={12} sm={6} md={3}  >
          <Paper className={classes.paperStyle} elevation={0} > 
            { assumptions.assumptionsText && !isTablet ? 
              <AssumptionsText assumptionsText={assumptions.assumptionsText}/>
            : null }
            {/* <Box sx={{height:"6px"}}/>  */}
            <TableHistoricalData 
              historicalFinancialData={historicalFinancialData} 
              isHistoricalTableVisible={isHistoricalTableVisible}
              setIsHistoricalTableVisible={setIsHistoricalTableVisible}
            />
            {/* { isTablet && ! isMobile ? <Box sx={{height:"8px"}}/>: null} */}
            {/* <Box sx={{height:"6px"}}/>
            <CheckboxEstimateTtmAsCurrYear 
              isTtmAsCurrYear={isTtmAsCurrYear} 
              setIsTtmAsCurrYear={setIsTtmAsCurrYear}
            /> */}
            <Box sx={{height:"6px"}}/>
            {/* { isTablet? <Box sx={{height:"8px"}}/>: null} */}
            {/* <Box sx={{height:"6px"}}/> */}
            { isEstimateFcffOnly? <>
            <EstimatedFcffText />
            <Box sx={{height:"6px"}}/>
            </>
            : null}
            <TableBusinessAssumptions 
              assumptions={assumptions}
              isEstimateFcffOnly={isEstimateFcffOnly}
            />
            <Box sx={{height:"6px"}}/>

            { isUserCostOfCapital? 
            <>
            <EstimatedCostOfCapitalText />
              {/* <CheckboxEstimateCostOfCapital 
                isUserCostOfCapital={isUserCostOfCapital}
                setIsUserCostOfCapital={setIsUserCostOfCapital}
                isDisabledChkBox={true} 
                isClickable={false}
              /> */}
              <Box sx={{height:"5px"}}/>
              <TableUserCostOfCapital 
                assumptions={assumptions}
              />
            </>
            :
            <>
            { calculatedCostOfCapital.costOfCapital> 0? 
              <TableCostOfCapitalAssumptions 
                assumptions={assumptions} 
                calculatedCostOfCapital={calculatedCostOfCapital}
              />           
            : null}
  
            </>
            }
            {/* <Box sx={{height:"5px"}}/>
            { isTablet && !isMobile ? 
            <>
              <Box sx={{height:"5px"}}/>
              <DisclaimerText />
            </>
            : null} */}
            { ! isMobile && ! isTablet ? <>
              <Box sx={{height:"5px"}}/>

              <ShowComments 
              
                valuationId={valuationId}
              /> 
              </>
            : null}
          </Paper>
        </Grid>
        { isTablet && ! isMobile? 
          <Grid item xs={12} sm={6} md={3}  >
            <Paper className={classes.paperStyle} elevation={0}  > 
            { combinedFinancialData ? 
              <>
            
              {/* <Box sx={{height:"5px"}}/> */}
              {/* <SocialNetworks /> */}
              <TableValuation 
                companyData={companyData}
                combinedFinancialData={combinedFinancialData} 
                calculatedCostOfCapital={calculatedCostOfCapital} 
                valuation={valuation} 
                valuationDateInfo={valuationDateInfo}
              />
              <Box sx={{height:"5px"}}/>
              
              {/* <Grid container>
                <Grid item sx={12} sm={6}>
                  <Paper elevation={3}>
                    <ChartFreeCashFlow
                      assumptions={assumptions} 
                      combinedFinancialData={combinedFinancialData} 
                      isCheckedShowPreviousYears={isCheckedShowPreviousYears} 
                      isCheckedDescOrder={isCheckedDescOrder} isEstimateFcffOnly={isEstimateFcffOnly}
                    />
                  </Paper>
                </Grid>
                <Box sx={{height:"5px"}}/>
                <Grid item sx={12} sm={6}>
                  <Paper elevation={3}>
                    <ChartRevenue
                      assumptions={assumptions} 
                      combinedFinancialData={combinedFinancialData} 
                      isCheckedShowPreviousYears={isCheckedShowPreviousYears} 
                      isCheckedDescOrder={isCheckedDescOrder} isEstimateFcffOnly={isEstimateFcffOnly}
                    />
                  </Paper>
                </Grid>
              </Grid> */}

              {/* { assumptions.assumptionsText ? <> */}

              </>
              : null } 
                  {/* combinedFinancialData ? */}
            </Paper>
          </Grid>
          
        : null }
            {/* isTablet && ! isMobile? */}

        {/* center grid */}
        <Grid item xs={12} md={6}  >
          <Paper className={classes.paperStyle} elevation={3}  > 
            { ! isMobile && ! isTablet? 
              <>
              <SavedInfoPanel
                companyIdSearch={companyIdSearch} 
                setCompanyIdSearch={setCompanyIdSearch} 
                companySearchName={companySearchName} 
                companyData={companyData} 
                historicalFinancialData={historicalFinancialData}
                assumptions={assumptions}
                calculatedCostOfCapital={calculatedCostOfCapital}
                forecastedFinancialData={forecastedFinancialData}
                combinedFinancialData={combinedFinancialData}
                valuation={valuation}
                editMode={editMode}
                setEditMode={setEditMode}
                isCheckedDescOrder={isCheckedDescOrder}
                isCheckedShowPreviousYears={isCheckedShowPreviousYears}
                isEstimateFcffOnly={isEstimateFcffOnly}
                isUserCostOfCapital={isUserCostOfCapital}
                valuationDateInfo={valuationDateInfo}
              />
              <Box sx={{height:"5px"}}/>
              </>
            : null }
                    {/* ! isMobile && ! isTablet? */}
                    { isTablet && ! isMobile? 
          <Grid container>
            <Box style={{height:"5px"}}/>
            <Grid item sx={12} sm={6}>
              <Paper elevation={3} style={{marginRight:"5px"}}>
                <ChartFreeCashFlow
                  assumptions={assumptions} 
                  combinedFinancialData={combinedFinancialData} 
                  isCheckedShowPreviousYears={isCheckedShowPreviousYears} 
                  isCheckedDescOrder={isCheckedDescOrder} isEstimateFcffOnly={isEstimateFcffOnly}
                />
              </Paper>
            </Grid>
            <Box sx={{height:"5px"}}/>
            <Grid item sx={12} sm={6}>
              <Paper elevation={3} style={{marginLeft:"5px"}}>
                <ChartRevenue
                  assumptions={assumptions} 
                  combinedFinancialData={combinedFinancialData} 
                  isCheckedShowPreviousYears={isCheckedShowPreviousYears} 
                  isCheckedDescOrder={isCheckedDescOrder} isEstimateFcffOnly={isEstimateFcffOnly}
                />
              </Paper>
            </Grid>


          </Grid>  
        : null }
            <CheckboxFinancialView 
              isCheckedDescOrder={isCheckedDescOrder} 
              setIsCheckedDescOrder={setIsCheckedDescOrder} 
              isCheckedShowIncStatement={isCheckedShowIncStatement} 
              setIsCheckedShowIncStatement={setIsCheckedShowIncStatement} 
              isCheckedShowPreviousYears={isCheckedShowPreviousYears} 
              setIsCheckedShowPreviousYears={setIsCheckedShowPreviousYears} 
            />
            { combinedFinancialData.length > 0 ? 
            <>
              { isCheckedShowIncStatement ? 
              <>
                <Box sx={{height:"5px"}}/>
                <TableIncomeStatement 
                  assumptions={assumptions} 
                  combinedFinancialData={combinedFinancialData} 
                  isCheckedShowPreviousYears={isCheckedShowPreviousYears} 
                  isCheckedDescOrder={isCheckedDescOrder} 
                  isEstimateFcffOnly={isEstimateFcffOnly}
                /> 
              </>
              : null }
                 {/* isCheckedShowIncStatement? */}
              <Box sx={{height:"5px"}}/>
              <TableCashFlow 
                assumptions={assumptions} 
                combinedFinancialData={combinedFinancialData} 
                isCheckedShowPreviousYears={isCheckedShowPreviousYears} 
                isCheckedDescOrder={isCheckedDescOrder} 
                isEstimateFcffOnly={isEstimateFcffOnly}
              /> 
              { isTablet && ! isMobile? 
              <>
              <Grid container>
                <Grid item xs={6}>
                  <Box sx={{height:"5px"}}/>
                  <ShowComments 
                    valuationId={valuationId}
                  />
                </Grid>  
                <Grid item xs={6}>
                  <Box sx={{height:"5px"}}/>
                  <DisclaimerText />
                </Grid>  
              </Grid>

              </>
              : null}
              { ! isTablet && ! isMobile?
              <>
                <Box sx={{height:"5px"}}/>
                <DisclaimerText />
              </>
              : null}

              </> 
            : null }
                {/* combinedFinancialData? */}
          </Paper>
        </Grid>  
        {/* { isTablet && ! isMobile? 
          <Grid container>
            <Grid item sx={12} sm={6}>
              <Paper elevation={3}>
                <ChartFreeCashFlow
                  assumptions={assumptions} 
                  combinedFinancialData={combinedFinancialData} 
                  isCheckedShowPreviousYears={isCheckedShowPreviousYears} 
                  isCheckedDescOrder={isCheckedDescOrder} isEstimateFcffOnly={isEstimateFcffOnly}
                />
              </Paper>
            </Grid>
            <Box sx={{height:"5px"}}/>
            <Grid item sx={12} sm={6}>
              <Paper elevation={3}>
                <ChartRevenue
                  assumptions={assumptions} 
                  combinedFinancialData={combinedFinancialData} 
                  isCheckedShowPreviousYears={isCheckedShowPreviousYears} 
                  isCheckedDescOrder={isCheckedDescOrder} isEstimateFcffOnly={isEstimateFcffOnly}
                />
              </Paper>
            </Grid>
          </Grid>   */}
     

        {/* rigth side grid ...Obs.: en veridon Mobile y tablet no hay right side grid*/}
        { ! isTablet || isMobile ? // 
          <>
          <Grid item xs={12} sm={6} md={3}  >
            <Paper className={classes.paperStyle} elevation={0}  > 
            { combinedFinancialData.length > 0 ? 
              <>
              {/* <Box sx={{height:"5px"}}/> */}
              <SocialNetworks />
              { isValuationSample? <LookOtherValuations/> : null}
              
              
              <TableValuation 
                companyData={companyData}
                combinedFinancialData={combinedFinancialData} 
                calculatedCostOfCapital={calculatedCostOfCapital} 
                valuation={valuation} 
                valuationDateInfo={valuationDateInfo}
              />
              <Box sx={{height:"5px"}}/>
              <Paper elevation={3} >
                <ChartFreeCashFlow
                  assumptions={assumptions} 
                  combinedFinancialData={combinedFinancialData} 
                  isCheckedShowPreviousYears={isCheckedShowPreviousYears} 
                  isCheckedDescOrder={isCheckedDescOrder} isEstimateFcffOnly={isEstimateFcffOnly}
                />
              </Paper>
              
              {/* <Paper>
                <ShowComments
                  valuationId={valuationId} 
                />
              </Paper> */}
              <Box sx={{height:"5px"}}/>
              <Paper elevation={3}>
                <ChartRevenue
                  assumptions={assumptions} 
                  combinedFinancialData={combinedFinancialData} 
                  isCheckedShowPreviousYears={isCheckedShowPreviousYears} 
                  isCheckedDescOrder={isCheckedDescOrder} isEstimateFcffOnly={isEstimateFcffOnly}
                />
              </Paper> 
              <Box sx={{height:"5px"}}/> 
              {/* <DisclaimerText /> */}
              {/* <Box sx={{height:"5px"}}/>
              <Paper elevation={3}>
                <ChartRevenue
                  assumptions={assumptions} 
                  combinedFinancialData={combinedFinancialData} 
                  isCheckedShowPreviousYears={isCheckedShowPreviousYears} 
                  isCheckedDescOrder={isCheckedDescOrder} isEstimateFcffOnly={isEstimateFcffOnly}
                />
              </Paper>  */}
              {isMobile ? 
              <>
              <Box sx={{height:"5px"}}/>
              <DisclaimerText />
              <Box sx={{height:"5px"}}/>
              <ShowComments 
                valuationId={valuationId}
              /> 
              </>
              : null}
              </>
              : 
              <CircularProgress />
              } 
                  {/* combinedFinancialData ? */}
            </Paper>
          </Grid>  
          </> 
          : null }
              {/* !isMobile && ! isTablet ?  */}
      </Grid> 
    </Grid>

    </>
    : 
    <Stack alignItems="center">
      <CircularProgress  variant="indeterminate" className={classes.circularProgressStyle} />
    </Stack>
  } 
  </>
}
  <Snackbar
    open={isSnackbarOpen}
    autoHideDuration={2000} 
    onClose={handleSnackbarClose}
    >
    <SnackbarContent
      className={classes.greenSnackbarContent}
      message={snackbarMessage}
    />
  </Snackbar>  
    {/* <PrintPdf
      open={isDialogPdfOpen} 
      onClose={handleDialogPdfClose} 
      companyData={companyData}
      historicalFinancialData={historicalFinancialData}
      combinedFinancialData={combinedFinancialData} 
      assumptions={assumptions} 
      calculatedCostOfCapital={calculatedCostOfCapital} 
      valuation={valuation}
      savedValuationData={savedValuationData}
      isCheckedDescOrder={isCheckedDescOrder}
      isCheckedShowPreviousYears={isCheckedShowPreviousYears}
      isEstimateFcffOnly={isEstimateFcffOnly}
    /> */}

    </>
  )
}
