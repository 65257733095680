import React,{ useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {Grid, Paper, Box, Radio, RadioGroup, FormControl, FormLabel, FormControlLabel, Typography, Button, Grow, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PublishIcon from '@mui/icons-material/Publish';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import StarRateIcon from '@mui/icons-material/StarRate';

import { LoginContext } from '../helpers/Context';
import useAxios from '../hooks/useAxios';
import valuationsWebApi from '../services/valuationsWebApi';

const useStyles = makeStyles((mainTheme) => ({
  gridContainerStyle:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '80vh', 
    [mainTheme.breakpoints.down('lg')]: {
      minHeight:"85vh",
    },
    marginTop: "64px",
    padding:"0px",
  },
  paperStyle: {
    // display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 370,
    // width:"100%",
    height: 440,
    margin: '10px',
    // backgroundColor:mainTheme.palette.secondary.main,  
    backgroundColor:mainTheme.palette.secondary.main,  
    padding: mainTheme.spacing(2),
  },
  iconBox:{
    width:'100%',
    textAlign: 'center',
    AlignItems: 'center',
  },
  iconStyle:{
    color:mainTheme.palette.primary.main,
  },
  titleStyle:{
    // color:mainTheme.palette.primary.main,
    color:"black"
  },
  textStyle:{
    // color:mainTheme.palette.primary.main,
    color:"black"
  },

  welcomeMessageStyle:{
    color:mainTheme.palette.primary.main
  },
  buttonStyle:{
    color: "white",
    width:"140px",
    // color:mainTheme.palette.primary.main,
    textTransform:"none",
    fontSize: 12,
    margin: "3px",
    backgroundColor:mainTheme.palette.primary.main,
    "&:hover": {
      color:mainTheme.palette.secondary.main,
      backgroundColor:mainTheme.palette.primary.main,
    },
    "&:disabled": {
      color:"gray",
      backgroundColor:mainTheme.palette.primary.main,
    },
  },
  }));

export default function Upgrade(){
  // component for a current free plan user upgrade to Monthly or Annual Plan, 
  // creating a stripe customer id and calling a stripe checkout session
  const classes = useStyles();
  const { userData }= useContext(LoginContext);
  const { userId } = userData;
  const [ newProduct, setNewProduct] = useState("1");// uses Monthly Plan as default
  const { axiosFetch: getUserStripeId } = useAxios(); 
  const { axiosFetch: updateUser } = useAxios(); 
  const { axiosFetch: postCreateStripeConsumer } = useAxios(); 
  const { axiosFetch: postCreateStripeCheckout } = useAxios(); 
  const [ isFetchingData, setIsFetchingData] = useState(false)
  const history = useHistory();
  let customer ="";

  const handleCancel=()=>{
    history.push('/');
  }

  const createStripeCheckoutSuccessCb=(apiData)=>{
    setIsFetchingData(false);
    const url = apiData.url;
    window.location = url  //Llama la ventana de checkout de Stripe
  }

  const createStripeCheckoutErrorCb=(error)=>{
    // alert("Error in createStripeCheckoutErrorCb: "+ error)
    setIsFetchingData(false);
    alert('There was a problem in the stripe checkout Please try later.')
    history.push('/home');
  }

  const updateUserSuccessCb=()=>{
    // const priceId =  newProduct==="1"? "price_1OdhEWLCP51AssL9vkCYk7iJ":"price_1OdhEyLCP51AssL9qZemHRcz"; 
    const priceId =  newProduct==="1"? "price_1PIf8VLCP51AssL97X5LY1Ha":"price_1PIf9BLCP51AssL9CZmTQ7Aw"; 
    const customer_email = userId;
    const dataToProcess = { customer,customer_email, priceId }
    // alert("createStripeCustomerSuccessCb, customer_email: "+ customer_email)
    postCreateStripeCheckout({ axiosInstance: valuationsWebApi, method: 'POST', url: '/create-stripe-checkout', data: dataToProcess, requestConfig: { headers: {'Authorization': userId,},}},createStripeCheckoutSuccessCb, createStripeCheckoutErrorCb);
  }

  const updateUserErrorCb=(error)=>{
    // alert("Error in updateUserErrorCb: "+ error);
    setIsFetchingData(false);
    alert('There was a problem in the server. Please try later')
    history.push('/home');
  }

  const createStripeCustomerSuccessCb=(apiData)=>{
    customer = apiData.customer.id;
    const dataToProcess={id: userId, stripeCustomerId:apiData.customer.id}
    updateUser({ axiosInstance: valuationsWebApi, method: 'PUT', url: '/users', data: dataToProcess, requestConfig: { headers: {'Authorization': userId,},}}, updateUserSuccessCb, updateUserErrorCb);

    // const dataToProcess={customer,customer_email,priceId }
    // alert("createStripeCustomerSuccessCb, customer_email: "+ customer_email)
    // postCreateStripeCheckout({ axiosInstance: valuationsWebApi, method: 'POST', url: '/create-stripe-checkout', data: dataToProcess, requestConfig: { headers: {'Authorization': userId,},}},createStripeCheckoutSuccessCb, createStripeCheckoutErrorCb);
  }

  const createStripeCustomerErrorCb=(error)=>{
    // put an error message
    setIsFetchingData(false);
    alert("Error in createStripeCustomerErrorCb: "+ error)
  }

  const getUserStripeIdSuccessCb=(apiData)=>{
    if(apiData.stripeCustomerId !=="" && apiData.stripeCustomerId !== null){
      // If the user already has a stripe customer id
      const customer = apiData.stripeCustomerId;
      const customer_email = userId;
      //* refactorar: poner como variable de ambiente
      // alert("getUserStripeIdSuccessCb, customer_email: "+ customer_email + " customer: "+ customer)
      // const priceId = newProduct==="1"? "price_1Obky9LCP51AssL9aWOynFcH":"price_1ObkyuLCP51AssL9leFLKtKx"; 
      // const priceId =  newProduct==="1"? "price_1OdhEWLCP51AssL9vkCYk7iJ":"price_1OdhEyLCP51AssL9qZemHRcz"; 
      const priceId =  newProduct==="1"? "price_1PIf8VLCP51AssL97X5LY1Ha":"price_1PIf9BLCP51AssL9CZmTQ7Aw"; 
      const dataToProcess={customer,customer_email,priceId }
      // alert("getUserStripeIdSuccessCb, customer_email: "+ customer_email)
      localStorage.setItem('isUpgradeComplete', JSON.stringify(false));
      // proceed to stripe checkout
      postCreateStripeCheckout({ axiosInstance: valuationsWebApi, method: 'POST', url: '/create-stripe-checkout', data: dataToProcess, requestConfig: { headers: {'Authorization': userId,},}},createStripeCheckoutSuccessCb, createStripeCheckoutErrorCb);

    } else {
      // Crea el customer en Stripe recibiendo "customer.id" como parametro (ej. "cus_PQzb0dtXQjxo8R")
      const dataToProcess = { email: userId }
      postCreateStripeConsumer({ axiosInstance: valuationsWebApi, method: 'POST', url: '/create-stripe-customer', data: dataToProcess, requestConfig: { headers: {'Authorization': userId,},}},createStripeCustomerSuccessCb, createStripeCustomerErrorCb);
    }
  }

  const getUserStripeIdErrorCb=(error)=>{
    setIsFetchingData(true);
    // alert("Error in getUserStripeIdErrorCb: "+ error)
  }

  const handleCheckout=()=>{
  
    // Checks if the user already has a stripe customer id 
    localStorage.setItem('newProduct', newProduct);
    localStorage.setItem('userId', userId);
    localStorage.setItem('isUpgradingPlan', JSON.stringify(true));

    const userIdParam=userId;
    setIsFetchingData(true);
    getUserStripeId({ axiosInstance: valuationsWebApi, method: 'GET', url: `/users/${userIdParam}`, requestConfig: { headers: {'Authorization': userId,},}},getUserStripeIdSuccessCb, getUserStripeIdErrorCb);
  }

  const handleRadioValue=(e)=>{
    setNewProduct(e.target.value)
  }

  return (
    <>
    {/* { alert("isUpgradingPlan en render" + isUpgradingPlan)} */}
    <Grid container alignItems="center" justifyContent="center" spacing={1}  className={classes.gridContainerStyle}  >
      <Grid item >
        <Grow in timeout = {1000}>
          <Paper elevation={12} className={classes.paperStyle} style={{paddingBottom: "10px"}} > 
            <Typography align='center' gutterBottom  className={classes.titleStyle} >
              Upgrade Your Plan</Typography>   
              <Box className={classes.iconBox} >
                <MoveUpIcon className={classes.iconStyle} style={{ fontSize: 40 }}/>
              </Box>
              <Typography align='center' className={classes.textStyle} style={{fontSize:"14px"}}>What Will You Get:</Typography>
                <Grid item container>
                  <Grid item container xs={12}>
                    <Grid item xs={1}>
                      <FindInPageIcon className={classes.iconStyle} />
                    </Grid>
                    <Grid item xs={11}>
                      <Typography variant="caption" className={classes.textStyle}  >See all valuations published by other users.</Typography>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={1}>
                      <AddCircleIcon className={classes.iconStyle} />
                    </Grid>  
                    <Grid item xs={11}>
                      <Typography variant="caption" className={classes.textStyle}> Create unlimited valuations</Typography>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={1}>
                      <PublishIcon className={classes.iconStyle} />
                    </Grid>
                    <Grid item xs={11}>
                      <Typography variant="caption" className={classes.textStyle}>Publish unlimited valuations</Typography>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={1}>
                      <PictureAsPdfIcon className={classes.iconStyle} />
                    </Grid>
                    <Grid item xs={11}>
                      <Typography variant="caption" className={classes.textStyle}>Generate unlimited PDF files of your valuations</Typography>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={1}>
                      <PictureAsPdfIcon className={classes.iconStyle}/>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography variant="caption" className={classes.textStyle}>Generate unlimited PDF files of public valuations</Typography>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={1}>  
                      <StarRateIcon className={classes.iconStyle}/>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography variant="caption" className={classes.textStyle}>Rate and comment other users valuations</Typography>
                    </Grid>
                  </Grid>
              </Grid>
  
              <Box style={{marginTop:"10px", display:'flex', justifyContent:"center", marginLeft:"5px"}}> 
                <FormControl >
                <Box style={{height:"5px"}}/>
                  <FormLabel id="demo-controlled-radio-buttons-group" style={{fontSize:"12px", color:"black"}} >Choose your Plan:</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={newProduct}
                    onChange={handleRadioValue}
                  >
                    <FormControlLabel value="1" control={<Radio size="small" disableRipple />} label="Monthly Plan USD 29.90" />
                    <FormControlLabel value="2" control={<Radio size="small" disableRipple />} label="Annual Plan USD 287.00" />
                  </RadioGroup>
                </FormControl>
            </Box>
            <Box style={{height:"10px"}}/>
            <Grid item xs={12} display="flex" alignItems="center" justifyContent="center" style={{paddingTop:"0px"}}>
              <Button autofocus variant="contained" className={classes.buttonStyle} disableRipple color="primary" onClick={handleCancel}>
                Cancel 
              </Button>
              <Button autofocus variant="contained" className={classes.buttonStyle} disableRipple color="primary" onClick={handleCheckout}>
                Payment Checkout
              </Button>
            </Grid>
          </Paper>
        </Grow> 
      </Grid> 
    </Grid> 
    {isFetchingData && (
      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
        <CircularProgress />
      </div>
    )}
    </>
  )
}