import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Grid, Box, Typography, Button, Rating, Tooltip, Snackbar, SnackbarContent,Grow, useMediaQuery, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import SaveIcon from '@mui/icons-material/Save';
import PublishIcon from '@mui/icons-material/Publish';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteIcon from '@mui/icons-material/Delete';

import {LoginContext} from './../../../../helpers/Context';
import useFetch from './../../../../hooks/useFetch';
import DialogModal from './../../../../components/modals/DialogModal.jsx'
import DownloadDesktopReport from './DownloadDesktopReport.jsx';
import DownloadMobileReport from './DownloadMobileReport.jsx';

const useStyles = makeStyles( (mainTheme) => ({
buttonStyle: {
  // width:"80px",
  [mainTheme.breakpoints.down('sm')]: {
    fontSize: "10px"
  },
  paddingTop:"0px",
  color:mainTheme.palette.secondary.main,
  backgroundColor:"white",
  "&:hover": {
    color:"#CD7F32",
    backgroundColor: "whitesmoke"//"#F49506ed",
    // borderColor:"#F49506ed"
  },
},
textStyle:{
  marginTop:"3px",
  fontSize:"11px",
  color: mainTheme.palette.secondary.main,
},
greenSnackbarContent: {
  backgroundColor: "#228B22",//"#50C878",//'green',
},
}));

export default function OwnValuationButtons({ companyData, historicalFinancialData, forecastedFinancialData, combinedFinancialData, editMode, setEditMode, assumptions, calculatedCostOfCapital, valuation, setComment,  interactions, setInteractions, isCheckedDescOrder, isCheckedShowPreviousYears, isEstimateFcffOnly,isUserCostOfCapital, valuationDateInfo}){
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const { userData, savedValuationData } = useContext(LoginContext);
  const { userId } = userData;
  const { valuationId, published } = savedValuationData;
  const [ isDialogPdfOpen, setIsDialogPdfOpen] = useState(false);
  const [ isDialogMobileReportOpen, setIsDialgoMobileReportOpen] = useState(false)
  // const [ isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  // const [ valuationId, setValuationId ] = useState(savedValuationData.id);
  const { saveValuation, handlePublish, handleDelete, handleDialogClose, dialogOptions, isDialogOpen}  = useFetch({setEditMode});
  const history = useHistory();

  // const handleSnackbarClose= ()=>{
  //   setIsSnackbarOpen(false);
  // }

  const handleNewValuation=()=>{
    history.push('/valuation')
  }

  function errorCallback(errorMessage){
    // alert("error cb")
    // pq esta pasando por aca
    // alert (errorMessage)
    // setIsFavoriteBtn(false)
    // setDialogOptions({severity:"error", title:"Error", message:errorMessage,buttons:{button1:"Ok"}})
    // setIsDialogOpen (true);
  }

  const handlePrintValuation=()=>{
    if (isMobile || isTablet){
      setIsDialgoMobileReportOpen(true)
    } else {
      setIsDialogPdfOpen(true)  
    }
  }

  const handleMobileReportClose=()=>{
    setIsDialgoMobileReportOpen(false)
  }

  const handleDialogPdfClose=()=>{
    setIsDialogPdfOpen(false);
  }

  return (
  <>
  <Grid container direction = "row" spacing = {1} style={{paddingTop:"0px"}} >

      <Grid item xs={12} sm = {12} >
        <Box sx={{height:"15px"}}/>
        <Box display ="flex" flexDirection="row" justifyContent="flex-end">
          <Tooltip title="Create a new valuation">
            <Button variant = "text" disableRipple 
              className = {classes.buttonStyle}
            // sx={{backgroundColor:"green"}} 
              startIcon={<AddCircleIcon />} 
              // disabled={editMode==="blank"}
              onClick = {handleNewValuation}
              >New
            </Button>
          </Tooltip>
          {/* <Tooltip title="Save this valuation">
            <Button variant = "text" disableRipple className = {classes.buttonStyle} 
              startIcon={<SaveIcon />} 
              disabled={true}
              // onClick ={handleSave}
              >Save 
            </Button>
          </Tooltip> */}
          <Tooltip title="Make this valuation visible to other users">
            <Button variant = "text" disableRipple className = {classes.buttonStyle} 
              startIcon={<PublishIcon />} 
              disabled = {published!==null && published!==""}
              onClick={() => (handlePublish (savedValuationData.valuationId))} 
              >Publish 
            </Button>
          </Tooltip>
          <Tooltip title="Delete this valuation">
            <Button variant = "text" disableRipple className = {classes.buttonStyle} 
              startIcon={<DeleteIcon />} 
              disabled = {false}
              onClick={(e) => (handleDelete (savedValuationData.valuationId))} 
              >Delete
            </Button>
          </Tooltip>
          <Tooltip title="Generate a pdf file to print or send to your contacts">
            <Button variant = "text" disableRipple className = {classes.buttonStyle} 
              startIcon={<CloudDownloadIcon />} 
              onClick = {handlePrintValuation} 
              disabled={isTablet}
              >Download
            </Button>
          </Tooltip>
        </Box>  
    </Grid>
  </Grid>
  <DialogModal open={isDialogOpen} onClose={handleDialogClose} severity={dialogOptions.severity} title={dialogOptions.title} buttons={dialogOptions.buttons} action={dialogOptions.action}>
    {dialogOptions.message}
  </DialogModal> 
  <DownloadDesktopReport
    open={isDialogPdfOpen} 
    onClose={handleDialogPdfClose} 
    companyData={companyData}
    historicalFinancialData={historicalFinancialData}
    combinedFinancialData={combinedFinancialData} 
    assumptions={assumptions} 
    calculatedCostOfCapital={calculatedCostOfCapital} 
    valuation={valuation}
    // savedValuationData={savedValuationData}
    isCheckedDescOrder={isCheckedDescOrder}
    isCheckedShowPreviousYears={isCheckedShowPreviousYears}
    isEstimateFcffOnly={isEstimateFcffOnly}
    isUserCostOfCapital={isUserCostOfCapital}
    valuationDateInfo={valuationDateInfo}
  />
  <DownloadMobileReport
    open={isDialogMobileReportOpen} 
    onClose={handleMobileReportClose} 
    companyData={companyData}
    historicalFinancialData={historicalFinancialData}
    combinedFinancialData={combinedFinancialData} 
    assumptions={assumptions} 
    calculatedCostOfCapital={calculatedCostOfCapital} 
    valuation={valuation}
    // savedValuationData={savedValuationData}
    isCheckedDescOrder={isCheckedDescOrder}
    isCheckedShowPreviousYears={isCheckedShowPreviousYears}
    isEstimateFcffOnly={isEstimateFcffOnly}
    isUserCostOfCapital={isUserCostOfCapital}
    valuationDateInfo={valuationDateInfo}
  />
  </>
)
}