import React, { memo, useContext }from 'react';

import { Box, Grid, Hidden, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { LoginContext } from '../helpers/Context';

const useStyles = makeStyles((mainTheme) => ({
  footerStyle: {
    bottom:"0px",
    left:"0px",
    width: "100%",
    height:"25px",
    // height:"auto",
    position:"fixed",
    // padding: "5px",  
    // paddingTop: "2px",  
    // paddingBottom: "2px",  
    // fontSize:"8px",
    // color: "#F9AA33",
    paddingLeft:"5px",
    paddingRight:"5px",
    paddingBottom:"0px",
    marginBottom:"0px",
    backgroundColor: "#344955"
  }
})
);

function Footer() {

  const classes = useStyles();
  const { userData } = useContext(LoginContext);
  return (
    <>
    <Box className={classes.footerStyle}>
      <Grid container direction="row" xs={12}>
        <Hidden smDown>
          <Grid item xs={12} md={4}>
            <Typography variant="caption" align="left" style={{fontSize:11, color:"#F9AA33"}}>Avenida Paulista nº 1.471, Conj. 511, Bela Vista, São Paulo-SP, Brasil</Typography>
          </Grid>
        </Hidden>
        <Grid item xs={12} md={4} align="center">
          { userData.userId !=="" ?
            <Typography variant="caption" align="center"  style={{fontSize:11,color:"#F9AA33"}}>E-mail: valuationshub@gmail.com</Typography>
          :
          <Typography variant="caption" align="center"  style={{fontSize:11,color:"#F9AA33"}}>E-mail: valuationshub@gmail.com</Typography>
        }
            {/* <Typography variant="caption" align="center">E-mail: info@valuationshub.com</Typography> */}
        </Grid>
        <Hidden smDown>
          <Grid item xs={12} md={4} align="right">
            <Typography variant="caption" align="right"  style={{fontSize:11,color:"#F9AA33"}}>@Copyright 2024, Martin Calcena Simoes Lopes</Typography>
            {/* <Typography variant="caption" align="right"  style={{fontSize:11,color:"#F9AA33"}}>@Copyright 2024, Hekove Tecnologia S.A.</Typography> */}

          </Grid>
        </Hidden>
      </Grid>
    </Box>
    </>  
)}

export default memo(Footer);