import React from 'react';

import { Paper, Grid, FormControlLabel, Checkbox } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles( (mainTheme) => ({
  textCheckboxStyle:{
    color:mainTheme.palette.primary.main,
    margingLeft: "20px",
    marginRight: "5px",
    paddingRight: "2px",
    fontSize:"9px"
  },  
}));
export default function CheckboxEstimateTtmAsCurrYear ({isTtmAsCurrYear, setIsTtmAsCurrYear}){
  const classes = useStyles();
  const currentYear = new Date().getFullYear();  

  function handleChangeChkBox(){
    if (isTtmAsCurrYear) {
      setIsTtmAsCurrYear(false);
    } else {
      setIsTtmAsCurrYear(true);
    }
  }

  return (
    <>
    <Paper elevation = {3}>
      <Grid container>
        <Grid item>
          <FormControlLabel 
            className = {classes.textCheckboxStyle}
            disabled = {true}
            control = {<Checkbox disableRipple style={{ paddingLeft: '20px' }}  defaultChecked = {false} checked = {isTtmAsCurrYear} size = "small"/>} 
            label = {`Use Trailing Twelve Months as ${currentYear} Forecast`}
            onChange = {handleChangeChkBox}
          />
        </Grid>
      </Grid>
    </Paper>
    </>
  )
}