import React from "react";

import { Bar } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";

import { Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((mainTheme) => ({
  paperStyle: {
    [mainTheme.breakpoints.down('sm')]: {
      // maxWidth: "350px",
      // margin:"5px"
    },
  }
  }));

export default function ChartFreeCashFlow ({ combinedFinancialData, assumptions, isCheckedShowPreviousYears, isCheckedDescOrder }) {
  const classes = useStyles();
  const theme = useTheme();

  const defineChartColors=()=>{
    const arrayOfForecastedColors = Array.from({ length: assumptions.cashFlowDiscretePeriod } , () => (theme.palette.primary.main));
    const arrayOfHistoricalColors = Array.from({ length: 3 } , () => (theme.palette.tertiary.main));  
    if (isCheckedShowPreviousYears){
      if (isCheckedDescOrder){
        const arrayOfColorsDesc = arrayOfForecastedColors.concat(arrayOfHistoricalColors);
        return arrayOfColorsDesc
      }
      const arrayOfColorsAsc = arrayOfHistoricalColors.concat(arrayOfForecastedColors);
      return arrayOfColorsAsc
    } else {
        return arrayOfForecastedColors
    }
  }

  const chartData = {
        labels:combinedFinancialData.map((currElement)=>(currElement.year)),
        datasets:[{label:"Free Cash Flow to the Firm (in Us$ b)", data:combinedFinancialData.map((currElement)=> (currElement.cashFlow)),
        backgroundColor:defineChartColors(),
      }]}

  const chartOptions = {
    plugins: {
      legend: {
        labels: {
          boxWidth: 0,
        }
      },
      }
    }

  return (
    <>
    <Paper elevation={3} className={classes.paperStyle}>
      {combinedFinancialData ? <>
        <Bar data={chartData} options={chartOptions} />
        </>  
      : null}
    </Paper>

    </>
  )
}
