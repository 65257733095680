import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs'
// import { format, parse } from 'date-fns';
// import DateFnsUtils from '@date-io/date-fns';

import { Paper, Grid, Dialog, DialogActions, DialogContent, DialogContentText, Box, Tooltip, Button, Typography, TextField, Avatar, useTheme, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { LoginContext } from '../../helpers/Context';
import useAxios from '../../hooks/useAxios.js';
import valuationsWebApi from '../../services/valuationsWebApi';
import useFetch from '../../hooks/useFetch';
import useForm from '../../hooks/useForm';
import DialogModal from '../../components/modals/DialogModal.jsx';

import ShowUserImageByUserId from '../../components/ShowUserImageByUserId';
import CountryAutocomplete from '../../components/CountryAutocomplete.jsx';
// import { ReactSearchAutocomplete } from 'react-search-autocomplete';

const useStyles = makeStyles( (mainTheme) => ({
  modalStyle:{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    height: 800,
    backgroundColor:"white",
    // border: '2px solid #000',
    // boxShadow: 24,
    // p: 4,
  },
  contentStyle: {
    position: 'absolute',
    top: '65px'
  },
  paperStyle: {
    // maxWidth:"350px"
    width:"350px",
    // height: 900,
  },
  textStyle:{
    marginLeft:"12px",
    fontSize:"12px",
    color:mainTheme.palette.primary.main
  },
  unsubscribedTextStyle:{
    marginRight:"3px",
    fontSize:"9px",
    color:mainTheme.palette.primary.main
  },
  sectionTitleStyle:{
    fontSize: mainTheme.sectionTitle.fontSize,
    color: mainTheme.sectionTitle.color,
    marginTop:mainTheme.sectionTitle.marginTop,
    marginLeft:mainTheme.sectionTitle.marginLeft,
    marginBottom:"5px"
  },
  userPhotoStyle:{
    width:"68px",
    height:"68px"
  },
  buttonStyle: {
    marginLeft:"10px",
    width:"100px",
    [mainTheme.breakpoints.down('sm')]: {
      marginLeft:"2px",
      fontSize: "8px",
      width:"70px",
    },
  },
  passwordButtonStyle: {
    marginLeft:"10px",
    width:"120px",
    [mainTheme.breakpoints.down('sm')]: {
      fontSize: "9px",
      marginLeft:"6px",
      width:"95px",
    },
  },
}));

export default function FormEditProfile({ open, onClose }){
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { userData, setUserData, usersImagesList, usersList, setUsersList, setUsersImagesList, isUpgradingPlan, setIsUpgradingPlan } = useContext(LoginContext);
  const { userId, userPassword, userProduct, userFirstName, userLastName, userCountry, userCountryName, userPhone, userBirthday, userDescription } = userData
  const countryObject = {id: userCountry, name: userCountryName}
  const [ userImageBase64, setUserImageBase64] = useState(null);
  const [ userImageFile, setUserImageFile] = useState(null);
  const [ isEditField, setIsEditField] = useState(false)
  const [ isDialogUnsubscribeOpen, setIsDialogUnsubscribeOpen ] = useState(false);
  const [ dialogUnsubscribeOptions, setDialogUnsubscribeOptions ] = useState({severity:"",title:"",message:"",buttons:{},action:""});
  const { handleChange, isValidName, isValidPhone, noBlanks, isValidCurrUser, registerData, setRegisterData, formErrors, setFormErrors, isDialogOpen, handleDialogClose, dialogOptions} = useForm ();
  const { axiosFetch: delUser, isLoading: isLoadingDelUser, error: isErrorDelUser } = useAxios();

  const [ editMode, setEditMode] = useState("")
  const { updateUser } = useFetch (setEditMode);
  let userRegistrationData={id:"", password:"", product:"", firstName:"", lastName:"", phone:"", birthday:null, occupation:"", company:"", email:"", country:"", city:"", description:"", website:"", twitter:"", linkedin:"", facebook:"", instagram:"", youtube:"", tiktok:""}

  const history = useHistory();

  const checkFormErrors=()=>{
    if (formErrors.id !=="" || formErrors.password !=="" ){
      return true
    }
    return false
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      handleCancel();
    }
  };

  const handleUpgrade=()=>{
    setIsUpgradingPlan(true)
    // localStorage.setItem('isUpgradingPlan', JSON.stringify(true));
    history.push('/upgrade')
  }

  const handleCancel=()=>{
    setRegisterData (prevState => ({...prevState, id:userId, password: userPassword, firstName:userFirstName, lastName:userLastName, phone:userPhone, birthday: userBirthday, country:userCountry, countryName:userCountryName, description: userDescription}))
    setUserImageFile(null);
    setUserImageBase64(null);
    setIsEditField(false);
    onClose()
  }

  const handleChangePassword=()=>{
    history.push('/reset')
  }

  const nameOfProduct=(product)=>{
    // console.log("product in FormEditProfile" + product)
    if (product==="0"){
      return "Free Plan" 
    } else if(product==="1") {
      return "Monthly Plan" 
    } else if(product==="2") {
      return "Annual Plan" 
    } else if(product==="9") {
      return "Unsubscribed" 
    }  
  }

  const checkEditChanges=()=> {// solamente define la variable isChangedField
    let isChangedField = false
    setIsEditField(false)
    const modifiedObjects = [];
    if (registerData.firstName !== userData.userFirstName){
      modifiedObjects.push(registerData.firstName)
      isChangedField=true
    }
    if (registerData.lastName !== userData.userLastName){
      modifiedObjects.push(registerData.lastName)
      if (! isChangedField){
        isChangedField=true
      } 
    }
    if (registerData.country !== userData.userCountry){
      modifiedObjects.push(registerData.country)
      if (! isChangedField){
        isChangedField=true
      } 
    }
    if (registerData.phone !== userData.userPhone){
      modifiedObjects.push(registerData.phone)
      if (! isChangedField){
        isChangedField=true
      } 
    }
    // if( registerData.birthday !== dayjs(userData.userBirthday)){
    //   modifiedObjects.push(registerData.birthday)
    //   if (! isChangedField){
    //     isChangedField=true
    //   } 
    // }
    if ( registerData.description !== userData.userDescription){
      modifiedObjects.push(registerData.description)
      if (! isChangedField){
        isChangedField=true
      } 
    }
    if (isChangedField){
      setIsEditField(true)
    }
    return modifiedObjects
  }

  const updateUsersImagesList=(image, id)=>{
    setUsersImagesList(prevList => {
      return prevList.map(user => {
        if (user.userId === id) {
          return {...user, image: image };
        }
        return user;
      });
    });
  }

  const handlePicture = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64Image = event.target.result;
        setUserImageFile(file);
        setUserImageBase64(base64Image);
        setIsEditField(true);
      };
      reader.readAsDataURL(file);
    }
  };


  function isValidDate(dateString) {
    // Check if the dateString is not null and is a non-empty string
    if (dateString && dateString.trim() !== '') {
      // Attempt to create a Date object from the dateString
      const dateObject = new Date(dateString);
  
      // Check if the dateObject is a valid date
      return !isNaN(dateObject.getTime());
    }
  
    // If dateString is null or an empty string, consider it invalid
    return false;
  }

  // const deleteUserSuccessCb=()=>{
  //   const updatedProfiles = usersList.filter(profile => profile.profileId !== userId);
  //   setUsersList(updatedProfiles)
  // }

  // const deleteUserErrorCb=()=>{
  //   console.log("Error deleting user from database")
  // }

  // const handleDialogUnsubscribeClose=(value)=>{
  //   setIsDialogUnsubscribeOpen(false)
  //   if (value === "Unsubscribe"){

  //     delUser({ axiosInstance: valuationsWebApi, method: 'DELETE', url: `/users/${userId}`, requestConfig: { headers: {'Authorization': userId,},}},deleteUserSuccessCb, deleteUserErrorCb);

  //     history.push('/logout')
  //   } else if ( value==="Cancel"){
  //       handleCancel();
  //   } else {
  //     userRegistrationData.id = registerData.id;
  //     userRegistrationData.password = registerData.password;
  //     userRegistrationData.product = "0";
  //     userRegistrationData.firstName = registerData.firstName;
  //     userRegistrationData.lastName = registerData.lastName;
  //     userRegistrationData.phone = registerData.phone;
  //     userRegistrationData.email = registerData.id;
  //     userRegistrationData.country = registerData.country
  //     userRegistrationData.description = registerData.description;
  //     updateUser({userRegistrationData});
      
  //   }
  //   setDialogUnsubscribeOptions({severity:"",title:"",message:"",buttons:{},action:""});
  // }

  const handleUnsubscribe=()=>{
    history.push('/unsubscribe')
  //   alert("handleUnsubscribe, userProduct: " + userProduct)
  //   if (userProduct==="0"){
  //     setDialogUnsubscribeOptions({severity:"warning",title:"Are You Sure You Want to Cancel Your Subscription?",message:"We're sorry to see you go! Before you proceed, let us share some benefits you might be missing out on: Create and share valuations, quiclky and easily. See valuations from other users to get insights. Interact with other users from our community. If there's anything specific you're unhappy with, please let us know, and we'll do our best to address your concerns.If you still decide to cancel, we appreciate your time with us and hope you consider coming back in the future. Thank you for being a part of our community!",buttons:{button1:"Cancel",button2:"Unsubscribe"},action:"unsubscribe"});
  //   } else {
  //     setDialogUnsubscribeOptions({severity:"warning",title:"Are You Sure You Want to Cancel Your Subscription?",message:"We're sorry to see you go! Before you proceed, let us share some benefits you might be missing out on: Full access to create and share valuations, quiclky and easily. See valuations from other users to get insights. Interact with other users from our community. If there's anything specific you're unhappy with, please let us know, and we'll do our best to address your concerns.If you still decide to cancel, we appreciate your time with us and hope you consider coming back in the future. Thank you for being a part of our community!",buttons:{button1:"Try Free Plan",button2:"Unsubscribe"},action:"unsubscribe"});

  //   }
  //   setIsDialogUnsubscribeOpen(true)
  }

  const handleSaveChanges = async () => {
  
    if (! checkFormErrors() ) {
      userRegistrationData.id = registerData.id;
      userRegistrationData.password = registerData.password;
      userRegistrationData.product = registerData.product;
      userRegistrationData.firstName = registerData.firstName;
      userRegistrationData.lastName = registerData.lastName;
      // Checar si eh uma data valida....
      // userRegistrationData.birthday =isValidDate(registerData.birthday)? registerData.birthday.format('YYYY/MM/DD'):"";
      userRegistrationData.phone = registerData.phone;
      userRegistrationData.email = registerData.id;
      userRegistrationData.country = registerData.country
      userRegistrationData.description = registerData.description;
      if (userImageFile){
        saveUploadedFile(userImageFile, registerData.id );        // Graba la nueva foto en el servidor
      }
      if (userImageBase64){
        updateUsersImagesList(userImageBase64, registerData.id ); // actualiza el state para mostrar la nueva foto
      }
      //Actualiza el state de datos del usuario con los cambios
      setUserData ( prevState => ({...prevState, 
        userFirstName:registerData.firstName, 
        userLastName:registerData.lastName, 
        userPhone:registerData.phone, 
        // userBirthday:registerData.birthday.format("YYYY/MM/DD"), // Convierte el date object a string
        userCountry:registerData.country, 
        userCountryName: registerData.countryName, 
        userDescription: registerData.description}))
      updateUser({userRegistrationData}); // Graba las alteraciones en la base de datos
      // limpia los states locales
      // setRegisterData({ id:"",  password:"", confirmPassword:"", product:"",firstName:"",lastName:"", country:"", countryName:"", phone:"", birthday:null, occupation:"", company:"",email:"", city:"", description:"", website:"", twitter:"", linkedin:"", facebook:"", instagram:"", youtube:"", tiktok:"", photo:null, banner:null})
      setIsEditField(false);
      setUserImageFile(null);
      setUserImageBase64(null);
      onClose(); // Cierra el FormEditProfile
    } else {
      //Refactorar...Crear error handler
      console.log("Not saved in database")
    }
  };

  // Muestra solo los 2 ultimos caracteres del password
  const hidePassword=(password)=> {
    if (typeof password !== 'string' || password.length < 3) {
      return 'Invalid Password';
    }
    const visibleChars = password.slice(-12); //2
    const hiddenChars = '*'.repeat(password.length - 12); //2
    return hiddenChars + visibleChars;
  }

  // Graba el archivo de imagen en el servidor, a traves de la API
  const saveUploadedFile = async (file, fileName) => {
    const formData = new FormData();
    formData.append('name',fileName)
    formData.append('image', file);
    try {
      const response = await valuationsWebApi.put('/user-image', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const imageUrl = response.data.imageUrl; 
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  useEffect(() => {
    setRegisterData (prevState => ({...prevState, id:userId, password: userPassword, product: userProduct, firstName:userFirstName, lastName:userLastName, phone:userPhone, birthday: dayjs(userBirthday), country:userCountry, countryName:userCountryName, description: userDescription}))
  }, [open]);

  return (
  <>
  <Paper className={classes.paperStyle}>
    <Dialog 
      open={open}
      onClose={onClose}
      onKeyDown={handleKeyDown}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth={"xs"}
      >
        <DialogContent style={{color:'white'}}>
          <DialogContentText id="alert-dialog-description">
            <Typography className={classes.sectionTitleStyle}>Edit My Profile</Typography>
            
            <Grid container direction='row'>
              <Grid item xs={4} sm={3}>
                <Box sx={{marginLeft:"10px"}}>
                  { userImageBase64 ? // Si no se cargo una nueva foto, muestra la actual....
                    <Avatar alt="Uploaded Image" 
                      src={userImageBase64 ? userImageBase64: null} className={classes.userPhotoStyle} />
                  : 
                    <ShowUserImageByUserId 
                      userId={userId} 
                      usersImages={usersImagesList} 
                      size={"large"}
                    /> 
                  }
                </Box>
              </Grid>
                
              <Grid item container direction="column" xs={8} sm={9} >
                <Grid item >
                  <Box sx={{height:"15px"}}/>
                  <Button variant="contained" component="label" disableRipple startIcon={<AddAPhotoIcon />}>
                    Change Photo
                    <input
                      type="file"
                      accept="image/*"
                      hidden
                      autoComplete="off"
                      onChange={(e) => handlePicture(e)}
                    />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Box sx={{height:"10px"}}/>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={7}>
                <Typography align="left"  className={classes.textStyle} gutterBottom>User:</Typography>
                <Typography align="left"  className={classes.textStyle} gutterBottom>{`${registerData.id}`}</Typography>
              </Grid>

              <Grid item xs={12} sm={5}>
                <Box style={{display:"flex", justifyContent:isMobile? "center": "flex-end"}}>
                  {/* { registerData.product ==="1" || registerData.product==="2" ?
                  <>
                  <Tooltip title="Cancel your current Plan">
                    <Button variant="contained" onClick={handleUnsubscribe} disableRipple>Unsubscribe</Button>
                  </Tooltip>
                  </>
                  : 
                  <>
                  <Tooltip title="Upgrade your Free Plan to have full access">
                    <Button variant="contained" disableRipple onClick={handleUpgrade}>Upgrade</Button>  
                  </Tooltip>         
                  </>
                  } */}
                  { userProduct !=="9"? 
                    <Tooltip title="Cancel your current Plan">
                      <Button variant="contained" onClick={handleUnsubscribe} disableRipple style={{width:"80px"}}>Unsubscribe</Button>
                    </Tooltip>                  
                  : 
                  <Typography align="right"  className={classes.unsubscribedTextStyle} gutterBottom>Unsubscribed on 2024-02-09</Typography>                  
                  // null
                  }

                  { registerData.product !=="1" && registerData.product!=="2" ? 
                    <Tooltip title="Upgrade your Free Plan to have full access">
                      <Button variant="contained" disableRipple onClick={handleUpgrade} style={{width:"80px"}}>Upgrade</Button>  
                    </Tooltip>               
                  : <>
                    {/* <Typography align="right"  className={classes.textStyle} gutterBottom>Your Plan:</Typography> */}
                    <Typography align="right"  className={classes.textStyle} style={{fontSize:9}} gutterBottom>{nameOfProduct(registerData.product)}</Typography>                  
                  </>

                  }
                  </Box>

              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField  variant ="outlined" margin="dense" size="small" fullWidth
                  label="First Name"
                  name="firstName"
                  value={registerData.firstName}
                  autoComplete="first-name"
                  onChange={ (e) => {
                    handleChange (e,setRegisterData,[isValidName]);
                  }}
                  onBlur={() => checkEditChanges()}
                  error={formErrors.firstName}
                />
                {formErrors.firstName ? <div className="error-helper-text">{formErrors.firstName}</div> : null}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField variant ="outlined" margin="dense" size="small" fullWidth
                  label="Last Name"
                  name="lastName"
                  value={registerData.lastName}
                  autoComplete="last-name"
                  onChange={ (e) => {
                    handleChange (e,setRegisterData,[isValidName]);
                  }}
                  onBlur={() => checkEditChanges()}
                  error={formErrors.lastName}
                />
                {formErrors.lastName ? <div className="error-helper-text">{formErrors.lastName}</div> : null}
              </Grid>
              <Grid item xs={12} sm={12} >
                <CountryAutocomplete 
                  countryObject={countryObject} 
                  registerData={registerData}
                  setRegisterData={setRegisterData}
                  isEditField={isEditField} 
                  setIsEditField={setIsEditField}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <TextField variant ="outlined" margin="dense" size="small" fullWidth
                  label="Phone"
                  name="phone"
                  value={registerData.phone}
                  autoComplete="phone"
                  onChange={ (e) => {
                    handleChange (e,setRegisterData,[isValidPhone]);
                  }}
                  onBlur={() => checkEditChanges()}
                  error={formErrors.phone}
                />
                {formErrors.phone ? <div className="error-helper-text">{formErrors.phone}</div> : null}
              </Grid>
              {/* <Grid item xs={6} sm={6} >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker disableFuture format="YYYY/MM/DD" 
                    label = "Birthday"
                    name = "birthday"
                    value = {registerData.birthday}
                    onChange={ (date) => {
                      setIsEditField (true)
                      setRegisterData (prevState => ({...prevState, birthday:date}))
                    }}
                    slotProps = {{ textField: {  variant: 'outlined' , size: "small", margin:"dense"}}}/>
                </LocalizationProvider>
              </Grid> */}
              <Grid item xs={12} sm={12}>
                <TextField variant ="outlined" margin="dense" size="small" fullWidth
                  label="Description"
                  name="description"
                  value={registerData.description}
                  autoComplete="description"
                  multiline
                  rows={3}
                  onChange={ (e) => {
                    handleChange (e,setRegisterData,[noBlanks]);
                  }}
                  onBlur={() => checkEditChanges()}
                  error={formErrors.description}
                />
                {formErrors.description ? <div className="error-helper-text">{formErrors.description}</div> : null}
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions >
          <Grid container direction="row"  >
            <Grid item xs={4} style={{textAlign:'left'}}>
              <Button disableRipple className={classes.buttonStyle} onClick={handleCancel} >Cancel</Button>
            </Grid>
            <Grid item xs={4} style={{textAlign:'left'}}>
              <Button onClick={handleChangePassword} disableRipple className={classes.passwordButtonStyle} >
                Change Password</Button>
            </Grid>
            <Grid item xs={4} style={{textAlign:'right'}}>
              <Button onClick={handleSaveChanges} disableRipple disabled={!isEditField} className={classes.buttonStyle} >
                Save</Button>
            </Grid>
          </Grid> 
          <Box style={{height: "60px"}}/>
        </DialogActions>
    </Dialog>
  </Paper>
  {/* <DialogModal open={isDialogUnsubscribeOpen} onClose={handleDialogUnsubscribeClose} severity={dialogUnsubscribeOptions.severity} title={dialogUnsubscribeOptions.title} buttons={dialogUnsubscribeOptions.buttons} action={dialogOptions.action}>
      {dialogUnsubscribeOptions.message}
  </DialogModal> */}
  </>
  )  
}