import React, { useState, useContext } from 'react';

import { Grid, Paper, Box, Button, Typography} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';

import useAxios from '../../../hooks/useAxios.js';
import valuationsWebApi from '../../../services/valuationsWebApi.js';
import { LoginContext } from '../../../helpers/Context.js';

const useStyles = makeStyles( (mainTheme) => ({
  buttonStyle:{
    color: "white",
    backgroundColor:mainTheme.palette.primary.main,
    textTransform:"none",
    fontSize:12,
    margin: "10px",
    //marginTop: "15px",
    "&:hover": {
      color:mainTheme.palette.secondary.main,
      backgroundColor:mainTheme.palette.primary.main,
    },
  },
  }))

export default function ShowLatestFavorites (){
  const classes = useStyles();
  const { userData } = useContext(LoginContext);
  const { userId } = userData;
  const [ savedFavoritesList, setSavedFavoritesList ] = useState([])
  const { axiosFetch: getAllFavorites, isLoading: isLoadingAllValuations, error: isErrorAllValuations } = useAxios();

  const getAllFavoritesSuccessCb=(apiData)=>{ 
    const dataFromApi = apiData.map(item => ({
      ...item,
      updatedAt: new Date(item.updatedAt)
    }));
    const sortedArrayDescending = dataFromApi.sort((a, b) => b.updatedAt - a.updatedAt);
    setSavedFavoritesList(sortedArrayDescending)
  }

  const getAllFavoritesErrorCb=(apiData)=>{
    // alert("Error fetching valuations")
  }

  const getSavedFavorites=()=>{
    getAllFavorites({ axiosInstance: valuationsWebApi, method: 'GET', url: '/favorites', requestConfig: { headers: {'Authorization': userId,},}},getAllFavoritesSuccessCb, getAllFavoritesErrorCb);
  }

  return ( 
    <>
    { userId==="martincsl@hotmail.com" ? 
      <>
      <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" >
    
          <Grid item xs={12} md={6} >
            <Paper className={classes.paper} elevation={12}>
              <Button 
                variant = "contained" 
                disableRipple
                onClick = {getSavedFavorites} 
                className = {classes.buttonStyle}
                >Get Latest Favorites
              </Button>
            </Paper>
            <Box style={{height:"10px", backgroundColor:"green"}}/>
          </Grid>
          { savedFavoritesList.length > 0 ? 
            
            <Paper elevation={12}>
              <Typography>{`Total de Favorites: ${savedFavoritesList.length}`}</Typography>
                <>
                  { savedFavoritesList.map ((currValuation)=>(<>
                    <Typography>{`ValuationId${currValuation.valuationId} from ${currValuation.profileId} updated at ${currValuation.updatedAt}}`}</Typography>
                    </>
                  ))}
                </>
            </Paper>
          : null}
      </Grid>
    </>
    : 
    null }
  </>
  )
}