import React from 'react';

import { Grid, Box, TextField } from '@mui/material';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import CountryAutocomplete from '../../components/CountryAutocomplete';

export default function PersonalData({ registerData, setRegisterData, useForm, isEditField, setIsEditField }){
  const { handleChange, isValidName, isValidPhone, formErrors } = useForm ();
  const countryObject = {id: registerData.country, name: registerData.countryName}


  return (
    <>
    <Grid container spacing={2}>
      <Grid item xs={6} sm={6}>
        <TextField autoFocus 
          variant ="filled" margin="dense" size="small" fullWidth  
          label="First Name"
          name="firstName"
          value={registerData.firstName}
          autoComplete="first-name"
          onChange={ (e) => {
            handleChange (e,setRegisterData,[isValidName]);
          }}
          // inputProps={{style: {textTransform: 'capitalize'}}}
          inputProps={{style: {fontSize: 14}}} 
          error={formErrors.firstName}
        />
        {formErrors.firstName ? <div className="error-helper-text">{formErrors.firstName}</div> : null}
      </Grid>
      <Grid item xs={6} sm={6}>
        <TextField 
          variant ="filled" margin="dense" size="small" fullWidth  
          label="Last Name"
          name="lastName"
          value={registerData.lastName}
          autoComplete="last-name"
          onChange={ (e) => {
            handleChange (e,setRegisterData,[isValidName]);
          }}
          // inputProps={{style: {textTransform: 'capitalize'}}}
          inputProps={{style: {fontSize: 14}}} 
          error={formErrors.lastName}
        />
        {formErrors.lastName ? <div className="error-helper-text">{formErrors.lastName}</div> : null}
      </Grid>
      <Grid item xs={12} sm={12} >
        <Box style={{backgroundColor:"white"}}>
        <CountryAutocomplete 
          countryObject={countryObject}
          registerData={registerData}
          setRegisterData={setRegisterData}
          isEditField={isEditField}
          setIsEditField={setIsEditField}
          variant="filled"
        />
        </Box>
      </Grid>
      <Grid item xs={6} sm={6}>
        <TextField 
          variant ="filled" margin="dense" size="small" fullWidth  
          label="Phone"
          name="phone"
          value={registerData.phone}
          autoComplete="phone"
          onChange={ (e) => {
            handleChange (e,setRegisterData,[isValidPhone]);
          }}
          inputProps={{style: {fontSize: 14}}} 
          error={formErrors.phone}
        />
        {formErrors.phone ? <div className="error-helper-text">{formErrors.phone}</div> : null}
      </Grid>
      <Grid item xs={6} sm={6} >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker disableFuture format="YYYY/MM/DD" 
            label = "Birthday"
            name = "birthday"
            value = {registerData.birthday}
            onChange={ (date) => {
              setRegisterData (prevState => ({...prevState, birthday:date}))
            }}
            // slotProps = {{ textField: { variant: 'outlined' , size: "small", margin:"dense"}}}/>
            slotProps = {{ textField: { variant :"filled", margin:"dense", size:"small"}}}
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  </>
  )
}