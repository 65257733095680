import React, { useState, useContext, useEffect } from 'react';

import { Grid, Box, Typography, Button, Rating, Tooltip, Snackbar, SnackbarContent, Grow, useMediaQuery, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import YouTubeIcon from '@mui/icons-material/YouTube';

import CommentIcon from '@mui/icons-material/Comment';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
// import ShareIcon from '@mui/icons-material/Share';

import {LoginContext} from './../../../../helpers/Context';
import useFetch from './../../../../hooks/useFetch';
import useAxios from './../../../../hooks/useAxios';
import valuationsWebApi from './../../../../services/valuationsWebApi.js';
import DialogModal from './../../../../components/modals/DialogModal.jsx';
import CreateCommentModal from '../CreateCommentModal';
// import CommentModal from './../CommentModal.jsx';
import DownloadDesktopReport from './DownloadDesktopReport';
import DownloadMobileReport from './DownloadMobileReport.jsx';


const useStyles = makeStyles( (mainTheme) => ({
buttonStyle: {
  // width:"80px",
  [mainTheme.breakpoints.down('sm')]: {
    fontSize: "10px"
  },
  paddingTop:"0px",
  color:mainTheme.palette.secondary.main,
  backgroundColor:"white",
  "&:hover": {
    color:"#CD7F32",
    backgroundColor: "whitesmoke"//"#F49506ed",
    // borderColor:"#F49506ed"
  },
},
textStyle:{
  marginTop:"3px",
  fontSize:"12px",
  color: mainTheme.palette.tertiary.main,
},
iconStyle:{
  fontSize:24, 
  marginLeft:"0px",
  color:mainTheme.palette.secondary.main,
  '&:hover': {
    color: "#F49506ed", 
  },
},
greenSnackbarContent: {
  backgroundColor: "#228B22",//"#50C878",//'green',
},
}));

// Llamada por SavedInfoPanel->SavedButtons->                                                                                                                                                                                    
export default function OtherUserValuationButtons({ companyData, historicalFinancialData, combinedFinancialData,setEditMode, assumptions, calculatedCostOfCapital, valuation, handleNewValuation, interactions, setInteractions, isCheckedDescOrder, isCheckedShowPreviousYears, isEstimateFcffOnly, isUserCostOfCapital,valuationDateInfo }){
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const youtubeLink = "https://www.youtube.com/watch?v=4ECoNgBvgSo&t=17s";
  const { userData, savedValuationData, valuationsList, setValuationsList, isValuationSample } = useContext(LoginContext);
  const { userId, userProduct } = userData;
  const { valuationId } = savedValuationData;
  const [ isDialogPdfOpen, setIsDialogPdfOpen] = useState(false);
  const [ isDialogMobileReportOpen, setIsDialgoMobileReportOpen] = useState(false)
  const [ isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [ isLikedBtn, setIsLikedBtn] = useState(false);
  const [ isFavoriteBtn, setIsFavoriteBtn] = useState(false);
  const [ snackbarMessage, setSnackbarMessage] = useState("");
  const [ isCommentModal, setIsCommentModal] = useState(false);
  const [ comment, setComment] = useState("");
  const [ favoriteId, setFavoriteId] = useState(null); // poner en un solo state
  const [ likeId, setLikeId] = useState(null);
  const [ ratingId, setRatingId] = useState(null);
  const [ ratingValue, setRatingValue] = useState(0);
  const { handleDialogClose, dialogOptions, isDialogOpen }  = useFetch({setEditMode, handleNewValuation});
  const {axiosFetch: getFavoriteValuation, isLoading: isLoadingFavorite } = useAxios();
  const {axiosFetch: saveFavoriteValuation} = useAxios();
  const {axiosFetch: deleteFavoriteValuation} = useAxios();
  const {axiosFetch: getLikeValuation, isLoading: isLoadingLike} = useAxios();
  const {axiosFetch: saveLikeValuation} = useAxios();
  const {axiosFetch: deleteLikeValuation} = useAxios();
  const {axiosFetch: getRatingValuation, isLoading: isLoadingRating} = useAxios();
  const {axiosFetch: saveRatingValuation} = useAxios();
  const {axiosFetch: updateRatingValuation}= useAxios();
  // const {axiosFetch: deleteRatingValuation} = useAxios();

  const handleSnackbarClose= ()=>{
    setIsSnackbarOpen(false);
  }

  const getRatingSuccessCb = (apiData) =>{
    // Check if this valuation was already liked by this user, if yes, turn the btn on and stores rating id
    if (apiData){  // returns {id, valuationId, profileId, value}
      const { id, value } = apiData
      setRatingValue (value)
      setRatingId (id)
    } else {
      // setRatingValue (0)
    }
  }

  const updateValuationsList=(valuationId, avgRating)=>{
    setValuationsList(prevValuationsList => {
      return prevValuationsList.map(valuation => {
        if (valuation.valuationId === valuationId) {
          return { ...valuation, avgRating: avgRating };
        }
        return valuation;
      });
    });
  }

  const saveRatingSuccessCb = (apiData) =>{

    if (apiData){  // returns {id, value, ratings, avgRating}
      // alert("Entrou em saveRatingSuccessCb")
      const {id, value, ratings, avgRating} = apiData;
      // const totalRatings = interactions.ratings * interactions.avgRating;
      // const newAvgRating= (totalRatings + value)/(interactions.ratings+1)
      setRatingValue(value);
      setRatingId(id);
      // ratings:0, avgRating:0
      setInteractions(prevInteractions => ({
        ...prevInteractions,
        avgRating: avgRating,
        ratings: ratings
      }));
      // updates valuationsList
      updateValuationsList(valuationId, avgRating);
      setSnackbarMessage("Your rating was saved");
      setIsSnackbarOpen(true);
    } else {
      // ja eh falso por default?
    }
  }
  
  const updateRatingSuccessCb=(apiData)=>{
    if (apiData){ // returns { id, value, ratings, avgRating }
      const { id, value, ratings, avgRating } = apiData;
      setRatingValue(value)
      setInteractions(prevInteractions => ({...prevInteractions,avgRating: avgRating }));
      setSnackbarMessage("Your rating was updated")
      setIsSnackbarOpen(true)
    }
  }

  const updateRatingErrorCb=()=>{
    setSnackbarMessage("Your rating was not updated")
    setIsSnackbarOpen(true)
  }

  // const deleteRatingSuccessCb = (apiData)=>{
  //   if (apiData){  // returns {id}
  //     const totalRatings = interactions.ratings * interactions.avgRating;
  //     const newAvgRating= (totalRatings + ratingValue)/(interactions.ratings-1)
  //     setRatingValue(0);   
  //     setRatingId(null)
  //     setInteractions(prevInteractions => ({
  //       ...prevInteractions,
  //       ratings: prevInteractions.ratings - 1,
  //       avgRating: newAvgRating
  //     }));
  //     setSnackbarMessage("Your rating was cancelled")
  //     setIsSnackbarOpen(true)
  //   } 
  // }

  const handleRating = (newValue)=> {
    const value = newValue !== null ? newValue : 0;
    if (ratingId) { 
      const dataToProcess={id: ratingId, valuationId, profileId: userId, value:value}
      updateRatingValuation({ axiosInstance: valuationsWebApi, method: 'PUT', url: `/ratings`, data: dataToProcess ,requestConfig: { headers: {'Authorization': userId,},}},updateRatingSuccessCb, updateRatingErrorCb);
    } else {
      const dataToProcess = { valuationId, profileId: userId, value:value };
      saveRatingValuation({ axiosInstance: valuationsWebApi, method: 'POST', url: '/ratings', data: dataToProcess, requestConfig: { headers: {'Authorization': userId,},}},saveRatingSuccessCb, errorCallback);
    }
  }

  const handleChangeFavoriteBtn = () => {
    if (isFavoriteBtn) {
      setIsFavoriteBtn(false);
      deleteFavoriteTest();
    } else {
        setIsFavoriteBtn(true);
        saveFavorite(valuationId, userId)
    }  
  };

  const handleChangeLikeBtn = () => {
    if (isLikedBtn) {
      setIsLikedBtn(false);
      deleteLike();
    } else {
      setIsLikedBtn(true);
      saveLike (valuationId, userId)
    }  
  };

  function errorFavoritesCallback(errorMessage){
    setIsFavoriteBtn(false)
    // setDialogOptions({severity:"error", title:"Error", message:errorMessage,buttons:{button1:"Ok"}})
    // setIsDialogOpen (true);
  }

  function errorLikeCallback(){
    setIsLikedBtn(false);
    setLikeId(null);
    // alert("errorLikeCallback")
    // setDialogOptions({severity:"error", title:"Error", message:errorMessage,buttons:{button1:"Ok"}})
    // setIsDialogOpen (true);
  }

  function errorRatingCallback(){
    setRatingValue(0)
    // setDialogOptions({severity:"error", title:"Error", message:errorMessage,buttons:{button1:"Ok"}})
    // setIsDialogOpen (true);
  }

  function errorCallback(errorMessage){
    // alert("error cb")
    // pq esta pasando por aca
    // alert (errorMessage)
    // setIsFavoriteBtn(false)
    // setDialogOptions({severity:"error", title:"Error", message:errorMessage,buttons:{button1:"Ok"}})
    // setIsDialogOpen (true);
  }

  function saveFavorite (valuationId, userId){
    const favoriteData = { valuationId, profileId: userId}
    saveFavoriteValuation({ axiosInstance: valuationsWebApi, method: 'POST', url: '/favorites', data: favoriteData, requestConfig: { headers: {'Authorization': userId,},}},saveFavoritesSuccessCb, errorCallback("server error"));
  }

  function saveLike (valuationId, userId){
    const likeData = { valuationId, profileId: userId}
    saveLikeValuation({ axiosInstance: valuationsWebApi, method: 'POST', url: '/likes', data: likeData, requestConfig: { headers: {'Authorization': userId,},}},saveLikeSuccessCb, errorLikeCallback);
  }

  const deleteFavoriteTest=()=>{
    deleteFavoriteValuation({ axiosInstance: valuationsWebApi, method: 'DELETE', url: `/favorites/${favoriteId}`, requestConfig: { headers: {'Authorization': userId,},}},deleteFavoriteSuccessCb, errorFavoritesCallback);
  }

  const deleteLike=()=>{
    deleteLikeValuation({ axiosInstance: valuationsWebApi, method: 'DELETE', url: `/likes/${likeId}`, requestConfig: { headers: {'Authorization': userId,},}},deleteLikeSuccessCb, errorLikeCallback);
  }

  function handleCommentClose(){
    setIsCommentModal(false);
  }

  function handleComment (){
    setIsCommentModal(true)
  }

  const handlePrintValuation=()=>{
    if (isMobile || isTablet){
      setIsDialgoMobileReportOpen(true)
    } else {
      setIsDialogPdfOpen(true)  
    }
  }
  
  const handleMobileReportClose=()=>{
    setIsDialgoMobileReportOpen(false)
  }

  function handleDialogPdfClose(){
    setIsDialogPdfOpen(false);
  }

  const getLikeSuccessCb = (apiData)=>{
    if (apiData){  // returns all table fields 
      const { id } = apiData;
      setIsLikedBtn (true);
      setLikeId (id);
    } else {
      // ja eh falso por default
    }
  }

  const getFavoritesSuccessCb = (apiData) =>{
    if (apiData){  // returns all table fields { id, valuationId, profileId and value}
      const { id } = apiData;
      // const favorites= apiData.length? apiData.length : 0
      setIsFavoriteBtn (true);
      setFavoriteId (id);

      // setInteractions(prevInteractions => ({
      //   ...prevInteractions,
      //   favorites: favorites,
      // }));
    } else {
      // ja eh falso por default
    }
  }

  const saveLikeSuccessCb = (apiData) =>{
    if (apiData){  // returns {id}
      const {id} = apiData
      setIsLikedBtn(true)
      setLikeId(id)
      setInteractions(prevInteractions => ({
        ...prevInteractions,
        likes: prevInteractions.likes + 1,
      }));
      setSnackbarMessage("Your like was saved");
      setIsSnackbarOpen(true);
    } else {
      // ja eh falso por default
    }
  }

  const saveFavoritesSuccessCb = (apiData) =>{
    if (apiData){  // returns {id}
      const {id} = apiData
      setIsFavoriteBtn(true)
      setFavoriteId(id)
      setInteractions(prevInteractions => ({
        ...prevInteractions,
        favorites: prevInteractions.favorites + 1,
      }));
      setSnackbarMessage("This valuation was added to your favorites list");
      setIsSnackbarOpen(true);
    } else {
      // ja eh falso por default
    }
  }

  const deleteLikeSuccessCb = (apiData)=>{
    if (apiData){  // returns {id}
      const {id} = apiData
      setIsLikedBtn(false)
      setLikeId(null)
      setInteractions(prevInteractions => ({
        ...prevInteractions,
        likes: prevInteractions.likes - 1,
      }));
      setSnackbarMessage("Your like was deleted");
      setIsSnackbarOpen(true);
    } 
  }

  const deleteFavoriteSuccessCb = (apiData)=>{
    if (apiData){  // returns {id}
      const {id} = apiData
      setIsFavoriteBtn(false)
      setFavoriteId(null)
      setInteractions(prevInteractions => ({...prevInteractions, favorites: prevInteractions.favorites - 1}));
      setSnackbarMessage("This valuation was removed from your favorites list");
      setIsSnackbarOpen(true);
    } 
  }

  useEffect(() => {
    // incluir getComments? comments deberia ser global/regional  pq se utiliza for a de InfoPanel....
    getFavoriteValuation({ axiosInstance: valuationsWebApi, method: 'GET', url: `/favorites?userId=${userId}&valuationId=${valuationId}`, requestConfig: { headers: {'Authorization': userId,},}},getFavoritesSuccessCb, errorFavoritesCallback);
    getLikeValuation({ axiosInstance: valuationsWebApi, method: 'GET', url: `/likes?userId=${userId}&valuationId=${valuationId}`, requestConfig: { headers: {'Authorization': userId,},}},getLikeSuccessCb, errorLikeCallback);
    getRatingValuation({ axiosInstance: valuationsWebApi, method: 'GET', url: `/ratings?userId=${userId}&valuationId=${valuationId}`, requestConfig: { headers: {'Authorization': userId,},}},getRatingSuccessCb, errorRatingCallback);
  }, []);

  return (
    <>
    <Grid container direction = "row" spacing = {1} style={{paddingTop:"0px"}} >
      <Grid item xs={12} sm = {12} >

        {/* Top side */}
        <Grid container direction="row" display={"flex"} justifyContent={"flex-end"}   >
          <Grid item xs={8}>
          <Grid item container xs={12}>
            { isValuationSample?
            <>
            <Grid item xs={2}>
            <Box style={{display:"flex", justifyContent:"center"}}>
              <a href={youtubeLink} target="_blank" rel="noopener noreferrer">  
                <YouTubeIcon className = {classes.iconStyle} />
              </a>
            </Box>
          </Grid>
          <Grid item xs={10}>
          <Typography align="justify" style={{marginTop:"5px",fontSize:"9px"}} className={classes.textStyle}>
              <b>Watch a video of how it works</b></Typography>
          </Grid>  
          </>


            : null}

                      </Grid>
   
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"flex-end"}  >
  
            {/* <Box sx={{height:"10px"}}/> */}
            
            <Rating
              name="simple-controlled"
              size={"small"}
              value={ratingValue}
              disabled={ userId==="" }
              onChange={(event, newValue) => {
                handleRating(newValue);
              }}
              style={{marginRight:"5px"}}
            />
            {/* { ratingId ? <Typography sx={{fontSize:9}} gutterBottom >{ratingId}</Typography>: <Typography  sx={{fontSize:9}}>NA-id</Typography>}
            { ratingValue ? <Typography sx={{fontSize:9}} gutterBottom >{`-${ratingValue}`}</Typography>:<Typography  sx={{fontSize:9}}>NA-value</Typography> }
            { interactions ? <Typography sx={{fontSize:9}}>{`-${interactions.ratings}`}</Typography>:<Typography  sx={{fontSize:9}}>sem num ratings</Typography> } */}

          </Grid>
          {/* { interactions ? <Typography sx={{fontSize:9}}>{`-${interactions.likes}`}</Typography>:<Typography  sx={{fontSize:9}}>NA likes</Typography> } */}


        </Grid> 
        {/* Bottom side */}
        <Box display ="flex" flexDirection="row" justifyContent="flex-end" >
          <Tooltip title={ ! isLikedBtn? "Give a Like to this valuation":"Take the like you gived to this valuation"}>
            <Button variant = "text" disableRipple className = {classes.buttonStyle} 
              startIcon={ isLikedBtn? <ThumbUpAltIcon />: <ThumbUpOffAltIcon /> } 
              disabled={ userId==="" }
              onClick = {handleChangeLikeBtn} 
              >Like 
            </Button>
          </Tooltip>
          <Tooltip title={isFavoriteBtn? "Remove this valuation from your favorites":"Add this valuation in your favorites to see it later"}>
            <Button variant = "text" disableRipple className = {classes.buttonStyle} 
              startIcon={isFavoriteBtn? <FavoriteIcon />:<FavoriteBorderIcon />} 
              disabled={ userId==="" }
              onClick ={handleChangeFavoriteBtn}
              >Favorite 
            </Button>
          </Tooltip>
          <Tooltip title="Comment this valuation">
            <Button variant = "text" disableRipple className = {classes.buttonStyle} 
              startIcon={<CommentIcon />} 
              disabled={ userId==="" || userProduct==="0" }
              onClick={handleComment} 
              >Comment 
            </Button>
          </Tooltip>
          <Tooltip title="Generate a pdf file to print or send to your contacts">
            <Button variant = "text" disableRipple className = {classes.buttonStyle} 
              startIcon={<CloudDownloadIcon />}  
              disabled={ (userId==="" || userProduct==="0") && ! isValuationSample }
              onClick = {handlePrintValuation} 
              >Download
            </Button>
          </Tooltip>
          {/* <Tooltip title="Share the link of this valuation">
            <Button variant = "text" disableRipple className = {classes.buttonStyle} 
              startIcon={<ShareIcon />} 
              disabled = {true}
              // onClick={(e) => (handleDelete (savedValuationData.id))} 
              >Share
            </Button>
          </Tooltip> */}
        </Box>  
      </Grid> 

    </Grid>
    <DialogModal open={isDialogOpen} onClose={handleDialogClose} severity={dialogOptions.severity} title={dialogOptions.title} buttons={dialogOptions.buttons} action={dialogOptions.action}>
      {dialogOptions.message}
    </DialogModal> 
    <Snackbar
      open={isSnackbarOpen}
      autoHideDuration={2000} 
      onClose={handleSnackbarClose}
      >
      <SnackbarContent
        className={classes.greenSnackbarContent}
        message={snackbarMessage}
      />
    </Snackbar>
    <CreateCommentModal 
      open={isCommentModal} 
      onClose={handleCommentClose} 
      valuationId={valuationId} 
      userId={userId}
      comment={comment}
      setComment={setComment}
      setInteractions={setInteractions}
    />
    <DownloadDesktopReport
      open={isDialogPdfOpen} 
      onClose={handleDialogPdfClose} 
      companyData={companyData}
      historicalFinancialData={historicalFinancialData}
      combinedFinancialData={combinedFinancialData} 
      assumptions={assumptions} 
      calculatedCostOfCapital={calculatedCostOfCapital} 
      valuation={valuation}
      // savedValuationData={savedValuationData}
      isCheckedDescOrder={isCheckedDescOrder}
      isCheckedShowPreviousYears={isCheckedShowPreviousYears}
      isEstimateFcffOnly={isEstimateFcffOnly}
      isUserCostOfCapital={isUserCostOfCapital}
      valuationDateInfo={valuationDateInfo}
    />
    <DownloadMobileReport
      open={isDialogMobileReportOpen} 
      onClose={handleMobileReportClose} 
      companyData={companyData}
      historicalFinancialData={historicalFinancialData}
      combinedFinancialData={combinedFinancialData} 
      assumptions={assumptions} 
      calculatedCostOfCapital={calculatedCostOfCapital} 
      valuation={valuation}
      // savedValuationData={savedValuationData}
      isCheckedDescOrder={isCheckedDescOrder}
      isCheckedShowPreviousYears={isCheckedShowPreviousYears}
      isEstimateFcffOnly={isEstimateFcffOnly}
      isUserCostOfCapital={isUserCostOfCapital}
      valuationDateInfo={valuationDateInfo}
    />
    </>
  )
}