import React from 'react';
import { useHistory } from 'react-router-dom';
import ReactGA from 'react-ga4';

import { Button, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles( (mainTheme) => ({
  buttonStyle: {
    [mainTheme.breakpoints.down('sm')]: {
      fontSize: "10px"
    },
  },
})) 

export default function ButtonUpgrade (){
  const classes = useStyles();
  const history = useHistory();

  const handleRegister=()=>{
    ReactGA.event({
      category: "ButtonUpgrade",
      action: "onclick",

    });
    history.push('/upgrade')
  }

  return(
    <>
    <Tooltip title="Upgrade your free account to get unlimited access to valuations and users profiles">
      <Button 
        variant = "contained" 
        // startIcon = {<AppRegistrationIcon />} 
        autoFocus
        disableRipple
        onClick = { handleRegister } 
        // disabled = {userId? false : true}
        className = {classes.buttonStyle}
        >Upgrade NOW, get full access !
      </Button>
    </Tooltip>
    </>
  )
}