import React, { useState, useContext, useEffect, useLayoutEffect, useRef  } from 'react';
import { useHistory } from 'react-router-dom';

import { Grid, Paper, Box, Button, Tooltip, Typography, Avatar, Snackbar, SnackbarContent } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import CakeIcon from '@mui/icons-material/Cake';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonOffIcon from '@mui/icons-material/PersonOff';
// import LocationOnIcon from '@mui/icons-material/LocationOn';
// import EmailIcon from '@mui/icons-material/Email';
// import YouTubeIcon from '@mui/icons-material/YouTube';
// import TwitterIcon from '@mui/icons-material/Twitter';
// import InstagramIcon from '@mui/icons-material/Instagram';
// import FacebookIcon from '@mui/icons-material/Facebook';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import WebIcon from '@mui/icons-material/Web';
// import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
// import CancelIcon from '@mui/icons-material/Cancel';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { format, parseISO } from 'date-fns';

import { LoginContext } from '../../helpers/Context.js';
import useFetch from '../../hooks/useFetch.js'
import useAxios from '../../hooks/useAxios.js';
import valuationsWebApi from '../../services/valuationsWebApi.js';
import ShowUserImageByUserId from '../../components/ShowUserImageByUserId.jsx';

const useStyles = makeStyles((mainTheme) => ({
  paperStyle:{
    margin:"0px", 
    padding:"5px", 
    margin:"auto", 
    height:"310px",
    
    [mainTheme.breakpoints.down('sm')]: {
      height: "auto",
  
    },
  },  
// buttonStyle: {
  // width:"120px",
// },
buttonStyle: {
  width:"90px",
  [mainTheme.breakpoints.down('sm')]: {
    fontSize: "10px"
  },
  // color:mainTheme.palette.secondary.main,
  // backgroundColor:"white",
  // "&:hover": {
  //   color:"#CD7F32",
  //   backgroundColor: "whitesmoke"//"#F49506ed",
  //   // borderColor:"#F49506ed"
  // },
},
sectionTitleStyle:{
  fontSize: mainTheme.sectionTitle.fontSize,
  color: mainTheme.sectionTitle.color
},
iconStyle:{
  height:"18px",
  width:"18px",
  color: mainTheme.palette.tertiary.main,
},
iconStyleInfo:{
  color: mainTheme.palette.primary.main,
},
textStyle:{
  color: mainTheme.palette.tertiary.main,
},
descriptionStyle:{
  fontSize: "10px",
  [mainTheme.breakpoints.down('sm')]: {
    fontSize: "13px"
  },
  color: mainTheme.palette.tertiary.main,
},
greenSnackbarContent: {
  backgroundColor: "#228B22",
},
// socialNetworkBox:{
//   width:'100%',
//   textAlign: 'center',
//   AlignItems: 'center',
//   backgroundColor:mainTheme.palette.secondary.main,
// }, 
})); 

export default function PeerUserProfile ({peerProfileData}){
  const classes = useStyles();
  const { userData, usersImagesList } = useContext(LoginContext);
  const { userId } = userData;
  const [ editMode, setEditMode] = useState("")
  const { profileId, profileFirstName, profileLastName, profileBirthday, profileDescription }= peerProfileData
  // const { profileId: userIdToFilter } = peerProfileData;
  // const { userId } = userData;
  const [ isFollowing, setIsFollowing] = useState(false)
  // const currentPeerUser = usersList?  usersList.filter(user => user.id === userIdToFilter): null;
  // const { id: profileId, firstName: profileFirstName, lastName:profileLastName, birthday: profileBirthday, description: profileDescription}= peerProfileData
  // const [ isEditProfile, setIsEditProfile] = useState(false); 
  const buttonRef = useRef(null);
  const { saveFollower, deleteFollower } = useFetch(setEditMode);
  const [ isSnackbarOpen, setIsSnackbarOpen]=useState(false);
  const [ snackbarMessage, setSnackbarMessage]=useState("");
  const { axiosFetch: getFollower, isLoading: isLoadingFollower, error: isErrorFollower } = useAxios();
  // const history = useHistory();

  // const handleRegister = ()=>{
  //   history.push('/pricing')
  // }

  // const handleEditProfile=()=>{
  //   setIsEditProfile(true);
  // }

  // const handleEditProfileClose=()=>{
  //   setIsEditProfile(false);
  // }

  // useLayoutEffect(() => {
  //   buttonRef.current.focus();
  // }, []);

  const handleSnackbarClose=()=>{
    setIsSnackbarOpen(false);
  }

  const handleFollowing = ()=>{
    if(isFollowing){
      setIsFollowing(false);
      deleteFollower(userId, profileId) 
      // deberia estar en useFecth successCb
      setSnackbarMessage(`You're not following ${profileFirstName} anymore`)
      setIsSnackbarOpen(true);

    } else {
      setIsFollowing(true);
      saveFollower(userId, profileId)
      // deberia estar en useFecth successCb
      setSnackbarMessage(`You're now following ${profileFirstName}`)
      setIsSnackbarOpen(true);
    }
  }

  const getFollowerSuccessCb=(apiData)=>{
    // alert(apiData.length)
    if (apiData ){
      setIsFollowing(true);
    } else {
      setIsFollowing(false);
    }
  }

  const getFollowerErrorCb=(apiData)=>{
    setIsFollowing(false);
  }

  useEffect(() => {
    getFollower({ axiosInstance: valuationsWebApi, method: 'GET', url: `/followers?userId=${profileId}&followerId=${userId}`, requestConfig: { headers: {'Authorization': userId,},}},getFollowerSuccessCb, getFollowerErrorCb);
  }, [peerProfileData]);

  return (
  <>
  { profileId? <>
  <Paper elevation={0} className={classes.paperStyle} >
    {/* <Typography className={classes.sectionTitleStyle} >{`${profileFirstName}'s Profile`}</Typography> */}
    <Typography className={classes.sectionTitleStyle} >{`Profile`}</Typography>


    <Grid container style={{marginTop:"5px"}}  >
      <Grid container justifyContent="center" >  

          <Grid item xs={12}>
            <Box style={{display:"flex", alignItems:"center", justifyContent:"center"}} >
              { profileId ? 
                <ShowUserImageByUserId 
                  userId={profileId} 
                  usersImages={usersImagesList} 
                  size={"large"}
                />
              : <>
              {/* <Avatar sx={{height:68, width:68}}>No Photo</Avatar> */}
              {/* { alert("ProfileId undefined")} */}
              </>
              }
            </Box>
          </Grid>

      </Grid>
      <Grid item xs={12} >
        { profileId ?  
          <Typography align="center" gutterBottom className={classes.textStyle} style={{fontSize: 12, marginTop:"5px"}}>
            {`${profileFirstName? profileFirstName:""} ${profileLastName? profileLastName: ""}`}</Typography>
        :
          <Typography align="center" gutterBottom className={classes.textStyle} style={{fontSize: 12, marginTop:"5px"}}>
            No bio data from the user </Typography>
        }
      </Grid>
    </Grid>
    <Box style={{height:"2px"}}/>
    <Box>
      {/* { profileDescription ?  */}
        <Typography gutterBottom align="justify" className={classes.descriptionStyle}  >
          {profileDescription}</Typography> 
      {/* //     : null 
      // } */}
    </Box>
    {/* <Grid container direction="row" style={{marginTop:"5px"}} > 
      <Grid item xs={1} >
        { profileBirthday ?
          <CakeIcon className={classes.iconStyle}  /> : null}
      </Grid>
      <Grid item xs={11} >
        { profileBirthday ? 
          <Typography className={classes.textStyle} style={{fontSize: 9, marginLeft: "10px", marginTop:"2px"}}>{format(new Date(profileBirthday), "MMM, dd")}</Typography> : null 
        }
      </Grid>
    </Grid> */}

    <Box style={{height:"2px"}}/>
    {/* <Grid container display="flex" alignItems="center" justifyContent="center" style={{backgroundColor:"yellow"}}> */}
    <Grid container alignItems="stretch" >

      { ! isFollowing ? 
      <>
      
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={12}  style={{display:"flex", alignItems:" center",justifyContent:"center"}}>
          
          <Typography className={classes.textStyle} style={{fontSize:11}}>
          {`You are not following ${profileFirstName? profileFirstName: null} !`}</Typography>
        </Grid>
        {/* <Grid item xs={12}  style={{display:"flex", alignItems:" center",justifyContent:"center"}}> */}
        <Grid container alignItems="center" justifyContent="center">
          <Tooltip title="Follow this profile">
            <Button ref={buttonRef} onClick={handleFollowing} variant = "contained" disableRipple  startIcon={<PersonAddIcon />} className = {classes.buttonStyle}>
              Follow</Button>
          </Tooltip>
        </Grid>
      </Grid>
      </>
      : 
      <>
    
      <Grid container alignItems="stretch" >
        {/* <Grid xs={12}  style={{display:"flex", alignItems:" center",justifyContent:"center"}}> */}
        <Grid container alignItems="center" justifyContent="center">

          <Typography className={classes.textStyle} style={{fontSize:11}}>
          {`You are following ${profileFirstName? profileFirstName: null}`}</Typography>
        </Grid>
        {/* <Grid xs={12}  style={{display:"flex", alignItems:" center",justifyContent:"center"}}> */}
        <Grid container alignItems="center" justifyContent="center">
        <Tooltip title="Unfollow this profile">
          <Button ref={buttonRef} onClick={handleFollowing} variant="text" disableRipple startIcon={<PersonOffIcon />} className = {classes.buttonStyle}>
            Unfollow</Button>
        </Tooltip> 
        </Grid>
      </Grid>
      </>
      }
    </Grid>

    <Box style={{height: "5px"}}/>
  </Paper> 
  
  </> : null}
  <Snackbar
      open={isSnackbarOpen}
      autoHideDuration={2000} 
      onClose={handleSnackbarClose}
      >
      <SnackbarContent
        className={classes.greenSnackbarContent}
        message={snackbarMessage}
      />
  </Snackbar>  


  

  </> 
  )
}
