import React, { useEffect, useState } from 'react';

import { CircularProgress } from '@mui/material'

import aaplLogo from '../assets/AAPL.png';
import amznLogo from '../assets/AMZN.png';
import catLogo from '../assets/CAT.png';
// import vLogo from '../assets/V.png';
import vLogo from '../assets/V.jpg'


import useAxios from '../hooks/useAxios.js';
import valuationsWebApi from '../services/valuationsWebApi.js';

export default function ShowCompanyLogo({companyId, size}){

  const { axiosFetch: getLogoImage, isLoading: isLoadingLogo, error: isErrorLogo} = useAxios();
  const [ companyLogoDataUrl, setCompanyLogoDataUrl] = useState(null);

  const getImageSize=(size)=>{
    if (! size){
      return "28px"
    }
    if (size === "small"){
      return "20px"
    }
    if (size === "medium"){
      return "32px"
    }
    if (size === "large"){
      return "48px"
    }
  }

  const getCustomizedLogo=(companyId)=>{
    if (companyId==='AAPL'){
      // alert("apl logo")
      return aaplLogo
    }
    if (companyId==='AMZN'){
      return amznLogo
    }
    if (companyId==='CAT'){
      // alert("cat logo")
      return catLogo
    }
    if (companyId==='V'){
      // alert("cat logo")
      return vLogo
    }
  }

  const getLogoImageSuccessCb=(apiData)=>{
    if (apiData){
      // alert("recibio datos de la API")
      setCompanyLogoDataUrl(apiData.dataUrl)
    }
  }

  const getLogoImageErrorCb=(error)=>{
    // alert("error en logo image "+ error)
  }

  useEffect(() => {
    if (companyId){
      getLogoImage({ axiosInstance: valuationsWebApi, method: 'GET', url: `/proxy-image-logo/${companyId}`, requestConfig: { headers: {'Authorization': "martincsl@hotmail.com",},}},getLogoImageSuccessCb, getLogoImageErrorCb);
    }
  }, []); 
  
  return (
    <>
      { companyId ? 
      <>
        { isLoadingLogo ? 
          <CircularProgress/>
        :
        <>
        { companyLogoDataUrl ? 
          <>
          { companyId==="AAPL" || companyId==="AMZN" || companyId==="CAT" || companyId==="V" ? 
            <img src = {getCustomizedLogo(companyId)} alt="Logo" style={{height:getImageSize(size)}}/>   
          :
            <img src = {companyLogoDataUrl} alt="Logo" style={{height:getImageSize(size)}}/>
          }
          </>
          :
          null
        }
        </>
        }
      </>
      : null }
    </>
  ) 
}