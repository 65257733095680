import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Grid, Paper, Box, Typography, TextField, Button,useTheme, useMediaQuery, Grow } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
import ContactMailIcon from '@mui/icons-material/ContactMail';

import AlertDialog from '../components/modals/AlertDialog';
import api from '../services/api';
import useForm from '../hooks/useForm.js';
import useUnsavedWarning from '../hooks/useUnsavedWarning';

import SocialNetworks from '../components/SocialNetworks.jsx';
import Footer from '../components/Footer.jsx';


const useStyles = makeStyles( (mainTheme) => ({
  contentStyle: {
    position: 'absolute',
    top: '65px'
  },
  paperStyle: {
    width:"350px",
    margin: 'auto',
    padding:'10px',
    backgroundColor:mainTheme.palette.secondary.main  
  },
  buttonStyle:{
    color: "white",
    backgroundColor:mainTheme.palette.primary.main,
    textTransform:"none",
    fontSize:12,
    margin: "10px",
    //marginTop: "15px",
    "&:hover": {
      color:mainTheme.palette.secondary.main,
      backgroundColor:mainTheme.palette.primary.main,
    },
  },
  iconBox:{
    width:'100%',
    textAlign: 'center',
    AlignItems: 'center',
  },
  iconStyle:{
    color: mainTheme.palette.primary.main, 
  },
  formStyle:{
    backgroundColor:'white'
  }
}))

export default function Contact (){
  
  const classes = useStyles();  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { handleChange, handleSubmit, checkBlankForm, chkFormErrors, isValidName, isValidPhone, isValidEmail, noBlanks, values, setValues, formErrors} = useForm (submit);
  const { contactName, contactMobile, contactEmail, contactMsg } = values;
  const valuesToCheck = { contactName, contactEmail, contactMsg }
  const [ isAlertOpen, setIsAlertOpen ] = useState(false);
  const [ alertMessage, setAlertMessage ] = useState({severity:"",title:"",message:""});
  const [ Prompt, setIsDirty, setIsPristine ] = useUnsavedWarning();
  const history = useHistory();

  const handleAlertClose = () => {
    setIsAlertOpen(false);
    if (alertMessage.severity==="success"){
      history.push('/home');  
    }
  };

  async function submit() {
    // if (chkBlankFormContact ()){
    if (checkBlankForm (valuesToCheck, values)){
      // Refactorar para mostrar inputs que necesitan ser completados y no msg generica
      // setAlertMessage(prevState => ( {...prevState, severity:"warning", title: "Some required data is missing", message:"Please complete the values, thank you!"}));
      setAlertMessage({severity:"warning", title: "Some required data is missing", message:"Please complete the values, thank you!"});
      setIsAlertOpen(true);
    }  else if (chkFormErrors()) {
      console.log(formErrors)
        // Refactorar para mostrar inputs que necesitan ser completados y no msg generica
        // setAlertMessage(prevState => ( {...prevState, severity:"error", title: "nahhh, Some required data is incorrect", message:"Please correct the values, thank you!"}));
        setAlertMessage({severity:"error", title: "Some required data is incorrect", message:"Please correct the values, thank you!"});
        setIsAlertOpen(true);
      }  
      else {
        setIsPristine();
        try {
          // Refactorar con usefetch?
          const data = { contactName, contactMobile, contactEmail, contactMsg } ;
          const response = await api.post('/messages', data );
          setAlertMessage({severity:"success", title: "Thank you for contacting us!", message: "We've received your message. Someone from our team will contact you soon." });
          setIsAlertOpen(true);
      
        } catch (err) {
            setAlertMessage({severity:"error", title: "Our server is unavaibable now. Plase try later.", message: "We apologize for the inconvenience, but the server that powers our website is experiencing some technical difficulties at the moment. This means that we are unable to fulfill your request. Our team is aware of the issue and is working hard to resolve it as quickly as possible. Please try again later or contact our support team for further assistance. Thank you for your patience." });
            setIsAlertOpen(true);
          }
      }
  }

  return <>

  <Grid container alignItems="center" justifyContent="center" className={classes.contentStyle} style={{ minHeight:'85vh'}}>
    {/* { isMobile?
        <Grid item xs={12} style={{display:"flex",justifyContent:"center", width:"100%"}}>
          <SocialNetworks />  
        </Grid>
      :
        <Grid item xs={12} style={{display:"flex",justifyContent:"flex-end",marginRight:"15px"}}>
          <SocialNetworks />  
        </Grid> 
    } */}
    <Grow in timeout = {1000}>
      <Grid item container className={classes.formStyle}>
        <Paper elevation={6} spacing={2} className={classes.paperStyle}>

            <form onSubmit={handleSubmit} noValidate>
              <Typography align="center" variant="subtitle1" style={{color:'white'}} gutterBottom>Contact Us</Typography>
              <Box className={classes.iconBox} >
                <ContactMailIcon className={classes.iconStyle} style={{ fontSize: 40 }}/>
              </Box>
              <Grid item xs={12}> 
                <TextField id="contactName" label="Name *" 
                  style={{borderRadius:"24px"}}
                  variant ="filled" margin="dense" size="small" fullWidth  
                  name="contactName" value={contactName} 
                  onChange={ (e) => {
                    handleChange (e,setValues,[isValidName]);
                    setIsDirty();
                  }}
                  inputProps={{style: {fontSize: 14}}} 
                  error={formErrors.contactName} ></TextField>
                  {formErrors.contactName ? <div className="error-helper-text">{formErrors.contactName}</div> : null}
              </Grid>
              <Grid item xs={12} md={9}> 
                <TextField id="contactMobile" label="Phone"
                  variant ="filled" margin="dense" size="small" fullWidth
                  name="contactMobile" value={values.contactMobile} 
                  onChange={ (e) => {
                    handleChange (e,setValues,[isValidPhone]);
                    setIsDirty();
                  }}
                  inputProps={{style: {fontSize: 14}}} 
                  error={formErrors.contactMobile}></TextField>
                  {formErrors.contactMobile ? <div className="error-helper-text">{formErrors.contactMobile}</div> : null}
              </Grid>  
              <Grid item xs={12}>
              <TextField id="contactEmail" label="Email *" 
                variant ="filled" margin="dense" size="small" fullWidth 
                name="contactEmail" value={values.contactEmail} 
                onChange={ (e) => {
                  handleChange (e,setValues,[noBlanks]);
                  setIsDirty();
                }}
                onBlur = { (e) => { handleChange (e,setValues,[isValidEmail])}}
                inputProps={{style: {fontSize: 14}}} 
                error={formErrors.contactEmail}></TextField>
                {formErrors.contactEmail ? <div className="error-helper-text">{formErrors.contactEmail}</div> : null}
              </Grid>
              <Grid item xs={12}>
                <Typography align="left" variant="subtitle2" style={{color:'white'}} gutterBottom>Message *</Typography>
                <div>
                  <textarea name="contactMsg" placeholder="Write your message" rows="4" style={{fontSize:"14px"}}
                    onChange={ (e) => {
                      handleChange (e,setValues,[noBlanks]);
                      setIsDirty();
                    }}
                  ></textarea>
          
                  {formErrors.contactMsg ? <div className="error-helper-text">{formErrors.contactMsg}</div> : null}
                </div>
              </Grid>
              <Grid container direction="row" alignItems="center" justifyContent="center"> 
                <Button type="submit" className={classes.buttonStyle} variant="outlined" disableRipple >Send Message</Button>
              </Grid>
          </form>
        </Paper>
      </Grid>
    </Grow>

    <Grid />

  </Grid>
  {/* <Footer /> */}
  {Prompt}
  <AlertDialog open={isAlertOpen} onClose={handleAlertClose} severity={alertMessage.severity} title={alertMessage.title}>
    {alertMessage.message}
  </AlertDialog>
  </>;
}