import React from 'react';
import { format, parseISO , startOfMonth, endOfMonth } from 'date-fns';

import { TableContainer, Table, TableBody, TableRow, TableCell, Grow, Slide } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import TodayIcon from '@mui/icons-material/Today';
import CalculateIcon from '@mui/icons-material/Calculate';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const useStyles = makeStyles((mainTheme) => ({
  textStyle:{
    fontSize:"11px",
    color:mainTheme.palette.tertiary.main,
    padding:"0px"
  },
  table:{
    width:"200px",///chequear
    margin:"0px",
    padding:"0px",
    // backgroundColor:"whitesmoke"
  },
  tableRowStyle:{
    height:"25px",
    fontSize:"8px",
  },
  tableCellStyle:{
    height:"25px",
    fontSize:"11px",
    padding:"0px",
    border: '1px solid white',
    color:mainTheme.palette.tertiary.main
  },
  iconStyle:{
    height:"20px",
    width:"20px",
    marginRight:"2px",
    color:"orange"
  }
  }));

export default function TablePricesSummary ({companyData, valuation, editMode}){
  const classes = useStyles();
  const updatedAtDate = companyData.updatedAt? parseISO(companyData.updatedAt):null;
  const currentPriceDate = updatedAtDate? format(updatedAtDate, "MMM,dd"):"";

  return(
    <>
    {/* <Grow in timeout = {1500}> */}
    {console.log(companyData.updatedAt)}

    <TableContainer >
      <Table className={classes.table} size="small" aria-label="stycky header" >
        <TableBody >
          <TableRow className={classes.tableRowStyle}>
            <TableCell align="right" style={{width:"20px"}}  className={classes.tableCellStyle}><CalculateIcon className={classes.iconStyle}/></TableCell>
            <TableCell align="left" style={{width:"80px"}} className={classes.tableCellStyle} >Valuation Price </TableCell>
            { companyData.regularMarketPrice !==0  ? 
              <TableCell align="right" style={{width:"40px"}}className={classes.tableCellStyle} >{Intl.NumberFormat('en-US',{style:'currency',currency:'USD'}).format(valuation.targetStockPrice)}</TableCell>
            :
              <TableCell align="right" style={{width:"40px"}} className={classes.tableCellStyle} ></TableCell> 
            }
            {/* <TableCell align="right" style={{width:"20px"}} className={classes.tableCellStyle}> {valuation.targetStockPrice>companyData.regularMarketPrice?<CheckCircleOutlineIcon className={classes.iconStyle} style={{marginTop:"2px",color:"white"}}/>:null}</TableCell> */}
          </TableRow>
          <TableRow className={classes.tableRowStyle}>

          <TableCell align="right" style={{width:"20px"}} className={classes.tableCellStyle} ><TodayIcon className={classes.iconStyle} style={{color:"#3C6E76"}}/></TableCell>
            <TableCell  align="left"style={{width:"80px"}} className={classes.tableCellStyle} >{`Current Price ${currentPriceDate}`}</TableCell>
            { companyData.regularMarketPrice !==0   ?
              <TableCell align="right" style={{width:"40px"}} className={classes.tableCellStyle} >{Intl.NumberFormat('en-US',{style:'currency',currency:'USD'}).format(companyData.regularMarketPrice )}</TableCell>
            :
              <TableCell align="right" style={{width:"40px"}} className={classes.tableCellStyle} ></TableCell> 
            }
            {/* <TableCell className={classes.tableCellStyle}></TableCell> */}
          </TableRow>
        </TableBody>
      </Table >
    </TableContainer > 

    {/* </Grow> */}
    </>
  )
}