import React, { useContext, useEffect, useState} from 'react';

import { Grid, Paper, Box, Typography, Avatar, AvatarGroup, ToggleButton, ToggleButtonGroup, Tab, Tabs, List, ListItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { LoginContext } from '../../helpers/Context.js';
import useAxios from '../../hooks/useAxios.js';
import valuationsWebApi from '../../services/valuationsWebApi.js';

import ShowUserById from '../../components/ShowUserById';

const useStyles = makeStyles( (mainTheme) => ({
  sectionTitleStyle:{
    fontSize: mainTheme.sectionTitle.fontSize,
    color: mainTheme.sectionTitle.color
  },
  paperStyle:{
    padding: '5px', 
    margin:"5px", 
    minHeight: 300,  // Set a default minimum height
    [mainTheme.breakpoints.down('sm')]: {
      height: "auto"
    },
    overflow: 'hidden',
    overflowX: 'hidden'
  },
  // connectionsTitleStyle:{
  //   width:"100%",
  //   marginTop:"3px", 
  //   marginRight:"5px",
  //   fontSize:11, 
  //   color: mainTheme.palette.tertiary.main
  // },
  textStyle:{
    marginTop:"5px",
    fontSize: 12, 
    color: mainTheme.palette.tertiary.main,
  },
}));

export default function TableYourNetwork() {
  const classes = useStyles();
  const { userData, usersImagesList, usersList } = useContext(LoginContext);
  const { userId } = userData;
  const { axiosFetch: getFollowers, isLoading: isLoadingFollowers, error: isErrorFollowers } = useAxios();
  const { axiosFetch: getFollowees, isLoading: isLoadingFollowees, error: isErrorFollowees } = useAxios();
  const [ followers, setFollowers] = useState();
  const [ numberOfFollowers, setNumberOfFollowers]=useState(0)
  const [ followees, setFollowees] = useState();
  const [ numberOfFollowees, setNumberOfFollowees]=useState(0)
  const [currentList, setCurrentList] = useState("followees");  // options: followers or followees
  const userFollowers = followers? usersList.filter(user => followers.some(follower => follower.followerId === user.profileId)):[];
  const userFollowees = followees? usersList.filter(user => followees.some(followee => followee.followeeId === user.profileId)):[];
  const cleanedImages = usersImagesList;
  const [ currentListToShow, setCurrentListToShow]=useState(userFollowees);

  function capitalizeFirstLetter(word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  function getEmailUsername(email) {  // In case there's no name given by the user...
    const parts = email.split('@');
    const username = parts[0];
    const capitalizedUsername = capitalizeFirstLetter(username);
    return capitalizedUsername;
  }

  function cleanUserImages(userImagesList) {
    if (usersImagesList){
      return userImagesList.filter((image) => image.userId !== undefined && image.image !== null);
    }
  }

  const getFollowersSuccessCb=(apiData)=>{
    if (apiData.length > 0){
      const followerList=apiData
      setFollowers(apiData);
      setNumberOfFollowers(apiData.length)
      // setCurrentListToShow(usersList.filter(user => followerList.some(followee => followee.followeeId === user.profileId)))
      // setCurrentListToShow(apiData)
    } else{
      setFollowers();
      setNumberOfFollowers(0);
    }
  }

  const getFollowersErrorCb=(apiData)=>{
    // alert("error: No followers");
    setFollowers();
  }

  const getFolloweesSuccessCb=(apiData)=>{
    if (apiData.length > 0){
      const followeeList=apiData
      setFollowees(apiData);
      setNumberOfFollowees(apiData.length)
      // setCurrentListToShow(usersList.filter(user => 
      //   followeeList.some(followee => followee.followeeId === user.profileId)
      // ));
    
      setCurrentListToShow(usersList.filter(user => followeeList.some(followee => followee.followeeId === user.profileId)))
      // setCurrentListToShow(apiData)
    } else {
      setFollowees();
      setNumberOfFollowees(0);
    }
  }

  const getFolloweesErrorCb=(apiData)=>{
    // alert("error: No followees");
    setFollowees();
  }

  const handleListChange = (newCurrentList) => {
    if (newCurrentList !== null) {
      setCurrentList(newCurrentList);
    }
  }

  useEffect(() => {
    setCurrentListToShow(currentList==="followees"? userFollowees : userFollowers)
  }, [currentList]);

  useEffect(() => {
    getFollowers({ axiosInstance: valuationsWebApi, method: 'GET', url: `/followers?userId=${userId}`, requestConfig: { headers: {'Authorization': userId,},}},getFollowersSuccessCb, getFollowersErrorCb);
    getFollowees({ axiosInstance: valuationsWebApi, method: 'GET', url: `/followees?userId=${userId}`, requestConfig: { headers: {'Authorization': userId,},}},getFolloweesSuccessCb, getFolloweesErrorCb);
    // setCurrentListToShow( userFollowees)
  }, []);

  return (
    <>

    <Paper elevation={6} className={classes.paperStyle} >
      <Grid container direction="row">
        <Grid item xs={12}>
          <Typography align = "left" className={classes.sectionTitleStyle} >My Network</Typography>
          <Box style={{height:"10px"}}/>
        </Grid>
        <Box style={{height:"15px"}}/>
        <Box style={{width:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>

          <ToggleButtonGroup
            value={currentList}
            exclusive
            onChange={(e) => handleListChange(e.target.value)}
          >
            <ToggleButton value="followees" disableRipple style={{ textTransform: 'none', color:"#3C6E76",fontSize:"11px" }}>
              {`Following: ${numberOfFollowees? numberOfFollowees: 0}`}
            </ToggleButton>
            <ToggleButton value="followers" disableRipple  style={{ textTransform: 'none',color:"#3C6E76", fontSize:"11px"  }}>
              {`Followed by: ${numberOfFollowers ? numberOfFollowers: 0}`}
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Grid>
      { ! userId ? 
        <Typography align="center" className={classes.textStyle} gutterBottom>Create a network of people who share the same interests with you about valuations and financial markets !</Typography>
      : null}
      
      <Box style={{height:"5px"}}/>
      { userId ? <>
        <List style={{ overflowY: 'auto', maxHeight: 200, padding:0, margin:0 }}>
          {currentListToShow.map((currUser, index) => (
            <ListItem key={index} style={{ margin:0, padding:0 }}>
              <ShowUserById userId={currUser.profileId} firstName={currUser.profileFirstName} lastName={currUser.profileLastName} usersImages={usersImagesList} textPosition="beside" sizes="small" isCreatedByText={false} isCountryFlag={false} />
            </ListItem>
          ))}
        </List>
        </>
      : <>
      <Box sx={{display:"flex", alignItems:"center", justifyContent:"center"}}>
        <AvatarGroup max = {7} total={usersList.length}  >
          {cleanedImages.slice(0, 10).map((currUser, index) => {
            if (currUser.image !== undefined){
              return (
                <Avatar 
                  style={{ width: "30px", height: "30px" }} 
                  alt={`User ${currUser.userId}`} 
                  src={`data:image/jpeg;base64,${currUser.image}`} 
                />)
            }
          })}
        </AvatarGroup>
      </Box>
      </>
      }
      <Box style={{height:"5px"}}/>
    </Paper>
    </>
  )
}