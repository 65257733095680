import React from 'react';

import { Grid, Typography, Rating } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((mainTheme) => ({
  iconStyle:{
    marginLeft:"5px",
    width:"18px",
    height:"18px",
    color: mainTheme.palette.secondary.main
  },	
  iconStyleDisabled: {
    marginLeft:"5px",
    width:"18px",
    height:"18px",
    color: "silver"
  },	
  numberStyle:{
    marginTop:"2px",
    marginLeft:"8px",
    fontSize:"9px",
    color:mainTheme.palette.tertiary.main
  },
  numberStyleDisabled:{
    marginTop:"2px",
    marginLeft:"8px",
    fontSize:"9px",
    color:"silver"
  }
  })); 

export default function ShowValuationRating ({value, ratings, isGrayColor}){
	const classes = useStyles();
  return (
	<>
	<Grid container >
    <Grid item xs={5}>
      <Rating name="read-only" precision ={0.5} value={value} readOnly size="small" className={! isGrayColor ? classes.iconStyle : classes.iconStyleDisabled}/>
    </Grid>
    {/* <Grid item xs={7}>
      <Typography className={! isGrayColor ? classes.numberStyle: classes.numberStyleDisabled}>{value===0 ? " not rated yet":ratings===1? `rated with ${value.toFixed(1)}, by 1 user `:` average of ${value.toFixed(1)}, by ${ratings} users`}</Typography>
    </Grid> */}
	</Grid>
	</>
	)
}