import React from 'react';

import { TableRow, TableCell } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

// import useTableStyling from '../hooks/useTableStyling'

const useStyles = makeStyles( (mainTheme) => ({
dataColumnWhiteStyle:{
  paddingLeft:"4px",
  paddingRight:"8px",
  fontSize: "9px",
  [mainTheme.breakpoints.down('sm')]: {
    fontSize: "9px"
  },
  color: mainTheme.palette.primary.main,
  backgroundColor: "whitesmoke",
},
dataColumnGrayStyle:{
  paddingLeft:"4px",
  paddingRight:"8px",
  fontSize: "9px",
  [mainTheme.breakpoints.down('sm')]: {
    fontSize: "9px"
  },
  color: mainTheme.palette.primary.main,
  backgroundColor: mainTheme.palette.contrast.main
},
TableRows : {
  color: mainTheme.palette.primary.main,
  }
}));

export default function CreateTable ({dataRows, dataSource, numberOfColumns, tableFontSize }) {
  // Create financial table (inc. statement, cash flow) 
  const classes = useStyles();
  // const { defineYearsColWidth, defineYearsColColor, defineYearsColBackColor, defineNumberFormat } = useTableStyling();
  const numberOfRows = Math.ceil(dataRows.length/numberOfColumns); //rounds up number of rows
  const arrNumberOfRows = Array.from({ length: numberOfRows });
  const numberOfBlankSpaces = (numberOfRows * numberOfColumns) - dataRows.length;
  const arrBlankSpaces = numberOfBlankSpaces > 0 ? Array.from({ length: numberOfBlankSpaces }): null

  function calcDecimalPlaces (dataField){
    if (dataField === "companyBeta"){
      return 4
    } 
    if (dataField === "cashFlowDiscretePeriod"){
      return 0
    } 
    return 1 
  }

  const calcPercentDecimalPlaces =(dataField)=>{
    if (dataField === "riskFreeReturn" || dataField === "equityRiskPremium" || dataField === "costOfDebt"  || dataField === "costOfEquity" || dataField === "costOfCapital"){
      return 2
    } 
    return 1 
  }

  return (
    <>
    { dataSource ? <>
      {arrNumberOfRows.map ((currElement, rowIndex)=>
        <> 
        <TableRow>
          { dataRows.map ( (currElement, index)=> <>
            
            { index >= numberOfColumns * (rowIndex) && index < numberOfColumns * (rowIndex + 1) ? 
              <>
              <TableCell align="left" className={classes.TableRows} style={{ fontSize: tableFontSize, width: "20%", marginLeft:"5px", marginRight:"2px", padding:"4px", borderLeft:"1px solid rgba(224, 224, 224, 1)" }}>{currElement.text}</TableCell>
              { currElement.style === "percent" ? 
                  <TableCell align="right" className={classes.TableRows} style={{ fontSize: tableFontSize, width: "10%", marginRight:"4px",padding:"4px"}}>{Intl.NumberFormat('en-US',{style:'percent', minimumFractionDigits:calcPercentDecimalPlaces(currElement.dataField), maximumFractionDigits:calcPercentDecimalPlaces(currElement.dataField)}).format(dataSource[dataRows[index].dataField]/100)}</TableCell>
                :
                  <TableCell align="right" className={classes.TableRows} style={{ fontSize: tableFontSize, width: "10%", marginRight:"4px",padding:"4px"}}>{Intl.NumberFormat('en-US',{style:'decimal', minimumFractionDigits:calcDecimalPlaces(currElement.dataField), maximumFractionDigits:calcDecimalPlaces(currElement.dataField)}).format(dataSource[dataRows[index].dataField])}</TableCell>
              }
              </> : 
              null
            }
          </> )}
          { numberOfBlankSpaces !== 0 ? 
          <>
            { arrBlankSpaces.map ( (currElement, index)=> <>
              <TableCell align="left"  style={{ fontSize: tableFontSize, width: "20%", marginLeft:"5px", marginRight:"2px", padding:"4px" }}></TableCell>
              <TableCell align="right" style={{ fontSize: tableFontSize, width: "10%", marginRight:"4px",padding:"4px"}}></TableCell>
            </> )}
          </>
            : null
          }
        </TableRow> 
        </>
      )}
    </>: null } 
    </>
  )
}