import React, { useState, useEffect } from 'react';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';

import valuationsWebApi from '../services/valuationsWebApi';
import useAxios from '../hooks/useAxios';

const useStyles = makeStyles((mainTheme) => ({
  // autocomplete: {
  //   '& .MuiAutocomplete-input': {
  //     height: '30px', 
  //     fontSize: '11px', 
  //     backgroundColor:'white'
  //   },
  // },
}));

export default function CountryAutocomplete({countryObject, setRegisterData, isEditField, setIsEditField, variant}){
  const classes = useStyles();
  const [ countriesList, setCountriesList] = useState([]);
  const { loading: isLoadingCountries, axiosFetch: getCountries} = useAxios();
  const componentVariant = variant? variant : "outlined"

  const checkCountryChange=(newValue)=>{
    if (newValue !== null && newValue !== undefined){
      // alert("CountryObj.id: " + countryObject.id + " newValue" + newValue.id)
      if(countryObject.id !== newValue.id){
        if ( ! isEditField){
          setIsEditField(true)
        } else{
          setIsEditField(false)
        }
      }
    }
  }

  const countriesSuccessCallback = (apiData)=>{
    const countries = apiData;
    if (countries.length > 0){
      setCountriesList(countries);
    }
  }

  const countriesErrorsCb=()=>{
    // alert ("Error accessing data from server")
  }

  useEffect ( ()=> {
    getCountries({ axiosInstance: valuationsWebApi, method: 'GET', url: '/countries', }, countriesSuccessCallback, countriesErrorsCb);
  },[]) 

  return (
    <>
    {countriesList ?
  
    <>
      <Autocomplete
        // className={classes.autocomplete}
        disablePortal
        id="combo-box-demo"
        defaultValue={ countryObject }
        options={countriesList ? countriesList : []}
        getOptionLabel={(option) => option.name? option.name: ""}
        ListboxProps={{ style: { maxHeight: 170 } }}
        onChange={(event, newValue) => {
          setRegisterData (prevState => ( {...prevState, country: newValue===null? "": newValue.id, countryName: newValue===null? "": newValue.name}));
          checkCountryChange(newValue);
        }}
        fullWidth
        // isOptionEqualToValue={(option, value) => option.id === value.id}
        freeSolo
        forcePopupIcon={true}
        renderOption={(props, option) => (
          <Box style={{fontSize:"11px"}}component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            <img
              loading="lazy"
              width="20"
              src={`https://flagcdn.com/w20/${option.id.toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${option.id.toLowerCase()}.png 2x`}
              alt=""
            />
            {option.name} ({option.id})
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select your country"
            variant={componentVariant}
            size="small"
          />
        )}
      />
    </>
    : null}
  </>
  )
} 