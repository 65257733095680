import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import { LoginContext } from '../helpers/Context';
import useAxios from '../hooks/useAxios';
import valuationsWebApi from '../services/valuationsWebApi';

export default function Logout(){


  // const [ qtyOfValuationsViews, setQtyOfValuationsViews] = useState(0); 
  // const [ qtyOfValuationsCreated, setQtyOfValuationsCreated] = useState(0); 

  const { userData, setUserData, setIsValuationSample,setSavedValuationData, setQtyOfValuationsViews, setQtyOfValuationsCreated} = useContext(LoginContext);
  const { userId } = userData;
  const { axiosFetch: postLogout, isLoading: isLoadingLogout, error: isErrorLogout } = useAxios();
  const history = useHistory();

  const getLogoutSuccessCb=()=>{
    setIsValuationSample(false);
    setUserData({userId:"", userPassword:"", userProduct:"", userFirstName:"", userLastName:"", userCountry:"", userCountryName:"", userPhone:"", userBirthday:"", userDescription:""})
    setSavedValuationData({valuationId:"", profileId:"", profileFirstName:"", profileLastName:"", symbol:"",shortName:"", createdAt:"", updatedAt:"", published:null, publishedDate:null})
    setQtyOfValuationsViews(0);
    setQtyOfValuationsCreated(0);
    history.push("/landing")
  }

  const postLogoutErrorCb=()=>{
    setIsValuationSample(false);
    setUserData({userId:"", userPassword:"", userProdct:"", userFirstName:"", userLastName:"", userCountry:"", userCountryName:"", userPhone:"", userBirthday:"", userDescription:""})
    setSavedValuationData({valuationId:"", profileId:"", profileFirstName:"", profileLastName:"", symbol:"",shortName:"", createdAt:"", updatedAt:"", published:null, publishedDate:null})
    setQtyOfValuationsViews(0);
    setQtyOfValuationsCreated(0);
    history.push("/landing")
  }

  useEffect(() => {
    postLogout({ axiosInstance: valuationsWebApi, method: 'POST', url: `/logout`, data:{userId},requestConfig: { headers: {'Authorization': userId,},}},getLogoutSuccessCb, postLogoutErrorCb);
  }, []);

  return(
    <>
    </>
  )
}