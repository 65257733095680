import React from 'react';

import { Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((mainTheme) => ({
  disclaimerStyle:{
    fontSize: "9px",
    marginLeft:"2px",
    padding:"3px",
    color: mainTheme.palette.primary.main
  },
})); 

export default function DisclaimerText (){
  const classes = useStyles();
  return(
    <>
    {/* <Paper elevation={3}> */}
      <Typography align="justify" className={classes.disclaimerStyle}>Disclaimer: ValuationsHub uses data obtained from public APIs for educational purposes only. We do not take responsibility for the accuracy, completeness, or timeliness of the information provided. None of the content on this site should be considered as investment advice, and we are not liable for any actions taken based on the data presented here.Users should conduct their own research and consult with qualified professionals before making any financial or investment decisions.</Typography>
    {/* </Paper> */}
    </>
  )
}
