import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Grid, Paper, Box, Button, Typography, Tooltip, useTheme, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { LoginContext } from '../../helpers/Context.js';
import useAxios from '../../hooks/useAxios.js';
import valuationsWebApi from '../../services/valuationsWebApi.js';

import DialogModal from '../../components/modals/DialogModal';
import MyProfile from './MyProfile'; 
import TableYourNetwork from './TableYourNetwork';
import TableRecValuations from './TableRecValuations';
import ButtonNewValuation from'./ButtonNewValuation';
import BottomNavValuations from './BottomNavValuations';
import DoYouKnow from './DoYouKnow';
import DisclaimerText from '../Valuations/DisclaimerText.jsx';
import DialogFreePlanLimits from '../../components/modals/DialogFreePlanLimits.jsx';
import WhatsNext from './WhatsNext.jsx';
import Footer from '../../components/Footer.jsx';
import SocialNetworks from '../../components/SocialNetworks.jsx'

const useStyles = makeStyles( (mainTheme) => ({
  buttonStyle: {
    [mainTheme.breakpoints.down('sm')]: {
      fontSize: "10px"
    },
  },
  sectionTitleStyle:{
    marginLeft:"5px",
    fontSize: mainTheme.sectionTitle.fontSize,
    color: mainTheme.sectionTitle.color
  },
  titleStyle: {
    width: "100%",
    padding: "15px",
    color: mainTheme.palette.secondary.main,
    backgroundColor: "white",
    marginBottom: "10px",
  },
  paperStyle: {
    width: "100%",
    minHeight: "690px", 
    [mainTheme.breakpoints.down('lg')]: {
      minHeight: "0px"
    },
    marginLeft:"3px",
    marginRight:"0px",
    color: mainTheme.palette.primary.main,
    backgroundColor: "whitesmoke",
    padding: "5px",
  },
})) 

// const fetchUsers = async ()=> {
//   const res = await axios.get("http://localhost:3333/users")
//   const users = res.data
//   return users
// }

export default function Home (){
  const classes = useStyles();
  const theme = useTheme();
  const { id } = useParams(); 
  const newProduct = id ? id: "";
  const localStorageUserId = localStorage.getItem('userId') ?  localStorage.getItem('userId') : "";
  const localStorageCustomerId = localStorage.getItem('customer') ? localStorage.getItem('customer') : "";
  const storedValue = localStorage.getItem('isUpgradingPlan');
  const storedRegistering = localStorage.getItem('isRegisteringPlan');
  const isUpgradingPlan =  storedValue ? JSON.parse(storedValue) : false;
  const isRegisteringPlan =  storedRegistering ? JSON.parse(storedRegistering) : false;
  
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));//md
  const { userData, setUserData, valuationsList, valuationsFilterCriteria, setValuationsFilterCriteria, savedValuationData, usersList, qtyOfValuationsCreated,setQtyOfValuationsCreated, qtyOfValuationsViews, setQtyOfValuationsViews, setIsValuationSample } = useContext(LoginContext);
  const { userId, userProduct } = userData;
  // const [ isUserLoggedIn, setIsUserLoggedIn]= useState(false);
  const [ isDialogOpen, setIsDialogOpen ] = useState(false);
  const [ isDialogLimitsOpen, setIsDialogLimitsOpen ] = useState(false);
  const [ dialogOptions, setDialogOptions ] = useState({severity:"",title:"",message:"",buttons:{},action:""});
  const [ isDialogUnsubscribeOpen, setIsDialogUnsubscribeOpen ] = useState(false);
  const [ dialogUnsubscribeOptions, setDialogUnsubscribeOptions ] = useState({severity:"",title:"",message:"",buttons:{},action:""});

  // const { axiosFetch: getValuationsViews, isLoading: isLoadingValuationsViews, error: isErrorValuationsViews } = useAxios();
  const { axiosFetch: delValuationViews, isLoading: isLoadingDelValuationsViews, error: isErrorDelValuationsViews } = useAxios();
  const { axiosFetch: putUser, isLoading: isLoadingPutUser, error: isErrorPutUser } = useAxios();
  const { axiosFetch: getUser} = useAxios();

  // const { data: usersList, isLoading: isLoadingUsers, isError: isErrorUsers } = useQuery(["users"],fetchUsers);
  const history = useHistory();

  const handleRegisterMessage = ()=> {
    setDialogOptions({severity:"alert",title:"Please Register",message:"You need to register to create a new valuation. Check out our 3 options, including the free one.",action:""});
    setIsDialogOpen(true);
  }

  const handleDialogLimitsClose = () => {
    
    setIsDialogLimitsOpen(false);
  };

  function handleDialogClose(){
    setIsDialogOpen(false);
    setDialogOptions({severity:"",title:"",message:"",buttons:{},action:""});
  }

  function handleNewValuation(){
    if (userProduct ==="0" && qtyOfValuationsCreated > 4){
      setIsDialogLimitsOpen(true);
    } else{
      history.push('/valuation')
    }
  }

  const putUserSuccessCb=()=>{
    // alert("putUserSuccessCb, userProduct: "+userProduct + " isUpgradingPlan: "+ isUpgradingPlan+" lstorageCustomerId: "+localStorageCustomerId)
    if (userProduct==="0" & isUpgradingPlan && localStorageCustomerId!==""){
      // alert(" you have successfully changed to free Plan, you w'ont be chraged anymore")
      
    }
  }

  const putUserErrorCb=()=>{
    alert("Error updating User in database")
  }

  // const deleteSuccessCb=()=>{

  // }

  // const deleteErrorCb=()=>{
  //   console.log("error in valuations wiews deletion")
  // }

  // const handleUnsubscribe=()=>{
  //   history.push('/unsubscribe');
  // }

  // const handleUpgradePlan=()=>{
  //      // a current user is upgrading his free plan toa paid one
  //   // if payment is sucessful, applies the new product on userData en database, set quantityViews to 0 
  //   if (newProduct){ // if a new product was passed as a route parameter....
  //     alert("newProduct: "+ newProduct)
  //     if (newProduct !=="0"){ // If it's not a free plan anymore
  //       // updates de global states that limits the user's views and creation on free plan
  //       setUserData((prevState) => ({ ...prevState, userProduct: newProduct }));
  //       setQtyOfValuationsCreated(0)
  //       setQtyOfValuationsViews(0)

       
  //       putUser({ axiosInstance: valuationsWebApi, method: 'PUT', url: '/users', data: dataToProcess, requestConfig: { headers: {'Authorization': userId,},}}, putUserSuccessCb, putUserErrorCb);
  //       delValuationViews({ axiosInstance: valuationsWebApi, method: 'DELETE', url: `/valuations-views/${userId}`, requestConfig: { headers: {'Authorization': userId,},}},deleteSuccessCb, deleteErrorCb);
  //     } else { // user downgrading the paid plan to free

  //       setUserData((prevState) => ({ ...prevState, userProduct: "0" }));
  //       const dataToProcess={id: userId, product:newProduct}
  //       putUser({ axiosInstance: valuationsWebApi, method: 'PUT', url: '/users', data: dataToProcess, requestConfig: { headers: {'Authorization': userId,},}}, putUserSuccessCb, putUserErrorCb);
        
  //     }
  //   }
  // }

  // const handleRegisterPlan=()=>{
  //   //refactorar:....ver si es necesario el userId, o ya retiene del logeo previo
  //   if (localStorageUserId!=="" && localStorageUserId!==undefined  ){
  //     if (! userId) {
  //       alert(userId)
  //       setUserData((prevState) => ({ ...prevState, userId: localStorageUserId }));
  //     }
  //   }
  //   alert("Registering: Home useEffect[] userProduct: " + userProduct + " newProduct: " + newProduct + "customerId:" + localStorageCustomerId)
  //   if (userProduct!==newProduct){
  //     setUserData((prevState) => ({ ...prevState, userProduct: newProduct }));
  //   }
  //   const dataToProcess={id: userId, product:newProduct, stripeCustomerId:localStorageCustomerId }
  //   putUser({ axiosInstance: valuationsWebApi, method: 'PUT', url: '/users', data: dataToProcess, requestConfig: { headers: {'Authorization': userId,},}}, putUserSuccessCb, putUserErrorCb);
  // }
  const getUserSuccessCb = async (apiData)=>{
    setIsValuationSample(false);
    await setUserData({userId:apiData.id, userPassword: apiData.password, userProduct: apiData.product, userFirstName:apiData.firstName, userLastName:apiData.lastName, userCountry:apiData.country, userCountryName:apiData.countryName,userBirthday:apiData.birthday, userDescription:apiData.description})
    console.log(userData)
  }
  
  const getUserErrorCb=()=>{
    // history.push("/landing");
  }

  useEffect ( ()=> {
    // alert("Home useEffect[], newProduct: " + newProduct + " userId: " + userId + " localStorageCustomerId" + localStorageCustomerId)
    // alert("localStorageUserId"+localStorageUserId)
    // console.log(userData)
    // if (! userId){  // check if the session is already on by checking the token
    //   console.log("entroum em !userId")
    //   getUser({ axiosInstance: valuationsWebApi, method: 'GET', url: `/users/${userId}`, requestConfig: { headers: {'Authorization': userId,},}},getUserSuccessCb, getUserErrorCb);
    // }
    // alert("newProduct: "+newProduct)
    let dataToProcess=""
    if (newProduct!==""){
      setUserData((prevState) => ({ ...prevState, userProduct: newProduct }));
      if (localStorageCustomerId !==""){
        dataToProcess={id: localStorageUserId, product:newProduct, stripeCustomerId:localStorageCustomerId }
      } else {
        dataToProcess={id: localStorageUserId, product:newProduct }
      }
      if (newProduct!=="0"){
        putUser({ axiosInstance: valuationsWebApi, method: 'PUT', url: '/users', data: dataToProcess, requestConfig: { headers: {'Authorization': userId,},}}, putUserSuccessCb, putUserErrorCb);
        setQtyOfValuationsCreated(0)
        setQtyOfValuationsViews(0)
      }
    }
    localStorage.removeItem('userId');
    localStorage.removeItem('customer');
    localStorage.removeItem('newProduct');
    localStorage.removeItem('isRegisteringPlan');
    localStorage.removeItem('isUpgradingPlan');
    localStorage.removeItem("isStripeCheckoutIncomplete")
    localStorage.removeItem("stripeCheckoutIncompleteUserId")
    // alert("Home useEffect[], newProduct: " + newProduct + " userId: " + userId + " localStorageCustomerId" + localStorageCustomerId)
    // if (newProduct==="unsubscribe"){
    //   handleUnsubscribe();

    // } else if(newProduct==="logout") {
    //   history.push('/logout')

    // } else {
    //   alert("isUpgradingPlan" + isUpgradingPlan)
    //   if (isUpgradingPlan){
    //     handleUpgradePlan();
    //   } else {
    //     if (isRegisteringPlan){
    //       handleRegisterPlan();
    //     }
    //   }  
    // }

  },[]) 

  return (
    <>
    { ! userId ? <>
      <Footer />
      {history.push('/landing')}
      </>
    : <> 
    <Grid container direction="column" alignItems="center" style = {{ minHeight: '80vh'}} >

      <Grid item xs={12} style = {{ minHeight: '69px'}} /> 

      <Grid item container direction="row"  >
        { isTablet && !isMobile ?
        <>
        {/* <Paper  className={classes.paperStyle} > */}
          <Grid item xs={6}>
            <Paper elevation = {6} style={{ padding: '5px', margin:"5px", height: 300, overflow: 'hidden', overflowX: 'hidden'}}>
              <MyProfile />
            </Paper>
  
          </Grid>
          <Grid item xs={6}>
            { usersList ?  <TableYourNetwork /> : null}
          </Grid>
        {/* </Paper> */}

        </>
        : 
        <Grid item xs={12} md={2} >
          {/* style={{ margin:"0px", padding:"5px",margin:"auto"}}> */}
          {/* padding: '5px', margin:"5px", height: 300, overflow: 'hidden', overflowX: 'hidden' */}
          <Paper className={classes.paperStyle} sx={{position:"sticky", top:"65px"}}>
            <Paper elevation = {6} style={{ marginLeft:"5px", marginRight:"5px",padding:"5px"}}> 
              <MyProfile />
            </Paper> 
            { usersList ?  <TableYourNetwork /> : null}
          </Paper>
        </Grid> 
        }
        {/* <Box style={{height:"25px"}}/> */}
        <Grid item xs={12} md={7} > 
          <Paper className={classes.paperStyle} >
            <Grid container>
              <Grid item xs={12}>
              <Typography className={classes.sectionTitleStyle}><b>Valuations</b></Typography>
              </Grid>
              { isMobile ? <>
                <Grid item xs={12} >
                  <Box style={{display:"flex", alignItems:"flex-end", justifyContent:"flex-end"}}>
                    <ButtonNewValuation 
                      userId={userId}
                      handleNewValuation={handleNewValuation}
                      handleRegisterMessage={handleRegisterMessage}
                    />

                  </Box>
                  <Box style={{height:"10px"}}/>
                </Grid>
              </> : null}

              <Grid container item xs={9}  >
                <Grid item xs={12} sm={9}>
                  <BottomNavValuations 
                    valuationsFilterCriteria={valuationsFilterCriteria}
                    setValuationsFilterCriteria={setValuationsFilterCriteria}
                  />
                </Grid>
                <Grid item xs={12} sm={3} >
                  { userProduct === "0" ? 
                  <>
                    { ! isMobile? 
                    <>
                      <Box style={{height:"15px"}}/> 
                      <Typography style={{fontSize:11, marginLeft:"15px"}}>{`Viewed Valuations : ${qtyOfValuationsViews}/5`}</Typography>
                      <Typography style={{fontSize:11, marginLeft:"15px"}}>{`Crafted Valuations : ${qtyOfValuationsCreated}/5`}</Typography>           
                    </>
                    : 
                    <>
                      <Typography style={{fontSize:11, marginLeft:"20px"}}>{`Viewed Valuations: ${qtyOfValuationsViews}/5 | Crafted Valuations: ${qtyOfValuationsCreated}/5`}</Typography>
                    </>
                    }
                  </>
                  : 
                  <>
                  { ! isMobile? 
                    <>
                      <Box style={{height:"15px"}}/> 
                      <Typography align="center" style={{fontSize:9, marginLeft:"15px"}}>You have unlimited access</Typography>
                      <Typography align="center" style={{fontSize:9, marginLeft:"15px"}}>to create and view valuations</Typography>           
                    </>
                    : 
                    <>
                      <Typography style={{fontSize:9, marginLeft:"20px"}}>You have unlimited access to create and view valuations</Typography>
                    </>
                    }
                  </>
                }
                </Grid>
              </Grid>
              { ! isMobile ? 
                <Grid item xs={3} >

                  <Box style={{display:"flex", alignItems:"flex-end", justifyContent:"flex-end"}}>
                    <ButtonNewValuation 
                        userId={userId}
                        handleNewValuation={handleNewValuation}
                        handleRegisterMessage={handleRegisterMessage}
                      />
                  </Box>
                </Grid>
              : null}
            </Grid>
            { valuationsList ? <TableRecValuations stakeholder="user"/>
              : <Typography style={{fontSize:14, marginTop:"15px"}}>You don't have any valuation</Typography>
            }
          </Paper>
        </Grid>
        { isTablet && ! isMobile ? 
        <>
        <Paper elevation={0} className={classes.paperStyle} >
          <Grid container >
      
            <Grid item xs={6} style={{backgroundColor:"#b0c4c7"}}>
                <SocialNetworks />
   
                {/* <Box style={{height:"5px"}}/>   */}
                <DoYouKnow 
                  isMobile={isMobile}
                  isTablet={isTablet}
                />
              <Box style={{height:"5px"}}/>  
            </Grid>

            <Grid item xs={6} >
              
              <WhatsNext />
              <Box style={{height:"5px"}}/>
              {/* <DisclaimerText /> */}
            </Grid>
        
          </Grid>
        </Paper>
        </>
        : 
        <Grid item xs={12} md={3} > 

          <Paper className={classes.paperStyle}>
          {/* <Paper elevation={0} style={{backgroundColor:"#F9AA33"}}>   */}
            <SocialNetworks />
            <DoYouKnow 
              isMobile={isMobile}
              isTablet={isTablet}
            />
            {/* </Paper> */}
            <Box style={{height:"5px"}}/>  
              <WhatsNext />
            <Box style={{height:"5px"}}/>
          </Paper>
      </Grid>
      }
      </Grid>

    </Grid>

    </> }

    <DialogFreePlanLimits open={isDialogLimitsOpen} onClose={handleDialogLimitsClose} typeOfLimit={"created"}/>
    <DialogModal open={isDialogOpen} onClose={handleDialogClose} severity={dialogOptions.severity} title={dialogOptions.title} buttons={dialogOptions.buttons} action={dialogOptions.action}>
      {dialogOptions.message}
    </DialogModal>
    </>
  )
}