import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import {Grid, Paper, Box, Typography, Button, Grow } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { LoginContext } from '../helpers/Context';
import useAxios from '../hooks/useAxios';
import valuationsWebApi from '../services/valuationsWebApi';

const useStyles = makeStyles((mainTheme) => ({
  gridContainerStyle:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '80vh', 
    [mainTheme.breakpoints.down('lg')]: {
      minHeight:"85vh",
    },
    marginTop: "64px",
    padding:"0px",
  },
  paperStyle: {
    // display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 350,
    // width:"100%",
    height: 330,
    margin: '10px',
    // backgroundColor:mainTheme.palette.secondary.main,  
    backgroundColor:mainTheme.palette.secondary.main,  
    padding: mainTheme.spacing(2),
  },
  iconBox:{
    width:'100%',
    textAlign: 'center',
    AlignItems: 'center',
  },
  iconStyle:{
    color:mainTheme.palette.primary.main,
  },
  textStyle:{
    // color:mainTheme.palette.primary.main,
    color:"white"
  },
  welcomeMessageStyle:{
    color:mainTheme.palette.primary.main
  },
  buttonStyle:{
    color: "white",
    width:"125px",
    // color:mainTheme.palette.primary.main,
    textTransform:"none",
    fontSize: 11,
    margin: "3px",
    backgroundColor:mainTheme.palette.primary.main,
    "&:hover": {
      color:mainTheme.palette.secondary.main,
      backgroundColor:mainTheme.palette.primary.main,
    },
    "&:disabled": {
      color:"gray",
      backgroundColor:mainTheme.palette.primary.main,
    },
  },
  }));

export default function StripeCanceled(){
  const classes = useStyles();
  const { userData, setUserData } = useContext(LoginContext);
  const { userId} = userData;
  const isUpgradingPlan = JSON.parse(localStorage.getItem('isUpgradingPlan')); //only for UI
  const storedUserData = JSON.parse(localStorage.getItem('registerData')); // retrieves the global state after stripe redirection
  const { axiosFetch: delUser, isLoading: isLoadingDelUser, error: isErrorDelUser } = useAxios();

  const history = useHistory();

  const handleFinish = async ()=>{ // If the user accepts the free plan after a failed payment
    if (storedUserData) {
      await setUserData({userId:storedUserData.id, userPassword: storedUserData.password, userProduct: storedUserData.product, userFirstName:storedUserData.firstName, userLastName:storedUserData.lastName, userCountry:storedUserData.country, userCountryName:storedUserData.countryName,userBirthday:storedUserData.birthday, userDescription:storedUserData.description})
    } 
    localStorage.removeItem("isRegisterComplete");
    localStorage.removeItem("registerData");
    history.push(`/home/0`) 
  }

  const handleUnsubscribe=()=>{ // If it's a user of a free plan that wants to unsubscribe
    history.push(`/unsubscribe`)
  }

  const delUserSuccessCb=()=>{
    localStorage.removeItem('userId');
    localStorage.removeItem('customer');
    localStorage.removeItem('newProduct');
    localStorage.removeItem('registerData');
    localStorage.removeItem('isRegisterComplete');
    localStorage.removeItem('isRegisteringPlan');
    localStorage.removeItem('isUpgradingPlan');
    // localStorage.removeItem("isStripeCheckoutIncomplete")
    // localStorage.removeItem("stripeCheckoutIncompleteUserId")

    history.push(`/logout`)
  }

  const delUserErrorCb=()=>{
    history.push(`/logout`)
  }

  const handleCancel=()=>{ // it's a new user with a failed payment and doesn't want the free plan either
    // it must be deleted, since before redirection to stripe checkout the user data has beeen saved on the database
    // alert("storedUserData.userId "+storedUserData.id)
    delUser({ axiosInstance: valuationsWebApi, method: 'DELETE', url: `/users/${storedUserData.id}`, requestConfig: { headers: {'Authorization': userId,},}},delUserSuccessCb, delUserErrorCb);
  }

  useEffect ( ()=> {
    // localStorage.removeItem("isStripeCheckoutIncomplete");
    // localStorage.removeItem("stripeCheckoutIncompleteUserId");
    // localStorage.removeItem("isRegisterComplete");
    // localStorage.removeItem("registerData");
  },[]) 
  
  return (
    <>
    <Grid container alignItems="center" justifyContent="center" spacing={1}  className={classes.gridContainerStyle}  >
      <Grid item>
      <Grow in timeout = {1000}>
        <Paper elevation={12} className={classes.paperStyle} style={{paddingBottom: "10px"}} >
          <Box style={{display:"flex", flexDirection:"column", height:"100%", justifyContent:"space-between"}}>
            <Box>
              { isUpgradingPlan ?
              <Typography align='center' gutterBottom  className={classes.textStyle} >
                Upgrade Inconvenience</Typography>  
              :
              <Typography align='center' gutterBottom  className={classes.textStyle} >
                Welcome to ValuationsHub !</Typography>  
              }
            {/* <Typography align='center' gutterBottom  className={classes.textStyle} >Welcome to ValuationsHub</Typography>   */}
            <Box className={classes.iconBox} >
              <CheckCircleOutlineIcon className={classes.iconStyle} style={{ fontSize: 40 }}/>
            </Box>
                <Grid container spacing={1}>
                  <Grid item container display="flex" alignItems="center" justifyContent="center" xs={12} style={{marginBottom:"8px"}}>
                  { isUpgradingPlan ? 
                    <>
                    <Typography variant="body2" align="center" gutterBottom className={classes.welcomeMessageStyle} ><b>
                      Payment Issue? Stay in Our Free Tier !</b></Typography>
                    <Typography variant="caption" align="justify" paragraph className={classes.welcomeMessageStyle} >
                      Oops! It appears there was an issue processing your payment as you tried to upgrade to our paid plan. In the meantime, continue enjoying the perks of our free tier, which still provides 5 valuations every month. We appreciate your interest in our paid plan, and we're committed to making sure you have a smooth experience.
                    </Typography>
                    </>
                  : 
                    <>
                    <Typography variant="body2" align="center" gutterBottom className={classes.welcomeMessageStyle} ><b>Payment Issue? Try Our Free Tier !</b></Typography>
                    <Typography variant="caption" align="justify" paragraph className={classes.welcomeMessageStyle} >
                      Oops! It looks like there was an issue processing your payment. No worries, these things happen! How about giving our free tier a spin instead? It's a great way to explore our service without any cost. If you have any questions or need assistance, feel free to reach out. We're here to help! 
                    </Typography>
                    </>
                  }
                  </Grid>
                  <Box style={{height:"45px"}}/>
                  <Grid item xs={12} display="flex" alignItems="center" justifyContent="center" style={{paddingTop:"0px"}}>
                  { isUpgradingPlan ? 
                  <>
                    <Button autofocus variant="contained" className={classes.buttonStyle} disableRipple color="primary" onClick={handleUnsubscribe}>
                      No, thanks 
                    </Button>
                    <Button autofocus variant="contained" className={classes.buttonStyle} disableRipple color="primary" onClick={handleFinish}>
                      Stay in Free Plan
                    </Button>
                  </>
                  : // isRegistering=true
                  <>
                    <Button autofocus variant="contained" className={classes.buttonStyle} disableRipple color="primary" onClick={handleCancel}>
                      No, thanks 
                    </Button>
                    <Button autofocus variant="contained" className={classes.buttonStyle} disableRipple color="primary" onClick={handleFinish}>
                      Sign Up for Free
                    </Button>
                  </>
                  }
                  </Grid>
                </Grid>
            </Box>
          </Box>
        </Paper>
      </Grow>
      </Grid>

    </Grid>
    </>
  )
}