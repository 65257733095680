import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Avatar, Button, Divider } from '@mui/material';

import { LoginContext } from '../helpers/Context';
import DialogModal from './modals/DialogModal';

const getImageSize=(size)=>{
  if (! size){
    return "45px"
  }
  if (size === "small"){
    return "32px"
  }
  if (size === "medium"){
    return "48px"
  }
  if (size === "large"){
    return "68px"
  }
}

function removeBase64Prefix(base64String) {
  //usage: const cleanedBase64 = removeBase64Prefix(base64String);
  const prefix = "data:image/jpeg;base64,";
  if (base64String){
    if (base64String.startsWith(prefix)) {
      return base64String.substring(prefix.length);
    } else {
      // If the prefix is not found, return the string as is
      return base64String;
  } 
  }
  return ""
}

const ShowUserImageByUserId = ({ userId, usersImages, size }) => {
  const { setPeerProfileData, usersList, userData, isValuationSample } = useContext(LoginContext);
  const { userId: currentLoggedUser, userFirstName} = userData;
  // const { profileFirstName, profileLastName }=peerProfileData
  const imageSize = getImageSize(size);
  const peerData = usersList? usersList.find(user => user.profileId === userId):{firstName:"",lastName:""};
  const userImage = usersImages.find(currUser => currUser.userId === userId);
  // const userImageBase64 = userImage ? removeBase64Prefix(userImage.image): null;
  const [ dialogOptions, setDialogOptions]= useState({severity:"",title:"",message1:"",message2:"",buttons:{}, action:""});
  const [ isDialogOpen, setIsDialogOpen] = useState(false);
  const history = useHistory();


  const handleDialogClose=()=>{
    setIsDialogOpen(false);
  }

  const initialsToDisplay=()=>{
    if (peerData){
      if (peerData.firstName){
        return peerData.firstName.charAt(0).toUpperCase()
      } else if (peerData.lastName) {
        return peerData.lastName.charAt(0).toUpperCase()
      }
    } else {
      return "U"
    }
  }

  const handlePeerProfile=()=>{
    if (isValuationSample){
      setDialogOptions({severity:"alert", title:"Please Sign up and register", message1:"You need to sign up to look at users' profiles and have access to other valuations", message2:"Check our Plans including the free tier one.",buttons:{button1:"Ok"}})
      setIsDialogOpen (true);
      // alert("You need to sign up to look at users' profiles. Check our Plans including the free tier one.")
    } else {
      if (userId===currentLoggedUser){
        history.push('/home')
      } 
      else {
        const peerProfile = usersList.find(user => user.profileId === userId);
        setPeerProfileData(peerProfile)
        history.push('/peer-profile')
        // const peerProfile = usersList.find(user => user.profileId === userId);
        // peerProfileData, (peerProfile)
        // history.push('/peer-profile')
      }
    }
  }

  return (
  <>
  { userImage ? 
    
    <Button onClick={handlePeerProfile} disableRipple style={{ background: 'none', border: 'none', cursor: 'pointer', width: imageSize, height: imageSize}} >
      <Avatar
      style={{ width: imageSize, height: imageSize }}
      alt={`User ${userId}`}
      // src={userImage ? `data:image/jpeg;base64,${userImageBase64}` : ''}
      src={userImage.image ? userImage.image : "/broken-image.jpg"}

      />
    </Button> 
    
  : 
    <Avatar style={{ width: imageSize, height: imageSize }}>{size==="small"? initialsToDisplay() : initialsToDisplay() }</Avatar> 
  }
  <DialogModal open={isDialogOpen} onClose={handleDialogClose} severity={dialogOptions.severity} title={dialogOptions.title} buttons={dialogOptions.buttons} action={dialogOptions.action}>
    <p>{dialogOptions.message1}</p>
    <p>{dialogOptions.message2}</p>
  </DialogModal> 
  </>
  );
};

export default ShowUserImageByUserId
