import React from 'react';

import {Grid, Box, Typography, CircularProgress } from '@mui/material';

export default function StripeCheckout(){

  return (
    <>
    <Grid container spacing={2}>
      <Box style={{height:"60px"}}/>
      <Box style={{display:"flex", AlignItems:"center", justifyContent:"center", width:"100%"}}>
        <Typography align="center" style={{fontSize:"13px", marginTop:"60px"}}>Loading payment info form... </Typography>
      </Box>
      <Box style={{display:"flex", AlignItems:"center", justifyContent:"center", width:"100%"}}>
      <CircularProgress />
      </Box>
    </Grid>
    </>
  )
}