import React, { useContext } from 'react';

import { Box, BottomNavigation, BottomNavigationAction } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ArchiveIcon from '@mui/icons-material/Archive';
import FavoriteIcon from '@mui/icons-material/Favorite';
import RecommendIcon from '@mui/icons-material/Recommend';

import { LoginContext } from '../../helpers/Context';

const useStyles = makeStyles( (mainTheme) => ({
  bottomNavStyle:{
    width: "460px",
    [mainTheme.breakpoints.down('sm')]: {
      width: "350px"
    },
  },  
  selected: {
    fontSize:'11px',
    padding:"0px"
  },
  actionLabel: {
    fontSize: '11px',
    padding:"0px"
  },
})) 

export default function NavPeerValuations ({valuationsFilterCriteria, setValuationsFilterCriteria}){
  const classes = useStyles();
  const { peerProfileData } = useContext(LoginContext);
  const { profileId, profileFirstName, profileLastName }= peerProfileData
 
  const displayShortName =(firstName, lastName)=>{
    const names = firstName.split(" ");
    const surnames = lastName.split("");
    if (firstName.length < 10) {
      return firstName
    }
    if (names.length > 0){
      if (names[0].trim().length < 10){
        return names[0].trim()
      }
    }
    if (lastName.length < 10){
      return lastName
    }
    if (surnames.length > 0){
      if (surnames[0].trim().length < 10){
        return surnames[0].trim()
      }
    }
    return `${firstName.slice(0, 1)}${lastName.slice(0, 1)}`
  }

  // const peerName=()=>{
  //   if (profileFirstName){
  //     return profileFirstName
  //   }
  //   if (profileLastName){
  //     return profileLastName
  //   }
  //   return "User"
  // }

  return (
    <>
    <Box className={classes.bottomNavStyle} >
      <BottomNavigation
        showLabels
        value={valuationsFilterCriteria}
        onChange={(event, newValue) => {
          setValuationsFilterCriteria(newValue);
        }}
        sx={{
          bgcolor: "whitesmoke", //"#F9AA33",
          '& .Mui-selected': {
            '& .MuiBottomNavigationAction-label': {
              fontSize: "11px", 
              transition: 'none',
              fontWeight: 'bold',
              lineHeight: '20px'
            },
            '& .MuiSvgIcon-root, & .MuiBottomNavigationAction-label': {
              color: "#F9AA33" 
            }
          }
        }}
      >
        <BottomNavigationAction label="Recommended" icon={<RecommendIcon />} className={valuationsFilterCriteria === 0 ? classes.selected : classes.actionLabel}/>
        <BottomNavigationAction label={`${displayShortName(profileFirstName,profileLastName)}'s Favorites`} icon={<FavoriteIcon />} className={valuationsFilterCriteria === 1 ? classes.selected : classes.actionLabel}/>
        <BottomNavigationAction label={`${displayShortName(profileFirstName,profileLastName)}'s Valuations`} icon={<ArchiveIcon />} className={valuationsFilterCriteria === 2 ? classes.selected : classes.actionLabel}/>
      </BottomNavigation>
    </Box>
    </>
  )
}