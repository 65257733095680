import React from 'react';
import { Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((mainTheme) => ({
  paperStyle:{
    width: "100%",   
    marginLeft:"3px",
    marginRight:"0px",
    padding: "5px",
    color: mainTheme.palette.primary.main,
    backgroundColor: "whitesmoke",
  },
  sectionTitleStyle:{
    marginLeft:"3px",
    marginTop:"3px",
    fontSize: mainTheme.sectionTitle.fontSize,
    color: mainTheme.sectionTitle.color
  },
  boldTextStyle:{
    marginLeft:"3px",
    marginTop:"3px",
    fontSize:"13px", 
    color:mainTheme.palette.primary.main,
  },
  textStyle:{
    marginLeft:"3px",
    marginTop:"3px",
    fontSize:"13px", 
    color:mainTheme.palette.tertiary.main,
  }
})); 

export default function WhatsNext (){
  const classes = useStyles();

  return (
    <>
    <Paper elevation={6} className={classes.paperStyle} >
      <Typography gutterBottom align="justify" className={classes.sectionTitleStyle} ><b>What's coming next...</b></Typography>
      <Typography gutterBottom align="justify" className={classes.textStyle} ><b className={classes.boldTextStyle}>Banks and Insurance companies valuation</b>, using the Gordon's Model</Typography>
      <Typography gutterBottom align="justify" className={classes.textStyle} ><b className={classes.boldTextStyle}>Sensitivity Analysis</b>, to see the different impact of variations of the assumptions</Typography>
      <Typography gutterBottom align="justify" className={classes.textStyle} ><b className={classes.boldTextStyle}>Step by Step valuation</b>, so you can create your own valuations more easily</Typography>
      <Typography gutterBottom align="justify" className={classes.textStyle} ><b className={classes.boldTextStyle}>2+ stages valuations</b>, to create different assumptions for the next years</Typography>
      <Typography gutterBottom align="justify" className={classes.textStyle} ><b className={classes.boldTextStyle}>Customized entry data for some companies</b>, such as Amazon, Google, Meta, Tesla and others, so you can simulate different scenarios by each line of business</Typography>
      {/* <Typography gutterBottom className={classes.textStyle} ><b className={classes.boldTextStyle}>A mobile app</b>, better suited for you phone</Typography> */}
    </Paper>
    </>
  )
}