import React, { useState, useContext, useEffect } from 'react';
import ReactGA from 'react-ga4';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import { Paper, Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box, Button, Avatar, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
import CancelIcon from '@mui/icons-material/Cancel';
// import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import { LoginContext } from '../../../../helpers/Context.js';
import useAxios from '../../../../hooks/useAxios.js';
import valuationsWebApi from '../../../../services/valuationsWebApi.js';

import LogoWhiteBackground from '../../../../assets/LogoVH-B&W1.svg';

import TableValuation from '../../TableValuation.jsx';
import TableMarginOfSafety from '../TableMarginOfSafety.jsx';
import TableCashFlow from '../../TableCashFlow.jsx';
import ChartFreeCashFlow from '../../ChartFreeCashFlow.jsx';
import EstimatedFcffText from '../EstimatedFcffText.jsx';
import EstimatedCostOfCapitalText from '../EstimatedCostOfcapitalText.jsx';
import TableBusinessAssumptions from '../TableBusinessAssumptions.jsx';
import TableCostOfCapitalAssumptions from '../TableCostOfCapitalAssumptions.jsx';
import TableIncomeStatement from '../../TableIncomeStatement.jsx';
import CompanyText from '../CompanyText.jsx';
// import AssumptionsText from '../../AssumptionsText.jsx';
import DisclaimerText from '../../DisclaimerText.jsx';
import TableUserCostOfCapital from '../TableUserCostOfCapital.jsx';
import ShowCompanyLogo from '../../../../components/ShowCompanyLogo.jsx';

// import custom components

import AssumptionsText from '../../AssumptionsText.jsx';


const useStyles = makeStyles((mainTheme) => ({
  buttonStyle:{
    minWidth:"135px",
  }, 
  dialogStyle:{
    width: "500px", 
  },
  logoStyle: {
    position: "relative",
    height: "56px",
    // padding: "24px",
    top:"0px"
  },
  userNameStyle:{
    fontSize: "12px",
    // marginLeft:"2px",
    color: mainTheme.palette.tertiary.main
  },
  simpleValuationStyle:{
    fontSize:11, 
    margin:"3px",
    color: mainTheme.palette.tertiary.main
  },

}))

export default function DownloadMobileReport ({ open, onClose, companyData, historicalFinancialData, combinedFinancialData, assumptions, calculatedCostOfCapital, valuation, isCheckedDescOrder, isCheckedShowPreviousYears, isEstimateFcffOnly, isUserCostOfCapital, valuationDateInfo }) {
  const classes = useStyles();
  const last8Years = combinedFinancialData.length>0 ? combinedFinancialData[0].year -5: 2022;
  const filteredCombinedFinancialData = combinedFinancialData.filter(item => item.year >= last8Years);
  const { savedValuationData } = useContext(LoginContext);
  const { profileId, profileFirstName, profileLastName} = savedValuationData
  const { assumptionsText } = assumptions
  const companyId= companyData.symbol;
  const { axiosFetch: getProfileImage } = useAxios();
  const [ profilePictureDataUrl, setProfilePictureDataUrl] = useState(null);
  const [ loader, setLoader] = useState(false);

  const canvasOptions = {
    backgroundColor: '#ffffff',
    allowTaint : true,
    useCors : true,
    imageTimeout: 15000,
    scale: 2
  };

  const downloadReport = () =>{
    const capture = document.querySelector('.valuation-report');
    setLoader(true);
    html2canvas(capture, canvasOptions).then((canvas)=>{
      const imgData = canvas.toDataURL('image/png', 1); // 0.8 is the quality parameter (0 to 1)
      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;
      const link = document.createElement('a');
      link.href = imgData;
      // link.download = `valuation-${companyData.shortName}.png`; // You can change the file extension to .jpeg if needed
      // link.click();

      var pdf = new jsPDF('p', 'px', [canvasWidth,canvasHeight]);
      pdf.addImage(imgData, 'PNG', 0, 0, canvasWidth, canvasHeight);
      pdf.setPage(1);
      pdf.setTextColor(52,73,85);
      // pdf.setFont("roboto");
      pdf.setFont("roboto",undefined, "bold");
      pdf.setFontSize(30);

      // Add a clickable link annotation
      pdf.textWithLink(
        'www.valuationshub.com',
        520, // X coordinate
        75, // Y coordinate
      {
        url: 'https://www.valuationshub.com', // Replace with your home page URL
      }
      );

      pdf.save(`valuation-${companyData.shortName}.pdf`);
      setLoader(false);
    })
  }

  const getFirstAndLastName= (fullName) => {
    const nameParts = fullName.split(' ');
    const firstName = nameParts[0];
    const lastName = nameParts[nameParts.length - 1];
    if (firstName==="" && lastName===""){
      return "Unidentified"
    }
    return `${firstName} ${lastName}`;
  } 

  const getProfileImageSuccessCb=(apiData)=>{
    if (apiData){
      setProfilePictureDataUrl(apiData.dataUrl)
    }
  }

  const getProfileImageErrorCb=()=>{
    // alert("error receiving user photo from the server")
  }

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/report-mobile", title: "Report Mobile" });
    getProfileImage({ axiosInstance: valuationsWebApi, method: 'GET', url: `/proxy-image/${profileId}`, requestConfig: { headers: {'Authorization': profileId,},}},getProfileImageSuccessCb, getProfileImageErrorCb);
  }, []); 


  return (
    <>
    { companyData ? <>
    
      <Dialog 
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="false"
        className={classes.dialogStyle}
      >
        <DialogTitle id="alert-dialog-title" ><Typography align="center" variant="h6" style={{color:'white'}}>Generate Pdf</Typography></DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
          
              <div className="valuation-report">

                <Grid item container xs={12} > 
                  
                  <Grid item container xs={8} >
                    <Grid item xs={12}>
                        <img src = {LogoWhiteBackground} alt="Logo" className={classes.logoStyle}  />
                    </Grid>

                    <Grid item xs={12}>
                      <Box style={{marginLeft:"5px"}} />
                      <Box style={{display:"flex", alignItems:"left", justifyContent:"flex-start", marginLeft:"20px"}}>
                        { profilePictureDataUrl && (
                            <>
                            <Grid container>
                              <Grid item xs={12}>
                                <Typography align ="left" className={classes.userNameStyle} style={{marginLeft:"10px"}} >
                                  Crafted by</Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Avatar
                                  style={{ width: '68px', height: '68px', marginLeft:"5px" }}
                                  alt={`User ${profileId}`}
                                  src={profilePictureDataUrl}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Typography align = "left" className={classes.userNameStyle} style={{marginLeft:"2px"}}>
                                {getFirstAndLastName(`${profileFirstName} ${profileLastName}`)}</Typography>
                              </Grid>
                            </Grid>
                            </>
                          )}
                      </Box>
                    </Grid>

                    <Grid item container direction="row" xs={12} style={{marginLeft:"15px",marginTop:"5px",marginBottom:"5px"}}>
                        {/* <Box style={{marginLeft:"5px", marginTop:"5px"}}> */}
                        <Grid item xs={2} sm={2} style={{ display:"flex", alignItems:"center", justifyContent:"center"}}>
                          <ShowCompanyLogo 
                            companyId={companyId}
                            // companyLogoDataUrl={companyLogoDataUrl}
                            size="medium"
                          /> 
                        
                          {/* { companyLogoDataUrl ? <img src = {companyLogoDataUrl} alt="company logo" style={{height:"28px"}}/>: <Typography>Sin imagen</Typography>}     */}
                        </Grid>  
                        <Grid item xs={10} sm={10}  >
                          <CompanyText />
                        </Grid>  
                        {/* { companyId==="AAPL" || companyId==="AMZN" || companyId==="CAT" ? 
                            <img src = {getCustomizedLogo(companyId)} alt="Logo" style={{height:"32px"}}/>   
                            : */}
                        {/* { companyLogoDataUrl ? <img src = {companyLogoDataUrl} alt="Logo" style={{height:"32px"}}/>: <Typography>Sin imagen</Typography>}     */}
                          
                        {/* } */}
                          {/* <CompanyText /> */}
                        {/* </Box> */}
                      </Grid>
                    
                    {/* <Grid item xs={12} >
                      <Box style={{marginLeft:"5px", marginTop:"10px"}}>
                        <CompanyText companyData={companyData} savedValuationData={savedValuationData}/>  
                      </Box>  
                    </Grid> */}

                    <Grid item xs={12}>
                      <Box style={{width:"100%", marginLeft:"0px"}}>
                        <TableMarginOfSafety 
                          valuation = {valuation} 
                          historicalFinancialData = {historicalFinancialData} 
                          calculatedCostOfCapital = {calculatedCostOfCapital}
                          assumptions = {assumptions}
                          companyData = {companyData}
                        />  
                      </Box>
                    </Grid>
                    <Box style={{height:"5px"}}/>
                  </Grid> 

                  {/* right side */}
                  <Grid item container xs={4}  >
                    <Typography align='center' gutterBottom style={{fontSize:"11px", marginRight:"10px", marginTop:"15px", color:"#344955"}}> 
                      find more valuations at:</Typography>
                  </Grid>
                </Grid>

                <Grid item container xs={12} >
                  { assumptionsText !=="" ? 
                    <Grid item container display="flex" alignItems="flex-end" justifyContent="flex-end" >  
                      <Box sx={{marginRight:"5px",paddingRight:"15px", width:"100%"}}>
                        <Grid item >
                          <AssumptionsText assumptionsText={assumptionsText}/>
                        </Grid>
                      </Box>
                    </Grid>
                  :
                    null
                  }
                </Grid>

                <Grid item container xs={12} >
                  <Paper style={{backgroundColor:"white"}}>
                    <Box style={{height:"8px"}}/>
                    {/* <Paper style={{marginLeft:"10px", width:"98%"}}>
                      <TableIncomeStatement 
                          combinedFinancialData = {filteredCombinedFinancialData}
                          assumptions = {assumptions}
                          isCheckedShowPreviousYears = {isCheckedShowPreviousYears}
                          isCheckedDescOrder = {isCheckedDescOrder}
                          isEstimateFcffOnly = {isEstimateFcffOnly}
                      />
                    </Paper> */}
                    {/* <Box style={{height:"5px"}}/>
                    <Paper style={{marginLeft:"10px", width:"98%"}}>
                      <TableCashFlow 
                        combinedFinancialData = {filteredCombinedFinancialData}
                        assumptions = {assumptions}
                        isCheckedShowPreviousYears = {isCheckedShowPreviousYears}
                        isCheckedDescOrder = {isCheckedDescOrder}
                        isEstimateFcffOnly = {isEstimateFcffOnly}
                      />
                    </Paper>
                    <Box style={{height:"10px"}}/> */}
                  </Paper>
                </Grid>

              <Grid item xs={12} style={{marginRigth:"5px"}} >

                { isEstimateFcffOnly ? <>
                  <Box style={{marginLeft:"5px", width:"97%"}}> 
                    <EstimatedFcffText />  
                  </Box>
                </> : null } 

                <Paper style={{margin:"10px",width:"95%"}}>
                  <TableBusinessAssumptions assumptions = {assumptions} isEstimateFcffOnly={isEstimateFcffOnly}/>
                </Paper>

                { isUserCostOfCapital ? 
                <>
                  <Paper style={{margin:"10px",width:"95%"}}>
                    <EstimatedCostOfCapitalText />
                    <TableUserCostOfCapital assumptions = {assumptions} />
                  </Paper>
                </>
                : 
                <>
                  <Paper style={{margin:"10px",width:"95%"}}>
                    <TableCostOfCapitalAssumptions assumptions = {assumptions} calculatedCostOfCapital = {calculatedCostOfCapital} />
                  </Paper>
                </>
                }
                
                <Paper style={{margin:"10px",width:"95%"}}>
                  <TableValuation 
                  valuation = {valuation} 
                  combinedFinancialData = {combinedFinancialData}
                  historicalFinancialData = {historicalFinancialData} 
                  calculatedCostOfCapital = {calculatedCostOfCapital}
                  assumptions = {assumptions}
                  companyData={companyData}
                  valuationDateInfo={valuationDateInfo}
                  />
                </Paper> 

                <Paper elevation={0} style={{marginLeft:"10px",width:"95%"}}>
                  <ChartFreeCashFlow
                    combinedFinancialData = {combinedFinancialData}
                    assumptions = {assumptions}
                    isCheckedShowPreviousYears = {isCheckedShowPreviousYears}
                    isCheckedDescOrder = {isCheckedDescOrder}
                  /> 
                </Paper>
              </Grid>
              <Box sx={{height:"5px"}}/>

              <Box sx={{width:"99%"}}>
                <DisclaimerText/>
              </Box>

              <Box sx={{height:"5px"}}/> 

            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions >
        
          <Grid container direction="row" xs={6} style={{textAlign:'center'}}>
            <Button disableRipple className={classes.buttonStyle} onClick={onClose} startIcon={<CancelIcon />}>Cancel</Button>
            <Button disableRipple className={classes.buttonStyle} onClick={downloadReport} startIcon={<CloudDownloadIcon />}>Download as PDF</Button>
            <Box style={{height: "20px"}}/>
          </Grid> 
        </DialogActions>
      </Dialog>

    </> : null}
  </>

  )
}