import React, { useState, useEffect} from 'react';
import ReactGA from 'react-ga4';

import { Grid, Paper, Box, Typography, Avatar, Grow, useTheme, useMediaQuery } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import makeStyles from '@mui/styles/makeStyles';

import picMcl from '../assets/mcl.jpg';

import Footer from '../components/Footer';
import SocialNetworks from '../components/SocialNetworks';

const useStyles = makeStyles((mainTheme) => ({
  // root: {
  //   flexGrow: 1,

  // },
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh', // Set the height of the container to full viewport height
    flexGrow: 1,
  },
  contentStyle: {
    position: 'absolute',
    top: '65px'
  },
  paper: {
    padding: mainTheme.spacing(2),
    margin: '10px',
    width:"350px"
    // minWidth: 350,
    // maxWidth: 500,
  },
  photoStyle:{
    height: '90px',
    width: "90px",
    textAlign: "center",
    display: "block",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    marginTop: "10px",
    marginBottom:"10px",
  }, 
  logoStyle:{
    height: "35px",
    [mainTheme.breakpoints.down('sm')]: {
      height: "22px",
    },
    textAlign: "center",
    display: "inline",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    marginTop: "10px",
  }, 
  logoJsStyle:{
    height: "40px",
    [mainTheme.breakpoints.down('sm')]: {
      height: "22px",
    },
    textAlign: "center",
    display: "inline",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    marginTop: "10px",
  }, 
  titleStyle:{
    fontSize:"14px",
    color:mainTheme.palette.primary.main
  },
  textStyle:{
    fontSize:"13px",
    color:mainTheme.palette.primary.main
  },
  circularProgressStyle:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh', 
  },
  })); 

export default function Whoweare2 () {


  const classes = useStyles();
  // const [ loading, setLoading]= useState(true)
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const images = {
    html: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original-wordmark.svg",
    css: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original-wordmark.svg",
    javascript: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg",
    python: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/python/python-original-wordmark.svg",
    react: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original-wordmark.svg",
    node: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-plain.svg",
    sql:"https://cdn.jsdelivr.net/gh/devicons/devicon/icons/postgresql/postgresql-plain.svg",
    figma: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/figma/figma-original.svg",
    git:"https://cdn.jsdelivr.net/gh/devicons/devicon/icons/git/git-plain.svg"
  };

  const getImageSize = () => {
    const numImages = 13;
    return `${Math.floor(350 / numImages)}px`;
  };

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/who-we-are", title: "whoweare" });

  }, []);

  return <>
   {/* <Grid container alignItems="center" justifyContent="center" spacing={1} sm={12} className={classes.gridContainerStyle}   > */}
  <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" className={classes.contentStyle} style={{ minHeight: '85vh' }} >
  {/* { isMobile ?
    <Grid item xs={12} style={{display:"flex",justifyContent:"center", width:"100%"}}>
      <SocialNetworks />  
    </Grid>
  :
    <Grid item xs={12} style={{display:"flex",justifyContent:"flex-end", width:"99%"}}>
      <SocialNetworks />  
    </Grid> 
  } */}
    <Grow in timeout = {1000}>

    <Grid item xs={12} md={6} >

      <Paper className={classes.paper} elevation={12} >
        <Typography gutterBottom align='center' className={classes.titleStyle}>
          Martin Calcena Simões Lopes
        </Typography>
          <Avatar src={picMcl} alt="Martin Calcena Picture"  className={classes.photoStyle} />
        <Typography gutterBottom className={classes.textStyle}>
          Education: BS in Business Administration and MBA in Finance
        </Typography>
        <Typography gutterBottom align='justify' className={classes.textStyle}> 
          29+ years of work experience in Business, Sales, Finance, Financial Modeling, Projects, FP&A and New Products Development in companies such as Chevron, DPA (Nestlé & Fonterra Joint Venture), and AON Affinity among others. 
        </Typography>
        <Typography gutterBottom align='justify' className={classes.textStyle}> 
          Founder of ValuationsHub
        </Typography>
        <Typography gutterBottom align='justify' className={classes.textStyle}>
          Self-taught full stack developer working with HTML, CSS, Javascript, Python, React, Node Js, SQL, Figma and Git/GitHub. 
        </Typography> 

        <Box style={{height:"10px"}}/>
        <Box display="flex" justifyContent="center" >
          {Object.keys(images).map((tool) => (
            <img
              key={tool}
              src={images[tool]}
              alt={tool}
              style={{ width: getImageSize(), height: 'auto', margin: '5px' }}
            />
          ))}
        </Box>

          {/* <Grid container direction='row' spacing={1}>
            <Grid item xs={2} />
            <Grid item xs={2} >
              <img src = {picJs} alt="Javascript"  className={classes.logoStyle} /> 
            </Grid>
            
            <Grid item xs={1}  >
              <img src = {picReact} alt="React" className={classes.logoStyle} /> 
            </Grid>
            <Grid item xs={1} >
              <img src = {picNode} alt="Node" className={classes.logoStyle} /> 
            </Grid>
            <Grid item xs={1}  >
              <img src = {picSql} alt="Sql" className={classes.logoStyle} style={{marginLeft:"5px"}}/> 
            </Grid>
            <Grid item xs={1} >
              <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/python/python-original-wordmark.svg" className={classes.logoStyle} />
              {/* <img src = {picPython} alt="Python" className={classes.logoStyle} />  */}
            {/* </Grid>
            <Grid item xs={1} >
              <img src = {picFigma} alt="Figma" className={classes.logoStyle} /> 
            </Grid>
            <Grid item xs={1} >
              <img src = {picGit} alt="Git" className={classes.logoStyle} /> 
            </Grid>
            <Grid item xs={2} />
          </Grid> */} 
        </Paper>

    </Grid>
    </Grow>


  </Grid>
  {/* <Footer /> */}
  {/* } */}
</>
}