import React from 'react';

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Box, Button, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
// import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
// import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';

const useStyles = makeStyles( (mainTheme) => ({
  iconBox:{
    width:'100%',
    textAlign: 'center',
    AlignItems: 'center',
  },
  iconStyle:{
    color: "white", 
  },
  buttonStyle:{
    backgroundColor:"white",
    textTransform:"none",
    fontSize: 12,
    [mainTheme.breakpoints.down('sm')]: {
      fontSize: "11px"
    },
    margin: "10px",
    // paddingLeft:"15px",
    // minWidth:"90px",
    width:"205px",
    // [mainTheme.breakpoints.down('sm')]: {
    //   width:"210px",
    // },
    borderColor:"white",
    "&:hover": {
      color:mainTheme.palette.primary.main,
      backgroundColor:"white",
      borderColor:"white",
    },
  },  
}))

export default function DialogFreePlanLimits ({open, onClose, typeOfLimit}) {

  const classes = useStyles();
  const bkColor= "#4caf50" //"#ff9800";
  
  // you've hit the monthly limit of valuations views
  const typeOfLimitText=()=>{

    if (! typeOfLimit){ // default
      return "valuations viewed"
    }
    if (typeOfLimit==="viewed") {
      return "valuations viewed"
    }
    if (typeOfLimit==="created"){
      return "valuations crafted"
    }
  }

  return (
    
    <div >
    <Dialog 
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box className={classes.iconBox} style={{color:'white',backgroundColor:bkColor}}>
      <Box style={{height:20}} />
        <InfoIcon className={classes.iconStyle} style={{ fontSize: 40 }} />
      </Box>
      <DialogTitle id="alert-dialog-title" style={{backgroundColor:bkColor, paddingTop:"0px", paddingBottom:"0px"}}><Typography align="center" variant="subtitle2" style={{color:'white'}}>Monthly Limit of Free Plan</Typography></DialogTitle>
      <DialogContent style={{color:'white', paddingTop:"0px", paddingBottom:"0px", backgroundColor:bkColor}}>
        <DialogContentText id="alert-dialog-description">
        <Box style={{height:10}} />
          <Box >
            <Typography gutterBottom align="center" style={{ color: 'white', fontSize :"13px" }} >You've been actively using ValuationsHub, and we love having you on board ! </Typography>
            <Box style={{height:2}} />
            <Typography gutterBottom align="center" style={{ color: 'white', fontSize :"13px" }}>{`Just a quick heads up: you've hit the monthly limit of ${typeOfLimitText()} for our free plan.`}</Typography> 
            <Box style={{height:2}} />
            <Typography gutterBottom align="center" style={{ color: 'white', fontSize :"13px" }}>Consider upgrading your plan to unlock unlimited access and exclusive features !</Typography>
            <Box style={{height:2}} />
            <Typography gutterBottom align="center" style={{ color: 'white', fontSize :"13px" }}>Thanks for being a valued user !</Typography> 
          </Box>
        </DialogContentText>
      </DialogContent>
        
      <DialogActions style={{color:'white', backgroundColor:bkColor, paddingTop:"0px", paddingBottom:"0px"}}>
        <Grid container direction="row" xs={12} style={{textAlign:'center'}}>
          <Box style={{width:"100%",display:"flex", AlignItems:"center", justifyContent:"center"}}>
            <Button type="submit" className={classes.buttonStyle} style={{color:bkColor}} variant="outlined" disableRipple onClick={() => onClose ("No", "Upgrade")}>
              No, thanks, maybe later</Button>
            <Button type="submit" className={classes.buttonStyle} style={{color:bkColor}} variant="outlined" disableRipple onClick={() => onClose ("Upgrade", "Upgrade")}>
              Upgrade to unlimited access !</Button>
          </Box>  
        </Grid>
        <Box style={{height:"10px"}}/>
      </DialogActions>
    </Dialog>
    <Box style={{height:"10px"}}/>
    </div>
  
  )
}
