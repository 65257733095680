import React from 'react';

import {Grid, Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
// import MoveUpIcon from '@mui/icons-material/MoveUp';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PublishIcon from '@mui/icons-material/Publish';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import StarRateIcon from '@mui/icons-material/StarRate';

const useStyles = makeStyles((mainTheme) => ({
  gridContainerStyle:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '80vh', 
    [mainTheme.breakpoints.down('lg')]: {
      minHeight:"85vh",
    },
    marginTop: "64px",
    padding:"0px",
  },
  paperStyle: {
    // display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // width: 370,
    // width:"100%",
    // height: 440,
    // margin: '5px',
    // backgroundColor:mainTheme.palette.secondary.main,  
    backgroundColor:mainTheme.palette.secondary.main,  
    // padding: mainTheme.spacing(2),
  },
  iconBox:{
    width:'100%',
    textAlign: 'center',
    AlignItems: 'center',
  },
  iconStyle:{
    color:mainTheme.palette.primary.main,
  },
  titleStyle:{
    // color:mainTheme.palette.primary.main,
    color:"black",
    // color:"white"
  },
  textStyle:{
    // color:mainTheme.palette.primary.main,
    color:"black",
    fontSize:"12px",
  },
  buttonStyle:{
    color: "white",
    // width:"135px",
    // color:mainTheme.palette.primary.main,
    textTransform:"none",
    // fontSize: 11,
    margin: "2px",
    backgroundColor:mainTheme.palette.primary.main,
    "&:hover": {
      color:mainTheme.palette.secondary.main,
      backgroundColor:mainTheme.palette.primary.main,
    },
    "&:disabled": {
      color:"gray",
      backgroundColor:mainTheme.palette.primary.main,
    },
  },
  }));

export default function PlanBenefitsPaid(){
  const classes = useStyles();
  return (
    <>
    <Paper elevation={0} className={classes.paperStyle}>
      {/* <Typography align='center' className={classes.textStyle} style={{fontSize:"14px"}}>See what you currently have in your Free Plan:</Typography> */}
      <Grid item container>
        <Grid item container xs={12}>
          <Grid item xs={1}>
            <FindInPageIcon className={classes.iconStyle} />
          </Grid>
          <Grid item xs={11}>
            <Typography variant="caption" className={classes.textStyle}  >See all valuations published by other users</Typography>
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={1}>
            <AddCircleIcon className={classes.iconStyle} />
          </Grid>  
          <Grid item xs={11}>
            <Typography variant="caption" className={classes.textStyle}>Create unlimited valuations</Typography>
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={1}>
            <PublishIcon className={classes.iconStyle} />
          </Grid>
          <Grid item xs={11}>
            <Typography variant="caption" className={classes.textStyle}>Publish your valuations</Typography>
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={1}>
            <PictureAsPdfIcon className={classes.iconStyle} />
          </Grid>
          <Grid item xs={11}>
            <Typography variant="caption" className={classes.textStyle}>Generate unlimited PDF files of your valuations</Typography>
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={1}>
            <PictureAsPdfIcon className={classes.iconStyle}/>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="caption" className={classes.textStyle}>Generate unlimited PDF files of public valuations</Typography>
          </Grid>
        </Grid> 
       <Grid item container xs={12}>
          <Grid item xs={1}>  
            <StarRateIcon className={classes.iconStyle}/>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="caption" className={classes.textStyle}>Rate and comment other users valuations</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
    </>
  )
}