import React, { useContext, useEffect } from 'react';
import ReactGA from 'react-ga4'
// import { useParams } from 'react-router-dom';

import { CircularProgress } from '@mui/material';

// import NewValuation from '../Valuations/NewValuation';
// import SavedValuation from '../Valuations/SavedValuation';

import { LoginContext } from '../../../helpers/Context';
// import useAxios from '../../../hooks/useAxios';
// import valuationsWebApi from '../../../services/valuationsWebApi';

import NewValuation from '../NewValuation';
import SavedValuation from '../SavedValuation';

export default function ValuationSample(){
  const { setSavedValuationData, isValuationSample, savedValuationData, setIsValuationSample } = useContext(LoginContext);
  // const { id } = useParams();  // parameter received from the route
  const id="56696d99"

  useEffect(() => {
    // ReactGA.pageview(window.location.pathname);
    // ReactGA.send({ hitType: "pageview", page: "/my-path", title: "Custom Title" });
    ReactGA.send({ hitType: "pageview", page: "/saved-valuation", title:"valuation sample" });
    setSavedValuationData({valuationId: "56696d99", 
      profileId: "martincsl@hotmail.com", 
      profileFirstName:"Martin", 
      profileLastName:"Calcena Simoes Lopes", 
      symbol: "META", 
      shortName: "Meta Platforms,inc.", 
      createdAt: " 2024-05-08", 
      updatedAt:" 2024-05-08", 
      published:"all", 
      publishedDate:" 2024-05-08"});
      setIsValuationSample(true);
  }, []);

  return (
    <>
    { savedValuationData.valuationId==="56696d99" ? <>
      { id ===undefined || id===null || id===""?
        <NewValuation />
        : 
        <SavedValuation />
      } 
    </>
    : null}
    </>
  ) 
}