import React, { useContext } from 'react';

import { Grid, Paper, Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { LoginContext } from '../../../helpers/Context';

import CompanySelect from './CompanySelect';
import ShowValuationRating from '../../../components/ShowValuationRating';
// import ShowValuationLikes from '../../../components/ShowValuationLikes';
import ShowValuationFavorites from '../../../components/ShowValuationFavorites';
import ShowTotalComments from '../../../components/ShowTotalComents';
import TablePricesSummary from '../TablePricesSummary';
import ShowUserById from '../../../components/ShowUserById';
import NewButtonsPanel from './NewButtonsPanel';

const useStyles = makeStyles( (mainTheme) => ({
TableContainerStyle: {
  width: "100%",
  minHeight: "668px", 
  marginLeft: "3px",
  marginRight: "0px",
  color: "white",
  backgroundColor: "#f0f8ff", 
  // backgroundColor:mainTheme.palette.primary.main,
  padding: "2px",
},
CompanyInfo: {
  // height:"165px",
  marginLeft: "0px",
  marginRight: "0px",
  padding: "10px",
  color: "black",
  backgroundColor: "white",
},
boxSelectStyle:{
  height: "30px",
  width: "60%",
  [mainTheme.breakpoints.down('sm')]: {
    width: "100%"
  },
},
}));

export default function NewInfoPanel ({ 
  companiesList,
  companyIdSearch, 
  setCompanyIdSearch, 
  companySearchName, 
  companyData, 
  assumptions,
  calculatedCostOfCapital,
  forecastedFinancialData,
  valuation,
  // setComment,
  editMode,
  setEditMode,
  isUserCostOfCapital
  }){

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { userData, usersImagesList } = useContext(LoginContext);
  const { userId, userFirstName, userLastName } = userData;
  // const [ companiesList, setCompaniesList ] = useState([]);  // List of companies to populate the Auto complete Search
  // const [ companyIdSearch, setCompanyIdSearch ] = useState(""); 
  // const [ snackbarMessage, setSnackbarMessage] = useState("");

  return (
    <>
    {/* sx={{position:"sticky", top:"70px"}} */}

    <Paper className = {classes.CompanyInfo} >
      {/* main grid */}
      <Grid container direction="row" justifyContent="space-between" >
    
      { companiesList ? <>
        {/* left grid */}
        <Grid item container direction="row" xs={12} sm={6} >
          <Grid item className = {classes.boxSelectStyle} xs={12}  >
            <CompanySelect 
              companiesList = {companiesList} 
              companySearchName = {companySearchName} 
              setCompanyIdSearch = {setCompanyIdSearch}
            />
          </Grid> 
          {/* style={{backgroundColor:"cyan"}} */}
          <Grid item xs={8}   >
            <Box style={{height:"10px"}}/>
            <ShowValuationRating 
              value={0} 
              ratings={0}
              isGrayColor={true}
            />
            <Grid container direction="row" justifyContent="flex-start" >
              {/* <Grid item>
                <ShowValuationLikes 
                  value={0}
                  isGrayColor={true}
                />
              </Grid> */}
              <Grid item>
                <ShowValuationFavorites 
                  value={0}
                  isGrayColor={true}
                />
              </Grid>
              <Grid item>
                <ShowTotalComments 
                  value={0} 
                  isGrayColor={true}
                />
              </Grid>
              <Grid item >
                <TablePricesSummary 
                  companyData={companyData} 
                  valuation={valuation}
                  editMode={editMode}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} sx={{display:"flex", alignItems:"center", justifyContent:"flex-end"}}>
          { isMobile? 
            <>
            <ShowUserById 
              userId={userId} 
              usersImages={usersImagesList}
              firstName={userFirstName} 
              lastName={userLastName} 
              textPosition={"below"} 
              size={"medium"} 
              isCreatedByText={true}
              isCountryFlag={true}
            />
            </>
          : null}

          </Grid>
        </Grid>

      </> : null}
      <Grid item direction="column" xs={12} sm={6} >
        <Box style={{display:"flex", justifyContent:"flex-end", marginRight:"10px"}}>
          {/* <Typography sx={{fontSize:8}}>{editMode? editMode: "sem edit mode"}</Typography> */}
          { ! isMobile? 
            <ShowUserById 
              userId={userId} 
              usersImages={usersImagesList}
              firstName={userFirstName} 
              lastName={userLastName} 
              textPosition={"below"} 
              size={"medium"} 
              isCreatedByText={true}
              isCountryFlag={true}
            />
          : null}
        </Box>
        { ! isMobile ? <Box sx={{height:"20px"}}/> : null}
        <Box style={{display:"flex", alignItems: "center",justifyContent:"flex-end", marginRight:"0px"}}>
          { companyData ? <>
          <NewButtonsPanel
            companyData={companyData}
            assumptions={assumptions} 
            calculatedCostOfCapital={calculatedCostOfCapital} 
            forecastedFinancialData={forecastedFinancialData} 
            valuation={valuation}
            editMode={editMode}
            setEditMode={setEditMode}
            isUserCostOfCapital={isUserCostOfCapital}
          />
        </> : null}
        </Box>  
      </Grid>
    </Grid>
    </Paper>
    </>
  )  
}