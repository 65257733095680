import React, {useContext} from 'react';
// import { format, parse } from 'date-fns';

import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { LoginContext } from '../../../helpers/Context';

const useStyles = makeStyles( (mainTheme) => ({

  companyNameText:{
    paddingLeft:"5px",
    
    fontSize: "11px",
    [mainTheme.breakpoints.down('sm')]: {
      fontSize: "11px"
    },
    color:mainTheme.palette.tertiary.main
  },
  dateText:{
    width:"200px",
    paddingLeft:"5px",
    fontSize: "10px",
    // [mainTheme.breakpoints.down('sm')]: {
    //   fontSize: "20px"
    // },
    color:mainTheme.palette.tertiary.main
  },
  }));

export default function CompanyText (){
  const classes = useStyles()
  const { savedValuationData} = useContext(LoginContext);
  const { valuationId, symbol, shortName, createdAt, publishedDate } = savedValuationData
  const shortStringCreatedAt = createdAt.substring(0, 10);
  // const shortStringPublishedDate= publishedDate? publishedDate.substring(0, 11): null;

  function valuationDatesText(){
    // if (createdAt ==="" || createdAt===null){
    //   return ""
    // }
    // if (publishedDate !=="" && publishedDate !==null ){
    //   if (shortStringCreatedAt === shortStringPublishedDate){
    //     return `Created and published on ${shortStringCreatedAt}`
    //   }
    //   else {
    //     return `Created on ${shortStringCreatedAt} and published on ${shortStringPublishedDate}`
    //   }
    // } else{
      return `Created on ${shortStringCreatedAt}`
    // }
  }

  return (
    <>
    { symbol ? 
      <>
      {/* <Box style={{backgroundColor:"blue"}}> */}
        <Typography align="left" className={classes.companyNameText} noWrap>{`${symbol} - ${shortName} `}</Typography>
        { valuationId !== ""  ? <>
          <Typography align="left" className={classes.dateText} >{valuationDatesText()}</Typography>
          </>
          : null
        }
      {/* </Box> */}
      </>
  
    : null }
    </>
  )
}