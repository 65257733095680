import React from 'react';

import { Paper, Grid, Tooltip, TextField, InputAdornment,Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import useValuationForm from '../../../hooks/useValuationForm';

const useStyles = makeStyles((mainTheme) => ({
  label: {
    fontSize: '14px', 
  },
}));

export default function FormAssumptionsSummaryText ({assumptions, setAssumptions}){
  const classes= useStyles();
  const { formErrors, handleChange, noBlanks } = useValuationForm({ assumptions, setAssumptions })
  return (
    <>
    <TextField variant ="outlined" margin="dense" size="small" fullWidth
      label="Assumptions Summary Text (* optional)"
      InputLabelProps={{
        classes: {
          root: classes.label,
        },
      }}
      name="assumptionsText"
      value={assumptions.assumptionsText}
      autoComplete="assumptions"
      multiline
      // rows={5}
      // maxRows={10}
      inputProps={{ maxLength: 900 }}
      autoFocus
      placeholder="Describe your assumptions fot this valuation (* optional)"
      onChange={(e) => {handleChange (e,[])}}
      error={formErrors.assumptionsText}
    />
    {formErrors.assumptionsText ? <div className="error-helper-text">{formErrors.assumptionsText}</div> : null}
    </>
  )
}
