import React from 'react';

import { Tooltip, Box, Typography, Button} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
// import countryFlag from  '../assets/flags/br.svg';
import ShowUserImageByUserId from './ShowUserImageByUserId';

const useStyles = makeStyles((mainTheme) => ({
  boxStyle:{
    display:"flex", 
    alignItems:"center", 
    backgroundColor:"white"
  },
  textStyle:{
    fontSize:"11px", 
    marginLeft:"2px",
    marginTop:"2px",
    color:mainTheme.palette.tertiary.main,
  }
})); 

const ShowUserById = ({ userId, firstName, lastName, usersImages, textPosition, size, isCreatedByText, isCountryFlag }) => {
  const classes = useStyles();
  const flexDirectionOption=textPosition==="below"? "column": "row";
  const boxWidth = textPosition==="below"? "90": "120";

  const getFirstAndLastName= (fullName) => {
    const nameParts = fullName.split(' ');
    const firstName = nameParts[0];
    const lastName = nameParts[nameParts.length - 1];
    return `${firstName} ${lastName}`;
  } 

  const getFontSize = (size) => {
    if (size="small"){
      return "11px"
    }
    return "11px"
  }

  return (
    <>

    {/* <Tooltip title={`Go to ${firstName}'s profile`}> */}
      <Box className={classes.boxStyle}  style={{ width:boxWidth, flexDirection:flexDirectionOption}}>
        { isCreatedByText? <Typography align = "center" sx={{fontSize:getFontSize(size)}} className={classes.textStyle} >Crafted By</Typography> : null}
      
          <ShowUserImageByUserId userId={userId} usersImages={usersImages} size={size} />
      
        <Typography align = "center" sx={{fontSize:"11px"}} className={classes.textStyle} >{getFirstAndLastName(`${firstName} ${lastName}`)}</Typography>
      </Box>
    {/* </Tooltip> */}

    </>
  );
};

export default ShowUserById